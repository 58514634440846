import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useButton1 } from "@/scripts/ui/buttons/Button1";
import { useButton2 } from "@/scripts/ui/buttons/Button2";
import { useButton3 } from "@/scripts/ui/buttons/Button3";
import { useButton4 } from "@/scripts/ui/buttons/Button4";
import { useButton5 } from "@/scripts/ui/buttons/Button5";
import { useDivider1 } from "@/scripts/ui/dividers/Divider1";
import { useContainer } from "@/scripts/ui/containers/Container";
import { useFlexContainer1 } from "@/scripts/ui/flex_containers/FlexContainer1";
import { useHeader1 } from "@/scripts/ui/headers/Header1";


export function useUIComponentMap() {
 
  const componentMap = {};
  componentMap.ButtonRadioGroup = useButtonRadioGroup;
  componentMap.Accordion1 = useAccordion1;
  componentMap.Accordion2 = useAccordion2;
  componentMap.Button1 = useButton1;
  componentMap.Button2 = useButton2;
  componentMap.Button3 = useButton3;
  componentMap.Button4 = useButton4;
  componentMap.Button5 = useButton5;  
  componentMap.Divider1 = useDivider1;  
  componentMap.Container = useContainer;
  componentMap.FlexContainer1 = useFlexContainer1;
  componentMap.Header1 = useHeader1;

 

  return componentMap;
}
