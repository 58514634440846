<template>

    <div class="reportfilterpresetmanagement w-100 mx-auto" :class="applicationStore.getPadding">
        <div class="content-bg-11" :class="{
            'pa-4': !applicationStore.grid.tabletAndUnder,
            'elevation-2': !applicationStore.grid.tabletAndUnder,
            'rounded-2': !applicationStore.grid.tabletAndUnder
        }">
            <div class="pa-4 pt-1 fs-3 text-color-6 text-align-left fw-bold">Report Filter Presets</div>

            <div class="mt-min pa-4 d-flex flex-column" style="background-color: var(--content-bg-13)">
                <div class="icon-color-1 text-align-left" style="font-weight: 700; font-size: 16px">Filters Preview
                </div>


                <div class="mt-2 mb-4 w-100 d-grid" style="
                            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
                            gap: 10px;
                          ">
                    <div v-for="presetgroup in dataPresetsReportFiltersStore.getPresetsGroups"
                        :key="'preset_preview' + presetgroup.id" style="margin-bottom: 0 !important"
                        class="mb-0 fs-9 text-align-left text-color-1">
                        <div class="fs-7 mb-1 text-align-left icon-color-1">{{ presetgroup.name }}</div>
                        <Dropdown style="width: 250px; max-width: 250px"
                            @update:modelValue="dataPresetsReportFiltersStore.setPreset"
                            v-model="dataPresetsReportFiltersStore.selectedPreset"
                            :options="dataPresetsReportFiltersStore.getPresetsWithGroup(presetgroup.id)"
                            optionLabel="name" placeholder="Select a Preset" class="" />

                    </div>
                </div>


                <div class="mt-2 mb-4 w-100 d-grid" style="
                            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
                            gap: 10px;
                          ">
                    <div v-for="presetwithoutgroup in dataPresetsReportFiltersStore.getPresetsWithoutGroup"
                        :key="'preset_preview' + presetwithoutgroup.id" style="margin-bottom: 0 !important"
                        class="mb-0 fs-9 text-align-left text-color-1">
                        <div class="fs-7 text-align-left icon-color-1 pa-2"
                            style="background-color:#333;cursor:pointer"
                            @click="dataPresetsReportFiltersStore.setPreset(presetwithoutgroup)">{{
                                presetwithoutgroup.name }}</div>

                    </div>
                </div>


            </div>

            <div class="fs-5 mt-2 text-align-left fw-bold text-color-1">
                Filter Groups :
            </div>

            <div v-if="!dataPresetsReportFiltersStore.getPresetsGroups.length" class="content-border-b-1 mb-1 pa-2"
                style="background-color:#616161;position: relative">

                <div class="text-align-left icon-color-1 fs-7 me-auto"
                    style="word-break: break-all;flex:1 1 0;min-width: 100px; pointer-events: none">
                    No Preset Groups Found
                </div>
            </div>

            <div class="reportfilterpresetmanagementgrouplist">

                <div class="content-border-b-1 content-bg-2 mb-1" style="position: relative"
                    v-for="(presetgroup, index) in dataPresetsReportFiltersStore.getPresetsGroups"
                    :key="`preset_groups_${uniqueKey}_${index}`">

                    <div class="pa-2 d-flex flex-wrap align-items-center gap-2">


                        <b-form-input class="text-color-dark" style="max-width: 150px" v-model="presetgroup.name"
                            @focus="dataPresetsReportFiltersStore.cacheInputGroupNameValue(presetgroup)"
                            @blur="dataPresetsReportFiltersStore.validateInputGroupNameValue(presetgroup)"
                            @keydown.enter="dataPresetsReportFiltersStore.validateInputGroupNameValue(presetgroup)"
                            trim></b-form-input>

                        <!--<div class="text-align-left icon-color-1 fs-7 me-auto"
                        style="word-break: break-all;flex:1 1 0;min-width: 100px; pointer-events: none">
                        {{ presetgroup.name }}
                    </div>-->
                        <div class="d-flex flex-wrap align-items-center">
                            <button class=" p-0 button-minimal" style="z-index: 1"
                                @click="dataPresetsReportFiltersStore.deletePresetGroup(presetgroup)">
                                <span class="mdi icon icon-color-1 mdi-delete"></span>
                            </button>
                        </div>
                    </div>
                </div>


            </div>



            <div class="mt-2 text-align-left text-color-2 fw-bold fs-7">Add Preset Group:</div>

            <div class="mb-4 gap-2 d-flex align-items-center">
                <b-form-input class="text-color-1" :id="`new_preset_group_input_${uniqueKey}`"
                    v-model="dataPresetsReportFiltersStore.newPresetGroupName" trim></b-form-input>

                <b-button style="width: 100%; max-width: 280px" class="p-1 fs-6 fw-bolder px-3" variant="primary"
                    @click="dataPresetsReportFiltersStore.addGroup()">Add Preset Group</b-button>
            </div>



            <div class="fs-5 mt-4 text-align-left fw-bold text-color-1">
                Filters :
            </div>


            <div v-if="!dataPresetsReportFiltersStore.getPresets.length" class="content-border-b-1 mb-1 pa-2"
                style="background-color:#616161;position: relative">

                <div class="text-align-left icon-color-1 fs-7 me-auto"
                    style="word-break: break-all;flex:1 1 0;min-width: 100px; pointer-events: none">
                    No Presets Found
                </div>
            </div>

            <div class="reportfilterpresetmanagementfilterlist">

                <div class="content-border-b-1 content-bg-2 mb-1" style="position: relative"
                    v-for="(preset, index) in dataPresetsReportFiltersStore.getPresets"
                    :key="`presets_${uniqueKey}_${index}`">
                    <div style="width: 100%; height: 100%; position: absolute; cursor: pointer"
                        @click="dataPresetsReportFiltersStore.setPreset(preset)"></div>
                    <div class="pa-2 d-flex flex-wrap align-items-center gap-2">
                        <b-form-input class="text-color-dark" style="z-index:1;max-width:  150px" v-model="preset.name"
                            @focus="dataPresetsReportFiltersStore.cacheInputPresetNameValue(preset)"
                            @blur="dataPresetsReportFiltersStore.validateInputPresetNameValue(preset)"
                            @keydown.enter="dataPresetsReportFiltersStore.validateInputPresetNameValue(preset)"
                            trim></b-form-input>


                        <!--<div class="text-align-left icon-color-1 fs-7 me-auto"
                            style="word-break: break-all;flex:1 1 0;min-width: 100px; pointer-events: none">
                            {{ preset.name }}
                        </div> -->
                        <Dropdown style="width: 250px; max-width: 250px"
                            @update:modelValue="dataPresetsReportFiltersStore.updatePresetFiltersGroup($event, preset)"
                            v-model="preset.selectedGroup" :options="dataPresetsReportFiltersStore.getPresetsGroups"
                            showClear optionLabel="name" placeholder="Select a Group" class="" />
                        <div class="d-flex flex-wrap align-items-center">
                            <button class=" p-0 button-minimal" style="z-index: 1"
                                @click="dataPresetsReportFiltersStore.deletePreset(preset)">
                                <span class="mdi icon icon-color-1 mdi-delete"></span>
                            </button>
                        </div>
                    </div>
                </div>


            </div>



            <div class="mt-2 text-align-left text-color-2 fw-bold fs-7">Add Preset:</div>

            <div class="mb-4 gap-2 d-flex align-items-center">
                <b-form-input class="text-color-1" :id="`new_preset_input_${uniqueKey}`"
                    v-model="dataPresetsReportFiltersStore.newPresetName" trim></b-form-input>

                <b-button style="width: 100%; max-width: 280px" class="p-1 fs-6 fw-bolder px-3" variant="primary"
                    @click="dataPresetsReportFiltersStore.addPreset()">Add Preset</b-button>
            </div>

            <div class="mt-4">
                <InclusionExclusionDimensions />
            </div>








        </div>
    </div>


</template>

<script setup>
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import {  onMounted } from 'vue'
import { useDataPresetsReportFiltersStore } from '@/stores/DataPresetsReportFiltersStore.js'
import InclusionExclusionDimensions from '@/components/charts/InclusionExclusionDimensions.vue'
import { useUniqueKey } from '@/scripts/utils/UniqueKey.js'
const applicationStore = useApplicationStore()
const { uniqueKey } = useUniqueKey()
const dataPresetsReportFiltersStore = useDataPresetsReportFiltersStore()


onMounted(() =>{
    dataPresetsReportFiltersStore.resetPresetToDefaultEmpty()
})


</script>


<style>
.reportfilterpresetmanagementfilterlist .form-control {
    background-color: #ffffff !important;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 4px !important;
    color: #222 !important;
}

.reportfilterpresetmanagementgrouplist .form-control {
    background-color: #ffffff !important;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 4px !important;
    color: #222 !important;
}

.reportfilterpresetmanagementfilterlist .p-inputtext {
    padding: 2px !important;
    color: #ffffff;
}

.reportfilterpresetmanagementfilterlist .p-dropdown {
    background: #48aad6;
    border: 1px solid #4369af;

}

.reportfilterpresetmanagementfilterlist .p-dropdown .p-dropdown-clear-icon {
    color: #ffffff;

}

.reportfilterpresetmanagementfilterlist .p-dropdown .p-dropdown-trigger {

    color: #ffffff;

}
</style>
