import { defineStore } from 'pinia'
import { useURLS } from '@/stores/URLS.js'
import { useReportCore } from '@/stores/ReportCore.js'
import { useRequests } from '@/stores/Requests.js'
import { markRaw } from 'vue'
import { useAuth } from '@/stores/Auth.js'
import SimpleDate from '../scripts/utils/SimpleDate'
import { useConvertObjectToSortedArray } from '@/scripts/utils/ConvertObjectToSortedArray.js'
export const useDataKPI1Store = defineStore('DataKPI1Store', {
  state: () => {
    return {
      unmappedKPIOwnersCORE: markRaw({}),
      unmappedKPIOwnersSM: markRaw({}),
      unmappedKPILeadGroupsCORE: markRaw({}),
      unmappedKPILeadGroupsSM: markRaw({}),

      unmappedKPIOwnersArrayCORE: [],
      unmappedKPIOwnersArraySM: [],
      unmappedKPILeadGroupsArrayCORE: [],
      unmappedKPILeadGroupsArraySM: [],

      reportCore: markRaw(useReportCore()),
      requests: markRaw(useRequests()),
      queryCacheDateCORE: null,
      queryCacheDateSM: null,

      requestModelNameToIndex: markRaw({}),
      requestModelIndexToName: markRaw([]),

      processedDataCORE: markRaw({}),
      processedDataSM: markRaw({}),
      processedDataCORELoaded: {},
      processedDataSMLoaded: {}
    }
  },

  actions: {
   

    //---------------------------------------------------------------------------------------------

    processLoadedData(result, key, year) {
     
      if (key === 'kpi1') {
        this.queryCacheDateCORE = 'unknown'
        this.processedDataCORELoaded[year] = true
        if (result.queryCacheDate) {
          this.queryCacheDateCORE = SimpleDate.getDateTimeStringFromJSDateObject(
            new Date(result.queryCacheDate)
          )
        }
      }
      if (key === 'kpi1SM') {
        this.queryCacheDateSM = 'unknown'
        this.processedDataSMLoaded[year] = true
        if (result.queryCacheDate) {
          this.queryCacheDateSM = SimpleDate.getDateTimeStringFromJSDateObject(
            new Date(result.queryCacheDate)
          )
        }
      }

      this.requestModelNameToIndex = result.keyMap

      this.requestModelIndexToName = []
      for (let prop in result.keyMap) {
        this.requestModelIndexToName[result.keyMap[prop]] = prop
      }

      this.indexDate = this.requestModelNameToIndex['DATE']
      this.indexOwner = this.requestModelNameToIndex['OWNER']
      this.indexLocation = this.requestModelNameToIndex['LOCATION']
      this.indexLeadGroup = this.requestModelNameToIndex['LEAD_GROUP']
      this.indexAppointmentCount = this.requestModelNameToIndex['APPOINTMENTS_COUNT']
      this.indexIssuedAppointmentCount = this.requestModelNameToIndex['ISSUED_APPOINTMENTS_COUNT']
      this.indexQuotesCount = this.requestModelNameToIndex['QUOTES_COUNT']
      this.indexSalesCount = this.requestModelNameToIndex['SALES_COUNT']
      this.indexLeadsCount = this.requestModelNameToIndex['LEADS_COUNT']
      this.indexSalesSum = this.requestModelNameToIndex['SALES_SUM']

      this.ownerFilter = null
      this.leadGroupFilter = null
      this.locationFilter = null

      // this.reportCore.fixDates(result.data, this.requestModelNameToIndex)
      // this.buildOptimizedData(result.data, key, year)
      this.buildOptimizedData(result, key, year)
    },

    //---------------------------------------------------------------------------------------------
    async loadDataAll() {
      let key = null
      if(this.reportCore.refreshKPIAgain){

        this.unmappedKPIOwnersCORE =  markRaw({});
        this.unmappedKPIOwnersSM=  markRaw({});
        this.unmappedKPILeadGroupsCORE=  markRaw({});
        this.unmappedKPILeadGroupsSM=  markRaw({});

      }

      let refreshKPIAgainTemp = this.reportCore.refreshKPIAgain

      key = 'kpi1'
      let asyncProcessSuccessful = false
      asyncProcessSuccessful = await this.loadData(key)
      if (!asyncProcessSuccessful) return

      //refreshKPIAgain gets reset so reset it again for both loads to be affected by its purpose
      this.reportCore.refreshKPIAgain = refreshKPIAgainTemp

      key = 'kpi1SM'
      asyncProcessSuccessful = false
      asyncProcessSuccessful = await this.loadData(key)
      if (!asyncProcessSuccessful) return

      return true
    },

    validateDataIsLoaded() {
      //if refreshKPI is set , then loading data again is manditory
      if (this.reportCore.refreshKPIAgain) {
        return false
      }
      // check if the year range contains a year not yet loaded into the app
      //default to true , so if a year is found missing in the data , it is set to false
      let skipLoading = true
      let yearMin = this.reportCore.dateRangeStart.year
      let yearMax = this.reportCore.dateRangeEnd.year

      // if min , max are equal , reduce min
      //since for timeline yoy calculations two years data is always required
      if (yearMin === yearMax) {
        yearMin--
      }
      let yearValidate = yearMin
      let whileTrue = true
      while (whileTrue) {
        if (!this.processedDataCORELoaded[yearValidate]) {
          skipLoading = false
        }
        //---------------
        if (!this.processedDataSMLoaded[yearValidate]) {
          skipLoading = false
        }
        yearValidate++
        if (yearValidate > yearMax || !skipLoading) {
          whileTrue = false
        }
      }
      return skipLoading
    },

    async loadData(key) {
      //get current range year min and max
      //if min and max are the same , then get year below
      //because always loading two years of data
      //check to see if that data for those years is not already loaded
      let yearMin = this.reportCore.dateRangeStart.year
      let yearMax = this.reportCore.dateRangeEnd.year
      if (yearMin === yearMax) {
        yearMin--
      }
      let yearValidate = yearMin
      //validate if years are already loaded , unless refresh is needed
      if (!this.reportCore.refreshKPIAgain) {
        let skipLoading = true
        let whileTrue = true
        while (whileTrue) {
          if (key === 'kpi1') {
            if (!this.processedDataCORELoaded[yearValidate]) {
              // console.log('skipLoading is getting set to false .. processedDataCORELoaded is')
              // console.log(this.processedDataCORELoaded)
              skipLoading = false
            }
          }
          //---------------
          if (key === 'kpi1SM') {
            if (!this.processedDataSMLoaded[yearValidate]) {
              // console.log('skipLoading is getting set to false .. processedDataSMLoaded is')
              // console.log(this.processedDataSMLoaded)
              skipLoading = false
            }
          }
          yearValidate++
          if (yearValidate > yearMax || !skipLoading) {
            whileTrue = false
          }
        }
        if (skipLoading) {
          return true
        }
      }

      this.reportCore.setLoadHeavy(true, 'KPI Data')
      const urls = useURLS()
      const auth = useAuth()

      var data = { key }
      data.years = []
      let whileTrue = true
      yearValidate = yearMin
      while (whileTrue) {
        if (key === 'kpi1') {
          if (!this.reportCore.refreshKPIAgain) {
            if (!this.processedDataCORELoaded[yearValidate]) {
              data.years.push(yearValidate)
            }
          } else {
            data.years.push(yearValidate)
          }
        }
        if (key === 'kpi1SM') {
          if (!this.reportCore.refreshKPIAgain) {
            if (!this.processedDataSMLoaded[yearValidate]) {
              data.years.push(yearValidate)
            }
          } else {
            data.years.push(yearValidate)
          }
        }
        yearValidate++
        if (yearValidate > yearMax) {
          whileTrue = false
        }
      }

      
     


      this.reportCore.refreshKPIAgain = false

      //console.log(data.years)
      var urlToUse = urls.getURL('kpi1_report', 'kpi1')
     
      try {
        let result = await this.requests.post({
          url: urlToUse,
          data
        })

        result = result?.data

        if (result) {
          for (let year in result.years) {
            this.processLoadedData(result.years[year], key, year)
          }
          this.reportCore.setLoadHeavy(false)
          return true
        }
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return false
      }
    },

    buildOptimizedData(result, key, year) {
      let data = result.data

      //let dataRef = null
      let dataRef2 = null

      if (key === 'kpi1') {
        // dataRef = this.dataCORE = markRaw({})
        dataRef2 = this.processedDataCORE

        this.unmappedKPIOwnersArrayCORE = []
        this.unmappedKPILeadGroupsArrayCORE = []
      }
      if (key === 'kpi1SM') {
        //  dataRef = this.dataSM = markRaw({})
        dataRef2 = this.processedDataSM

        this.unmappedKPIOwnersArraySM = []
        this.unmappedKPILeadGroupsArraySM = []
      }

      //loop structure of owners , and clear the year being passed in if it exists
      //this is because refreshing cache will rebuild this structure and values will be additive if not cleared
      for (let ownerKey in dataRef2) {
        let ownerTemp = dataRef2[ownerKey]
        if (ownerTemp.years) {
          if (ownerTemp.years[year]) {
            delete ownerTemp.years[year]
          }
        }
      }

      let i = data.length
      while (i--) {
        let element = data[i]

        //first just set it to the value
        let dateValue = element[this.indexDate]
        //now check if a value map exist
        let valueMapKey = this.requestModelIndexToName[this.indexDate]
        let columnValueMap = result.columnValueMaps[valueMapKey]
        if (columnValueMap) {
          //if it exists pull the actual value from the value map , since the value is an index pointing to the value
          dateValue = columnValueMap[dateValue]
        }

        let sd = new SimpleDate()
        sd.getValuesFromDateString(dateValue)
        dateValue = sd

        //first just set it to the value
        let ownerValue = element[this.indexOwner]
        //now check if a value map exist
        valueMapKey = this.requestModelIndexToName[this.indexOwner]
        columnValueMap = result.columnValueMaps[valueMapKey]
        if (columnValueMap) {
          //if it exists pull the actual value from the value map , since the value is an index pointing to the value
          ownerValue = columnValueMap[ownerValue]
        }

        if (ownerValue === null || ownerValue === undefined) {
          continue
        }

        ownerValue = ownerValue.trim()

        if (ownerValue === 'Deploy' || ownerValue === '') {
          continue
        }
        if (ownerValue.toLowerCase().indexOf('sandbox') !== -1) {
          continue
        }

        //owner------------------------------------------------------------------

        let systemOwner = this.reportCore.dataOwners[ownerValue]

        if (ownerValue && systemOwner) {
          if (systemOwner.exclude_from_reports) {
            continue
          }
          ownerValue = systemOwner.name
        }

        //location----------------------------------------------------------------

        //first just set it to the value
        let locationValue = element[this.indexLocation]
        //now check if a value map exist
        valueMapKey = this.requestModelIndexToName[this.indexLocation]
        columnValueMap = result.columnValueMaps[valueMapKey]
        if (columnValueMap) {
          //if it exists pull the actual value from the value map , since the value is an index pointing to the value
          locationValue = columnValueMap[locationValue]
        }

        if (locationValue) {
          locationValue = locationValue.trim()
        }
        let systemLocation = this.reportCore.dataLocations[locationValue]

        //leadGroupValue----------------------------------------------------------

        //first just set it to the value
        let leadGroupValue = element[this.indexLeadGroup]
        //now check if a value map exist
        valueMapKey = this.requestModelIndexToName[this.indexLeadGroup]
        columnValueMap = result.columnValueMaps[valueMapKey]
        if (columnValueMap) {
          //if it exists pull the actual value from the value map , since the value is an index pointing to the value
          leadGroupValue = columnValueMap[leadGroupValue]
        }

        if (
          leadGroupValue === null ||
          leadGroupValue === undefined ||
          leadGroupValue.trim() === 'null' ||
          leadGroupValue.trim() === ''
        ) {
          if (ownerValue) {
            leadGroupValue = `${ownerValue} NO Leadgroup`
          } else {
            // console.log(element)// need to log these to a debug table since they are getting thrown out
            continue
          }
        }
        if (leadGroupValue) {
          leadGroupValue = leadGroupValue.trim()
        }

        //leadGroupValue----------------------------------------------------------

        let systemLeadGroup = this.reportCore.dataLeadGroups[leadGroupValue]

        if (leadGroupValue && systemLeadGroup) {
          leadGroupValue = systemLeadGroup.name
        }

        //---------------------------------------------------

        //write back onto object incase it had whitespace issues
        //!! not needed .. i dont ever use the loaded data , this function creates the data i use
       // element[this.indexOwner] = ownerValue
       // element[this.indexLocation] = locationValue
       // element[this.indexLeadGroup] = leadGroupValue

        if (systemOwner) {
          ownerValue = systemOwner.name
        } else {
          if (key === 'kpi1') {
            let unmappedItem = this.unmappedKPIOwnersCORE[ownerValue]

            if (!unmappedItem) {
              unmappedItem = this.unmappedKPIOwnersCORE[ownerValue] = {}
              unmappedItem.name = ownerValue
              unmappedItem.props = {}

              unmappedItem.props.dataRangeStart = {}
              unmappedItem.props.dataRangeStart.label = 'data range start'
              unmappedItem.props.dataRangeStart.value = dateValue

              unmappedItem.props.dataRangeEnd = {}
              unmappedItem.props.dataRangeEnd.label = 'data range end'
              unmappedItem.props.dataRangeEnd.value = dateValue

              unmappedItem.props.sales = {}
              unmappedItem.props.sales.label = 'sales'
              unmappedItem.props.sales.value = 0
            } else {
              if (dateValue < unmappedItem.props.dataRangeStart.value) {
                unmappedItem.props.dataRangeStart.value = dateValue
              }
              if (dateValue > unmappedItem.props.dataRangeEnd.value) {
                unmappedItem.props.dataRangeEnd.value = dateValue
              }

              // unmappedItem.props.sales.value += salesSum || 0
            }
          }
          if (key === 'kpi1SM') {
            let unmappedItem = this.unmappedKPIOwnersSM[ownerValue]
            if (!unmappedItem) {
              unmappedItem = this.unmappedKPIOwnersSM[ownerValue] = {}
              unmappedItem.name = ownerValue
              unmappedItem.props = {}
              unmappedItem.props.dataRangeStart = {}
              unmappedItem.props.dataRangeStart.label = 'data range start'
              unmappedItem.props.dataRangeStart.value = dateValue

              unmappedItem.props.dataRangeEnd = {}
              unmappedItem.props.dataRangeEnd.label = 'data range end'
              unmappedItem.props.dataRangeEnd.value = dateValue

              unmappedItem.props.sales = {}
              unmappedItem.props.sales.label = 'sales'
              unmappedItem.props.sales.value = 0
            } else {
              if (dateValue < unmappedItem.props.dataRangeStart.value) {
                unmappedItem.props.dataRangeStart.value = dateValue
              }
              if (dateValue > unmappedItem.props.dataRangeEnd.value) {
                unmappedItem.props.dataRangeEnd.value = dateValue
              }
              //  unmappedItem.props.sales.value += salesSum || 0
            }
          }
        }
        if (systemLeadGroup) {
          leadGroupValue = systemLeadGroup.name
        } else {
          if (key === 'kpi1') {
            let unmappedItem = this.unmappedKPILeadGroupsCORE[leadGroupValue]
            if (!unmappedItem) {
              unmappedItem = this.unmappedKPILeadGroupsCORE[leadGroupValue] = {}
              unmappedItem.name = leadGroupValue
              unmappedItem.props = {}
              unmappedItem.props.dataRangeStart = {}
              unmappedItem.props.dataRangeStart.label = 'data range start'
              unmappedItem.props.dataRangeStart.value = dateValue

              unmappedItem.props.dataRangeEnd = {}
              unmappedItem.props.dataRangeEnd.label = 'data range end'
              unmappedItem.props.dataRangeEnd.value = dateValue

              unmappedItem.props.sales = {}
              unmappedItem.props.sales.label = 'sales'
              unmappedItem.props.sales.value = 0
            } else {
              if (dateValue < unmappedItem.props.dataRangeStart.value) {
                unmappedItem.props.dataRangeStart.value = dateValue
              }
              if (dateValue > unmappedItem.props.dataRangeEnd.value) {
                unmappedItem.props.dataRangeEnd.value = dateValue
              }
              // unmappedItem.props.sales.value += salesSum || 0
            }
          }
          if (key === 'kpi1SM') {
            let unmappedItem = this.unmappedKPILeadGroupsSM[leadGroupValue]
            if (!unmappedItem) {
              unmappedItem = this.unmappedKPILeadGroupsSM[leadGroupValue] = {}
              unmappedItem.name = leadGroupValue
              unmappedItem.props = {}
              unmappedItem.props.dataRangeStart = {}
              unmappedItem.props.dataRangeStart.label = 'data range start'
              unmappedItem.props.dataRangeStart.value = dateValue

              unmappedItem.props.dataRangeEnd = {}
              unmappedItem.props.dataRangeEnd.label = 'data range end'
              unmappedItem.props.dataRangeEnd.value = dateValue

              unmappedItem.props.sales = {}
              unmappedItem.props.sales.label = 'sales'
              unmappedItem.props.sales.value = 0
            } else {
              if (dateValue < unmappedItem.props.dataRangeStart.value) {
                unmappedItem.props.dataRangeStart.value = dateValue
              }
              if (dateValue > unmappedItem.props.dataRangeEnd.value) {
                unmappedItem.props.dataRangeEnd.value = dateValue
              }

              // unmappedItem.props.sales.value += salesSum || 0
            }
          }
        }

        let ownerObj = dataRef2[ownerValue]
        if (!ownerObj) {
          ownerObj = dataRef2[ownerValue] = {}
          ownerObj.years = {}
          ownerObj.name = ownerValue
          ownerObj.isMapped = !!systemOwner

          //  ownerObj.systemOwner = this.reportCore.dataOwners[ownerValue]
          // ownerObj.locations = {}
          //  ownerObj.leadGroups = {}
        }

        let yearObj = ownerObj.years[dateValue.year]
        if (!yearObj) {
          yearObj = ownerObj.years[dateValue.year] = {}
          yearObj.months = {}
        }

        let monthObj = yearObj.months[dateValue.month]
        if (!monthObj) {
          monthObj = yearObj.months[dateValue.month] = {}
          monthObj.days = {}
        }

        let dayObj = monthObj.days[dateValue.day]
        if (!dayObj) {
          dayObj = monthObj.days[dateValue.day] = {}
          dayObj.locations = {}
          dayObj.leadGroups = {}
          // dayObj.kpiData = {}
        }

        let locationObj = dayObj.locations[locationValue]
        if (!locationObj) {
          locationObj = dayObj.locations[locationValue] = {}
          // locationObj.systemLocation = this.reportCore.dataLocations[locationValue]
          locationObj.leadGroups = {}
        }

        let dimensionObjs = [dayObj.leadGroups, locationObj.leadGroups]
        for (let j = 0; j < dimensionObjs.length; j++) {
          let dimension = dimensionObjs[j]

          let leadGroupObj = dimension[leadGroupValue]
          if (!leadGroupObj) {
            leadGroupObj = dimension[leadGroupValue] = {}
            // leadGroupObj.systemLeadGroup = this.reportCore.dataLeadGroups[leadGroupValue]
            leadGroupObj.kpiData = {}
          }

          leadGroupObj.kpiData['APPOINTMENTS_COUNT'] =
            leadGroupObj.kpiData['APPOINTMENTS_COUNT'] || 0
          leadGroupObj.kpiData['APPOINTMENTS_COUNT'] += element[this.indexAppointmentCount] || 0

          leadGroupObj.kpiData['ISSUED_APPOINTMENTS_COUNT'] =
            leadGroupObj.kpiData['ISSUED_APPOINTMENTS_COUNT'] || 0
          leadGroupObj.kpiData['ISSUED_APPOINTMENTS_COUNT'] +=
            element[this.indexIssuedAppointmentCount] || 0

          leadGroupObj.kpiData['QUOTES_COUNT'] = leadGroupObj.kpiData['QUOTES_COUNT'] || 0
          leadGroupObj.kpiData['QUOTES_COUNT'] += element[this.indexQuotesCount] || 0

          leadGroupObj.kpiData['SALES_COUNT'] = leadGroupObj.kpiData['SALES_COUNT'] || 0
          leadGroupObj.kpiData['SALES_COUNT'] += element[this.indexSalesCount] || 0

          leadGroupObj.kpiData['LEADS_COUNT'] = leadGroupObj.kpiData['LEADS_COUNT'] || 0
          leadGroupObj.kpiData['LEADS_COUNT'] += element[this.indexLeadsCount] || 0

          leadGroupObj.kpiData['SALES_SUM'] = leadGroupObj.kpiData['SALES_SUM'] || 0
          leadGroupObj.kpiData['SALES_SUM'] += element[this.indexSalesSum] || 0
        }
      }

      //console.log(dataRef2)

      if (key === 'kpi1') {
        this.unmappedKPIOwnersArrayCORE = useConvertObjectToSortedArray(this.unmappedKPIOwnersCORE)
        this.unmappedKPILeadGroupsArrayCORE = useConvertObjectToSortedArray(
          this.unmappedKPILeadGroupsCORE
        )
      }
      if (key === 'kpi1SM') {
        this.unmappedKPIOwnersArraySM = useConvertObjectToSortedArray(this.unmappedKPIOwnersSM)
        this.unmappedKPILeadGroupsArraySM = useConvertObjectToSortedArray(
          this.unmappedKPILeadGroupsSM
        )
      }

      // console.log(this.reportCore.dataOwners)
    },

    async refreshQueryCache($event, key) {
      this.reportCore.setLoadHeavy(true, 'KPI data', 'building cache')
      const urls = useURLS()
      let result = null
      let data = {}
      data.years = []
      data.key = key
      if (key === 'kpi1') {
        let keys = Object.keys(this.processedDataCORELoaded)
        keys.forEach((element) => data.years.push(parseInt(element)))
      }

      if (key === 'kpi1SM') {
        let keys = Object.keys(this.processedDataSMLoaded)
        keys.forEach((element) => data.years.push(parseInt(element)))
      }

      try {
        result = await this.requests.post({
          url: urls.getURL('kpi1_report', 'kpi1QueryRefresh'),
          data
        })

        result = result?.data

        if (result) {
          for (let year in result.years) {
            this.processLoadedData(result.years[year], key, year)
          }
          this.reportCore.setLoadHeavy(false)
          return true
        }
      } catch (error) {
        this.reportCore.setLoadHeavy(false)
        return false
      }
    },

    buildDebugInfo() {
      this.unmappedKPIOwnersArrayCORE = useConvertObjectToSortedArray(this.unmappedKPIOwnersCORE)
      this.unmappedKPILeadGroupsArrayCORE = useConvertObjectToSortedArray(
        this.unmappedKPILeadGroupsCORE
      )

      this.unmappedKPIOwnersArraySM = useConvertObjectToSortedArray(this.unmappedKPIOwnersSM)
      this.unmappedKPILeadGroupsArraySM = useConvertObjectToSortedArray(
        this.unmappedKPILeadGroupsSM
      )
    },
    clearDebugInfo() {
      //clear unmapped sales amounts-------------------------------------
      for (let ownerName in this.unmappedKPIOwnersCORE) {
        let unmappedItem = this.unmappedKPIOwnersCORE[ownerName]
        unmappedItem.props.sales.value = 0
      }
      for (let ownerName in this.unmappedKPIOwnersSM) {
        let unmappedItem = this.unmappedKPIOwnersSM[ownerName]
        unmappedItem.props.sales.value = 0
      }
      for (let leadGroupName in this.unmappedKPILeadGroupsCORE) {
        let unmappedItem = this.unmappedKPILeadGroupsCORE[leadGroupName]
        unmappedItem.props.sales.value = 0
      }
      for (let leadGroupName in this.unmappedKPILeadGroupsSM) {
        let unmappedItem = this.unmappedKPILeadGroupsSM[leadGroupName]
        unmappedItem.props.sales.value = 0
      }
      //---------------------------------------------------------------
    }
  }
})
