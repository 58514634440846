<template>
  <div class="w-100 mx-auto printable" style="max-width: 99%" :class="applicationStore.getPadding">
    <div class="content-bg-11" :class="{
    'pa-4': !applicationStore.grid.tabletAndUnder,
    'elevation-2': !applicationStore.grid.tabletAndUnder,
    'rounded-2': !applicationStore.grid.tabletAndUnder
  }">
      <div class="pa-4 d-flex flex-column" style="background-color: var(--content-bg-13)">
        <div class="d-flex align-items-center" style="cursor: pointer"
          @click="reportKPI1.section1Expanded = !reportKPI1.section1Expanded">
          <div class="flex-ui-1 text-type-10">
            <div class="fs-7 mr-2 text-type-10 icon-color-1">report:</div>
            <div class="icon-color-1" style="font-weight: 700; font-size: 20px">KPI Report</div>
          </div>

          <span class="ms-auto mdi icon icon-color-1" :class="{
    ' mdi-plus-circle': !reportKPI1.section1Expanded,
    ' mdi-minus-circle': reportKPI1.section1Expanded
  }">
          </span>
        </div>

        <div v-if="reportKPI1.section1Expanded">
          <div class="d-flex align-items-center flex-wrap text-type-10 p-0">
            <div class="icon-color-1">Current Filters Applied:</div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">owner:</div>
              <div style="color: #ffca00">{{ reportKPI1.ownerFilter || 'All Owners' }} ,</div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">location:</div>
              <div style="color: #ffca00">{{ reportKPI1.locationFilter || 'All Locations' }} ,</div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">group:</div>
              <div style="color: #ffca00">{{ reportKPI1.leadGroupFilter || 'All Groups' }} ,</div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">date:</div>
              <div style="color: #ffca00">
                {{ reportCore.dateRangeStart.getDateString() }}
              </div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mx-2 text-type-10 icon-color-1">to</div>
              <div style="color: #ffca00">
                {{ reportCore.dateRangeEnd.getDateString() }}
              </div>
            </div>
          </div>

          <div class="mt-2 flex-ui-1" style="align-items: center">
            <Checkbox @change="onUseDataSourceOverrideChanged" v-model="reportCore.useDataSourceOverride"
              inputId="expandScorecards" :binary="true" />
            <label for="expandScorecards" class="fs-8 text-color-5 text-type-10 ml-2">
              override per user data sources
            </label>
          </div>

          <div v-if="reportCore.useDataSourceOverride">
            <div class="mt-3 text-type-11 icon-color-1 p-0">select data source:</div>
            <div class="mt-1 mb-2 flex-ui-1 gap-3" style="align-items: center">
              <div class="d-flex align-items-center">
                <RadioButton @update:modelValue="onKPIRouteKeyChange" v-model="reportCore.dataSourceCurrent"
                  inputId="datasource1" name="kpi_route_key" :value="reportCore.DATA_SOURCE_CORE" />
                <label for="datasource1" class="fs-8 text-color-5 text-type-10 ml-2">CORE</label>
              </div>
              <div class="d-flex align-items-center">
                <RadioButton @update:modelValue="onKPIRouteKeyChange" v-model="reportCore.dataSourceCurrent"
                  inputId="datasource2" name="kpi_route_key" :value="reportCore.DATA_SOURCE_SM" />
                <label for="datasource2" class="fs-8 text-color-5 text-type-10 ml-2">SM</label>
              </div>
              <div class="d-flex align-items-center">
                <RadioButton @update:modelValue="onKPIRouteKeyChange" v-model="reportCore.dataSourceCurrent"
                  inputId="datasource3" name="kpi_route_key" :value="reportCore.DATA_SOURCE_BLEND" />
                <label for="datasource3" class="fs-8 text-color-5 text-type-10 ml-2">BLEND</label>
              </div>
            </div>

            <div v-if="reportCore.dataSourceCurrent === reportCore.DATA_SOURCE_BLEND"
              class="mt-3 mb-3 d-flex justify-content-start" :class="{
    'flex-column': applicationStore.grid.tabletAndUnder,
    'align-items-start': applicationStore.grid.tabletAndUnder,
    'align-items-center': !applicationStore.grid.tabletAndUnder
  }">
              <p class="text-type-9 text-color-5 p-0 mb-0 mr-2 fs-8">Blend Date</p>
              <Calendar panelClass="min-w-min" v-model="reportCore.dateSelectionBlend"
                @date-select="onDateSelectionBlend" selectionMode="single" :manualInput="false" inputId="range" />
            </div>
          </div>

          <div class="mt-3 flex-ui-1" style="align-items: center">
            <div class="text-type-11 icon-color-1 p-0 mr-2">KPI CORE cached:</div>
            <div class="text-type-11 p-0" style="color: #ffca00">
              {{ dataKPI1Store.queryCacheDateCORE ?? '' }}
            </div>
            <button v-if="auth.hasPermission('invalidate_kpi_1_report_cache')" class="button-minimal"
              @click="toggleQueryCacheExtendedDisplay = !toggleQueryCacheExtendedDisplay">
              <span class="mdi icon icon-color-1 mdi-help-circle"></span>
            </button>
          </div>
          <div class="flex-ui-1" style="align-items: center">
            <div class="text-type-11 icon-color-1 p-0 mr-2">KPI SM cached:</div>
            <div class="text-type-11 p-0" style="color: #ffca00">
              {{ dataKPI1Store.queryCacheDateSM ?? '' }}
            </div>
            <button v-if="auth.hasPermission('invalidate_kpi_1_report_cache')" class="button-minimal"
              @click="toggleQueryCacheExtendedDisplay = !toggleQueryCacheExtendedDisplay">
              <span class="mdi icon icon-color-1 mdi-help-circle"></span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="toggleQueryCacheExtendedDisplay && reportKPI1.section1Expanded" class="mt-min pa-4"
        style="background-color: var(--content-bg-13)">
        <div class="text-type-11 icon-color-1 p-0">Queries are cached once a day at midnight</div>
        <div class="mt-1 d-flex flex-wrap align-items-center gap-2">
          <b-button class="p-1 fs-6 fw-bolder px-3" variant="primary"
            @click="reportKPI1.refreshQueryCache($event, 'kpi1')">refresh CORE</b-button>

          <b-button class="p-1 fs-6 fw-bolder px-3" variant="primary"
            @click="reportKPI1.refreshQueryCache($event, 'kpi1SM')">refresh SM</b-button>
        </div>
      </div>

      <div class="mt-min pa-1 py-3 px-3" style="background-color: var(--content-bg-13)">
        <div style="
            display: grid !important;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            grid-gap: 0.4rem !important;
          ">
          <div class="d-flex align-items-center justify-content-center"
            :class="{ 'flex-column': applicationStore.grid.tabletAndUnder }">
            <p class="text-type-9 text-color-5 p-0 mb-0 mr-2 fs-8">Range</p>
            <Calendar panelClass="min-w-min" :key="invalidateRangeCalenderKey" v-model="dateSelectionRange"
              @date-select="onDateRangeSelect" selectionMode="range" :manualInput="false" inputId="range" />
          </div>

          <div class="d-flex align-items-center justify-content-center"
            :class="{ 'flex-column': applicationStore.grid.tabletAndUnder }">
            <p class="text-type-9 text-color-5 p-0 mb-0 mr-2 fs-8">Month</p>
            <Calendar panelClass="min-w-min" v-model="dateSelectionMonth" :yearRange="reportCore.getYearRange()"
              :minDate="reportCore.getYearRangeMin()" :maxDate="reportCore.getYearRangeMax()"
              @date-select="onDateMonthSelect" view="month" dateFormat="mm/yy" inputId="month" />
          </div>

          <div class="d-flex align-items-center justify-content-center"
            :class="{ 'flex-column': applicationStore.grid.tabletAndUnder }">
            <p class="text-type-9 text-color-5 p-0 mb-0 mr-2 fs-8">Year</p>
            <Calendar panelClass="min-w-min" :yearRange="reportCore.getYearRange()"
              :minDate="reportCore.getYearRangeMin()" :maxDate="reportCore.getYearRangeMax()"
              v-model="dateSelectionYear" @date-select="onDateYearSelect" view="year" dateFormat="yy" inputId="year" />
          </div>

          <div class="d-flex align-items-center justify-content-center">
            <button class="text-color-9 h6 m-0 mx-1 button-minimal" @click="dateQ1" style="display: block">
              <div>Q1</div>
            </button>

            <button class="text-color-9 h6 m-0 mx-1 button-minimal" @click="dateQ2" style="display: block">
              <div>Q2</div>
            </button>

            <button class="text-color-9 h6 m-0 mx-1 button-minimal" @click="dateQ3" style="display: block">
              <div>Q3</div>
            </button>

            <button class="text-color-9 h6 m-0 mx-1 button-minimal" @click="dateQ4" style="display: block">
              <div>Q4</div>
            </button>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <button class="text-color-9 h6 m-0 mx-1 button-minimal" @click="dateYTD" style="display: block">
              <div>YTD</div>
            </button>
            <button class="text-color-9 h6 m-0 mx-1 button-minimal" @click="dateYTLM" style="display: block">
              <div>YTLM</div>
            </button>
          </div>
        </div>
        <div v-if="false" class="mt-2 flex-ui-1" style="align-items: center">
          <Checkbox @change="showDataAsDailyAvgChanged" v-model="reportCore.showDataAsDailyAvg"
            inputId="expandScorecards" :binary="true" />
          <label for="expandScorecards" class="fs-8 text-color-5 text-type-10 ml-2">
            convert results to daily average
          </label>
        </div>


      </div>


     <ReportFiltersComponent @update="reportKPI1.buildReport()"/>








      <div class="d-flex mt-4 flex-wrap justify-content-around gap-2">
        <div class="d-flex flex-column justify-content-left" :style="getTableContainerStyle">
          <div class="d-flex justify-content-left mb-2">
            <SelectButton v-model="currentTableSelection" :options="options" optionLabel="name" :allowEmpty="false" />
          </div>

          <div class="mb-4 d-flex-inline">
            <ReportTableComponent configKey="reportKPI1TableOwner" :vShowKey="currentTableSelection?.name" />
            <ReportTableComponent configKey="reportKPI1TableLeadGroup" :vShowKey="currentTableSelection?.name" />
          </div>
        </div>

        <div v-if="!applicationStore.grid.smaller" class="d-flex flex-column justify-content-left"
          :style="getTableContainerStyle">
          <div class="d-flex justify-content-left mb-2">
            <SelectButton v-model="currentTableSelection2" :options="options" optionLabel="name" :allowEmpty="false" />
          </div>

          <div class="mb-4 d-flex-inline">
            <ReportTableComponent configKey="reportKPI1TableOwner" :vShowKey="currentTableSelection2?.name" />
            <ReportTableComponent configKey="reportKPI1TableLeadGroup" :vShowKey="currentTableSelection2?.name" />
          </div>
        </div>
      </div>

      <div class="pa-4 mb-2 d-flex flex-column content-bg-10">
        <div class="d-flex flex-wrap fs-8 fw-bold p-0" :class="{
    'align-items-center': !applicationStore.grid.tabletAndUnder,
    'align-items-start': applicationStore.grid.tabletAndUnder,
    'flex-column': applicationStore.grid.tabletAndUnder
  }">
          <div class="text-color-1 fs-6">Growth Date Ranges:</div>

          <div class="flex-ui-1 ml-2">
            <div class="fs-7 mr-2 text-type-10 text-color-1">YOY:</div>
            <div style="color: #ff6600">
              {{ reportCore.oneYearBeforeStart.getDateString() }}
            </div>
            <div class="fs-7 mx-2 text-type-10 text-color-1">to</div>
            <div style="color: #ff6600">
              {{ reportCore.oneYearBeforeEnd.getDateString() }}
            </div>
          </div>

          <div v-if="reportCore.popRangeValid || reportCore.momRangeValid" class="flex-ui-1 ml-2">
            <div class="fs-7 mr-2 text-type-10 text-color-1">
              {{ reportCore.momRangeValid ? 'MOM' : 'POP' }}:
            </div>
            <div style="color: #ff6600">
              {{ reportCore.periodBeforeStart.getDateString() }}
            </div>
            <div class="fs-7 mx-2 text-type-10 text-color-1">to</div>
            <div style="color: #ff6600">
              {{ reportCore.periodBeforeEnd.getDateString() }}
            </div>
          </div>
        </div>
        <div class="mt-2 flex-ui-1" style="align-items: center">
          <Checkbox v-model="reportCore.expandScorecards" inputId="expandScorecards" :binary="true" />
          <label for="expandScorecards" class="fs-8 text-color-1 text-type-10 ml-2">
            Expand Scorecards
          </label>
        </div>
      </div>
      <div class="w-100" style="
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          grid-gap: 0.5rem 0.5rem;
        ">
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.amount"
          :labels="reportKPI1.scorecardLabels"></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.sales"
          :labels="reportKPI1.scorecardLabels"></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.app_close_rate"
          :labels="reportKPI1.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.issued_close_rate"
          :labels="reportKPI1.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.sales_avg"
          :labels="reportKPI1.scorecardLabels"></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.leads"
          :labels="reportKPI1.scorecardLabels"></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.appointments"
          :labels="reportKPI1.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.appointments_issued"
          :labels="reportKPI1.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.quotes"
          :labels="reportKPI1.scorecardLabels"></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.appt_set_rate"
          :labels="reportKPI1.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.issued_rate"
          :labels="reportKPI1.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.closing_rate"
          :labels="reportKPI1.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.demo_rate"
          :labels="reportKPI1.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPI1?.scoreCards.lead_efficiency"
          :labels="reportKPI1.scorecardLabels">
        </ScoreCardComponent>
      </div>

      <div class="elevation-4 pa-4 content-bg-4 rounded-2 w-100 mt-4">
        <div class="mb-2">
          <ItemContainerLeftRightSlotsComponent>
            <template #left>
              <p class="text-type-10 nav-color-1 p-0 m-0 fs-6">
                {{ reportKPI1.currentLineChartMetric }}
              </p>
              <div class="ml-2 d-flex align-content-center">
                <div class="d-inline-block" :style="reportKPI1.timelineData.datasets[0].lengendBlockStyle"></div>
                <p class="d-inline-block text-type-10 nav-color-1 p-0 m-0 fs-8 ms-1">
                  {{ reportCore.currentDate.year }}
                </p>
              </div>

              <div class="ml-2 d-flex align-content-center">
                <div class="d-inline-block" :style="reportKPI1.timelineData.datasets[1].lengendBlockStyle"></div>
                <p class="d-inline-block text-type-10 nav-color-1 p-0 m-0 fs-8 ms-1">
                  {{ reportCore.currentDate.year - 1 }}
                </p>
              </div>
            </template>
            <template #right>
              <button class="button-minimal" @click="toggleTimelineMetrics = !toggleTimelineMetrics">
                <span class="mdi icon nav-color-1 mdi-menu"></span>
              </button>

              <Dropdown v-if="toggleTimelineMetrics" v-model="selectedTimelineMetric"
                :options="reportKPI1.tableColumnsMetrics" placeholder="Select Metric" class="m-2"
                style="width: 250px; max-width: 250px" @update:modelValue="onUpdateTimelineMetric" />
            </template>
          </ItemContainerLeftRightSlotsComponent>
        </div>

        <Chart type="line" :data="reportKPI1.timelineData" :options="chartOptions" :height="260" />
      </div>

      <div class="mt-3 pa-4" style="background-color: var(--content-bg-13)">
        <div class="flex-ui-1" style="align-items: center">
          <Checkbox v-model="showDrillDownData" inputId="showDrillDownData" :binary="true" />
          <label for="showDrillDownData" class="fs-8 icon-color-1 text-type-10 ml-2">
            Drill Down Sales Data
          </label>
        </div>

        <div class="mt-3" v-if="showDrillDownData">
          <div class="mt-1 d-flex flex-wrap align-items-center gap-2">
            <b-button class="p-1 fs-6 fw-bolder px-3" variant="primary"
              @click="reportKPI1.loadDrillDownData">Load</b-button>
          </div>
        </div>
      </div>

      <div id="drilldowndatacontent" v-if="showDrillDownData"
        class="d-flex mt-4 flex-wrap justify-content-around gap-2">
        <div class="" :style="getDebugTableStyle">
          <div>
            <p class="text-type-10 nav-color-1 p-0 m-0">CORE</p>

            <RCTable :componentData="reportKPI1.dataTableDrillDownCORE" sortField="NAME" :sortOrder="1"
              classDynamic="data-table-4"></RCTable>
          </div>
        </div>

        <div class="" :style="getDebugTableStyle">
          <div>
            <p class="text-type-10 nav-color-1 p-0 m-0">SM</p>

            <RCTable :componentData="reportKPI1.dataTableDrillDownSM" sortField="NAME" :sortOrder="1"
              classDynamic="data-table-4"></RCTable>
          </div>
        </div>
      </div>

      <div v-if="auth.hasPermission('view_kpi_1_report_debug')" class="pa-4 mt-2"
        style="background-color: var(--content-bg-13)">
        <div class="flex-ui-1" style="align-items: center">
          <Checkbox v-model="reportKPI1.showDebugData" inputId="showdebugdata" :binary="true" />
          <label for="showdebugdata" class="fs-8 icon-color-1 text-type-10 ml-2">
            Show Debug Data
          </label>
        </div>
      </div>

      <div v-if="reportKPI1.showDebugData && auth.hasPermission('view_kpi_1_report_debug')" class="mt-4">
        <div v-if="dataKPI1Store.unmappedKPIOwnersArrayCORE?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped owners CORE</div>
          <ListOfGrid :listData="dataKPI1Store.unmappedKPIOwnersArrayCORE" classDynamic="data-table-4"></ListOfGrid>
        </div>

        <div v-if="dataKPI1Store.unmappedKPIOwnersArraySM?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped owners SM</div>
          <ListOfGrid :listData="dataKPI1Store.unmappedKPIOwnersArraySM" classDynamic="data-table-4"></ListOfGrid>
        </div>

        <div v-if="dataKPI1Store.unmappedKPILeadGroupsArrayCORE?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped lead groups CORE</div>
          <ListOfGrid :listData="dataKPI1Store.unmappedKPILeadGroupsArrayCORE" classDynamic="data-table-4"></ListOfGrid>
        </div>

        <div v-if="dataKPI1Store.unmappedKPILeadGroupsArraySM?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped lead groups SM</div>
          <ListOfGrid :listData="dataKPI1Store.unmappedKPILeadGroupsArraySM" classDynamic="data-table-4"></ListOfGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, onUnmounted } from 'vue'

import { useReportKPI1 } from '@/stores/ReportKPI1.js'
import { useDataKPI1Store } from '@/stores/DataKPI1Store.js'

import { useReportCore } from '@/stores/ReportCore.js'
import { useAuth } from '@/stores/Auth.js'
import ItemContainerLeftRightSlotsComponent from '@/components/layout/ItemContainerLeftRightSlotsComponent.vue'

import RCTable from '@/components/charts/RCTable.vue'


import ScoreCardComponent from '@/components/charts/ScoreCardComponent.vue'

import { useApplicationStore } from '@/stores/ApplicationStore.js'

import ListOfGrid from '@/components/ui/ListOfGrid.vue'

import ReportTableComponent from '@/components/ui/ReportTableComponent.vue'
import ReportFiltersComponent from '@/components/ui/ReportFiltersComponent.vue'



const applicationStore = useApplicationStore()

const dateSelectionRange = ref([null, null])
const dataKPI1Store = useDataKPI1Store()
const dateSelectionMonth = ref()

const dateSelectionYear = ref()
const reportKPI1 = useReportKPI1()

const reportCore = useReportCore()
const auth = useAuth()
const invalidateRangeCalenderKey = ref(0)

const toggleTimelineMetrics = ref(false)
const toggleQueryCacheExtendedDisplay = ref(false)

const selectedTimelineMetric = ref(reportKPI1.currentLineChartMetric)
const optionsAllOwners = ref([{ name: 'Owners' }, { name: 'Lead Groups' }])
const optionsOwner = ref([{ name: 'Owners' }, { name: 'Lead Groups' }])
const currentTableSelection = ref(null)
const currentTableSelection2 = ref(null)
const chartOptions = ref()




const showDrillDownData = ref(false)

//-------------------------------------------------------------------

function onUseDataSourceOverrideChanged() {
  reportKPI1.buildReport()
}

function onDateSelectionBlend() {
  reportCore.dataBlendDate.getValuesFromDate(reportCore.dateSelectionBlend)
  if (reportCore.useDataSourceOverride) {
    reportKPI1.buildReport()
  }
}

function onKPIRouteKeyChange(val) {
  reportCore.dataSourceCurrent = val
  if (reportCore.useDataSourceOverride) {
    reportKPI1.buildReport()
  }
}

//----------------------------------------------------------------------

function onUpdateTimelineMetric(event) {
  reportKPI1.changeTimelineMetric(event)
}

//----------------------------------------------------------------------



const getDateFiltersClass = computed(() => {
  return {
    'justify-content-left': !applicationStore.grid.tabletAndUnder,
    'justify-content-center': applicationStore.grid.tabletAndUnder,
    'flex-column': applicationStore.grid.smaller
  }
})

//----------------------------------------------------------------------

const options = computed(() => {
  let optionsToUse = optionsOwner.value
  if (auth.hasPermission('read_kpi_1_report_all_owners')) {
    optionsToUse = optionsAllOwners.value
  }
  return optionsToUse
})

const getTableContainerStyle = computed(() => {
  return applicationStore.grid.smaller
    ? 'flex:1 1 0;width:100%'
    : 'flex:1 1 0;width:900px;max-width:49%'
})



//----------------------------------------------------------------------

function showDataAsDailyAvgChanged() {
  reportKPI1.buildReport()
}

//----------------------------------------------------------------------

function onDateRangeSelect() {
  if (dateSelectionRange.value) {
    if (dateSelectionRange.value[1] !== null) {
      dateSelectionMonth.value = null
      reportCore.dateRangeStart.getValuesFromDate(dateSelectionRange.value[0])

      reportCore.dateRangeEnd.getValuesFromDate(dateSelectionRange.value[1])

      //set the year selector to the year of the start date , since range can bridge acoss two different years eg , dec- jan , had to just prioritize one?
      dateSelectionYear.value = new Date(reportCore.dateRangeStart.year, 0, 1)

      reportKPI1.buildReport()
    }
  }
}

//----------------------------------------------------------------------

function onDateMonthSelect() {
  if (dateSelectionMonth.value) {
    reportCore.dateForMonth(new Date(dateSelectionMonth.value))

    dateSelectionYear.value = new Date(reportCore.currentDate.year, 0, 1)

    dateSelectionRange.value[0] = new Date(
      reportCore.dateRangeStart.year,
      reportCore.dateRangeStart.month,
      reportCore.dateRangeStart.day
    )
    dateSelectionRange.value[1] = new Date(
      reportCore.dateRangeEnd.year,
      reportCore.dateRangeEnd.month,
      reportCore.dateRangeEnd.day
    )

    invalidateRangeCalenderKey.value++
    reportKPI1.buildReport()
  }
}

//----------------------------------------------------------------------

function onDateYearSelect(event) {
  if (dateSelectionYear.value) {
    dateSelectionMonth.value = null

    reportCore.dateForFullYear(dateSelectionYear.value.getFullYear())
    dateSelectionRange.value[0] = new Date(
      reportCore.dateRangeStart.year,
      reportCore.dateRangeStart.month,
      reportCore.dateRangeStart.day
    )
    dateSelectionRange.value[1] = new Date(
      reportCore.dateRangeEnd.year,
      reportCore.dateRangeEnd.month,
      reportCore.dateRangeEnd.day
    )

    invalidateRangeCalenderKey.value++
    reportKPI1.buildReport()
  }
}

function dateYTD() {
  reportCore.dateYTD()
  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.dateRangeEnd.year,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )

  invalidateRangeCalenderKey.value++
  dateSelectionMonth.value = null
  reportKPI1.buildReport()
}

function dateYTLM() {
  reportCore.dateYTLM()
  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.dateRangeEnd.year,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )

  invalidateRangeCalenderKey.value++
  dateSelectionMonth.value = null
  reportKPI1.buildReport()
}

//----------------------------------------------------------------------

function dateQ1() {
  reportCore.dateQ1()
  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.dateRangeEnd.year,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )

  invalidateRangeCalenderKey.value++
  dateSelectionMonth.value = null
  reportKPI1.buildReport()
}

//----------------------------------------------------------------------

function dateQ2() {
  reportCore.dateQ2()
  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.dateRangeEnd.year,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )
  invalidateRangeCalenderKey.value++
  dateSelectionMonth.value = null
  reportKPI1.buildReport()
}

//----------------------------------------------------------------------

function dateQ3() {
  reportCore.dateQ3()
  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.dateRangeEnd.year,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )
  invalidateRangeCalenderKey.value++
  dateSelectionMonth.value = null
  reportKPI1.buildReport()
}

//----------------------------------------------------------------------

function dateQ4() {
  reportCore.dateQ4()
  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.r,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )
  invalidateRangeCalenderKey.value++
  dateSelectionMonth.value = null
  reportKPI1.buildReport()
}

//----------------------------------------------------------------------

const setChartOptions = () => {
  const documentStyle = getComputedStyle(document.documentElement)
  const textColor = documentStyle.getPropertyValue('--text-color')
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
  const surfaceBorder = documentStyle.getPropertyValue('--surface-600')

  return {
    stacked: false,
    maintainAspectRatio: false,
    aspectRatio: 0.6,

    interaction: {
      intersect: false,
      axis: 'xy',
      mode: 'index'
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        animation: false,
        callbacks: {
          label: (tooltipItem, data) => {
            // Assuming 'value' is the property you want to format
            let dataset = null
            if (tooltipItem.datasetIndex === 0) {
              dataset = reportKPI1.timelineSingleMetricSet1
            }
            if (tooltipItem.datasetIndex === 1) {
              dataset = reportKPI1.timelineSingleMetricSet2
            }
            const dataPoint = dataset[tooltipItem.dataIndex]

            return dataPoint.formattedValue
          }
        }
      }
    },

    scales: {
      x: {
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder
        }
      }
    }
  }
}

//----------------------------------------------------------------------
onUnmounted(() => {
  console.log("unmounted called in reportKPI1View")
})

onMounted(() => {
  console.log(`on mounted in ReportKPIView`)
  reportCore.reset()



  if (auth.hasPermission('read_kpi_1_report_all_owners')) {
    currentTableSelection.value = { name: 'Owners' }
    currentTableSelection2.value = { name: 'Lead Groups' }
  } else {
    currentTableSelection.value = { name: 'Owners' }
    currentTableSelection2.value = { name: 'Lead Groups' }
  }

  chartOptions.value = setChartOptions()

  dateSelectionMonth.value = new Date(reportCore.currentDate.year, reportCore.currentDate.month, 1)
  dateSelectionYear.value = new Date(reportCore.currentDate.year, 0, 1)
  reportCore.dateForMonth(dateSelectionMonth.value)

  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.dateRangeEnd.year,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )



  invalidateRangeCalenderKey.value++
  applicationStore.scollToTop()
  reportCore.calculateGrowthDateRanges()
  reportKPI1.buildReport()
})

const getDebugTableStyle = computed(() => {
  return applicationStore.grid.tabletAndUnder
    ? ' flex:1 1 0;width:100%'
    : 'flex:1 1 0;max-width: 49%;'
})
</script>

<style>
ol,
ul {
  padding-left: unset !important;
}

ol,
ul,
dl {
  margin-top: unset !important;
  margin-bottom: unset !important;
}

.p-buttonset .p-button {
  margin: 0;
  font-size: 14px;
}

.p-component {
  font-size: 0.9rem;
}

.min-w-min {
  min-width: min-content !important;
}
</style>
