import { defineStore } from 'pinia'
import axios from 'axios'
import { useRequests } from '@/stores/Requests.js'
import { useGlobalInjects } from '@/stores/GlobalInjects.js'

import { useURLS } from '@/stores/URLS.js'

export const useAuth = defineStore('Auth', {
  state: () => {
    return {     
      token: null,
      refresh: null,
      refreshIntervalID: null,
      showComponent: false,
      rolesAndPermissions: null,      
      rolesAndPermissionsSystem: null,      
      user:{
        name: null,
        email: null,
        ownerID: null,
        user_id:null,

      }
    }
  },

  actions: {
   
    

    setUserInfo(payload) {
      this.user.name = payload.name     
        this.user.email = payload.email
        this.user.user_id = payload.id    
    },

    hasRole(roleToCheck,dataSet){
      let dataSetToUse = dataSet||this.rolesAndPermissions.roles
      let roleItem = dataSetToUse.find(
        (element) => element.value === roleToCheck
      )
      if(!roleItem){
        return false
      }
      return true

    },

    hasPermission(permissionToCheck) {
    
      let permissionItem = this.rolesAndPermissions.permissions.find(
        (element) => element.value === permissionToCheck
      )
     
      if (permissionItem) {
        if (permissionItem.PermissionUser.revoked) {
          return false
        }
        return true
      }
      //if code has not returned at this point no user permissions were found , so check via user roles.
     
      for(let i=0 ;i< this.rolesAndPermissions.roles.length;i++){
        let role = this.rolesAndPermissions.roles[i]
        if (role.Permissions?.length) {
        
          let permissionItem = role.Permissions.find(
            (permission) => permission.value === permissionToCheck
          )

         
         
          if (permissionItem) {
            if (permissionItem.PermissionRole.revoked) {
              return false
            }
          
            return true
          }
        }
      }
      //if code reaches here no permission was found via roles either, so user does not have permission
      return false
    },
   

    trackRefreshToken() {
      if (this.refresh) {
        let expires = this.refresh.getTime() + (5 * 60 + 45) * 60 * 1000
        let dateNow = Date.now()

        if (dateNow > expires) {
          this.showComponent = true
          this.stopTokenRefreshTracking()
        }
      }
    },

    async logout() {
    
        
      useGlobalInjects().router.push({ path: '/applicationlanding/login' })
    
     
    },
    refreshToken() {
      const requests = useRequests()
      const urls = useURLS()

      requests
        .post({
          url: urls.auth.refresh_token
        })
        .then((response) => {
          this.showComponent = false
        })
    },

    startTokenRefreshTracking() {
      this.refreshIntervalID = setInterval(this.trackRefreshToken, 10000)
    },
    stopTokenRefreshTracking() {
      clearInterval(this.refreshIntervalID)
      this.refreshIntervalID = null
    },

    setToken(token) {
      this.token = token
    },
    setRolesAndPermissions(userRolesAndPermissions) {
      this.rolesAndPermissions = userRolesAndPermissions
    },
    setRolesAndPermissionsSystem(systemRolesAndPermissions) {
      this.rolesAndPermissionsSystem = systemRolesAndPermissions
    },

    addTokenInvalidate() {
      axios.interceptors.response.use(
        (response) => {
          if (response.data.refresh) {
            this.refresh = new Date(response.data.refresh)
          }
          if (response.data.token) {
            this.token = response.data.token
          }
          if (!this.refreshIntervalID) {
            this.startTokenRefreshTracking()
          }

          return response
        },
        function (error) {
          return Promise.reject(error)
        }
      )
    },

    setTokenHeader() {
      axios.interceptors.request.use(
        (config) => {
          config.headers['Authorization'] = this.token
          return config
        },
        function (error) {
          return Promise.reject(error)
        }
      )
    }
  }
})
