import { useComponentDisplayData } from "@/scripts/ui/ComponentDisplayData";
import { useComponentData } from "@/scripts/ui/ComponentData";
import { useComponentStylingType6 } from "@/scripts/ui/ComponentStylingType6";
export function useHeader1() {
  let data = {};
  data.component = "Header1Component";
  let attr = (data.attributes = {});
  attr.componentData = useComponentData();
  //set this to true because these are not interactive but are serialized
  attr.componentData.enabled = true;
  attr.componentData.styling = useComponentStylingType6();
  attr.componentData.display = useComponentDisplayData();

  return data;
}
