export function useBuildMetricDataTables2(target, title, format, metrics, metricKey) {
    let valueCurrent = null
  
    if (Array.isArray(metricKey)) {
      let valA = null
      let valB = null
      if (Array.isArray(metricKey[0])) {
        valA = (metrics[metricKey[0][0]] / metrics[metricKey[0][1]]) * 100
      } else {
        valA = metrics[metricKey[0]]
      }
      if (Array.isArray(metricKey[1])) {
        valB = (metrics[metricKey[1][0]] / metrics[metricKey[1][1]] )* 100
      } else {
        valB = metrics[metricKey[1]]
      }
      valueCurrent = valA / valB
  
      if (format === '%delta') {
        valueCurrent = valueCurrent - 1
      }
    } else {
      valueCurrent = metrics[metricKey]
    }
  
    if (format === 'STRING' ) {
      target[title] = valueCurrent
      return
    }
  
    if (format === 'DATE') {
      target[title] = valueCurrent.toString()
      return
    }
  
    target[title] = valueCurrent ?? 0
  
    if (isNaN(target[title]) || !target[title] || !isFinite(target[title])) {
      target[title] = 0
    }
  }
  