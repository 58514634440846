<template>
 <div v-if="!componentData.disabled" class="d-flex align-items-center" :class="componentData.styling.classStringComponentDefault">
     <div v-if="componentData.display.useLeft" class="divider-line"  :class="componentData.styling.classStringLineDefault"></div>
       <div v-if="componentData.display.label" :class="componentData.styling.classStringTextDefault">{{ componentData.display.label }}</div>
      <div v-if="componentData.display.useRight" class="divider-line"  :class="componentData.styling.classStringLineDefault"></div>
    </div>
  
</template>

<script setup>
import {   computed } from "vue";

const props = defineProps({
  componentData: {
    type: Object,
    default: () => {
      return null;
    },
  },
   
});

let getStyle = computed(() => {
  return props.componentData.disabled
    ? "opacity:0.1;user-select:none;"
    : "opacity:unset;user-select:none;"
});

</script>
<style>
.divider-line {
  flex: 1 1 0;
  height:1px;
}



</style>