<template>
  <div class="toast-container-custom">
    <div
      class="shadow rounded content-bg-5 toast-content"
     
    >
      <div class="p-3 d-flex align-items-center">
        <div >
          <span style="font-size:60px" class="mdi icon"
          :class="{
                'mdi-check-circle': toastType === 'success',
                'mdi-alert-circle': toastType === 'error',
                'mdi-alert-circle': toastType === 'warning',
                'icon-success': toastType === 'success',
                'icon-error': toastType === 'error',
                'icon-warning': toastType === 'warning'
              }"
          ></span>
        </div>
        <div class="mx-8" style="width:1px;height:40px;background-color:var(--text-color-1)"></div>
        <div class=" text-color-1">
          <div class="fw-bolder fs-4" style="line-height: normal">
            {{ toastType }}
          </div>
          <div class="fs-9">
            {{ toastMessage }}
          </div>
         
          
        </div>
      </div>
      <b-button
            variant="outline-light"
            style=" border-radius: 0 !important;width: 100% !important;background-color:var(--content-bg-5-inverted) !important"
            class="dismiss button-minimal p-2 px-4 text-uppercase fs-6 fw-bolder text-color-button"
            @click="toasts.dismiss"
            >dismiss</b-button>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue'
import { useToasts } from '@/stores/Toasts.js'
import { gsap } from 'gsap'
const toasts = useToasts()

const toastMessage = computed(() => {
  return toasts.message
})

const toastType = computed(() => {
  return toasts.type
})

const display = computed(() => {
  return toasts.display
})

onMounted(() => {
  gsap.set('.toast-container-custom', { autoAlpha: 0 })
})

watch(display, (newDisplay, prevDisplay) => {
  if (newDisplay) {
    gsap.to('.toast-container-custom', { autoAlpha: 1 })
  } else {
    gsap.to('.toast-container-custom', { autoAlpha: 0 })
  }
})
</script>

<style scoped>
.dismiss:hover {
  background-color: #ffffff2d;
  color: white;
}
.toast-content {
  width: fit-content;
  max-width: 600px;
  
  border: 4px solid #ffffff;
  
}
.toast-container-custom {
  
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100000;
  
}
.icon-error {
  color: #e32424;
}
.icon-success {
 color: #0dbd0d;
}
.icon-warning {
 color: #dac41b;
}

.text-color-button{
  color:var(--content-bg-5) !important
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 40%) !important;
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa38 !important;
}
</style>
