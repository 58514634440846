<template>
  <div>
    <b-input-group :class="{
          'flex-wrap': grid.breakpoint === 'xs',
          'justify-content-end': grid.breakpoint === 'xs',
          'align-items-center': fieldType !== 'BFormInput',
        }">
      <template #append>
        <b-button
          :disabled="requests.loading"
          class="icon-ms24 p-0 px-1 btn-no-focus-shadow button-minimal border-0 border-radius-0"
          variant="light"
          @click="setPrimary"
        >
          <span
            v-if="isPrimary"
            class="icon-c1 mdi icon nav-color-1 mdi-checkbox-marked"
          ></span>
          <span
            v-if="!isPrimary"
            class="icon-c1 mdi icon nav-color-1 mdi-checkbox-blank-outline"
          ></span>
        </b-button>

        <b-button
          :disabled="requests.loading"
          class="icon-ms24 p-0 px-1 btn-no-focus-shadow button-minimal border-0 border-radius-0"
          variant="light"
          @click="addAdditionalInputField"
        >
          <span
           
            class="icon-c2 mdi icon nav-color-1 mdi-plus-circle"
          ></span>
        </b-button>

        <b-button
          :disabled="requests.loading || isPrimaryInjected"
          class="icon-ms24 p-0 px-1 btn-no-focus-shadow button-minimal border-0 border-radius-0"
          variant="light"
          @click="deleteInputField"
        >
          <span
           
            class="icon-c3 mdi icon nav-color-1 mdi-close-octagon"
          ></span>
        </b-button>
      </template>

        <b-form-group
      :label="fieldAttributes.name"
      :label-for="'nested_'+fieldAttributes"
      :style="{'flex-basis':grid.breakpoint === 'xs'?'100%':0}"
      class="flex-grow-1 flex-shrink-1 fs-9 text-align-left text-color-1"
    >
      <component
        class="p-0 shadow-none"
        
        :is="fieldType"
        placeholder=" "
        :disabled="requests.loading"
        type="text"
         :id="'nested_'+fieldAttributes"
        v-model="data_input"
        @input="valueChanged"
        v-bind="fieldAttributes"
      ></component>
    </b-form-group>

     
    </b-input-group>
  </div>
</template>

<script setup>
import { useRequests } from "@/stores/Requests.js";
import { ref, onMounted } from "vue";
import { useGrid } from "vue-screen";
const grid = useGrid("bootstrap");
const requests = useRequests();
const props = defineProps({
  data_input_injected: {
    type: String,
    default: "",
  },
  fieldAttributes: {
    type: Object,
    default: function () {
      return { name: "data", label: "data" };
    },
  },
  fieldType: {
    type: String,
    default: "BFormInput",
  },
  index: {
    type: Number,
  },
 
  isPrimaryInjected: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits([
  "delete",
  "setprimary",
  "valuechanged",
  "addadditionalinputfield",
]);
const data_input = ref("");
const isPrimary = ref(false);
onMounted(() => {
  data_input.value = props.data_input_injected;
  isPrimary.value = props.isPrimaryInjected;
});
function deleteInputField() {
  emit("delete", {
    index: props.index,
  });
}
function setPrimary() {
  emit("setprimary", {
    index: props.index,
    name: props.fieldAttributes.name,
  });
}
function valueChanged() {
  console.log('valueChanged')
  emit("valuechanged", {
    index: props.index,
    data_input: data_input.value,
  });
}
function addAdditionalInputField() {
  emit("addadditionalinputfield", {
    component: "InputSetComponent",

    attributes: {
      index: props.index,
      isPrimaryInjected: false,
      "field-attributes": { ...props.fieldAttributes },
      "field-type": props.fieldType,
    },
  });
}
</script>
