import { nextTick } from 'vue'
export function useConvertObjectToSortedArray(obj) {
   // Get the keys of the object and sort them alphabetically
   const sortedKeys = Object.keys(obj).sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));

  
   // Initialize an array to store the values
   const valuesArray = [];

   // Loop through the sorted keys and push the corresponding values into the array
   sortedKeys.forEach(key => {
       valuesArray.push(obj[key]);
   });

   // Return the array
   return valuesArray;
}
