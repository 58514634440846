import { createApp,markRaw } from 'vue'
import App from '@/App.vue'
import store from "@/stores/index.js";
import router from '@/router'
import BootstrapVue from 'bootstrap-vue-3';
import {BFormInput,BFormTextarea,BFormCheckbox,BFormSelect} from 'bootstrap-vue-3';
import InputSetComponent from "@/components/navigation/InputSetComponent.vue";
import AccordionComponent from "@/components/navigation/AccordionComponent.vue";
import ContainerComponent from "@/components/navigation/ContainerComponent.vue";
import SimpleList from "@/components/ui/SimpleList.vue";
import SimpleBlank from "@/components/ui/SimpleBlank.vue";
import PaletteComponent from "@/components/navigation/PaletteComponent.vue";
import CofigurableButtonComponent from "@/components/navigation/CofigurableButtonComponent.vue";
import CofigurableButtonContent1Component from "@/components/navigation/CofigurableButtonContent1Component.vue";
import CofigurableButtonContent2Component from "@/components/navigation/CofigurableButtonContent2Component.vue";
import CofigurableButtonContent3Component from "@/components/navigation/CofigurableButtonContent3Component.vue";
import ConfigurableButtonsContainerComponent from "@/components/navigation/ConfigurableButtonsContainerComponent.vue";
import CofigurableButtonContent4Component from "@/components/navigation/CofigurableButtonContent4Component.vue";
import CofigurableButtonContent5Component from "@/components/navigation/CofigurableButtonContent5Component.vue";


import FlexContainerComponent from "@/components/navigation/FlexContainerComponent.vue";
import DividerComponent from "@/components/navigation/DividerComponent.vue";
import Header1Component from "@/components/navigation/Header1Component.vue";


import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import SelectButton from 'primevue/selectbutton';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import RadioButton from 'primevue/radiobutton';




import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import "primevue/resources/themes/mira/theme.css"
/*import "primevue/resources/themes/bootstrap4-light-purple/theme.css"
import "primevue/resources/themes/bootstrap4-dark-blue/theme.css"
import "primevue/resources/themes/bootstrap4-dark-purple/theme.css"*/



const app = createApp(App);
app.config.globalProperties.window = window
app.use(store)
app.use(router)







app.use(BootstrapVue,{components:{BFormInput,BFormTextarea,BFormCheckbox,BFormSelect}})
app.use(PrimeVue);

app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Calendar', Calendar);
app.component('SelectButton', SelectButton);
app.component('Chart', Chart);
app.component('Divider', Divider);
app.component('Checkbox', Checkbox);
app.component('Dropdown', Dropdown);
app.component('MultiSelect', MultiSelect);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('RadioButton', RadioButton);

app.component('AccordionComponent', AccordionComponent)
app.component('ContainerComponent', ContainerComponent)
app.component('PaletteComponent', PaletteComponent)
app.component('CofigurableButtonComponent', CofigurableButtonComponent)
app.component('CofigurableButtonContent1Component', CofigurableButtonContent1Component)
app.component('CofigurableButtonContent2Component', CofigurableButtonContent2Component)
app.component('CofigurableButtonContent3Component', CofigurableButtonContent3Component)
app.component('ConfigurableButtonsContainerComponent', ConfigurableButtonsContainerComponent)
app.component('CofigurableButtonContent4Component', CofigurableButtonContent4Component)
app.component('CofigurableButtonContent5Component', CofigurableButtonContent5Component)
app.component('SimpleList', SimpleList)
app.component('SimpleBlank', SimpleBlank)



app.component('FlexContainerComponent', FlexContainerComponent)
app.component('DividerComponent', DividerComponent)
app.component('Header1Component', Header1Component)
app.component('InputSetComponent', InputSetComponent)
app.mount("#app");


