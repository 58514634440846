<template>
  <div class="layout-group py-4">
    <div class="text-color-2 text-center h3 display-5 fw-normal m-0">Welcome</div>
    <div class="text-color-2 text-center h4 display-6 fw-normal m-0">login to continue</div>
  </div>
  <div class="layout-group">
    <b-form-group
      label="Email:"
      label-for="nested-name"
      :invalid-feedback="formfield1Invalid"
      :state="formfield1State"
      class="fs-9 text-align-left text-color-1"
    >
      <b-form-input
        :disabled="requests.loading"
        id="nested-name"
        v-model="formfield1"
        :state="formfield1State"
        trim
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Password:"
      label-for="nested-password"
      :invalid-feedback="formfield2Invalid"
      :state="formfield2State"
      class="fs-9 text-align-left text-color-1"
    >
      <b-input-group>
        <template #append>
          <b-button
            class="btn-no-focus-shadow button-minimal border-0 border-radius-0"
            variant="light"
            @click="showpassword = !showpassword"
          >
            <span
              class="mdi icon icon-color-2"
              :class="{
                'mdi-eye': !showpassword,
                'mdi-eye-off': showpassword
              }"
            ></span>
          </b-button>
        </template>
        <b-form-input
          :disabled="requests.loading"
          :type="showpassword ? 'text' : 'password'"
          id="nested-password"
          v-model="formfield2"
          :state="formfield2State"
          trim
        ></b-form-input>
      </b-input-group>
    </b-form-group>
  </div>
  <div class="layout-group mt-5">
    <b-button
      class="p-1 fs-4 fw-bolder w-100"
      variant="primary"
      @click="submit"
      :disabled="requests.loading"
      >submit</b-button
    >
    <div>
      <router-link
        to="forgotPassword"
        exact
        class="text-secondary d-block my-0 mx-auto mt-4"
        :disabled="requests.loading"
        >reset password?</router-link
      >
    </div>
  </div>
</template>

<script setup>
import { ref, computed ,onMounted } from 'vue'
import { useURLS } from '@/stores/URLS.js'
import { useAuth } from '@/stores/Auth.js'
import { useRequests } from '@/stores/Requests.js'
import { useRouter ,useRoute} from 'vue-router'

import { useReportCore } from '@/stores/ReportCore.js'
import { useDataPresetsTablesStore } from '@/stores/DataPresetsTablesStore.js'
import { useDataPresetsReportFiltersStore } from '@/stores/DataPresetsReportFiltersStore.js'
const reportCore = useReportCore()
const dataPresetsTablesStore = useDataPresetsTablesStore()
const dataPresetsReportFiltersStore = useDataPresetsReportFiltersStore()
const route = useRoute()
const urls = useURLS()
const auth = useAuth()
const requests = useRequests()
const router = useRouter()
const formfield1 = ref('')
const formfield2 = ref('')
const showpassword = ref(false)

onMounted(()=>{
  console.log("onMounted in login")
 

})

var formValidate = ref(false)
var formfield1InvalidMessage = ref('')
var formfield2InvalidMessage = ref('')
function setFormfield1InvalidMessage(value) {
  formfield1InvalidMessage.value = value
}
function setFormfield2InvalidMessage(value) {
  formfield2InvalidMessage.value = value
}
const formfield1State = computed(() => {
  if (!formValidate.value) return undefined
  if (!formfield1.value) {
    setFormfield1InvalidMessage('E-mail is required')

    return false
  }
  if (!/.+@.+\..+/.test(formfield1.value)) {
    setFormfield1InvalidMessage('E-mail must be valid')

    return false
  }
  setFormfield1InvalidMessage('')
  return true
})

const formfield1Invalid = computed(() => {
  if (!formValidate.value) return undefined
  return formfield1InvalidMessage.value
})

const formfield2State = computed(() => {
  if (!formValidate.value) return undefined
  if (!formfield2.value) {
    setFormfield2InvalidMessage('Password is required')

    return false
  }
  //DISABLED DURING DEV FOR EASY TO USE PASSWORDS , UNCOMMENT FOR PRODUCTION SO WE DONT SUPPORT WEAK PASSWORDS

  /*if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(formfield2.value)) {
    setFormfield2InvalidMessage(
      "Password must contain at least lowercase letter, one number, a special character and one uppercase letter."
    );
    return false;
  }*/
  setFormfield2InvalidMessage('')
  return true
})

const formfield2Invalid = computed(() => {
  if (!formValidate.value) return undefined
  return formfield2InvalidMessage.value
})

function submit() {
  formValidate.value = false
  formValidate.value = true
  if (!formfield1State.value || !formfield2State.value) {
    return
  }
  login(formfield1.value, formfield2.value)
}

function login(email, password) {
 
  requests
    .post({
      url: urls.getURL('auth','login'),
      data: { email, password, urlParams:route.query }
    })
    .then((response) => {   
     
     
      auth.setToken(response.data.token)
      auth.setRolesAndPermissions(response.data.userRolesAndPermissions)   
      auth.setRolesAndPermissionsSystem(response.data.systemRolesAndPermissions)   
      reportCore.processSystemDimensions(response.data.systemDimensions)    
      auth.setTokenHeader()
      auth.addTokenInvalidate()     
      auth.startTokenRefreshTracking()      
      auth.setUserInfo(response.data.userInfo)
      dataPresetsTablesStore.process(response.data.userPresetsTables)
      dataPresetsReportFiltersStore.process(response.data.userReportFilterPresets)

     

      router.push({ path: '/application' })
    })
}
</script>

<style scoped>
.theme-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 0;
}
.form-label {
  margin-bottom: 0 !important;
}
</style>
