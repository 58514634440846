<template>
  <div class="content-bg-4 pa-4 rounded-2 elevation-4" style="min-width: 300px">
    <div class="d-flex flex-column">
      <div class="d-flex flex-wrap rounded-0 pa-1  m-0 mb-1 content-bg-10 align-items-center">
        <div class="pl-2 me-auto text-align-left fs-7 fw-bold text-color-11"> {{ getTitle }}</div>
        <div v-if="getBenchmarkValid" class="pa-1 fs-7 fw-bold" :style="getBenchmarkStyle">{{ getBenchmark }}</div>

      </div>

      <div class="text-align-center h2 fw-bold m-0 text-color-2">{{ getValue }}</div>
      <div class="d-flex align-items-center justify-content-around mt-1">
        <div v-if="getMetricSub1Valid" class="d-flex align-items-center">
          <span class="mdi icon h4 m-0" :class="getMetricSub1Class"> </span>
          <div class="d-flex flex-column justify-content-center">
            <div class="text-align-center fs-10 fw-normal m-0 text-color-2">{{ props.labels.subMetric1 }}:</div>
            <div class="text-align-center h4 fw-bold m-0 text-color-10">{{ getMetricSub1 }}</div>
          </div>
        </div>

        <div v-if="getMetricSub2Valid" class="d-flex align-items-center">
          <span class="mdi icon h4 m-0" :class="getMetricSub2Class"> </span>
          <div class="d-flex flex-column justify-content-center">
            <div class="text-align-center fs-10 fw-normal m-0 text-color-2">{{ props.labels.subMetric2 }}:</div>
            <div class="text-align-center h4 fw-bold m-0 text-color-10">{{ getMetricSub2 }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="reportCore.expandScorecards" class="mt-2 w-100 border-top-1">
      <div class="d-inline-flex flex-column justify-content-center py-2">
        <div v-if="getValueSub1Valid" class="flex-ui-1">
          <div class="fs-7 mr-2 text-type-10 text-color-10">{{ props.labels.subValue1 }}:</div>
          <div class="text-align-center h5 fw-bold m-0 text-color-10">{{ getValueSub1 }}</div>
        </div>

        <div v-if="getValueSub2Valid" class="flex-ui-1">
          <div class="fs-7 mr-2 text-type-10 text-color-10">{{ props.labels.subValue2 }}:</div>
          <div class="text-align-center h5 fw-bold m-0 text-color-10">{{ getValueSub2 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useReportCore } from '@/stores/ReportCore.js'
const reportCore = useReportCore()

const props = defineProps({
  componentData: {
    type: Object,
    default: null
  },
  labels: {
    type: Object,
    default: () => {
      return { subValue1: "", subValue2: "", subMetric1: "", subMetric2: "" };
    },
  }
})

const getTitle = computed(() => {
  return props.componentData?.title ?? ''
})
const getValue = computed(() => {
  let val = props.componentData?.value ?? ''
  if (val !== '' && props.componentData.valueFormat === 'USD') {
    val = reportCore.formatCurrency(val)
  }
  if (val !== '' && props.componentData.valueFormat === '%') {
    val = reportCore.formatPercent(val)
  }
  if (val !== '' && props.componentData.valueFormat === "%ROI") {
    val = reportCore.formatPercentROI(val)
  }

  if (val !== '' && props.componentData.valueFormat === "GRADE") {
    val = reportCore.formatGrade(val)
  }

  if (val !== '' && props.componentData.valueFormat === '') {
    val = Math.round(val)
  }
  if (val !== '' && props.componentData.valueFormat === 'FLOATX100') {
    val = reportCore.formatPercentWithoutSymbol(val)
  }
  if (val !== '' && props.componentData.valueFormat === 'INTX100') {
    val = reportCore.formatIntX100(val)
  }
  
  return val
})

const getValueSub1 = computed(() => {
  let val = props.componentData?.valueSub1 ?? ''
  let format = props.componentData.valueSub1Format || props.componentData.valueFormat
  if (val !== '' && format === 'USD') {
    val = reportCore.formatCurrency(val)
  }
  if (val !== '' && format === '%') {
    val = reportCore.formatPercent(val)
  }
  if (val !== '' && format === "%ROI") {
    val = reportCore.formatPercentROI(val)
  }
  if (val !== '' && format === "GRADE") {
    val = reportCore.formatGrade(val)
  }
  if (val !== '' && format === 'INT') {
    val = Math.round(val)
  }
  if (val !== '' && format === '') {
    val = Math.round(val)
  }

  if (val !== '' && format === 'FLOATX100') {
    val = reportCore.formatPercentWithoutSymbol(val)
  }
  if (val !== '' && format === 'INTX100') {
    val = reportCore.formatIntX100(val)
  }

 

  
  return val
})

const getValueSub2 = computed(() => {
  let val = props.componentData?.valueSub2 ?? ''
  let format = props.componentData.valueSub2Format || props.componentData.valueFormat
  if (val !== '' && format === 'USD') {
    val = reportCore.formatCurrency(val)
  }
  if (val !== '' && format === '%') {
    val = reportCore.formatPercent(val)
  }
  if (val !== '' && format === "%ROI") {
    val = reportCore.formatPercentROI(val)
  }
  if (val !== '' && format === "GRADE") {
    val = reportCore.formatGrade(val)
  }
  if (val !== '' && format === 'INT') {
    val = Math.round(val)
  }
  if (val !== '' && format === '') {
    val = Math.round(val)
  }
  
  if (val !== '' && format === 'FLOATX100') {
    val = reportCore.formatPercentWithoutSymbol(val)
  }
  if (val !== '' && format === 'INTX100') {
    val = reportCore.formatIntX100(val)
  }
  return val
})

const getMetricSub1 = computed(() => {
  let val = props.componentData?.metricSub1 ?? ''
  let format = props.componentData.valueMetric1Format || '%'

  if (val !== '' && format === 'USD') {
    val = reportCore.formatCurrency(val)
  }
  if (val !== '' && format === '%') {
    val = reportCore.formatPercent(val)
  }
  if (val !== '' && format === "%ROI") {
    val = reportCore.formatPercentROI(val)
  }
  if (val !== '' && format === "GRADE") {
    val = reportCore.formatGrade(val)
  }
  if (val !== '' && format === 'INT') {
    val = Math.round(val)
  }
  if (val !== '' && format === '') {
    val = Math.round(val)
  }
  
  if (val !== '' && format === 'FLOATX100') {
    val = reportCore.formatPercentWithoutSymbol(val)
  }
  if (val !== '' && format === 'INTX100') {
    val = reportCore.formatIntX100(val)
  }
  return val

})

const getMetricSub2 = computed(() => {
  let val = props.componentData?.metricSub2 ?? ''
  let format = props.componentData.valueMetric2Format || '%'

  if (val !== '' && format === 'USD') {
    val = reportCore.formatCurrency(val)
  }
  if (val !== '' && format === '%') {
    val = reportCore.formatPercent(val)
  }
  if (val !== '' && format === "%ROI") {
    val = reportCore.formatPercentROI(val)
  }
  if (val !== '' && format === "GRADE") {
    val = reportCore.formatGrade(val)
  }
  if (val !== '' && format === 'INT') {
    val = Math.round(val)
  }
  if (val !== '' && format === '') {
    val = Math.round(val)
  }
  
  if (val !== '' && format === 'FLOATX100') {
    val = reportCore.formatPercentWithoutSymbol(val)
  }
  if (val !== '' && format === 'INTX100') {
    val = reportCore.formatIntX100(val)
  }
  return val
})

const getMetricSub1Class = computed(() => {
  return {
    'mdi-arrow-up-bold': props.componentData.metricSub1 >= props.componentData.metricSub1Threshold,
    'mdi-arrow-down-bold': props.componentData.metricSub1 < props.componentData.metricSub1Threshold,
    'icon-color-9': props.componentData.metricSub1 >= props.componentData.metricSub1Threshold,
    'icon-color-10': props.componentData.metricSub1 < props.componentData.metricSub1Threshold
  }
})

const getMetricSub1ColorClass = computed(() => {
  return {
    'icon-color-9': props.componentData.metricSub1 >= 0,
    'icon-color-10': props.componentData.metricSub1 < 0
  }
})

const getMetricSub2Class = computed(() => {
  return {
    'mdi-arrow-up-bold': props.componentData.metricSub2 >= props.componentData.metricSub2Threshold,
    'mdi-arrow-down-bold': props.componentData.metricSub2 < props.componentData.metricSub2Threshold,
    'icon-color-9': props.componentData.metricSub2 >= props.componentData.metricSub2Threshold,
    'icon-color-10': props.componentData.metricSub2 < props.componentData.metricSub2Threshold
  }
})

const getMetricSub2ColorClass = computed(() => {
  return {
    'icon-color-9': props.componentData.metricSub2 >= 0,
    'icon-color-10': props.componentData.metricSub2 < 0
  }
})

const getValueSub1Valid = computed(() => {
  return props.componentData?.valueSub1Valid ?? false
})

const getValueSub2Valid = computed(() => {
  return props.componentData?.valueSub2Valid ?? false
})


const getMetricSub1Valid = computed(() => {
  return props.componentData?.metricSub1Valid ?? props.componentData?.valueSub1Valid ?? false
})

const getMetricSub2Valid = computed(() => {
  return props.componentData?.metricSub2Valid ?? props.componentData?.valueSub2Valid ?? false
})

const getBenchmarkValid = computed(() => {
  return props.componentData?.benchmark ?? false
})

const getBenchmark = computed(() => {
  let val = props.componentData?.benchmark ?? ''

  if (val !== '') {
    val = reportCore.formatPercent(val)
    val = `Goal: ${val}`
  }
  return val
})

const getBenchmarkStyle = computed(() => {
  let valueBenchmark = props.componentData?.benchmark ?? 0
  if (!valueBenchmark) {
    return ''
  }

  let valueCompare = props.componentData?.value ?? 0

  let colorRed = "#de1216"
  let colorOrange = "#f8c243"
  let colorGreen = "#098f30"

  let colorToUse = colorRed;
  if (valueCompare) {
    let percent = valueCompare / valueBenchmark
    if (percent >= 0.9) {
      colorToUse = colorOrange
    }
    if (percent >= 1) {
      colorToUse = colorGreen
    }

  }

  return `color: #ffffff;background-color: ${colorToUse};text-align: center;`;


})

</script>
