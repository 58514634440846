import { defineStore } from 'pinia'
import { useURLS } from '@/stores/URLS.js'
import { useReportCore } from '@/stores/ReportCore.js'
import { useRequests } from '@/stores/Requests.js'
import { markRaw } from 'vue'
import { useAuth } from '@/stores/Auth.js'
import SimpleDate from '../scripts/utils/SimpleDate'
import { useConvertObjectToSortedArray } from '@/scripts/utils/ConvertObjectToSortedArray.js'
export const useDataKPI2Store = defineStore('DataKPI2Store', {
  state: () => {
    return {
      unmappedKPIOwnersCORE: markRaw({}),
      unmappedKPIOwnersSM: markRaw({}),
      unmappedKPILeadGroupsCORE: markRaw({}),
      unmappedKPILeadGroupsSM: markRaw({}),

      unmappedKPISalesPersonsCORE: markRaw({}),
      unmappedKPISalesPersonsSM: markRaw({}),

      unmappedKPIOwnersArrayCORE: [],
      unmappedKPIOwnersArraySM: [],
      unmappedKPILeadGroupsArrayCORE: [],
      unmappedKPILeadGroupsArraySM: [],

      unmappedKPISalesPersonsArrayCORE: [],
      unmappedKPISalesPersonsArraySM: [],

      reportCore: markRaw(useReportCore()),
      requests: markRaw(useRequests()),
      queryCacheDateCORE: null,
      queryCacheDateSM: null,

      requestModelNameToIndex: markRaw({}),
      requestModelIndexToName: markRaw([]),

      processedDataCORE: markRaw({}),
      processedDataSM: markRaw({}),
      processedDataCORELoaded: {},
      processedDataSMLoaded: {},

      metricTypeMap: markRaw({
        appointment: 'APPOINTMENTS_COUNT',
        issued_appointment: 'ISSUED_APPOINTMENTS_COUNT',
        quote: 'QUOTES_COUNT',
        sale: 'SALES_COUNT'
      })
    }
  },

  actions: {
    

    //---------------------------------------------------------------------------------------------

    processLoadedData(result, key, year) {
      // console.log(`processLoadedData ${key}`)
      if (key === 'kpi2') {
        this.queryCacheDateCORE = 'unknown'
        this.processedDataCORELoaded[year] = true
        if (result.queryCacheDate) {
          this.queryCacheDateCORE = SimpleDate.getDateTimeStringFromJSDateObject(
            new Date(result.queryCacheDate)
          )
        }
      }
      if (key === 'kpi2SM') {
        this.queryCacheDateSM = 'unknown'
        this.processedDataSMLoaded[year] = true
        if (result.queryCacheDate) {
          this.queryCacheDateSM = SimpleDate.getDateTimeStringFromJSDateObject(
            new Date(result.queryCacheDate)
          )
        }
      }

      this.requestModelNameToIndex = result.keyMap

      this.requestModelIndexToName = []
      for (let prop in result.keyMap) {
        this.requestModelIndexToName[result.keyMap[prop]] = prop
      }

      this.indexDate = this.requestModelNameToIndex['DATE']
      this.indexOwner = this.requestModelNameToIndex['OWNER']
      this.indexLocation = this.requestModelNameToIndex['LOCATION']
      this.indexLeadGroup = this.requestModelNameToIndex['LEAD_GROUP']
      this.indexSalesPerson = this.requestModelNameToIndex['SALES_PERSON']
      this.indexContact = this.requestModelNameToIndex['CONTACT']
      this.indexMetric = this.requestModelNameToIndex['METRIC']
      this.indexMetricType = this.requestModelNameToIndex['METRIC_TYPE']

      this.ownerFilter = null
      this.salesPersonFilter = null
      this.salesPersonUnfilteredFilter = null
      this.leadGroupFilter = null
      this.locationFilter = null

      // this.reportCore.fixDates(result.data, this.requestModelNameToIndex)
      this.buildOptimizedData(result, key, year)
    },
    //---------------------------------------------------------------------------------------------
    async loadDataAll() {
      let key = null

      let refreshKPI2AgainTemp = this.reportCore.refreshKPI2Again

      key = 'kpi2'
      let asyncProcessSuccessful = false
      asyncProcessSuccessful = await this.loadData(key)
      if (!asyncProcessSuccessful) return

      //refreshKPI2Again gets reset so reset it again for both loads to be affected by its purpose
      this.reportCore.refreshKPI2Again = refreshKPI2AgainTemp

      key = 'kpi2SM'
      asyncProcessSuccessful = false
      asyncProcessSuccessful = await this.loadData(key)
      if (!asyncProcessSuccessful) return

      return true
    },

    validateDataIsLoaded() {
      //if refreshKPI is set , then loading data again is manditory
      if (this.reportCore.refreshKPI2Again) {
        return false
      }
      // check if the year range contains a year not yet loaded into the app
      //default to true , so if a year is found missing in the data , it is set to false
      let skipLoading = true
      let yearMin = this.reportCore.dateRangeStart.year
      let yearMax = this.reportCore.dateRangeEnd.year

      // if min , max are equal , reduce min
      //since for timeline yoy calculations two years data is always required
      if (yearMin === yearMax) {
        yearMin--
      }
      let yearValidate = yearMin
      let whileTrue = true
      while (whileTrue) {
        if (!this.processedDataCORELoaded[yearValidate]) {
          skipLoading = false
        }
        //---------------
        if (!this.processedDataSMLoaded[yearValidate]) {
          skipLoading = false
        }
        yearValidate++
        if (yearValidate > yearMax || !skipLoading) {
          whileTrue = false
        }
      }
      return skipLoading
    },

    async loadData(key) {
      //get current range year min and max
      //if min and max are the same , then get year below
      //because always loading two years of data
      //check to see if that data for those years is not already loaded
      let yearMin = this.reportCore.dateRangeStart.year
      let yearMax = this.reportCore.dateRangeEnd.year
      if (yearMin === yearMax) {
        yearMin--
      }
      let yearValidate = yearMin
      //validate if years are already loaded , unless refresh is needed
      if (!this.reportCore.refreshKPI2Again) {
        let skipLoading = true
        let whileTrue = true
        while (whileTrue) {
          if (key === 'kpi2') {
            if (!this.processedDataCORELoaded[yearValidate]) {
              // console.log('skipLoading is getting set to false .. processedDataCORELoaded is')
              // console.log(this.processedDataCORELoaded)
              skipLoading = false
            }
          }
          //---------------
          if (key === 'kpi2SM') {
            if (!this.processedDataSMLoaded[yearValidate]) {
              // console.log('skipLoading is getting set to false .. processedDataSMLoaded is')
              // console.log(this.processedDataSMLoaded)
              skipLoading = false
            }
          }
          yearValidate++
          if (yearValidate > yearMax || !skipLoading) {
            whileTrue = false
          }
        }
        if (skipLoading) {
          return true
        }
      }

      this.reportCore.setLoadHeavy(true, 'KPI Data')
      const urls = useURLS()
      const auth = useAuth()

      var data = { key }
      data.years = []
      let whileTrue = true
      yearValidate = yearMin
      while (whileTrue) {
        if (key === 'kpi2') {
          if (!this.reportCore.refreshKPI2Again) {
            if (!this.processedDataCORELoaded[yearValidate]) {
              data.years.push(yearValidate)
            }
          } else {
            data.years.push(yearValidate)
          }
        }
        if (key === 'kpi2SM') {
          if (!this.reportCore.refreshKPI2Again) {
            if (!this.processedDataSMLoaded[yearValidate]) {
              data.years.push(yearValidate)
            }
          } else {
            data.years.push(yearValidate)
          }
        }
        yearValidate++
        if (yearValidate > yearMax) {
          whileTrue = false
        }
      }

      this.reportCore.refreshKPI2Again = false

      //console.log(data.years)
      var urlToUse = urls.getURL('kpi2_report', 'kpi2')
     
      try {
        let result = await this.requests.post({
          url: urlToUse,
          data
        })

        result = result?.data

        if (result) {
          for (let year in result.years) {
            this.processLoadedData(result.years[year], key, year)
          }
          this.reportCore.setLoadHeavy(false)
          return true
        }
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return false
      }
    },

    buildOptimizedData(result, key, year) {
      let data = result.data

      //let dataRef = null
      let dataRef2 = null

      if (key === 'kpi2') {
        // dataRef = this.dataCORE = markRaw({})
        dataRef2 = this.processedDataCORE

        this.unmappedKPIOwnersArrayCORE = []
        this.unmappedKPISalesPersonsArrayCORE = []
        this.unmappedKPILeadGroupsArrayCORE = []
      }
      if (key === 'kpi2SM') {
        //  dataRef = this.dataSM = markRaw({})
        dataRef2 = this.processedDataSM

        this.unmappedKPIOwnersArraySM = []
        this.unmappedKPISalesPersonsArraySM = []
        this.unmappedKPILeadGroupsArraySM = []
      }

      //loop structure of owners , and clear the year being passed in if it exists
      //this is because refreshing cache will rebuild this structure and values will be additive if not cleared
      for (let ownerKey in dataRef2) {
        let ownerTemp = dataRef2[ownerKey]
        if (ownerTemp.years) {
          if (ownerTemp.years[year]) {
            delete ownerTemp.years[year]
          }
        }
      }

      let i = data.length
      while (i--) {
        let element = data[i]

        //first just set it to the value
        let dateValue = element[this.indexDate]
        //now check if a value map exist
        let valueMapKey = this.requestModelIndexToName[this.indexDate]
        let columnValueMap = result.columnValueMaps[valueMapKey]
        if (columnValueMap) {
          //if it exists pull the actual value from the value map , since the value is an index pointing to the value
          dateValue = columnValueMap[dateValue]
        }

        let sd = new SimpleDate()
        sd.getValuesFromDateString(dateValue)
        dateValue = sd

        //first just set it to the value
        let ownerValue = element[this.indexOwner]
        //now check if a value map exist
        valueMapKey = this.requestModelIndexToName[this.indexOwner]
        columnValueMap = result.columnValueMaps[valueMapKey]
        if (columnValueMap) {
          //if it exists pull the actual value from the value map , since the value is an index pointing to the value
          ownerValue = columnValueMap[ownerValue]
        }

        if (ownerValue === null || ownerValue === undefined) {
          continue
        }

        ownerValue = ownerValue.trim()

        if (ownerValue === 'Deploy' || ownerValue === '') {
          continue
        }
        if (ownerValue.toLowerCase().indexOf('sandbox') !== -1) {
          continue
        }

        //owner------------------------------------------------------------------

        let systemOwner = this.reportCore.dataOwners[ownerValue]

        if (systemOwner) {
          if (systemOwner.exclude_from_reports) {
            continue
          }
          ownerValue = systemOwner.name
        }

        //location----------------------------------------------------------------

        //first just set it to the value
        let locationValue = element[this.indexLocation]
        //now check if a value map exist
        valueMapKey = this.requestModelIndexToName[this.indexLocation]
        columnValueMap = result.columnValueMaps[valueMapKey]
        if (columnValueMap) {
          //if it exists pull the actual value from the value map , since the value is an index pointing to the value
          locationValue = columnValueMap[locationValue]
        }

        if (locationValue) {
          locationValue = locationValue.trim()
        }
        if (locationValue) {
          locationValue = locationValue.trim()
        }
        let systemLocation = this.reportCore.dataLocations[locationValue]

        //salesPersonValue----------------------------------------------------------

        //first just set it to the value
        let salesPersonValue = element[this.indexSalesPerson]
        //now check if a value map exist
        valueMapKey = this.requestModelIndexToName[this.indexSalesPerson]
        columnValueMap = result.columnValueMaps[valueMapKey]
        if (columnValueMap) {
          //if it exists pull the actual value from the value map , since the value is an index pointing to the value
          salesPersonValue = columnValueMap[salesPersonValue]
        }

        if (
          salesPersonValue === null ||
          salesPersonValue === undefined ||
          salesPersonValue.trim() === 'null' ||
          salesPersonValue.trim() === ''
        ) {
          salesPersonValue = `${ownerValue} NO Sales Person`
        }

        salesPersonValue = salesPersonValue.trim()

        let systemSalesPerson = this.reportCore.dataSalesPersons[salesPersonValue]

        if (systemSalesPerson) {
          if (systemSalesPerson.exclude_from_reports) {
            continue
          }
          salesPersonValue = systemSalesPerson.name
        }

        //leadGroupValue----------------------------------------------------------

        //first just set it to the value
        let leadGroupValue = element[this.indexLeadGroup]
        //now check if a value map exist
        valueMapKey = this.requestModelIndexToName[this.indexLeadGroup]
        columnValueMap = result.columnValueMaps[valueMapKey]
        if (columnValueMap) {
          //if it exists pull the actual value from the value map , since the value is an index pointing to the value
          leadGroupValue = columnValueMap[leadGroupValue]
        }
        if (
          leadGroupValue === null ||
          leadGroupValue === undefined ||
          leadGroupValue.trim() === 'null' ||
          leadGroupValue.trim() === ''
        ) {
          leadGroupValue = `${ownerValue} NO Leadgroup`
        }

        leadGroupValue = leadGroupValue.trim()

        //leadGroupValue----------------------------------------------------------

        let systemLeadGroup = this.reportCore.dataLeadGroups[leadGroupValue]

        if (systemLeadGroup) {
          leadGroupValue = systemLeadGroup.name
        }

        //---------------------------------------------------

        //write back onto object incase it had whitespace issues
        //!! not needed .. i dont ever use the loaded data , this function creates the data i use
        /* element[this.indexOwner] = ownerValue
              element[this.indexLocation] = locationValue
              element[this.indexLeadGroup] = leadGroupValue
              element[this.indexSalesPerson] = salesPersonValue*/

        if (systemOwner) {
          ownerValue = systemOwner.name
        } else {
          if (key === 'kpi2') {
            let unmappedItem = this.unmappedKPIOwnersCORE[ownerValue]

            if (!unmappedItem) {
              unmappedItem = this.unmappedKPIOwnersCORE[ownerValue] = {}
              unmappedItem.name = ownerValue
              unmappedItem.props = {}

              unmappedItem.props.dataRangeStart = {}
              unmappedItem.props.dataRangeStart.label = 'data range start'
              unmappedItem.props.dataRangeStart.value = dateValue

              unmappedItem.props.dataRangeEnd = {}
              unmappedItem.props.dataRangeEnd.label = 'data range end'
              unmappedItem.props.dataRangeEnd.value = dateValue

              unmappedItem.props.sales = {}
              unmappedItem.props.sales.label = 'sales'
              unmappedItem.props.sales.value = 0
            } else {
              if (dateValue < unmappedItem.props.dataRangeStart.value) {
                unmappedItem.props.dataRangeStart.value = dateValue
              }
              if (dateValue > unmappedItem.props.dataRangeEnd.value) {
                unmappedItem.props.dataRangeEnd.value = dateValue
              }

              // unmappedItem.props.sales.value += salesSum || 0
            }
          }
          if (key === 'kpi2SM') {
            let unmappedItem = this.unmappedKPIOwnersSM[ownerValue]
            if (!unmappedItem) {
              unmappedItem = this.unmappedKPIOwnersSM[ownerValue] = {}
              unmappedItem.name = ownerValue
              unmappedItem.props = {}
              unmappedItem.props.dataRangeStart = {}
              unmappedItem.props.dataRangeStart.label = 'data range start'
              unmappedItem.props.dataRangeStart.value = dateValue

              unmappedItem.props.dataRangeEnd = {}
              unmappedItem.props.dataRangeEnd.label = 'data range end'
              unmappedItem.props.dataRangeEnd.value = dateValue

              unmappedItem.props.sales = {}
              unmappedItem.props.sales.label = 'sales'
              unmappedItem.props.sales.value = 0
            } else {
              if (dateValue < unmappedItem.props.dataRangeStart.value) {
                unmappedItem.props.dataRangeStart.value = dateValue
              }
              if (dateValue > unmappedItem.props.dataRangeEnd.value) {
                unmappedItem.props.dataRangeEnd.value = dateValue
              }
              //  unmappedItem.props.sales.value += salesSum || 0
            }
          }
        }
        if (systemLeadGroup) {
          leadGroupValue = systemLeadGroup.name
        } else {
          if (key === 'kpi2') {
            let unmappedItem = this.unmappedKPILeadGroupsCORE[leadGroupValue]
            if (!unmappedItem) {
              unmappedItem = this.unmappedKPILeadGroupsCORE[leadGroupValue] = {}
              unmappedItem.name = leadGroupValue
              unmappedItem.props = {}
              unmappedItem.props.dataRangeStart = {}
              unmappedItem.props.dataRangeStart.label = 'data range start'
              unmappedItem.props.dataRangeStart.value = dateValue

              unmappedItem.props.dataRangeEnd = {}
              unmappedItem.props.dataRangeEnd.label = 'data range end'
              unmappedItem.props.dataRangeEnd.value = dateValue

              unmappedItem.props.sales = {}
              unmappedItem.props.sales.label = 'sales'
              unmappedItem.props.sales.value = 0
            } else {
              if (dateValue < unmappedItem.props.dataRangeStart.value) {
                unmappedItem.props.dataRangeStart.value = dateValue
              }
              if (dateValue > unmappedItem.props.dataRangeEnd.value) {
                unmappedItem.props.dataRangeEnd.value = dateValue
              }
              // unmappedItem.props.sales.value += salesSum || 0
            }
          }
          if (key === 'kpi2SM') {
            let unmappedItem = this.unmappedKPILeadGroupsSM[leadGroupValue]
            if (!unmappedItem) {
              unmappedItem = this.unmappedKPILeadGroupsSM[leadGroupValue] = {}
              unmappedItem.name = leadGroupValue
              unmappedItem.props = {}
              unmappedItem.props.dataRangeStart = {}
              unmappedItem.props.dataRangeStart.label = 'data range start'
              unmappedItem.props.dataRangeStart.value = dateValue

              unmappedItem.props.dataRangeEnd = {}
              unmappedItem.props.dataRangeEnd.label = 'data range end'
              unmappedItem.props.dataRangeEnd.value = dateValue

              unmappedItem.props.sales = {}
              unmappedItem.props.sales.label = 'sales'
              unmappedItem.props.sales.value = 0
            } else {
              if (dateValue < unmappedItem.props.dataRangeStart.value) {
                unmappedItem.props.dataRangeStart.value = dateValue
              }
              if (dateValue > unmappedItem.props.dataRangeEnd.value) {
                unmappedItem.props.dataRangeEnd.value = dateValue
              }
            }
          }
        }

        //sales persons , system and unmapped handling for debugging
        if (systemSalesPerson) {
          salesPersonValue = systemSalesPerson.name
        } else {
          if (key === 'kpi2') {
            let unmappedItem = this.unmappedKPISalesPersonsCORE[salesPersonValue]
            if (!unmappedItem) {
              unmappedItem = this.unmappedKPISalesPersonsCORE[salesPersonValue] = {}
              unmappedItem.name = salesPersonValue
              unmappedItem.props = {}
              unmappedItem.props.dataRangeStart = {}
              unmappedItem.props.dataRangeStart.label = 'data range start'
              unmappedItem.props.dataRangeStart.value = dateValue

              unmappedItem.props.dataRangeEnd = {}
              unmappedItem.props.dataRangeEnd.label = 'data range end'
              unmappedItem.props.dataRangeEnd.value = dateValue

              unmappedItem.props.sales = {}
              unmappedItem.props.sales.label = 'sales'
              unmappedItem.props.sales.value = 0
            } else {
              if (dateValue < unmappedItem.props.dataRangeStart.value) {
                unmappedItem.props.dataRangeStart.value = dateValue
              }
              if (dateValue > unmappedItem.props.dataRangeEnd.value) {
                unmappedItem.props.dataRangeEnd.value = dateValue
              }
            }
          }
          if (key === 'kpi2SM') {
            let unmappedItem = this.unmappedKPISalesPersonsSM[salesPersonValue]
            if (!unmappedItem) {
              unmappedItem = this.unmappedKPISalesPersonsSM[salesPersonValue] = {}
              unmappedItem.name = salesPersonValue
              unmappedItem.props = {}
              unmappedItem.props.dataRangeStart = {}
              unmappedItem.props.dataRangeStart.label = 'data range start'
              unmappedItem.props.dataRangeStart.value = dateValue

              unmappedItem.props.dataRangeEnd = {}
              unmappedItem.props.dataRangeEnd.label = 'data range end'
              unmappedItem.props.dataRangeEnd.value = dateValue

              unmappedItem.props.sales = {}
              unmappedItem.props.sales.label = 'sales'
              unmappedItem.props.sales.value = 0
            } else {
              if (dateValue < unmappedItem.props.dataRangeStart.value) {
                unmappedItem.props.dataRangeStart.value = dateValue
              }
              if (dateValue > unmappedItem.props.dataRangeEnd.value) {
                unmappedItem.props.dataRangeEnd.value = dateValue
              }
            }
          }
        }

        let ownerObj = dataRef2[ownerValue]
        if (!ownerObj) {
          ownerObj = dataRef2[ownerValue] = {}
          ownerObj.years = {}
          ownerObj.name = ownerValue
          ownerObj.isMapped = !!systemOwner
        }

        let yearObj = ownerObj.years[dateValue.year]
        if (!yearObj) {
          yearObj = ownerObj.years[dateValue.year] = {}
          yearObj.months = {}
        }

        let monthObj = yearObj.months[dateValue.month]
        if (!monthObj) {
          monthObj = yearObj.months[dateValue.month] = {}
          monthObj.days = {}
        }

        let dayObj = monthObj.days[dateValue.day]
        if (!dayObj) {
          dayObj = monthObj.days[dateValue.day] = {}
          dayObj.locations = {}
        }

        let locationObj = dayObj.locations[locationValue]
        if (!locationObj) {
          locationObj = dayObj.locations[locationValue] = {}
          // locationObj.systemLocation = this.reportCore.dataLocations[locationValue]
          locationObj.salesPersons = {}
        }

        let salesPersonObj = locationObj.salesPersons[salesPersonValue]
        if (!salesPersonObj) {
          salesPersonObj = locationObj.salesPersons[salesPersonValue] = {}
          // locationObj.systemLocation = this.reportCore.dataLocations[locationValue]
          salesPersonObj.leadGroups = {}
        }

        let dimension = salesPersonObj.leadGroups

        let leadGroupObj = dimension[leadGroupValue]
        if (!leadGroupObj) {
          leadGroupObj = dimension[leadGroupValue] = {}
          // leadGroupObj.systemLeadGroup = this.reportCore.dataLeadGroups[leadGroupValue]
          leadGroupObj.kpiData = {}
        }

        /*
      
                 this.indexDate = this.requestModelNameToIndex['DATE']
            this.indexOwner = this.requestModelNameToIndex['OWNER']
            this.indexLocation = this.requestModelNameToIndex['LOCATION']
            this.indexLeadGroup = this.requestModelNameToIndex['LEAD_GROUP']
            this.indexSalesPerson = this.requestModelNameToIndex['SALES_PERSON']
            this.indexContact = this.requestModelNameToIndex['CONTACT']
            this.indexMetric = this.requestModelNameToIndex['METRIC']
            this.indexMetricType = this.requestModelNameToIndex['METRIC_TYPE']
      
              */
        let metric = element[this.indexMetric]

        //first just set it to the value
        let metricType = element[this.indexMetricType]
        //now check if a value map exist
        valueMapKey = this.requestModelIndexToName[this.indexMetricType]
        columnValueMap = result.columnValueMaps[valueMapKey]
        if (columnValueMap) {
          //if it exists pull the actual value from the value map , since the value is an index pointing to the value
          metricType = columnValueMap[metricType]
        }

        let metricKey = this.metricTypeMap[metricType]
        // console.log(`metricType ${metricType} metric ${metric}`)

        if (metric !== null && metric !== undefined) {
          leadGroupObj.kpiData[metricKey] = leadGroupObj.kpiData[metricKey] || 0
          leadGroupObj.kpiData[metricKey] += 1
          if (metricKey === 'SALES_COUNT') {
            let sales_sum = 'SALES_SUM'
            leadGroupObj.kpiData[sales_sum] = leadGroupObj.kpiData[sales_sum] || 0
            leadGroupObj.kpiData[sales_sum] += metric
          }
        }
      }

      //console.log(dataRef2)

      if (key === 'kpi2') {
        this.unmappedKPIOwnersArrayCORE = useConvertObjectToSortedArray(this.unmappedKPIOwnersCORE)
        this.unmappedKPISalesPersonsArrayCORE = useConvertObjectToSortedArray(
          this.unmappedKPISalesPersonsCORE
        )
        this.unmappedKPILeadGroupsArrayCORE = useConvertObjectToSortedArray(
          this.unmappedKPILeadGroupsCORE
        )
      }
      if (key === 'kpi2SM') {
        this.unmappedKPIOwnersArraySM = useConvertObjectToSortedArray(this.unmappedKPIOwnersSM)
        this.unmappedKPISalesPersonsArraySM = useConvertObjectToSortedArray(
          this.unmappedKPISalesPersonsSM
        )
        this.unmappedKPILeadGroupsArraySM = useConvertObjectToSortedArray(
          this.unmappedKPILeadGroupsSM
        )
      }

      //console.log(this.reportCore.dataOwners)

      //set this to false here as it all processes that would have used it are completed
      //and it needs to be reset after being used
      this.reportCore.refreshKPI2Again = false
    },

    async refreshQueryCache($event, key) {
      this.reportCore.setLoadHeavy(true, 'KPI data', 'building cache')
      const urls = useURLS()
      let result = null
      let data = {}
      data.years = []
      data.key = key
      if (key === 'kpi2') {
        let keys = Object.keys(this.processedDataCORELoaded)
        keys.forEach((element) => data.years.push(parseInt(element)))
      }

      if (key === 'kpi2SM') {
        let keys = Object.keys(this.processedDataSMLoaded)
        keys.forEach((element) => data.years.push(parseInt(element)))
      }

      try {
        result = await this.requests.post({
          url: urls.getURL('kpi2_report', 'kpi2QueryRefresh'),
          data
        })

        result = result?.data

        if (result) {
          for (let year in result.years) {
            this.processLoadedData(result.years[year], key, year)
          }
          this.reportCore.setLoadHeavy(false)
          return true
        }
      } catch (error) {
        this.reportCore.setLoadHeavy(false)
        return false
      }
    },

    buildDebugInfo() {
      this.unmappedKPIOwnersArrayCORE = useConvertObjectToSortedArray(this.unmappedKPIOwnersCORE)
      this.unmappedKPILeadGroupsArrayCORE = useConvertObjectToSortedArray(
        this.unmappedKPILeadGroupsCORE
      )

      this.unmappedKPIOwnersArraySM = useConvertObjectToSortedArray(this.unmappedKPIOwnersSM)
      this.unmappedKPILeadGroupsArraySM = useConvertObjectToSortedArray(
        this.unmappedKPILeadGroupsSM
      )
    },
    clearDebugInfo() {
      //clear unmapped sales amounts-------------------------------------
      for (let ownerName in this.unmappedKPIOwnersCORE) {
        let unmappedItem = this.unmappedKPIOwnersCORE[ownerName]
        unmappedItem.props.sales.value = 0
      }
      for (let ownerName in this.unmappedKPIOwnersSM) {
        let unmappedItem = this.unmappedKPIOwnersSM[ownerName]
        unmappedItem.props.sales.value = 0
      }
      for (let leadGroupName in this.unmappedKPILeadGroupsCORE) {
        let unmappedItem = this.unmappedKPILeadGroupsCORE[leadGroupName]
        unmappedItem.props.sales.value = 0
      }
      for (let leadGroupName in this.unmappedKPILeadGroupsSM) {
        let unmappedItem = this.unmappedKPILeadGroupsSM[leadGroupName]
        unmappedItem.props.sales.value = 0
      }
      //---------------------------------------------------------------
    }
  }
})
