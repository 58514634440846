import { defineStore } from 'pinia'
import { markRaw, toRaw } from 'vue'
import { useRequests } from '@/stores/Requests'
import { useReportCore } from '@/stores/ReportCore'
import { useURLS } from '@/stores/URLS.js'
import { useToasts } from '@/stores/Toasts.js'
import { useAuth } from '@/stores/Auth.js'

export const useDataBenchmarkLeadGroupStore = defineStore('DataBenchmarkLeadGroupStore', {
  state() {
    return {
      reportCore: markRaw(useReportCore()),
      requests: markRaw(useRequests()),
      urls: markRaw(useURLS()),
      benchmarkData: {},
      benchmarkDataByLeadGroup: markRaw({}),
      benchmarkDataLoaded: {},
      leadGroupsUnique: markRaw({})
    }
  },

  getters: {

    benchmarkDataCurrent(state) {
      let a = [];
      //need to return benchmarks in specific order
      let bm = state.benchmarkData[state.reportCore.dateRangeEnd.year]?.benchmarks
      if(bm){       
        a.push(bm['Appt Set Rate'])
        a.push(bm['Issued Rate'])
        a.push(bm['Demo Rate'])
        a.push(bm['Closing Rate'])      
        return a
      }else{
        return null
      }      
    },

  },

  actions: {
   

    validateDataIsLoaded() {
      //if refreshDataBenchmarkLeadGroup is set , then loading data again is manditory
      if (this.reportCore.refreshDataBenchmarkLeadGroup) {
        return false
      }

      // check if the year range contains a year not yet loaded into the app
      //default to true , so if a year is found missing in the data , it is set to false
      let skipLoading = true

      let yearMin = this.reportCore.dateRangeStart.year
      let yearMax = this.reportCore.dateRangeEnd.year

      // if min , max are equal , reduce min
      //since for timeline yoy calculations two years data is always required
      if (yearMin === yearMax) {
        yearMin--
      }

      let yearValidate = yearMin

      let whileTrue = true
      while (whileTrue) {
        if (!this.benchmarkDataLoaded[yearValidate]) {
          skipLoading = false
        }
        yearValidate++

        if (yearValidate > yearMax || skipLoading) {
          whileTrue = false
        }
      }

      return skipLoading
    },

    //-----------------------------------------------------------------

    async loadData() {
      //get current range year min and max
      //if min and max are the same , then get year below
      //because always loading two years of data
      //check to see if that data for those years is not already loaded

      let yearMin = this.reportCore.dateRangeStart.year
      let yearMax = this.reportCore.dateRangeEnd.year

      if (yearMin === yearMax) {
        yearMin--
      }
      let yearValidate = yearMin
      //validate if years are already loaded , unless refresh is needed
      if (!this.reportCore.refreshDataBenchmarkLeadGroup) {
        let skipLoading = true
        let whileTrue = true
        while (whileTrue) {
          if (!this.benchmarkDataLoaded[yearValidate]) {
            skipLoading = false
          }
          yearValidate++
          if (yearValidate > yearMax) {
            whileTrue = false
          }
        }
        if (skipLoading) {
          return true
        }
      }

      var data = {}
      data.years = []
      let whileTrue = true
      yearValidate = yearMin

      while (whileTrue) {
        if (!this.reportCore.refreshDataBenchmarkLeadGroup) {
          if (!this.benchmarkDataLoaded[yearValidate]) {
            data.years.push(yearValidate)
          }
        } else {
          data.years.push(yearValidate)
        }

        yearValidate++
        if (yearValidate > yearMax) {
          whileTrue = false
        }
      }

      //reset refreshDataBenchmarkLeadGroup to false incase it was true
      this.reportCore.refreshDataBenchmarkLeadGroup = false
      // fetches all benchmarks for current year
      let url = this.urls.getURL('benchmark_lead_group', 'find')
      this.reportCore.setLoadHeavy(true, 'Benchmark Data')
      var formData = data || {}

      let result
      try {
        result = await this.requests.post({
          url: url,
          data: formData
        })

        result = result?.data

        if (result) {
          for (let year in result.years) {
            this.processLoadedData(result.years[year], year)
          }
          this.reportCore.setLoadHeavy(false)
          return true
        }
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return false
      }
    },

    processLoadedData(result, year) {
     
      if(result.distinctBenchmarkNames.length === 0){
        return
      }
     
      this.benchmarkDataLoaded[year] = true
      let yearOb = (this.benchmarkData[year] = {})
      yearOb.distinctBenchmarkNames = result.distinctBenchmarkNames
      yearOb.benchmarks = {}
      result.benchmarks.forEach((element) => {
        let benchmark = (yearOb.benchmarks[element.name] = {})
        benchmark.name = element.name
        benchmark.id = element.id
        benchmark.weight = element.weight;
        benchmark.expanded = false
        benchmark.year = year
        benchmark.selected = false;
        benchmark.leadGroups = element.BenchmarkLeadGroupItems
      })

     //reset the object
      this.benchmarkDataByLeadGroup[year] = {}

      result.benchmarks.forEach((element) => {
       
        if (element.BenchmarkLeadGroupItems) {
          element.BenchmarkLeadGroupItems.forEach((elementItem) => {
          
            if (elementItem.active) {
              let yearOb = this.benchmarkDataByLeadGroup[year]
             
              let leadGroupOb = yearOb[elementItem.LeadGroup.name]
              if (!leadGroupOb) {
                leadGroupOb = yearOb[elementItem.LeadGroup.name] = {}
              }
              let benchmark = leadGroupOb[element.name]
              if (!benchmark) {
                benchmark = leadGroupOb[element.name] = elementItem
              }

              let leadGroupName = elementItem.LeadGroup.name
              this.leadGroupsUnique[leadGroupName] = leadGroupName
            }
          })
        }
      })

     
    }
  }
})
