<template>
  <div :key="dataPresetsReportFiltersStore.renderKey" class="pa-4 mt-min" style="background-color: var(--content-bg-13)">
    <div class="d-flex flex-wrap gap-3">
      <div>
        <div class="icon-color-1 fs-6 text-align-left">Dimension to Filter:</div>

        <div class="d-inline-flex flex-column align-items-start w-100">
          <div
            @click="setDimensionToFilterOwner"
            class="cursor-pointer d-inline-flex text-align-left align-items-baseline justify-content-start"
          >
            <span
              class="mdi icon icon-color-7"
              :class="{
                'mdi-checkbox-marked-outline': dataPresetsReportFiltersStore.getPresetData.useDimensionFilterOwner,
                'mdi-checkbox-blank-outline': !dataPresetsReportFiltersStore.getPresetData.useDimensionFilterOwner
              }"
            ></span>
            <div class="ms-1 fs-7 text-color-3">owner</div>
          </div>

          <div
            @click="setDimensionToFilterLeadGroup"
            class="cursor-pointer d-inline-flex text-align-left align-items-baseline justify-content-start"
          >
            <span
              class="mdi icon icon-color-7"
              :class="{
                'mdi-checkbox-marked-outline': dataPresetsReportFiltersStore.getPresetData.useDimensionFilterLeadGroup,
                'mdi-checkbox-blank-outline': !dataPresetsReportFiltersStore.getPresetData.useDimensionFilterLeadGroup
              }"
            ></span>
            <div class="ms-1 fs-7 text-color-3">lead group</div>
          </div>
        </div>
      </div>

      <div>
        <div class="icon-color-1 fs-6 text-align-left">Treat selections as:</div>
        <div class="d-inline-flex flex-column align-items-start w-100">
          <div
            @click="setInclusive"
            class="cursor-pointer d-inline-flex text-align-left align-items-baseline justify-content-start"
          >
            <span
              class="mdi icon icon-color-7"
              :class="{
                'mdi-checkbox-marked-outline': dataPresetsReportFiltersStore.getPresetData.isInclusive,
                'mdi-checkbox-blank-outline': !dataPresetsReportFiltersStore.getPresetData.isInclusive
              }"
            ></span>
            <div class="ms-1 fs-7 text-color-3">inclusive</div>
          </div>

          <div
            @click="setExclusive"
            class="cursor-pointer d-inline-flex text-align-left align-items-baseline justify-content-start"
          >
            <span
              class="mdi icon icon-color-7"
              :class="{
                'mdi-checkbox-marked-outline': dataPresetsReportFiltersStore.getPresetData.isExclusive,
                'mdi-checkbox-blank-outline': !dataPresetsReportFiltersStore.getPresetData.isExclusive
              }"
            ></span>
            <div class="ms-1 fs-7 text-color-3">exclusive</div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="mt-4 d-flex justify-content-left mb-4">
        <SelectButton
          v-model="currentTableSelection"
          :options="options"
          optionLabel="name"
          :allowEmpty="false"
        />
      </div>

      <div>
        <div v-show="currentTableSelection?.name === 'Owners'" class="w-100">
          <div class="d-flex align-items-center">
            <div class="icon-color-1 fw-bold fs-4 text-align-left">Owners</div>
            <span
              @click="refreshOwnersSelections"
              class="ms-2 fs-1 cursor-pointer mdi icon icon-color-1 mdi-refresh"
            ></span>
          </div>

          <div class="my-2">
            <div class="icon-color-1 fw-bold fs-4 text-align-left">start year</div>
            <div class="grid-2-local my-1">
              <div
                v-for="(startdateitem, index) in startdateitemsLabels"
                class="cursor-pointer text-color-3 fs-7 text-align-left"
                :key="`startdateitem_${index}`"
                @click="onStartDateItemSelected(startdateitem)"
              >
                {{ startdateitem }}
              </div>
            </div>
          </div>

          <div>
            <div
              class="pb-4 mt-2 border-bottom-4"
              v-for="alphagroup in dataPresetsReportFiltersStore.getDataOwnersLocal"
              :key="`owner_${alphagroup.name}`"
            >
              <div class="mb-2 icon-color-1 fw-bold fs-4 text-align-left">
                {{ alphagroup.name }}
              </div>
              <div class="grid-local" :style="getStyleForGrid(alphagroup)">
                <SelectionListItem
                  v-for="element in alphagroup.data"
                  :key="`owner_${element.id}`"
                  :componentData="element"
                  @select="onOwnerSelect"
                ></SelectionListItem>
              </div>
            </div>
          </div>
        </div>

        <div v-show="currentTableSelection?.name === 'Lead Groups'" class="w-100">
          <div class="d-flex align-items-center">
            <div class="icon-color-1 fw-bold fs-4 text-align-left">Lead Groups</div>
            <span
              @click="refreshLeadGroupsSelections"
              class="ms-2 fs-1 cursor-pointer mdi icon icon-color-1 mdi-refresh"
            ></span>
          </div>
          <div>
            <div
              class="pb-4 mt-2 border-bottom-4"
              v-for="alphagroup in dataPresetsReportFiltersStore.getDataLeadGroupsLocal"
              :key="`leadgroup_${alphagroup.name}`"
            >
              <div class="mb-2 icon-color-1 fw-bold fs-4 text-align-left">
                {{ alphagroup.name }}
              </div>
              <div class="grid-local" :style="getStyleForGrid(alphagroup)">
                <SelectionListItem
                  v-for="element in alphagroup.data"
                  :key="`leadgroup_${element.id}`"
                  :componentData="element"
                  @select="onLeadGroupSelect"
                ></SelectionListItem>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useReportCore } from '@/stores/ReportCore.js'
import { useDataPresetsReportFiltersStore} from '@/stores/DataPresetsReportFiltersStore.js'


import { ref, reactive, onMounted, computed, watch } from 'vue'
import SelectionListItem from '@/components/ui/SelectionListItem.vue'
import { useAuth } from '@/stores/Auth.js'

const emit = defineEmits(['update'])

const dataPresetsReportFiltersStore = useDataPresetsReportFiltersStore();



const reportCore = useReportCore()
const currentTableSelection = ref()
const optionsAllOwners = ref([{ name: 'Owners' }, { name: 'Lead Groups' }])
const auth = useAuth()
var startdateitemsObjects = {}
const startdateitemsLabels = ref()
const options = computed(() => {
  return optionsAllOwners.value
})

function setDimensionToFilterOwner() {
  dataPresetsReportFiltersStore.getPresetData.useDimensionFilterOwner = !dataPresetsReportFiltersStore.getPresetData.useDimensionFilterOwner

  emit('update')
}
function setDimensionToFilterLeadGroup() {
  dataPresetsReportFiltersStore.getPresetData.useDimensionFilterLeadGroup = !dataPresetsReportFiltersStore.getPresetData.useDimensionFilterLeadGroup

  emit('update')

}

function setInclusive() {
  dataPresetsReportFiltersStore.getPresetData.isInclusive = true
  dataPresetsReportFiltersStore.getPresetData.isExclusive = false
  emit('update')
}
function setExclusive() {
  dataPresetsReportFiltersStore.getPresetData.isInclusive = false
  dataPresetsReportFiltersStore.getPresetData.isExclusive = true
  emit('update')
}

function refreshOwnersSelections() {
  Object.values(dataPresetsReportFiltersStore.getPresetData.dataOwnersNoAliases).forEach((owner) => {
    owner.selected = false
  })

  emit('update')
}

function refreshLeadGroupsSelections() {
  
  Object.values(dataPresetsReportFiltersStore.getPresetData.dataLeadGroupsNoAliases).forEach((element) => {
    element.selected = false
  })

  emit('update')
}



function onOwnerSelect(event) {
  let owner = dataPresetsReportFiltersStore.getPresetData.dataOwnersNoAliases[event.name]
  owner.selected = event.selected

  emit('update')
}

function onLeadGroupSelect(event) {
  let leadgroup = dataPresetsReportFiltersStore.getPresetData.dataLeadGroupsNoAliases[event.name]
  leadgroup.selected = event.selected

  emit('update')
}

function generateStartDateItems() {
  let obs = {}
  let labels = []
  Object.values(reportCore.dataOwnersNoAliases).forEach((element) => {
    let item = obs[element.started_at.year]
    if (!item) {
      labels.push(element.started_at.year)
      item = obs[element.started_at.year] = []
    }
    item.push(element.name)
  })
  startdateitemsObjects = obs
  labels.sort((a, b) => {
    const numA = isNaN(a) ? a : parseFloat(a)
    const numB = isNaN(b) ? b : parseFloat(b)

    return numA - numB
  })
  startdateitemsLabels.value = labels
}

function onStartDateItemSelected(event) {
 
  let a = startdateitemsObjects[event]
  
  if (a) {
    a.forEach((element) => {
      let owner = dataPresetsReportFiltersStore.getPresetData.dataOwnersNoAliases[element]
     
      owner.selected = true
    })
  }
 

  emit('update')
}

onMounted(() => {
 
  currentTableSelection.value = { name: 'Owners' }

  

  generateStartDateItems()

 
 
})

function getStyleForGrid(dataItem) {
  let numrows = Math.min(8, dataItem.data.length)
  return ``
}
</script>

<style scoped>
.grid-local {
  display: grid;
  grid-gap: 0.4rem;

  /** grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));**/
}

.grid-2-local {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 0.4rem;
}
</style>
