<template>
  <div class="w-100 content-bg-5" :class="applicationStore.getPadding">
    <div
      class="content-bg-11 pa-6 d-flex flex-column justify-content-center align-items-center"
      style="gap: 2rem"
      :class="{
        'pa-4': !applicationStore.grid.tabletAndUnder,
        'elevation-2': !applicationStore.grid.tabletAndUnder,
        'rounded-2': !applicationStore.grid.tabletAndUnder
      }"
    >
      <div
        class="d-flex w-100 pa-4 flex-wrap justify-content-center align-items-center"
        style="gap: 3.5rem; background-color: #232222"
      >
        <img class="elevation-4" style="max-height: 300px; height: 30%" :src="imageUrl1" />

        <div
          class="text-align-left fw-bold fs-4 display-6 fw-normal m-0"
          style="line-height: 30px; color: #fff"
          v-html="blurb1"
        ></div>
      </div>

      <div class="w-100 px-4">
        <div class="w-100 mb-2 text-color-2 text-align-left fw-bold fs-5 display-6 fw-normal m-0">
          ERROR INFO:
        </div>
        <div class="w-100 text-color-2 text-align-left fw-regular fs-6 fw-normal m-0">
          {{ errorHandler.errorMessage }}
        </div>
      </div>
      <div class="w-100 d-flex flex-column align-items-center justify-content-center">
        <div
          style="background-color: var(--divider-1-color-top); width: 98%; height: 1px"
          class=""
        ></div>
        <div
          style="background-color: var(--divider-1-color-bottom); width: 98%; height: 1px"
          class=""
        ></div>
      </div>
      <div class="w-100 px-4 text-align-left">
        <div class="w-100 mb-2 text-color-2 text-align-left fw-regular fs-6 fw-normal m-0">
          Do you want to send this info to the application development team?
        </div>

        <b-button class="p-2 px-4 fs-5 fw-bolder" variant="primary" @click="submitError"
          >send</b-button
        >
      </div>
    </div>
  </div>
</template>
<script setup>
import imageUrl1 from '/images/error_page_graphic_1.jpeg'
import { useReportCore } from '@/stores/ReportCore.js'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import { computed, onMounted } from 'vue'
import { useRequests } from '@/stores/Requests.js'
import { useURLS } from '@/stores/URLS.js'
import { useErrorHandler } from '@/stores/ErrorHandler.js'
import { useToasts } from '@/stores/Toasts.js'
const reportCore = useReportCore()
const applicationStore = useApplicationStore()
const requests = useRequests()
const urls = useURLS()
const errorHandler = useErrorHandler()
const toasts = useToasts()
onMounted(() => {
  reportCore.setLoadHeavy(false)
})
//---------------------------------------------------------------------------------------

async function submitError() {
  reportCore.setLoadHeavy(true,"Error Data","submit")
  let errorMessage = errorHandler.errorMessage
  let stack = errorHandler.error?.stack

  if (stack) {
    let errorDetails = ''
    const stackLines = stack.split('\n')

    // Iterate through each line and extract the script name and line number
    stackLines.forEach((stackLine) => {
      const match = stackLine.match(/\s+at\s+(\S+)\s+\((.*?):(\d+):(\d+)\)/)
      if (match) {
        let scriptName = match[2].split('/').pop() // Extract script name without path
        scriptName = scriptName.split('?')[0]
        const lineNumber = match[3]
        errorDetails += `Error occurred in script: ${scriptName} at line: ${lineNumber}<br>`
      }
    })

    errorMessage += '<br><br>' + errorDetails
  }

  if (!stack) {
    stack = errorHandler.error?.response?.data?.stack
    if (stack) {
      let errorDetails = ''
      const stackLines = stack.split('\n')
      stackLines.forEach((stackLine) => {       
        errorDetails += `${stackLine}<br>`
      })
      errorMessage += '<br><br>' + errorDetails
    }
  }

 
  var formData = { errorMessage,stacktrace:stack }
  let result
  try {
    result = await requests.post({
      url: urls.getURL('application', 'errorReport'),
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    toasts.setSuccessMessage(result.data.message)
  }
}

const blurb1 = computed(() => {
  return `I'm sure that <br /><font color="#fd7e14" size="24px"> was working </font> <br />just a minute ago...`
})


</script>
<style scoped></style>
