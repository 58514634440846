import { useReportCore } from '@/stores/ReportCore.js'

export function useScoreCardBudgetOwners(title, reportBudget, key) {
  const reportCore = useReportCore()

  let data = {}
  data.title = title

  let valueCurrent = null

  valueCurrent = 0
  let ownersCount = 0



  for (let ownerName in reportBudget.tableDataOwnersBudgetsObj) {
    let ownerBudgetItem = reportBudget.tableDataOwnersBudgetsObj[ownerName]
    ownerBudgetItem.budgetTotal = 0

    let ownerItem = reportBudget.tableDataOwnersObj[ownerName]

    if (ownerBudgetItem.ignore) {
      // if ignore is true it is because the start date of the owner is after the selected date range
      //but check if the owner made a sale , then it is consided valied
     if(ownerItem){
      if (ownerItem.data.SALES_SUM > 0) {
        ownerBudgetItem.ignore = false
      }
    }
    }

    

      for (let budgetYearKey in ownerBudgetItem.budgetStore) {
        let budgetYear = ownerBudgetItem.budgetStore[budgetYearKey]
        for (let budgetMonthKey in budgetYear) {
          let budgetMonth = budgetYear[budgetMonthKey]
          ownerBudgetItem.budgetTotal += budgetMonth.value
        }
      }
   
    let mustProcess = false
    if(! ownerBudgetItem.ignore){
      if (ownerBudgetItem.ownerType === reportBudget.ownerTableFilterCurrent || reportBudget.ownerTableFilterCurrent === reportBudget.ownerTableFilterValueALL) {
        mustProcess = true
      }

    }
    if(mustProcess){

      let salesSum = ownerItem?.data.SALES_SUM||0
      let budget =  ownerBudgetItem.budgetTotal

      ownersCount++
      if (key === 'over') {
        let val = salesSum / budget

        if (isNaN(val) || !val || !isFinite(val)) {
          val = 0
        }

        if (val >= 1) {
          valueCurrent++
        }
      }
      if (key === 'under') {
        let val = salesSum / budget

        if (isNaN(val) || !val || !isFinite(val)) {
          val = 0
        }
        if (val < 1) {
          valueCurrent++
        }
      }
    }
  }



  

  data.value = valueCurrent

  if (isNaN(data.value) || !data.value || !isFinite(data.value)) {
    data.value = 0
  }
  if (isNaN(data.valueSub1) || !data.valueSub1 || !isFinite(data.valueSub1)) {
    data.valueSub1 = 0
  }
  if (isNaN(data.valueSub2) || !data.valueSub2 || !isFinite(data.valueSub2)) {
    data.valueSub2 = 0
  }

  data.valueFormat = 'INT'
  data.valueSub1Valid = true
  data.valueSub2Valid = true

  data.metricSub1Threshold = 0
  data.metricSub2Threshold = 1

  data.metricSub2 = data.value / ownersCount
  data.valueSub2 = ownersCount

  if (isNaN(data.metricSub1) || !data.metricSub1 || !isFinite(data.metricSub1)) {
    data.valueSub1Valid = false
  }

  if (isNaN(data.metricSub2) || !data.metricSub2 || !isFinite(data.metricSub2)) {
    data.valueSub2Valid = false
  }

  return data
}
