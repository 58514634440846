<template>
  <div class="mt-min pa-4 d-flex flex-column" style="background-color: var(--content-bg-13)">
    <div
      class="d-flex align-items-center"
      style="cursor: pointer"
      @click="sectionExpanded = !sectionExpanded"
    >
      <div class="flex-ui-1 text-type-10">
        <div class="icon-color-1" style="font-weight: 700; font-size: 20px">Filtering</div>
      </div>

      <span
        class="ms-auto mdi icon icon-color-1"
        :class="{
          ' mdi-plus-circle': !sectionExpanded,
          ' mdi-minus-circle': sectionExpanded
        }"
      >
      </span>
    </div>

    <div v-if="sectionExpanded">
      <div class="mt-2 flex-ui-1" style="align-items: center">
        <Checkbox
          v-model="dataPresetsReportFiltersStore.useDimensionFilters"
          @update:modelValue="updateuseDimensionFilters"
          inputId="useDimensionFilters"
          :binary="true"
        />
        <label for="useDimensionFilters" class="fs-8 text-color-5 text-type-10 ml-2">
          use filtering
        </label>
      </div>

      <div v-if="dataPresetsReportFiltersStore.getPresetsGroups.length || dataPresetsReportFiltersStore.getPresetsWithoutGroup.length" class="mt-4">
        <div class="icon-color-1 text-align-left" style="font-weight: 700; font-size: 16px">
          Filters
        </div>

        <div
          class="mt-2 mb-4 w-100 d-grid"
          style="grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); gap: 10px"
        >
          <div
            v-for="presetgroup in dataPresetsReportFiltersStore.getPresetsGroups"
            :key="'preset_preview' + presetgroup.id"
            style="margin-bottom: 0 !important"
            class="mb-0 fs-9 text-align-left text-color-1"
          >
            <div class="fs-7 mb-1 text-align-left icon-color-1">{{ presetgroup.name }}</div>
            <Dropdown
              style="width: 250px; max-width: 250px"
              @update:modelValue="setPreset"
              v-model="dataPresetsReportFiltersStore.selectedPreset"
              :options="dataPresetsReportFiltersStore.getPresetsWithGroup(presetgroup.id)"
              optionLabel="name"
              placeholder="Select a Preset"
              class=""
            />
          </div>
        </div>

        <div
          class="mt-2 mb-4 w-100 d-grid"
          style="grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); gap: 10px"
        >
          <div
            v-for="presetwithoutgroup in dataPresetsReportFiltersStore.getPresetsWithoutGroup"
            :key="'preset_preview' + presetwithoutgroup.id"
            style="margin-bottom: 0 !important"
            class="mb-0 fs-9 text-align-left text-color-1"
          >
            <div
              class="fs-7 text-align-left icon-color-1 pa-2"
              style="background-color: #333; cursor: pointer"
              @click="setPreset(presetwithoutgroup)"
            >
              {{ presetwithoutgroup.name }}
            </div>
          </div>
        </div>
      </div>

      <div class="mt-2 flex-ui-1" style="align-items: center">
        <Checkbox
          v-model="showDimensionFilterSelections"
          inputId="showDimensionFilterSelections"
          :binary="true"
        />
        <label for="showDimensionFilterSelections" class="fs-8 text-color-5 text-type-10 ml-2">
          show filter selections
        </label>
      </div>

      <InclusionExclusionDimensions v-if="showDimensionFilterSelections" @update="updateFromChild" />
    </div>
  </div>
</template>

<script setup>
import { useDataPresetsReportFiltersStore } from '@/stores/DataPresetsReportFiltersStore.js'
import InclusionExclusionDimensions from '@/components/charts/InclusionExclusionDimensions.vue'
import { ref, onMounted } from 'vue'
const emit = defineEmits(['update'])

const dataPresetsReportFiltersStore = useDataPresetsReportFiltersStore()
const showDimensionFilterSelections = ref(false)
const sectionExpanded = ref(false)
function updateuseDimensionFilters(useDimensionFilters) {
 
  dataPresetsReportFiltersStore.useDimensionFilters = useDimensionFilters
  update()
}

function update() {
  emit('update')
}

function updateFromChild() {
  //since update is emit from child , check if useDimensionFilters is true before emit 
  if (dataPresetsReportFiltersStore.useDimensionFilters) {
    update()
  }
}

function setPreset(preset) {
  //when a preset is selected , activate filtering automatically
  dataPresetsReportFiltersStore.useDimensionFilters = true
  dataPresetsReportFiltersStore.setPreset(preset)
  update()
}

onMounted(() => {
  dataPresetsReportFiltersStore.resetPresetToDefaultEmpty()
})
</script>
