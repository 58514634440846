<template>
    <div class="width-100 height-100">
    <div  style="display: flex">
      <div
        v-for="(item, index) in componentData.children"
        :key="keyref+ '_child_' + index"
        :style="'background-color:'+item.color"
        class="grid-1-item"
       
      >
    </div>
    
    </div>
    <input type="range" v-model="sliderIndex" class="form-range" min="0" max="49" step="1" id="customRange3"/>
  </div>
    
  </template>
  
  <script setup>
  
  import { ref,reactive,watch,computed } from "vue";
  let sliderIndex = ref(null);
  const props = defineProps({
    componentData: {
      type: Object,
      default: () => {
        return;
      },
    },
   
  });
  
  const componentDataLocal = reactive({ value: props.componentData });
  watch(
    () => props.componentData,
    () => {
      componentDataLocal.value = { ...props.componentData };
    }
  );
 
  
  
  
 
 
  </script>
  
  <style></style>
  