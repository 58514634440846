import pinia from "@/stores/index.js";
import { useAuth } from '@/stores/Auth.js';
export default function auth( to, from ) { 
  const auth = useAuth()
 
  if (!auth.token) {
   
    return {
      path: "/",
    };
  }
  

  return true;
}
