import { useReportCore } from '@/stores/ReportCore.js'
export function useBuildMetricDataScoreCards(title, format, metrics, metricKey) {
  const reportCore = useReportCore()

  let data = {}
  data.title = title

  let valueCurrent = null
  let valueSub1 = null
  let valueSub2 = null

  if (Array.isArray(metricKey)) {
    let valA = null
    let valB = null

    let valAsub1 = null
    let valBsub1 = null

    let valAsub2 = null
    let valBsub2 = null

    if (Array.isArray(metricKey[0])) {
     
      valA = (metrics.rangeCurrent[metricKey[0][0]] / metrics.rangeCurrent[metricKey[0][1]])*100
      valAsub1 = (metrics.rangeSub1[metricKey[0][0]] / metrics.rangeSub1[metricKey[0][1]])*100
      valAsub2 = (metrics.rangeSub2[metricKey[0][0]] / metrics.rangeSub2[metricKey[0][1]])*100
    } else {
      valA = metrics.rangeCurrent[metricKey[0]]
      valAsub1 = metrics.rangeSub1[metricKey[0]]
      valAsub2 = metrics.rangeSub2[metricKey[0]]
    }

    if (Array.isArray(metricKey[1])) {
     
      valB = (metrics.rangeCurrent[metricKey[1][0]] / metrics.rangeCurrent[metricKey[1][1]])*100
      valBsub1 = (metrics.rangeSub1[metricKey[1][0]] / metrics.rangeSub1[metricKey[1][1]])*100
      valBsub2 = (metrics.rangeSub2[metricKey[1][0]] / metrics.rangeSub2[metricKey[1][1]])*100
    } else {
      valB = metrics.rangeCurrent[metricKey[1]]
      valBsub1 = metrics.rangeSub1[metricKey[1]]
      valBsub2 = metrics.rangeSub2[metricKey[1]]
    }
    valueCurrent = valA / valB
    valueSub1 = valAsub1 / valBsub1
    valueSub2 = valAsub2 / valBsub2
  } else {
    valueCurrent = metrics.rangeCurrent[metricKey]
    valueSub1 = metrics.rangeSub1[metricKey]
    valueSub2 = metrics.rangeSub2[metricKey]
  }

  data.value = valueCurrent ?? 0
  data.valueSub1 = valueSub1 ?? 0
  data.valueSub2 = valueSub2 ?? 0

  if (isNaN(data.value) || !data.value || !isFinite(data.value)) {
    data.value = 0
  }
  if (isNaN(data.valueSub1) || !data.valueSub1 || !isFinite(data.valueSub1)) {
    data.valueSub1 = 0
  }
  if (isNaN(data.valueSub2) || !data.valueSub2 || !isFinite(data.valueSub2)) {
    data.valueSub2 = 0
  }

  data.valueFormat = format
  data.valueSub1Valid = true
  data.valueSub2Valid = true

  data.metricSub1 = reportCore.getGrowth(valueSub1, valueCurrent)

  if (reportCore.popRangeValid || reportCore.momRangeValid) {
    data.metricSub2 = reportCore.getGrowth(valueSub2, valueCurrent)
  }

  if (isNaN(data.metricSub1) || data.metricSub1 === null || data.metricSub1 === undefined || !isFinite(data.metricSub1)) {
    data.valueSub1Valid = false
  }
  if (reportCore.popRangeValid || reportCore.momRangeValid) {
    if (isNaN(data.metricSub2) || data.metricSub2 === null || data.metricSub2 === undefined || !isFinite(data.metricSub2)) {
      data.valueSub2Valid = false
    }
  } else {
    data.valueSub2Valid = false
  }

  data.metricSub1Threshold = 0
  data.metricSub2Threshold = 0

  return data
}
