<template>
  <button v-if="!componentDataLocal.value.disabled" :class="getClassButton" @click="handleInteraction">
    <component
      :is="componentDataLocal.value.content"
      v-bind="{ componentData: componentDataLocal.value,styling:styling }"
    >
    </component>
  </button>
</template>

<script setup>
import { reactive, watch, computed } from "vue";


const emit = defineEmits(["select"]);
const props = defineProps({
  componentData: {
    type: Object,
    default: () => {
      return null;
    },
  },
  styling: {
    type: Object,
    default: () => {
      return null;
    },
  },
  keyref: {
    type: String,
    default: "",
  },
});
const componentDataLocal = reactive({ value: props.componentData });
watch(
  () => props.componentData,
  () => {
    componentDataLocal.value = { ...props.componentData };
  }
);

let getClassButton = computed(() => {
  return componentDataLocal.value.enabled
    ? props.styling?.classStringContainerSelected ?? componentDataLocal.value.styling.classStringContainerSelected
    : props.styling?.classStringContainerDefault ?? componentDataLocal.value.styling.classStringContainerDefault;
});

let getStyle = computed(() => {
  return componentDataLocal.value.disabled
    ? "opacity:0.1;cursor:auto !important"
    : "opacity:unset;cursor:pointer !important"
});


function handleInteraction($event) {
  if (componentDataLocal.value.disabled) return;
  if (componentDataLocal.value.interaction.toggle) {
    componentDataLocal.value.enabled = !componentDataLocal.value.enabled;
  } else {
    if (componentDataLocal.value.enabled) {
      return;
    }
    componentDataLocal.value.enabled = !componentDataLocal.value.enabled;
  }

  emit("select", componentDataLocal.value, $event);
}
</script>
