<template>
  <div class="w-100 h-100 d-flex flex-wrap">
    <div class="d-flex me-auto align-items-center">
      <slot name="left"></slot>
    </div>
    <div class="d-flex align-items-center flex-row-reverse ms-auto">
      <slot name="right"></slot>
    </div>
  </div>
</template>


