<template>
  <div class="w-100 content-bg-5" :class="applicationStore.getPadding">
    <div
      class="content-bg-11 pa-6 d-flex flex-column justify-content-center align-items-center"
      style="gap: 2rem"
      :class="{
        'pa-4': !applicationStore.grid.tabletAndUnder,
        'elevation-2': !applicationStore.grid.tabletAndUnder,
        'rounded-2': !applicationStore.grid.tabletAndUnder
      }"
    >
      <div
        class="d-flex w-100 pa-4 flex-wrap justify-content-center align-items-center"
        style="gap: 3.5rem; background-color: #232222"
      >
        <img class="elevation-4" style="max-height: 300px; height: 30%" :src="imageUrl1" />

        <div
          class="text-align-left fw-bold fs-5 display-6 fw-normal m-0"
          style="color: #fff"
          v-html="blurb1"
        ></div>
      </div>

     
    </div>
  </div>
</template>
<script setup>
import imageUrl1 from '/images/access_denied_graphic_1.jpeg'
import { useReportCore } from '@/stores/ReportCore.js'
import { useToasts } from '@/stores/Toasts.js'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import { computed, onMounted } from 'vue'
import { useRequests } from '@/stores/Requests.js'
import { useURLS } from '@/stores/URLS.js'
const reportCore = useReportCore()
const applicationStore = useApplicationStore()
const toasts = useToasts()
const requests = useRequests()
const urls = useURLS()
onMounted(() => {
  reportCore.setLoadHeavy(false)
})
//---------------------------------------------------------------------------------------


const blurb1 = computed(() => {
  return `You don't have permission to access this resource <br /><font color="#fd7e14" size="18px"> Did you forget to say the magic words? </font> <br />Maybe if you ask the system administrator nicely, you'll get permission....`
})


</script>
<style scoped></style>
