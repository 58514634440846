<template>
  <div class="w-100 mx-auto printable" style="max-width: 99%" :class="applicationStore.getPadding">
    <div class="content-bg-11" :class="{
    'pa-4': !applicationStore.grid.tabletAndUnder,
    'elevation-2': !applicationStore.grid.tabletAndUnder,
    'rounded-2': !applicationStore.grid.tabletAndUnder
  }">
      <div class="pa-4 d-flex flex-column" style="background-color: var(--content-bg-13)">
        <div class="d-flex align-items-center" style="cursor: pointer"
          @click="reportKPIBenchmarkLeadGroup.section1Expanded = !reportKPIBenchmarkLeadGroup.section1Expanded">

          <div class="flex-ui-1 text-type-10">
            <div class="fs-7 mr-2 text-type-10 icon-color-1">report:</div>
            <div class="icon-color-1" style="font-weight: 700; font-size: 20px">Benchmarks Report</div>
          </div>

          <span class="ms-auto mdi icon icon-color-1" :class="{
    ' mdi-plus-circle': !reportKPIBenchmarkLeadGroup.section1Expanded,
    ' mdi-minus-circle': reportKPIBenchmarkLeadGroup.section1Expanded
  }">
          </span>
        </div>

        <div v-if="reportKPIBenchmarkLeadGroup.section1Expanded">

          <div class="d-flex align-items-center flex-wrap text-type-10 p-0">
            <div class="icon-color-1">Current Filters Applied:</div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">owner:</div>
              <div style="color: #ffca00">{{ reportKPIBenchmarkLeadGroup.ownerFilter || 'All Owners' }} ,</div>
            </div>



            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">group:</div>
              <div style="color: #ffca00">{{ reportKPIBenchmarkLeadGroup.leadGroupFilter || 'All Groups' }} ,</div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">date:</div>
              <div style="color: #ffca00">
                {{ reportCore.dateRangeStart.getDateString() }}
              </div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mx-2 text-type-10 icon-color-1">to</div>
              <div style="color: #ffca00">
                {{ reportCore.dateRangeEnd.getDateString() }}
              </div>
            </div>
          </div>

          <div class="mt-2 flex-ui-1" style="align-items: center">
            <Checkbox @change="onUseDataSourceOverrideChanged" v-model="reportCore.useDataSourceOverride"
              inputId="expandScorecards" :binary="true" />
            <label for="expandScorecards" class="fs-8 text-color-5 text-type-10 ml-2">
              override per user data sources
            </label>
          </div>


          <div v-if="reportCore.useDataSourceOverride">

            <div class="mt-3 text-type-11 icon-color-1 p-0">select data source:</div>
            <div class="mt-1 mb-2 flex-ui-1 gap-3" style="align-items: center">
              <div class="d-flex align-items-center">
                <RadioButton @update:modelValue="onKPIRouteKeyChange" v-model="reportCore.dataSourceCurrent"
                  inputId="datasource1" name="kpi_route_key" :value="reportCore.DATA_SOURCE_CORE" />
                <label for="datasource1" class="fs-8 text-color-5 text-type-10 ml-2">CORE</label>
              </div>
              <div class="d-flex align-items-center">
                <RadioButton @update:modelValue="onKPIRouteKeyChange" v-model="reportCore.dataSourceCurrent"
                  inputId="datasource2" name="kpi_route_key" :value="reportCore.DATA_SOURCE_SM" />
                <label for="datasource2" class="fs-8 text-color-5 text-type-10 ml-2">SM</label>
              </div>
              <div class="d-flex align-items-center">
                <RadioButton @update:modelValue="onKPIRouteKeyChange" v-model="reportCore.dataSourceCurrent"
                  inputId="datasource3" name="kpi_route_key" :value="reportCore.DATA_SOURCE_BLEND" />
                <label for="datasource3" class="fs-8 text-color-5 text-type-10 ml-2">BLEND</label>
              </div>
            </div>

            <div v-if="reportCore.dataSourceCurrent === reportCore.DATA_SOURCE_BLEND"
              class="mt-3 mb-3 d-flex justify-content-start"
              :class="{ 'flex-column': applicationStore.grid.tabletAndUnder, 'align-items-start': applicationStore.grid.tabletAndUnder, 'align-items-center': !applicationStore.grid.tabletAndUnder }">
              <p class="text-type-9 text-color-5 p-0 mb-0 mr-2 fs-8">Blend Date</p>
              <Calendar panelClass="min-w-min" v-model="reportCore.dateSelectionBlend"
                @date-select="onDateSelectionBlend" selectionMode="single" :manualInput="false" inputId="range" />
            </div>


          </div>




          <div class="mt-3 flex-ui-1" style="align-items: center">
            <div class="text-type-11 icon-color-1 p-0 mr-2">KPI CORE cached:</div>
            <div class="text-type-11 p-0" style="color: #ffca00">
              {{ dataKPI1Store.queryCacheDateCORE ?? '' }}
            </div>
            <button v-if="auth.hasPermission('invalidate_kpi_1_report_cache')" class="button-minimal"
              @click="toggleQueryCacheExtendedDisplay = !toggleQueryCacheExtendedDisplay">
              <span class="mdi icon icon-color-1 mdi-help-circle"></span>
            </button>
          </div>
          <div class="flex-ui-1" style="align-items: center">
            <div class="text-type-11 icon-color-1 p-0 mr-2">KPI SM cached:</div>
            <div class="text-type-11 p-0" style="color: #ffca00">
              {{ dataKPI1Store.queryCacheDateSM ?? '' }}
            </div>
            <button v-if="auth.hasPermission('invalidate_kpi_1_report_cache')" class="button-minimal"
              @click="toggleQueryCacheExtendedDisplay = !toggleQueryCacheExtendedDisplay">
              <span class="mdi icon icon-color-1 mdi-help-circle"></span>
            </button>
          </div>


        </div>



      </div>

      <div v-if="toggleQueryCacheExtendedDisplay && reportKPIBenchmarkLeadGroup.section1Expanded" class="mt-min pa-4"
        style="background-color: var(--content-bg-13)">
        <div class="text-type-11 icon-color-1 p-0">Queries are cached once a day at midnight</div>
        <div class="mt-1 d-flex flex-wrap align-items-center gap-2">
          <b-button class="p-1 fs-6 fw-bolder px-3" variant="primary"
            @click="reportKPIBenchmarkLeadGroup.refreshQueryCache($event, 'kpi1')">refresh CORE</b-button>

          <b-button class="p-1 fs-6 fw-bolder px-3" variant="primary"
            @click="reportKPIBenchmarkLeadGroup.refreshQueryCache($event, 'kpi1SM')">refresh SM</b-button>
        </div>
      </div>

      <div class="mt-min pa-1 py-3 px-3" style="background-color: var(--content-bg-13)">


        <div
          style="display: grid !important;grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));grid-gap: 0.4rem !important;">
          <div class="d-flex align-items-center justify-content-center"
            :class="{ 'flex-column': applicationStore.grid.tabletAndUnder }">
            <p class="text-type-9 text-color-5 p-0 mb-0 mr-2 fs-8">Range</p>
            <Calendar panelClass="min-w-min" :key="invalidateRangeCalenderKey" v-model="dateSelectionRange"
              @date-select="onDateRangeSelect" selectionMode="range" :manualInput="false" inputId="range" />
          </div>

          <div class="d-flex align-items-center justify-content-center"
            :class="{ 'flex-column': applicationStore.grid.tabletAndUnder }">
            <p class="text-type-9 text-color-5 p-0 mb-0 mr-2 fs-8">Month</p>
            <Calendar panelClass="min-w-min" v-model="dateSelectionMonth" :yearRange="reportCore.getYearRange()"
              :minDate="reportCore.getYearRangeMin()" :maxDate="reportCore.getYearRangeMax()"
              @date-select="onDateMonthSelect" view="month" dateFormat="mm/yy" inputId="month" />
          </div>



          <div class="d-flex align-items-center justify-content-center"
            :class="{ 'flex-column': applicationStore.grid.tabletAndUnder }">
            <p class="text-type-9 text-color-5 p-0 mb-0 mr-2 fs-8">Year</p>
            <Calendar panelClass="min-w-min" :yearRange="reportCore.getYearRange()"
              :minDate="reportCore.getYearRangeMin()" :maxDate="reportCore.getYearRangeMax()"
              v-model="dateSelectionYear" @date-select="onDateYearSelect" view="year" dateFormat="yy" inputId="year" />
          </div>

          <div class="d-flex align-items-center justify-content-center">
            <button class="text-color-9 h6 m-0 mx-1 button-minimal" @click="dateQ1" style="display: block">
              <div>Q1</div>
            </button>

            <button class="text-color-9 h6 m-0 mx-1 button-minimal" @click="dateQ2" style="display: block">
              <div>Q2</div>
            </button>

            <button class="text-color-9 h6 m-0 mx-1 button-minimal" @click="dateQ3" style="display: block">
              <div>Q3</div>
            </button>

            <button class="text-color-9 h6 m-0 mx-1 button-minimal" @click="dateQ4" style="display: block">
              <div>Q4</div>
            </button>

          </div>
          <div class="d-flex align-items-center justify-content-center">

            <button class="text-color-9 h6 m-0 mx-1 button-minimal" @click="dateYTD" style="display: block">
              <div>YTD</div>
            </button>
            <button class="text-color-9 h6 m-0 mx-1 button-minimal" @click="dateYTLM" style="display: block">
              <div>YTLM</div>
            </button>
          </div>


        </div>
        <div v-if="false" class="mt-2 flex-ui-1" style="align-items: center">
          <Checkbox @change="showDataAsDailyAvgChanged" v-model="reportCore.showDataAsDailyAvg"
            inputId="expandScorecards" :binary="true" />
          <label for="expandScorecards" class="fs-8 text-color-5 text-type-10 ml-2">
            convert results to daily average
          </label>
        </div>

      


      </div>

      <ReportFiltersComponent @update="reportKPIBenchmarkLeadGroup.buildReport()"/>

      




        <div class="mt-4 d-grid" :style="getGridStyle">
              <div v-for="(benchmark, index) in dataBenchmarkLeadGroupStore.benchmarkDataCurrent" :key="'benchmark_' + index"
                >
                <div class="text-type-12 icon-color-1 p-2 text-center" :class="{
    'nav-item-1-bg': !benchmark.selected,
    'nav-item-1-selected-bg': benchmark.selected
  }" style="cursor: pointer" @click="reportKPIBenchmarkLeadGroup.selectAndInvalidateBenchmark(benchmark)">
{{ benchmark.name }}
                </div>

                <div v-if="auth.hasPermission('edit_benchmark_lead_group_management_data')" class="d-flex mt-1" style="align-items: center">
                    
                    <label :for="'benchmarkweight' + benchmark.name"  class="fs-8 text-color-2 mr-2 text-type-10">
                      weight
                    </label>

                    <b-form-input  :id="'benchmarkweight' + benchmark.name" v-model="benchmark.weight"
  class="fw-regular text-color-2-inverted" @focus="kpiBenchmarkLeadGroupManagement.cacheInputBenchmarkWeightValue(benchmark)"
  @blur="reportKPIBenchmarkLeadGroup.validateInputBenchmarkWeightValue(benchmark)" @keydown.enter="reportKPIBenchmarkLeadGroup.validateInputBenchmarkWeightValue(benchmark)"
  trim :disabled="!kpiBenchmarkLeadGroupManagement.manageBenchmarkData"></b-form-input>

                  </div>

                
              </div>
            </div>

      <div class="d-flex my-4 flex-wrap justify-content-around gap-2">
       
       

          
            <div :style="getTableContainerStyle1">
              <ReportTableComponent configKey="reportKPIBenchmarkLeadGroupOwner" vShowKey="Owners"/>
            </div>
            <div :style="getTableContainerStyle2">
              <ReportTableComponent configKey="reportKPIBenchmarkLeadGroupLeadGroupAppSetRate" :vShowKey="reportKPIBenchmarkLeadGroup.leadGrouptableVShow"/>
              <ReportTableComponent configKey="reportKPIBenchmarkLeadGroupLeadGroupIssuedRate" :vShowKey="reportKPIBenchmarkLeadGroup.leadGrouptableVShow"/>
              <ReportTableComponent configKey="reportKPIBenchmarkLeadGroupLeadGroupDemoRate" :vShowKey="reportKPIBenchmarkLeadGroup.leadGrouptableVShow"/>
              <ReportTableComponent configKey="reportKPIBenchmarkLeadGroupLeadGroupClosingRate" :vShowKey="reportKPIBenchmarkLeadGroup.leadGrouptableVShow"/>
            </div>

         
        


      </div>

      <div class="pa-4 mb-2 d-flex flex-column content-bg-10">
        <div class="d-flex flex-wrap fs-8 fw-bold p-0"
          :class="{ 'align-items-center': !applicationStore.grid.tabletAndUnder, 'align-items-start': applicationStore.grid.tabletAndUnder, 'flex-column': applicationStore.grid.tabletAndUnder }">
          <div class="text-color-1 fs-6">Growth Date Ranges:</div>

          <div class="flex-ui-1 ml-2">
            <div class="fs-7 mr-2 text-type-10 text-color-1">YOY:</div>
            <div style="color: #ff6600">
              {{ reportCore.oneYearBeforeStart.getDateString() }}
            </div>
            <div class="fs-7 mx-2 text-type-10 text-color-1">to</div>
            <div style="color: #ff6600">
              {{ reportCore.oneYearBeforeEnd.getDateString() }}
            </div>
          </div>

          <div v-if="reportCore.popRangeValid || reportCore.momRangeValid" class="flex-ui-1 ml-2">
            <div class="fs-7 mr-2 text-type-10 text-color-1">
              {{ reportCore.momRangeValid ? 'MOM' : 'POP' }}:
            </div>
            <div style="color: #ff6600">
              {{ reportCore.periodBeforeStart.getDateString() }}
            </div>
            <div class="fs-7 mx-2 text-type-10 text-color-1">to</div>
            <div style="color: #ff6600">
              {{ reportCore.periodBeforeEnd.getDateString() }}
            </div>
          </div>
        </div>
        <div class="mt-2 flex-ui-1" style="align-items: center">
          <Checkbox v-model="reportCore.expandScorecards" inputId="expandScorecards" :binary="true" />
          <label for="expandScorecards" class="fs-8 text-color-1 text-type-10 ml-2">
            Expand Scorecards
          </label>
        </div>
      </div>

     


       

<div class="w-100" style="
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 0.5rem 0.5rem;
  ">
<ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPIBenchmarkLeadGroup?.scoreCards.ApptSetRate"
  :labels="reportKPIBenchmarkLeadGroup?.scoreCards.ApptSetRate?.scorecardLabels"></ScoreCardComponent>

  <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPIBenchmarkLeadGroup?.scoreCards.IssuedRate"
  :labels="reportKPIBenchmarkLeadGroup?.scoreCards.IssuedRate?.scorecardLabels"></ScoreCardComponent>

  <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPIBenchmarkLeadGroup?.scoreCards.DemoRate"
  :labels="reportKPIBenchmarkLeadGroup?.scoreCards.DemoRate?.scorecardLabels"></ScoreCardComponent>

  <ScoreCardComponent style="flex: 0 0 0" :componentData="reportKPIBenchmarkLeadGroup?.scoreCards.ClosingRate"
  :labels="reportKPIBenchmarkLeadGroup?.scoreCards.ClosingRate?.scorecardLabels"></ScoreCardComponent>

 

  

</div>


<div class="mt-4 content-bg-4 pa-4 rounded-2 elevation-4">
    <Gauge :key="reportKPIBenchmarkLeadGroup.gaugeConfig.renderKey" :config="reportKPIBenchmarkLeadGroup.gaugeConfig"/>
  </div>
      

      <div class="elevation-4 pa-4 content-bg-4 rounded-2 w-100 mt-4">
        <div class="mb-2">
          <ItemContainerLeftRightSlotsComponent>
            <template #left>
              <p class="text-type-10 nav-color-1 p-0 m-0 fs-6">
                {{ reportKPIBenchmarkLeadGroup.currentLineChartMetricTitle }}
              </p>
              <div class="ml-2 d-flex align-content-center">
                <div class="d-inline-block"
                  :style="reportKPIBenchmarkLeadGroup.timelineData.datasets[0].lengendBlockStyle"></div>
                <p class="d-inline-block text-type-10 nav-color-1 p-0 m-0 fs-8 ms-1">
                  {{ reportCore.currentDate.year }}
                </p>
              </div>

              <div class="ml-2 d-flex align-content-center">
                <div class="d-inline-block"
                  :style="reportKPIBenchmarkLeadGroup.timelineData.datasets[1].lengendBlockStyle"></div>
                <p class="d-inline-block text-type-10 nav-color-1 p-0 m-0 fs-8 ms-1">
                  {{ reportCore.currentDate.year - 1 }}
                </p>
              </div>
            </template>
            <template #right>
              <button class="button-minimal" @click="toggleTimelineMetrics = !toggleTimelineMetrics">
                <span class="mdi icon nav-color-1 mdi-menu"></span>
              </button>

              <Dropdown v-if="toggleTimelineMetrics" v-model="selectedTimelineMetric"
                :options="reportKPIBenchmarkLeadGroup.timelineOptionsMetrics" placeholder="Select Metric" class="m-2"
                style="width: 250px; max-width: 250px" @update:modelValue="onUpdateTimelineMetric" />
            </template>
          </ItemContainerLeftRightSlotsComponent>
        </div>

        <Chart type="line" :data="reportKPIBenchmarkLeadGroup.timelineData" :options="chartOptions" :height="260" />
      </div>

     

     


      <div v-if="auth.hasPermission('view_kpi_1_report_debug')" class="pa-4 mt-2"
        style="background-color: var(--content-bg-13)">
        <div class="flex-ui-1" style="align-items: center">
          <Checkbox v-model="reportKPIBenchmarkLeadGroup.showDebugData" inputId="showdebugdata" :binary="true" />
          <label for="showdebugdata" class="fs-8 icon-color-1 text-type-10 ml-2">
            Show Debug Data
          </label>
        </div>


      </div>

      <div v-if="reportKPIBenchmarkLeadGroup.showDebugData && auth.hasPermission('view_kpi_1_report_debug')"
        class="mt-4">


        <div v-if="dataKPI1Store.unmappedKPIOwnersArrayCORE?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped owners CORE</div>
          <ListOfGrid :listData="dataKPI1Store.unmappedKPIOwnersArrayCORE" classDynamic="data-table-4"></ListOfGrid>

        </div>

        <div v-if="dataKPI1Store.unmappedKPIOwnersArraySM?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped owners SM</div>
          <ListOfGrid :listData="dataKPI1Store.unmappedKPIOwnersArraySM" classDynamic="data-table-4"></ListOfGrid>

        </div>

        <div v-if="dataKPI1Store.unmappedKPILeadGroupsArrayCORE?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped lead groups CORE</div>
          <ListOfGrid :listData="dataKPI1Store.unmappedKPILeadGroupsArrayCORE" classDynamic="data-table-4"></ListOfGrid>

        </div>

        <div v-if="dataKPI1Store.unmappedKPILeadGroupsArraySM?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped lead groups SM</div>
          <ListOfGrid :listData="dataKPI1Store.unmappedKPILeadGroupsArraySM" classDynamic="data-table-4"></ListOfGrid>

        </div>
      </div>



    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from 'vue'

import { useReportKPIBenchmarkLeadGroup } from '@/stores/ReportKPIBenchmarkLeadGroup.js'
import { useKPIBenchmarkLeadGroupManagement } from '@/stores/KPIBenchmarkLeadGroupManagement.js'
import { useDataBenchmarkLeadGroupStore } from '@/stores/DataBenchmarkLeadGroupStore.js'


import { useDataKPI1Store } from '@/stores/DataKPI1Store.js'

import { useReportCore } from '@/stores/ReportCore.js'
import { useAuth } from '@/stores/Auth.js'
import ItemContainerLeftRightSlotsComponent from '@/components/layout/ItemContainerLeftRightSlotsComponent.vue'
import Gauge from '@/components/charts/Gauge.vue';

import ReportFiltersComponent from '@/components/ui/ReportFiltersComponent.vue'




import ScoreCardComponent from '@/components/charts/ScoreCardComponent.vue'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import ListOfGrid from '@/components/ui/ListOfGrid.vue'

import ReportTableComponent from '@/components/ui/ReportTableComponent.vue'




const gaugeConfig = ref({
  maxValue: 400,
  segments: [
    { value: 100, color: 'red' },
    { value: 170, color: 'orange' },
    { value: 130, color: 'green' }
  ],
  useLables: true,
  useWedgeStrokes: true,
  needleValue: 270,
  textPadding: 30,
  textPaddingLabel: 20,
  textSize: 64,
  textSizeLabel: 14,
  textColor: 'black',
  textColorLabel: '#888888',
  wedgeStrokeWidth: 10,
  canvasSize: 300,

});






const applicationStore = useApplicationStore()

const dateSelectionRange = ref([null, null])
const dataKPI1Store = useDataKPI1Store();
const dateSelectionMonth = ref()

const dateSelectionYear = ref()
const reportKPIBenchmarkLeadGroup = useReportKPIBenchmarkLeadGroup()
const kpiBenchmarkLeadGroupManagement = useKPIBenchmarkLeadGroupManagement()
const dataBenchmarkLeadGroupStore = useDataBenchmarkLeadGroupStore()



const reportCore = useReportCore()
const auth = useAuth()
const invalidateRangeCalenderKey = ref(0)

const toggleTimelineMetrics = ref(false)
const toggleQueryCacheExtendedDisplay = ref(false)

const selectedTimelineMetric = ref(reportKPIBenchmarkLeadGroup.currentLineChartMetric)

const chartOptions = ref()



const getGridStyle = computed(() => {
  let val = 'grid-template-columns: repeat(auto-fit, minmax(60px, 1fr)); gap: 0.2rem'
  if (applicationStore.grid.tabletAndUnder) {
    val = 'grid-template-columns: repeat(2, minmax(60px, 1fr)); gap: 0.2rem'
  }

  

  return val
})



//-------------------------------------------------------------------

function onUseDataSourceOverrideChanged() {
  reportKPIBenchmarkLeadGroup.buildReport()
}

function onDateSelectionBlend() {
  reportCore.dataBlendDate.getValuesFromDate(reportCore.dateSelectionBlend)
  if (reportCore.useDataSourceOverride) {
    reportKPIBenchmarkLeadGroup.buildReport()
  }
}

function onKPIRouteKeyChange(val) {
  reportCore.dataSourceCurrent = val
  if (reportCore.useDataSourceOverride) {
    reportKPIBenchmarkLeadGroup.buildReport()
  }

}


//----------------------------------------------------------------------

function onUpdateTimelineMetric(event) {
  reportKPIBenchmarkLeadGroup.changeTimelineMetric(event)
}

//----------------------------------------------------------------------


const getTableContainerStyle1 = computed(() => {
  return applicationStore.grid.smaller ? 'flex:1 1 0;width:100%' : 'flex:1 1 0;min-width:300px;width:600px;max-width:25%'
})

const getTableContainerStyle2 = computed(() => {
  return applicationStore.grid.smaller ? 'flex:1 1 0;width:100%' : 'flex:1 1 0;width:600px;max-width:74%'
})

//----------------------------------------------------------------------


function updateuseDimensionFilters() {
  reportKPIBenchmarkLeadGroup.buildReport()
}

//----------------------------------------------------------------------

function showDataAsDailyAvgChanged() {
  reportKPIBenchmarkLeadGroup.buildReport()
}

//----------------------------------------------------------------------



function onDateRangeSelect() {
  if (dateSelectionRange.value) {
    if (dateSelectionRange.value[1] !== null) {
      dateSelectionMonth.value = null
      reportCore.dateRangeStart.getValuesFromDate(dateSelectionRange.value[0])

      reportCore.dateRangeEnd.getValuesFromDate(dateSelectionRange.value[1])

      //set the year selector to the year of the start date , since range can bridge acoss two different years eg , dec- jan , had to just prioritize one?
      dateSelectionYear.value = new Date(reportCore.dateRangeStart.year, 0, 1)

      reportKPIBenchmarkLeadGroup.buildReport()
    }
  }
}

//----------------------------------------------------------------------

function onDateMonthSelect() {
  if (dateSelectionMonth.value) {
    reportCore.dateForMonth(new Date(dateSelectionMonth.value))

    dateSelectionYear.value = new Date(reportCore.currentDate.year, 0, 1)

    dateSelectionRange.value[0] = new Date(
      reportCore.dateRangeStart.year,
      reportCore.dateRangeStart.month,
      reportCore.dateRangeStart.day
    )
    dateSelectionRange.value[1] = new Date(
      reportCore.dateRangeEnd.year,
      reportCore.dateRangeEnd.month,
      reportCore.dateRangeEnd.day
    )

    invalidateRangeCalenderKey.value++
    reportKPIBenchmarkLeadGroup.buildReport()
  }
}

//----------------------------------------------------------------------

function onDateYearSelect(event) {
  if (dateSelectionYear.value) {
    dateSelectionMonth.value = null

    reportCore.dateForFullYear(dateSelectionYear.value.getFullYear())
    dateSelectionRange.value[0] = new Date(
      reportCore.dateRangeStart.year,
      reportCore.dateRangeStart.month,
      reportCore.dateRangeStart.day
    )
    dateSelectionRange.value[1] = new Date(
      reportCore.dateRangeEnd.year,
      reportCore.dateRangeEnd.month,
      reportCore.dateRangeEnd.day
    )

    invalidateRangeCalenderKey.value++
    reportKPIBenchmarkLeadGroup.buildReport()
  }
}

function dateYTD() {

  reportCore.dateYTD()
  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.dateRangeEnd.year,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )

  invalidateRangeCalenderKey.value++
  dateSelectionMonth.value = null
  reportKPIBenchmarkLeadGroup.buildReport()

}

function dateYTLM() {

  reportCore.dateYTLM()
  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.dateRangeEnd.year,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )

  invalidateRangeCalenderKey.value++
  dateSelectionMonth.value = null
  reportKPIBenchmarkLeadGroup.buildReport()

}


//----------------------------------------------------------------------

function dateQ1() {
  reportCore.dateQ1()
  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.dateRangeEnd.year,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )

  invalidateRangeCalenderKey.value++
  dateSelectionMonth.value = null
  reportKPIBenchmarkLeadGroup.buildReport()
}

//----------------------------------------------------------------------

function dateQ2() {
  reportCore.dateQ2()
  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.dateRangeEnd.year,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )
  invalidateRangeCalenderKey.value++
  dateSelectionMonth.value = null
  reportKPIBenchmarkLeadGroup.buildReport()
}

//----------------------------------------------------------------------

function dateQ3() {
  reportCore.dateQ3()
  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.dateRangeEnd.year,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )
  invalidateRangeCalenderKey.value++
  dateSelectionMonth.value = null
  reportKPIBenchmarkLeadGroup.buildReport()
}

//----------------------------------------------------------------------

function dateQ4() {
  reportCore.dateQ4()
  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.r,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )
  invalidateRangeCalenderKey.value++
  dateSelectionMonth.value = null
  reportKPIBenchmarkLeadGroup.buildReport()
}

//----------------------------------------------------------------------

const onOwnerSelect = (event) => {
  reportKPIBenchmarkLeadGroup.ownerFilter = event.data.Owner


  reportKPIBenchmarkLeadGroup.ownerFilterByID = event.data.OwnerID

  reportKPIBenchmarkLeadGroup.leadGroupFilter = null
  reportKPIBenchmarkLeadGroup.locationFilter = null

  reportKPIBenchmarkLeadGroup.buildReport()
}

//----------------------------------------------------------------------

const onOwnerUnselect = (event) => {
  reportKPIBenchmarkLeadGroup.ownerFilter = null


  reportKPIBenchmarkLeadGroup.leadGroupFilter = null
  reportKPIBenchmarkLeadGroup.locationFilter = null

  reportKPIBenchmarkLeadGroup.buildReport()
}

//----------------------------------------------------------------------

const onGroupSelect = (event) => {
  reportKPIBenchmarkLeadGroup.leadGroupFilter = event.data['Lead Group']

  reportKPIBenchmarkLeadGroup.buildReport()
}

//----------------------------------------------------------------------

const onGroupUnselect = (event) => {
  reportKPIBenchmarkLeadGroup.leadGroupFilter = null

  reportKPIBenchmarkLeadGroup.buildReport()
}

//----------------------------------------------------------------------

const onLocationSelect = (event) => {
  reportKPIBenchmarkLeadGroup.locationFilter = event.data.Location
  reportKPIBenchmarkLeadGroup.leadGroupFilter = null

  reportKPIBenchmarkLeadGroup.buildReport()
}

//----------------------------------------------------------------------

const onLocationUnselect = (event) => {
  reportKPIBenchmarkLeadGroup.locationFilter = null
  reportKPIBenchmarkLeadGroup.leadGroupFilter = null

  reportKPIBenchmarkLeadGroup.buildReport()
}

//----------------------------------------------------------------------

const setChartOptions = () => {
  const documentStyle = getComputedStyle(document.documentElement)
  const textColor = documentStyle.getPropertyValue('--text-color')
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
  const surfaceBorder = documentStyle.getPropertyValue('--surface-600')

  return {
    stacked: false,
    maintainAspectRatio: false,
    aspectRatio: 0.6,

    interaction: {
      intersect: false,
      axis: 'xy',
      mode: 'index'
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        animation: false,
        callbacks: {
          label: (tooltipItem, data) => {
            // Assuming 'value' is the property you want to format
            let dataset = null
            if (tooltipItem.datasetIndex === 0) {
              dataset = reportKPIBenchmarkLeadGroup.timelineSingleMetricSet1
            }
            if (tooltipItem.datasetIndex === 1) {
              dataset = reportKPIBenchmarkLeadGroup.timelineSingleMetricSet2
            }
            const dataPoint = dataset[tooltipItem.dataIndex]

            return dataPoint.formattedValue
          }
        }
      }
    },

    scales: {
      x: {
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder
        }
      }
    }
  }
}

//----------------------------------------------------------------------

onMounted(() => {
  console.log(`on mounted in ReportKPIBenchmarkLeadGroupView`)
  reportCore.reset()



  chartOptions.value = setChartOptions()


  

  dateSelectionMonth.value = new Date(reportCore.currentDate.year, reportCore.currentDate.month, 1)
  dateSelectionYear.value = new Date(reportCore.currentDate.year, 0, 1)
  reportCore.dateForMonth(dateSelectionMonth.value)

  dateSelectionRange.value[0] = new Date(
    reportCore.dateRangeStart.year,
    reportCore.dateRangeStart.month,
    reportCore.dateRangeStart.day
  )
  dateSelectionRange.value[1] = new Date(
    reportCore.dateRangeEnd.year,
    reportCore.dateRangeEnd.month,
    reportCore.dateRangeEnd.day
  )
  invalidateRangeCalenderKey.value++
  applicationStore.scollToTop()
  reportCore.calculateGrowthDateRanges()
  reportKPIBenchmarkLeadGroup.buildReport()

})

const getDebugTableStyle = computed(() => {
  return applicationStore.grid.tabletAndUnder
    ? ' flex:1 1 0;width:100%'
    : 'flex:1 1 0;max-width: 49%;'
})

</script>

<style>
ol,
ul {
  padding-left: unset !important;
}

ol,
ul,
dl {
  margin-top: unset !important;
  margin-bottom: unset !important;
}

.p-buttonset .p-button {
  margin: 0;
  font-size: 14px;
}

.p-component {
  font-size: 0.9rem;
}

.min-w-min {
  min-width: min-content !important;
}
</style>