<template>
  <div class="w-100 d-flex nav-bar-custom navbar-bg-1">
    <ItemContainerLeftRightSlotsComponent>
      <template #left>
        <button class="button-minimal" @click="toggleMenu">
          <span class="mdi icon nav-color-1 mdi-menu"></span>
        </button>
        <button class="button-minimal" @click="toggleTheme">
          <span
            class="mdi icon nav-color-1"
            :class="{
              'mdi-lightbulb-outline': theme.themeKey === 'light',
              'mdi-lightbulb-on': theme.themeKey === 'dark',
            }"
          ></span>
        </button>
      </template>
      <template #right>

        <button class="button-minimal" @click="auth.logout">
          <div class="fs-10 fw-bolder nav-color-1">logout</div>
        </button>
       
      </template>
    </ItemContainerLeftRightSlotsComponent>
  </div>
</template>

<script setup>
import { useTheme } from "@/stores/Theme.js";
import { useAuth } from "@/stores/Auth.js";
import ItemContainerLeftRightSlotsComponent from "@/components/layout/ItemContainerLeftRightSlotsComponent.vue";
const emit = defineEmits(["togglemenu"]);
const auth = useAuth();
const theme = useTheme();

function toggleMenu() {
  emit("togglemenu");
}
function toggleTheme() {
  if (theme.themeKey === "light") {
    theme.writeTheme("dark");
    return;
  }
  if (theme.themeKey === "dark") {
    theme.writeTheme("light");
    return;
  }
}
</script>

<style scoped>
.nav-bar-custom {
  align-content: center;
  justify-content: flex-start;
  height: 48px;
  border-bottom: var(--border-bottom-3);
}
</style>
