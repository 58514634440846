import { ref } from 'vue';

export function useUniqueKey() {
  const uniqueKey = ref(generateUniqueKey());

  function generateUniqueKey() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
  }

  return {
    uniqueKey,
  };
}