import { defineStore } from 'pinia'
import { useToasts } from "@/stores/Toasts.js";
import ErrorMessageParser from "@/scripts/parsers/ErrorMessageParser.js";
import { useGlobalInjects } from '@/stores/GlobalInjects.js'
import { markRaw } from "vue";
export const useErrorHandler = defineStore('ErrorHandler', {
  state: () => {
    return {     
      
      toasts: markRaw(useToasts()),
     globalInjects: markRaw(useGlobalInjects()),
     
      error:null,
      errorMessage:null,
    }
  },

  actions: {
    

   

    processRequestError(error) {
        //some errors just display a toast
        //some errors must redirect to denied
        //some errors must redirect to error report page
      console.log('processRequestError called')
      console.log(error)
      if(!error.response){
        this.errorMessage = ErrorMessageParser(error)
        this.error = error
        this.toasts.setErrorMessage(this.errorMessage);
      }
      if(error.response?.status === 400){// Bad Request
        this.errorMessage = ErrorMessageParser(error)
        this.error = error
        let redirect = error.response?.data?.redirectTo
        if(redirect){
          this.globalInjects.router.push(redirect);
        }else{
          this.toasts.setErrorMessage(this.errorMessage);
        }        
        return
      }

      if(error.response?.status === 401){
        this.globalInjects.router.push("/");
        return
      }

      if(error.response?.status === 403){
        this.errorMessage = ErrorMessageParser(error)
        this.error = error
        this.globalInjects.router.push("/application/access-denied");       
        return
      }

      this.errorMessage = ErrorMessageParser(error)
      this.error = error
      this.globalInjects.router.push("/application/error-view");

    },

    //all other codes to error report page

    processScriptError(error) {
      console.log(error)
      this.errorMessage = ErrorMessageParser(error)
      this.error = error
        this.globalInjects.router.push("/application/error-view");
    }
   
   

    
  }
})
