import { defineStore } from "pinia";
import { markRaw,toRaw } from "vue";
import { useRequests } from '@/stores/Requests'
import { useReportCore } from '@/stores/ReportCore'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import { useURLS } from '@/stores/URLS.js'
import { useToasts } from '@/stores/Toasts.js'
import { useAuth } from '@/stores/Auth.js'
import { useDataBenchmarkLeadGroupStore } from '@/stores/DataBenchmarkLeadGroupStore.js'
import { useScrollToElement } from '@/scripts/utils/ScrollToElement.js'
export const useKPIBenchmarkLeadGroupManagement = defineStore("KPIBenchmarkLeadGroupManagement", {
  state() {
    return {
      pendingNewBenchmarkYear: null,
      toasts: markRaw(useToasts()),
      reportCore: markRaw(useReportCore()),
      urls: markRaw(useURLS()),
      requests: markRaw(useRequests()),
      auth: markRaw(useAuth()),
      applicationStore: markRaw(useApplicationStore()),
      dataBenchmarkLeadGroupStore: markRaw(useDataBenchmarkLeadGroupStore()),

      kpiBenchmarkOptions: markRaw({
        'Appt Set Rate': {},
        'Issued Rate': {},
        'Closing Rate': {},
        'Demo Rate': {},
        
      }),

      createBenchmarkYearFieldSet: null,     
      createCloneBenchmarkYearFieldSet: null,
      optionsBenchmarkYears: null,
      yearSelectionCurrent: { locked: false },
      pageIsActive: false,
      yearCurrent: null,
      optionBenchmarkNames: [],
      selectedBenchmarkToCreate: null,
      benchmarkData: null,
      currentSelected: null,

    }
  },

  getters: {

    optionsBenchmarkYearsComputed(state) {
      return state.optionsBenchmarkYears
    },

    optionBenchmarkNamesComputed(state) {

      return state.optionBenchmarkNames


    },

    manageBenchmarkData(state) {

      return (
        state.auth.hasPermission('edit_benchmark_lead_group_management_data') &&
        !state.yearSelectionCurrent?.locked
      )


    },


    //---------------------------------------------------------------------------------------






  },

  actions: {
    

    async init() {
     
     
      this.setupCreateBenchmarkYears()

      await this.fetchBenchmarkYears()

      await this.tryLoadDefaultYear()
     
    },



    //-----------------------------------------------------------------
    //CREATE
    //-----------------------------------------------------------------

    async createBenchmark() {
     
      if (!this.selectedBenchmarkToCreate) return


      let url = this.urls.getURL('benchmark_lead_group_management', 'add')

      this.reportCore.setLoadHeavy(true,"Benchmark Item","create")

      let result
      try {
        result = await this.requests.post({
          url: url,
          data: { name: this.selectedBenchmarkToCreate, year: this.yearCurrent }
        })
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return
      }
      if (result) {
        this.reportCore.setLoadHeavy(false)
        if (this.pageIsActive) {
         
          this.reportCore.refreshDataBenchmarkLeadGroup = true
          await this.dataBenchmarkLeadGroupStore.loadData()
          this.processBenchmarks();

        }
      }

    },

    //-----------------------------------------------------------------

    createFieldSetYears(fieldSet, obj, index) {
    
      fieldSet.fields = []

      let field = {}
      field.vif = true
      field.attributes = {}
      field.label = 'year'
      field.modelKey = 'year'
      field.modelTable = 'Benchmark'
      field.modelValue = null
      field.modelID = null
      field.component = 'b-form-input'
      field.index = `Benchmark_${index}_${fieldSet.fields.length}`
      fieldSet.fields.push(field)
    },

    createCloneFieldSetYears(fieldSet, obj, index) {
    
      fieldSet.fields = []

      let field = {}
      field.vif = true
      field.attributes = {}
      field.label = 'clone source'
      field.modelKey = 'year source'
      field.modelTable = 'Benchmark'
      field.modelValue = null
      field.modelID = null
      field.component = 'b-form-input'
      field.index = `Benchmark_${index}_${fieldSet.fields.length}`
      fieldSet.fields.push(field)

      field = {}
      field.vif = true
      field.attributes = {}
      field.label = 'clone target'
      field.modelKey = 'year target'
      field.modelTable = 'Benchmark'
      field.modelValue = null
      field.modelID = null
      field.component = 'b-form-input'
      field.index = `Benchmark_${index}_${fieldSet.fields.length}`
      fieldSet.fields.push(field)
    },

    //-----------------------------------------------------------------


    setupCreateBenchmarkYears() {
     
      let fieldSet = {}
      fieldSet.key = 'benchmarkyears'
      this.createFieldSetYears(fieldSet, null, 'create', null)
      this.createBenchmarkYearFieldSet = fieldSet

      fieldSet = {}
      fieldSet.key = 'benchmarkCloneyears'
      this.createCloneFieldSetYears(fieldSet, null, 'clone', null)
      this.createCloneBenchmarkYearFieldSet = fieldSet
    },

    //-----------------------------------------------------------------

    async createBenchmarkYear(payload) {
     
      let fieldsWrite = [...payload.fields]
      this.pendingNewBenchmarkYear = parseInt(fieldsWrite[0].modelValue)

      let url = this.urls.getURL('benchmark_lead_group_management', 'add_years')
      let data = { fields: fieldsWrite }

      this.reportCore.setLoadHeavy(true,"Benchmark Year","create")
      var formData = data || {}
      let result
      try {
        result = await this.requests.post({
          url: url,
          data: formData
        })
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return
      }
      if (result) {
        this.reportCore.setLoadHeavy(false)
        if (this.pageIsActive) {          
          await this.fetchBenchmarkYears()
          await this.tryLoadDefaultYear()
          this.toasts.setSuccessMessage(result.data.message)
         
        }


      }


    },


    async createCloneBenchmarkYear(payload) {

      let modalResult = await this.reportCore.modalConfirmCancelStart('This process can overwrite existing data, proceed if this is understood')
      if (!modalResult) {
        return
      }
     
      let fieldsWrite = [...payload.fields]
      this.pendingNewBenchmarkYear = parseInt(fieldsWrite[1].modelValue)

      let url = this.urls.getURL('benchmark_lead_group_management', 'clone_years')
      let data = { fields: fieldsWrite }

      this.reportCore.setLoadHeavy(true,"Benchmark Year","clone")
      var formData = data || {}
      let result
      try {
        result = await this.requests.post({
          url: url,
          data: formData
        })
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return
      }
      if (result) {
        this.reportCore.setLoadHeavy(false)
        if (this.pageIsActive) {   
          this.reportCore.refreshDataBenchmarkLeadGroup = true;       
          await this.fetchBenchmarkYears()
          await this.tryLoadDefaultYear()
          this.toasts.setSuccessMessage(result.data.message)
         
        }


      }


    },

    //-----------------------------------------------------------------
    //DELETE
    //-----------------------------------------------------------------

    async deleteBenchmark(benchmark) {


      let modalResult = await this.reportCore.modalConfirmCancelStart('do you want to delete this benchmark?')
      if (!modalResult) {
        return
      }

     
      let url = this.urls.getURL('benchmark_lead_group_management', 'delete')

      this.reportCore.setLoadHeavy(true,"Benchmark Item","delete")

      let result
      try {
        result = await this.requests.post({
          url: url,
          data: benchmark
        })
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return
      }
      if (result) {
        this.reportCore.setLoadHeavy(false)
        if (this.pageIsActive) {
         
       
          this.toasts.setSuccessMessage(result.data.message)
          this.reportCore.refreshDataBenchmarkLeadGroup = true
          await this.dataBenchmarkLeadGroupStore.loadData()
          this.processBenchmarks();
         
        }
      }

    },

    //-----------------------------------------------------------------
    //FETCH
    //-----------------------------------------------------------------

    async fetchBenchmarkYears() {
      this.reportCore.setLoadHeavy(true,"Benchmark Year Data")

     
      let url = this.urls.getURL('benchmark_lead_group_management', 'find_years')

     

      let result
      try {
        result = await this.requests.post({
          url: url,
          data: {}
        })
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return
      }
      if (result) {
        this.reportCore.setLoadHeavy(false)
        if (this.pageIsActive) {
         
          this.optionsBenchmarkYears = result.data
          
        }
      }
    },

    async tryLoadDefaultYear() {
     
      if (this.optionsBenchmarkYears) {
        let yeartouse = this.pendingNewBenchmarkYear || this.optionsBenchmarkYears[0]?.year
        this.pendingNewBenchmarkYear = null
        let yearObject = this.optionsBenchmarkYears.find((element) => element.year === yeartouse)
        if (yearObject) {
          this.onYearChange({ year: yearObject.year })
          this.yearSelectionCurrent = yearObject
         
        }
      }
    },

    async onYearChange(event) {
     
      this.yearCurrent = event.year
      this.reportCore.dateForFullYear(this.yearCurrent)
      await this.dataBenchmarkLeadGroupStore.loadData()
      this.processBenchmarks();
    },

    //-----------------------------------------------------------------

//this should try use DataBenchmarkLeadGroupStore 

    

    processBenchmarks() {
     
      let data =   this.dataBenchmarkLeadGroupStore.benchmarkData[this.yearCurrent]
      this.benchmarkData = data.benchmarks      

    },

    //-----------------------------------------------------------------
    //UPDATE
    //-----------------------------------------------------------------

    async updateBenchmarkItem(benchmarkItem) {
     
     
      let url = this.urls.getURL('benchmark_lead_group_management', 'update_lead_group')
      let data = {}
      data.rowData = {};
      data.rowData.value = benchmarkItem.value
      data.rowData.active = benchmarkItem.active
      data.rowData.id = benchmarkItem.id     
      data.leadgroupName = benchmarkItem.LeadGroup.name

      this.reportCore.setLoadHeavy(true,"Benchmark Item","update")
      var formData = data || {}
      let result
      try {
        result = await this.requests.post({
          url: url,
          data: formData
        })
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return
      }
      if (result) {
        this.reportCore.setLoadHeavy(false)
        //if (this.pageIsActive) {
             // conditional blocking function called from report that shares this function .. ? just removed to solve
             //it , but need to think about the logic of pageIsActive - what and why or if it was needed 
          this.toasts.setSuccessMessage(result.data.message)
        //}
      }
    },


    //-----------------------------------------------------------------

    async updateBenchmark(benchmark) {
     
     
      let url = this.urls.getURL('benchmark_lead_group_management', 'update')
      let data = {}
      data.rowData = {};
      data.rowData.weight = benchmark.weight
      data.rowData.active = benchmark.active
      data.rowData.id = benchmark.id
      data.name = benchmark.name
    

      this.reportCore.setLoadHeavy(true,"Benchmark Item","update")
      var formData = data || {}
      let result
      try {
        result = await this.requests.post({
          url: url,
          data: formData
        })
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return
      }
      if (result) {
        this.reportCore.setLoadHeavy(false)
        if (this.pageIsActive) {
          
          this.toasts.setSuccessMessage(result.data.message)
        }
      }
    },

    //-----------------------------------------------------------------

    async updateYearLock() {

    
      // fetches all benchmarks for current year
      let url = this.urls.getURL('benchmark_lead_group_management', 'lock_years')
      let data = { ...this.yearSelectionCurrent }
      this.reportCore.setLoadHeavy(true,"Benchmark Year Lock","update")
      var formData = data || {}
      let result
      try {
        result = await this.requests.post({
          url: url,
          data: formData
        })
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return
      }
      if (result) {
        this.reportCore.setLoadHeavy(false)
        if (this.pageIsActive) {
         
        
          this.toasts.setSuccessMessage(result.data.message)
        }
      }

    },

    //-----------------------------------------------------------------


    cacheInputBenchmarkValue(benchmarkItem) {

      benchmarkItem.cachedValue = benchmarkItem.value

    },

    //-----------------------------------------------------------------

    validateInputBenchmarkValue(benchmarkItem) {

      if (benchmarkItem.cachedValue != benchmarkItem.value) {
        benchmarkItem.cachedValue = parseFloat(benchmarkItem.value)
        benchmarkItem.value =  benchmarkItem.cachedValue
        this.updateBenchmarkItem(benchmarkItem)
      }
    },
//-----------------------------------------------------------------

    cacheInputBenchmarkWeightValue(benchmark) {

      benchmark.cachedValue = benchmark.weight

    },

    //-----------------------------------------------------------------

   async validateInputBenchmarkWeightValue(benchmark) {

      if (benchmark.cachedValue != benchmark.weight) {
        benchmark.cachedValue = parseFloat(benchmark.weight)
        benchmark.weight = benchmark.cachedValue
       await this.updateBenchmark(benchmark)
      }
    },

    handleExpand(record, event) {
      //if expanded , then this is a contraction so expand false and exit function
      if (record.expanded) {
        record.expanded = false
        return
      }
      if (this.currentSelected) {
        this.currentSelected.expanded = false
      }
      this.currentSelected = record
      this.currentSelected.expanded = true

      useScrollToElement(
        event.currentTarget.offsetParent.offsetParent,
        toRaw(this.applicationStore.contentDomElement)
      )

    }



  },
});
