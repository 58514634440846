<template>

  <CofigurableButtonComponent
    :componentData="componentData"
    @select="onSelect"
  ></CofigurableButtonComponent>
  <div v-if="expanded && componentData.vifValue" class="child-list-1 mb-2">
    <component
      v-for="(item, index) in componentData.children"
      :key="keyref+ '_child_' + index"
      :keyref="keyref+ '_child_' + index"
      :is="item.component"
      @select="onChildSelect"
      v-bind="item.attributes"
    >
    </component>
  </div>
  
</template>

<script setup>
import CofigurableButtonComponent from "@/components/navigation/CofigurableButtonComponent.vue";
import { ref,reactive,watch,computed } from "vue";
const emit = defineEmits(["select"]);
const props = defineProps({
  componentData: {
    type: Object,
    default: () => {
      return;
    },
  },
  keyref:{
    type:String,
    default:""
  }
});

const componentDataLocal = reactive({ value: props.componentData });
watch(
  () => props.componentData,
  () => {
    componentDataLocal.value = { ...props.componentData };
  }
);

let expanded = computed(() => {
  return componentDataLocal.value.enabled;
   
});


function onSelect(payload,event) {
  componentDataLocal.value.enabled =  payload.enabled; 
   emit("select", payload,event);
}
function onChildSelect(payload,event) {
  emit("select", payload,event);
 
}
</script>

<style></style>
