<template>
  <div v-if="loadingOverlayStore.loadingHeavy" id="global-cover"></div>
  <div id="global-cover-2">
    <div
      v-if="loadingOverlayStore.loadingHeavy"
      class="w-100 elevation-8 d-flex pa-2 flex-column justify-content-center"
      style="
        background-color: #fff;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        border: 8px solid #e5e5e5;
        border-top: 0;
      "
    >
      <div class="text-center text-type-11 p-0" style="line-height: 16px; color: #777"> {{ loadingOverlayStore.loadingHeavyTitle }}</div>
      <div class="text-center fw-bolder fs-3 text-type-10 p-0" style="line-height: 24px">
        {{ loadingOverlayStore.loadingHeavyInfo }}
      </div>
      <div class="text-center text-type-11 p-0" style="color: #777">please wait...</div>
    </div>
  </div>
</template>

<script setup>
import { useLoadingOverlayStore } from '@/stores/LoadingOverlayStore.js'
const loadingOverlayStore = useLoadingOverlayStore()
</script>
