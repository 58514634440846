export function useDividerStyling1() {
   
    const data = {
      classStringComponentDefault:"width-90 mx-auto my-6",  
        classStringLineDefault:"dividerSolid mx-3",       
        classStringTextDefault:"m-0 h3 fw-bold text-color-1",       
        classStringIconDefault:"",       
       
          };

 
  return data;
}