<template>
  <div class="layout-group py-4">
    <div class="text-color-2 text-center h3 display-6 fw-normal m-0">Please verify your email</div>
  </div>
  <div class="layout-group">
    <div class="text-color-2 text-center fs-6 fw-normal m-0">
      An email with an OTP has been sent to you
    </div>
    <b-form-group
      label="OTP:"
      label-for="nested-otp"
      :invalid-feedback="formfield1Invalid"
      :state="formfield1State"
      class="fs-9 text-align-left text-color-1"
    >
      <b-form-input
        :disabled="requests.loading"
        id="nested-otp"
        v-model="formfield1"
        :state="formfield1State"
        trim
      ></b-form-input>
    </b-form-group>

    <b-button
      class="p-1 fs-4 fw-bolder w-100"
      variant="primary"
      @click="validateVerify"
      :disabled="requests.loading"
      >verify</b-button
    >
  </div>

  <div class="layout-group mt-6">
    <div class="text-color-2 text-center fs-6 fw-normal m-0 mb-2">
      Click below if you would like to have a new OTP sent to you
    </div>
    <b-button
      class="p-1 fs-4 fw-bolder w-100"
      variant="primary"
      @click="resend"
      :disabled="requests.loading"
      >send</b-button
    >
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useURLS } from '@/stores/URLS.js'
import { useAuth } from '@/stores/Auth.js'
import { useRequests } from '@/stores/Requests.js'
import { useRouter } from 'vue-router'
import { useToasts } from '@/stores/Toasts.js'


const urls = useURLS()
const auth = useAuth()
const requests = useRequests()
const router = useRouter()
const formfield1 = ref('')
const toasts = useToasts()

var formValidate = ref(false)
var formfield1InvalidMessage = ref('')

function setFormfield1InvalidMessage(value) {
  formfield1InvalidMessage.value = value
}

const formfield1State = computed(() => {
  if (!formValidate.value) return undefined
  if (!formfield1.value) {
    setFormfield1InvalidMessage('OTP is required')

    return false
  }

  setFormfield1InvalidMessage('')
  return true
})

const formfield1Invalid = computed(() => {
  if (!formValidate.value) return undefined
  return formfield1InvalidMessage.value
})

function validateVerify() {
  formValidate.value = false
  formValidate.value = true
  if (!formfield1State.value) {
    return
  }
  verify()
}

function verify() {
  let formData = {}
  formData.otp = formfield1.value
  requests
    .post({
      url: urls.auth.verify_email,
      data: formData
    })
    .then((response) => {
      auth.setVerification(response.data)
      auth.setToken(response.data)
      toasts.setSuccessMessage('email verification successful')
      if (!auth.staff_authorized_at) {
        router.push('verifyStaff')
      } else {
        router.redirect('login')
      }
    })
}

function resend() {
  let formData = {}

  requests
    .post({
      url: urls.auth.resend_verify_email,
      data: formData
    })
    .then((response) => {
      toasts.setSuccessMessage(
        'A verification email with a new OTP to authorize your registration was sent'
      )
    })
}
</script>

<style scoped>
.theme-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 0;
}
.form-label {
  margin-bottom: 0 !important;
}
</style>
