import { defineStore } from "pinia";
import axios from "axios";
import { markRaw } from "vue";
import { useToasts } from "@/stores/Toasts.js";
import { useErrorHandler } from "@/stores/ErrorHandler.js";


export const useRequests = defineStore("Requests", {
  state() {
    return {
      loading: false,
      toasts: markRaw(useToasts()),
     
      errorHandler : markRaw(useErrorHandler()),
    };
  },

  actions: {
    post(requestdata) {
      this.loading = true;
     
      return new Promise((resolve, reject) => {
        axios
          .post(requestdata.url, requestdata.data, {})
          .then((response) => {
            resolve(response);
            this.loading = false;
          })
          .catch((error) => {
            reject(error);
            console.log(error)
            this.loading = false;
          
           
           this.errorHandler.processRequestError(error)

         
          });
      });
    },
    get(requestdata) {
      this.loading = true;
     
      return new Promise((resolve, reject) => {
        axios
          .get(requestdata.url, requestdata.data, {})
          .then((response) => {
            resolve(response);
            this.loading = false;
          })
          .catch((error) => {
            reject(error);
            this.loading = false;
            this.errorHandler.processRequestError(error)
          });
      });
    },
  },
});
