<template>
  <div class="layout-group py-4">
    <div class="text-color-2 text-center h3 display-6 fw-normal m-0">
      Reset your password?
    </div>
    
      <div class="text-color-2 text-center fs-6 fw-normal m-0">
      Submit your email address below to have an OTP sent to you to authorize a password reset
    </div>
    
  </div>
  <div class="layout-group">
    <b-form-group
      label="Email:"
      label-for="nested-email"
      :invalid-feedback="formfield1Invalid"
      :state="formfield1State"
      class="fs-9 text-align-left text-color-1"
    >
      <b-form-input
        :disabled="requests.loading"
        id="nested-email"
        v-model="formfield1"
        :state="formfield1State"
        trim
      ></b-form-input>
    </b-form-group>

    <b-button
      class="p-1 fs-4 fw-bolder w-100"
      variant="primary"
      @click="validateForgot"
      :disabled="requests.loading"
      >submit</b-button
    >
  </div>
  
</template>

<script setup>
import { ref, computed } from "vue";
import { useURLS } from "@/stores/URLS.js";
import { useAuth } from "@/stores/Auth.js";
import { useRequests } from "@/stores/Requests.js";
import { useRouter } from "vue-router";
import { useToasts } from "@/stores/Toasts.js";


const urls = useURLS();
const auth = useAuth();
const requests = useRequests();
const router = useRouter();
const formfield1 = ref("");
const toasts = useToasts();

var formValidate = ref(false);
var formfield1InvalidMessage = ref("");

function setFormfield1InvalidMessage(value) {
  formfield1InvalidMessage.value = value;
}

const formfield1State = computed(() => {
  if (!formValidate.value) return undefined;
  if (!formfield1.value) {
    setFormfield1InvalidMessage("E-mail is required");

    return false;
  }
  if (!/.+@.+\..+/.test(formfield1.value)) {
    setFormfield1InvalidMessage("E-mail must be valid");

    return false;
  }
  setFormfield1InvalidMessage("");
  return true;
});

const formfield1Invalid = computed(() => {
  if (!formValidate.value) return undefined;
  return formfield1InvalidMessage.value;
});

function validateForgot() {
  formValidate.value = false;
  formValidate.value = true;
  if (!formfield1State.value) {
    return;
  }
  forgot();
}

function forgot() {
  let formData = {}; 
  formData.email = formfield1.value;
  auth.user.email = formData.email
  requests
    .post({
      url: urls.getURL('auth','resetPassword'),
      data: formData,
    })
    .then((response) => {
          
      toasts.setSuccessMessage("An OTP has been sent to your email ");
      router.push("passwordReset");
    });
}


</script>

<style scoped>
.theme-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 0;
}
.form-label {
  margin-bottom: 0 !important;
}
</style>
