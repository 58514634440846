import { useComponentStylingAccordion1 } from "@/scripts/ui/ComponentStylingAccordion1";
import { useButtonInteraction } from "@/scripts/ui/ButtonInteraction";
import { useComponentDisplayData } from "@/scripts/ui/ComponentDisplayData";
import { useComponentData } from "@/scripts/ui/ComponentData";
export function useAccordion1() {
  const data = {};
  data.component = "AccordionComponent";
  let attr = (data.attributes = {});
  attr.componentData = useComponentData();
  attr.componentData.content = "CofigurableButtonContent1Component";
  attr.componentData.styling = useComponentStylingAccordion1();
  attr.componentData.interaction = useButtonInteraction();
  attr.componentData.display = useComponentDisplayData(); 
  attr.componentData.children = [];

  return data;
}
