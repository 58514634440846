<template>
  <div>
    <div style="cursor:pointer" class="elevation-1 ui-bg-5 pa-3 py-1 d-flex align-items-center my-1">
      <div class="m-0 h6 fw-bold text-color-12">{{ props.label }}</div>
      
      <span class="ms-auto mdi icon text-color-12"
      :class="{' mdi-plus-circle':!expanded,' mdi-minus-circle':expanded}"
      > </span>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  expanded: {
    type: Boolean,
    default: false
  }
})
</script>
