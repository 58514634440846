<template>
  <div
    class="content-bg-5 d-flex"
    :class="{ home: !applicationStore.grid.tabletAndUnder, 'home-smaller': applicationStore.grid.tabletAndUnder }"
  >
    <div class="p-3 home-col-1 bg-color-1 d-flex justify-content-center align-items-center pa-4">
      <b-img v-if="applicationStore.grid.tabletAndUnder" :src="imageUrl1"></b-img>
      <b-img class="image-landscape" v-if="!applicationStore.grid.tabletAndUnder" :src="imageUrl2"></b-img>
    </div>
    <div class="p-4 home-col-2 content-bg-5 d-flex justify-content-center align-items-center">
      <button @click="toggleTheme" class="theme-button">
        <span
          class="mdi icon icon-color-2"
          :class="{
            'mdi-lightbulb-outline': theme.themeKey === 'light',
            'mdi-lightbulb-on': theme.themeKey === 'dark'
          }"
        ></span>
      </button>
      <div class="home-col-2b w-100">
       
        <component :is="current_component"> </component>
      </div>
    </div>
  </div>
</template>

<script setup>
import imageUrl1 from '/images/home_image_portrait.png'
import imageUrl2 from '/images/home_image.png'
import { useTheme } from '@/stores/Theme.js'
import { useAuth } from '@/stores/Auth.js'
import { ref, computed ,onMounted,watch,onBeforeMount} from 'vue'
import LoginComponent from '@/components/auth/LoginComponent.vue'
import RegisterComponent from '@/components/auth/RegisterComponent.vue'
import VerifyEmailComponent from '@/components/auth/VerifyEmailComponent.vue'
import VerifyStaffComponent from '@/components/auth/VerifyStaffComponent.vue'
import ForgotPasswordComponent from '@/components/auth/ForgotPasswordComponent.vue'
import PasswordResetComponent from '@/components/auth/PasswordResetComponent.vue'
import { useRoute } from 'vue-router'
import { useRequests } from '@/stores/Requests.js'
import { useApplicationStore } from "@/stores/ApplicationStore.js";
import { useScreen } from "vue-screen";
import { useStoreManagement } from '@/stores/StoreManagement.js'
const applicationStore = useApplicationStore()

const requests = useRequests()
const theme = useTheme()
const auth = useAuth()

const route = useRoute()
const screen = useScreen();

onBeforeMount(() => {
  useStoreManagement().resetStoresAll();  
})

onMounted(() => {  
 
  applicationStore.invalidateGrid();
  
 
 
});
watch(screen, invalidateDisplay);

function invalidateDisplay() {
 applicationStore.invalidateGrid();

}


function toggleTheme() {
  if (theme.themeKey === 'light') {
    theme.writeTheme('dark')
    return
  }
  if (theme.themeKey === 'dark') {
    theme.writeTheme('light')
    return
  }
}

const windowIndex = ref(0)
const windowKeyToComponent = {
  login: LoginComponent,
  register: RegisterComponent,
  verifyEmail: VerifyEmailComponent,
  verifyStaff: VerifyStaffComponent,
  forgotPassword: ForgotPasswordComponent,
  passwordReset: PasswordResetComponent,
  
}

const current_component = computed(() => {
  return windowKeyToComponent[route.params.windowkey] || windowKeyToComponent.login
})



const isUserToken = computed(() => {
  return auth.token !== null
})



</script>

<style scoped>
.home {
  width: 100%;
  height: 100%;
  align-content: center;
}
.home-smaller {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
}
.bg-color-1 {
  background-color: #1976d2;
}

.home-col-1 {
  width: 100%;
}
.home-col-2 {
  width: 100%;
  max-width: 640px;
  position: relative;
  flex-grow: 1;
}



.home-col-2b {
  max-width: 500px;
}
.image-landscape {
  width: 80%;
  max-width: 655px;
}
.theme-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 0;
}
</style>
