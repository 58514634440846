<template>
 
    <LoadingOverlay></LoadingOverlay>
    <ModalConfirmCancel></ModalConfirmCancel>

    <div
      style="position: absolute; bottom: 20px; right: 20px; z-index: 5000"
      v-if="applicationStore.displayScollToTopButton"
    >
      <button class="button-minimal" @click="scollToTop">
        <span style="font-size: 40px" class="mdi icon icon-color-2 mdi-arrow-up-bold-box"></span>
      </button>
    </div>

    <DormantSessionCover></DormantSessionCover>
    <div id="content-container" class="d-flex flex-row-reverse content-bg-1">
      <div id="content" class="d-flex flex-column content-bg-1">
        <!-- nav bar and side nav -->
        <NavBarCustomComponent @togglemenu="toggleMenu" />

        <div
          ref="contentelement"
          class="printable-container"
          :class="{ 'content-bg-1': !applicationStore.grid.tabletAndUnder, 'content-bg-11': applicationStore.grid.tabletAndUnder }"
          style="position: relative;  flex-basis: 100%; overflow-y: auto"
        >
          <!-- vue-router -->
          <router-view></router-view>
        </div>
        <div id="content-cover"></div>
      </div>
      <div id="side-nav" class="p-2">
        <SideNavComponent></SideNavComponent>
      </div>

      <div id="displace-nav"></div>
    </div>
 
</template>

<script setup>
import NavBarCustomComponent from '@/components/navigation/NavBarCustomComponent.vue'
import LoadingOverlay from '@/components/app/LoadingOverlay.vue'
import ModalConfirmCancel from '@/components/app/ModalConfirmCancel.vue'
import DormantSessionCover from '@/components/app/DormantSessionCover.vue'
import { ref, onBeforeUnmount, watch, onMounted, onUnmounted, nextTick, computed, onBeforeMount } from 'vue'
import { useScreen } from 'vue-screen'
import SideNavComponent from '@/components/navigation/SideNavComponent.vue'
import { gsap } from 'gsap'
import { useApplicationStore } from '@/stores/ApplicationStore.js'

const applicationStore = useApplicationStore()
applicationStore.processNewUI()

const content = ref(null)
const contentCover = ref(null)
const contentContainer = ref(null)
const sideNav = ref(null)
const displaceNav = ref(null)

const contentelement = ref()

var displacement = null
const screen = useScreen()

const getStyle = computed(() => {
  return applicationStore.grid.tabletAndUnder ? 'color:#ff0000' : 'color:#ffff00'
})

onUnmounted(() => {
 // dispose()
})

function dispose() {
 // window.onresize = null

 
}



onBeforeUnmount(() => {
  console.log('beforeUnmount called in ApplicationView')
  contentelement.value.removeEventListener('scroll', checkScollPosition)
})

onMounted(() => {
  content.value = document.getElementById('content')
  applicationStore.domElements.content = document.getElementById('content')

  contentCover.value = document.getElementById('content-cover')
  contentCover.value.addEventListener('click', () => {
    toggleMenu()
  })
  contentCover.value.style.display = 'none'

  contentContainer.value = document.getElementById('content-container')
  sideNav.value = document.getElementById('side-nav')
  displaceNav.value = document.getElementById('displace-nav')
  applicationStore.invalidateGrid()
  initiateDisplay()

  window.onresize = calculateWidth
  applicationStore.contentDomElement = contentelement.value

  contentelement.value.addEventListener('scroll', checkScollPosition)
})

function checkScollPosition() {
  if (contentelement.value.scrollTop > 200) {
    applicationStore.displayScollToTopButton = true
  } else {
    applicationStore.displayScollToTopButton = false
  }
}

function scollToTop() {
  contentelement.value.scrollTo({
    top: 0,
    behavior: 'smooth' // Optional: Smooth scrolling animation
  })
}

watch(screen, invalidateDisplay)

watch(() => applicationStore.isNavDisplaying, animateMenu)

async function initiateDisplay() {
  await invalidateDisplay()
 /* if (!applicationStore.grid.tabletAndUnder) {
    toggleMenu()
  }*/
}

async function invalidateDisplay() {
  applicationStore.invalidateGrid()

  //clear container width to not affect recalculation on next frame
  contentContainer.value.style.width = 0
await nextTick()

  calculateWidth()
  calculateLayout()
}

var widthcalc

function calculateWidth() {
  let width = window.innerWidth
  widthcalc = width
  let val = parseInt(width * 0.2)
  val = Math.min(val, 370)
  val = Math.max(val, 300)
  displacement = 300;//applicationStore.grid.tabletAndUnder ? 300 : val
  contentContainer.value.style.width = width + displacement + 'px'
  contentContainer.value.style.position = 'absolute'
  contentContainer.value.style.height = '100%'
  contentContainer.value.style.left = -displacement + 'px'

  //reset width of  nav
  sideNav.value.style.width = displacement + 'px'
  content.value.style.width = width + 'px'
}

function calculateLayout() {

   //if screen changes default to menu hidden
   applicationStore.isNavDisplaying = false
    //pop side nav from layout
    sideNav.value.style.position = 'absolute'
    sideNav.value.style.height = '100%'
    sideNav.value.style.left = 0

    //reset width of displace nav
    displaceNav.value.style.width = displacement + 'px'
}

function animateMenu() {
  let val = displacement;
  let duration = 0.28;
  if (!applicationStore.isNavDisplaying) {
    val = 0;
  }
  // Use native JavaScript to set the left property
  sideNav.value.style.left = `${val}px`;
    if (applicationStore.isNavDisplaying) {
      contentCover.value.style.display = 'block';
      applicationStore.blurContent(true)
    } else {
      contentCover.value.style.display = 'none';
      applicationStore.blurContent(false)
    }
}

function toggleMenu() {
  applicationStore.isNavDisplaying = !applicationStore.isNavDisplaying
}
</script>

<style >
#content {
  position: relative;
  flex-basis: 100%;
}
#content-container {
  overflow: hidden;
}
#content-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background-color: #00000073;
}
#global-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10002;
  background-color: #00000073;
}
#global-cover-2 { 
  width: 100%;
  position: absolute;
  z-index: 10003;
 
}
</style>
