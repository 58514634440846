import { defineStore } from "pinia";

export const useURLS = defineStore("URLS", {
  state: () => {
    return {
      application: {
        base: "api/application",
        errorReport: "/error_report",
        systemDimensions: "/system_dimensions",
        generatePDF: "/generate_PDF",
      },
      kpi1_report: {
        base: "api/kpi1_report",
        kpi1: "/kpi_1",
        kpi1QueryRefresh: "/kpi_1_query_refresh",
        kpi1DrillDown: "/kpi_1_drill_down",
      },
      kpi2_report: {
        base: "api/kpi2_report",
        kpi2: "/kpi_2",
        kpi2QueryRefresh: "/kpi_2_query_refresh",

      },

      marketing_spend_report: {
        base: "api/marketing_spend_report",
        budgetSaleYearsFind: "/budget_sale_years_find",
        marketingSpendYearsFind: "/marketing_spend_years_find",
        marketingSpendFind: "/marketing_spend_find",
        spendDataForMonthForOwner: "/spend_data_for_month_for_owner",
        refreshCache: "/refresh_cache",
        refreshCacheInfo: "/refresh_cache_info",
        updateSingleRow: "/update_single_row",
        updateOverride: "/update_override",
        getDebugInfo: "/get-debug-info",
        budgetSaleFind: "/marketing_spend_find",
      },
      budget_sale_report: {
        base: "api/budget_sale_report",
        budgetSaleYearsFind: "/budget_sale_years_find",
        budgetSaleFind: "/marketing_spend_find",
      },
      auth: {
        base: "api/auth",
        login: "/login",
        loginWithoutPassword: "/login_without_password",
        register: "/register",
        refreshToken: "/refresh_token",
        verifyEmailOTP: "/verify_email_otp",
        verifyPasswordOTP: "/verify_password_otp",
        resetPassword: "/reset_password",
        resendVerifyPasswordOTP: "/resend_verify_password_otp",
        resendVerifyEmailOTP: "/resend_verify_email_otp",
      },

      user_management: {
        base: "api/user_management",
        find: "/find",
        add: "/add",
        update: "/update",
        delete: "/delete",
        sendInvite: "/send_invite",
        sendMassInvite: "/send_mass_invite",
        setDefaultDataSource: "/set_default_data_source",
        updatePermissionUser: "/update_permission_user",
        addAliasOwner: "/add_alias_owner",
        deleteAliasOwner: "/delete_alias_owner",
        addAliasSalesPerson: "/add_alias_sales_person",
        deleteAliasSalesPerson: "/delete_alias_sales_person",
        addAliasStaff: "/add_alias_staff",
        deleteAliasStaff: "/delete_alias_staff",
      },

      location_management: {
        base: "api/location_management",
        find: "/find",
        add: "/add",
        update: "/update",
        delete: "/delete",
        addAlias: "/add_alias",
        deleteAlias: "/delete_alias",
      },
      lead_group_management: {
        base: "api/lead_group_management",
        find: "/find",
        add: "/add",
        update: "/update",
        delete: "/delete",
        addAlias: "/add_alias",
        deleteAlias: "/delete_alias",
      },
      budget_sale_management: {
        base: "api/budget_sale_management",
        find: "/find_allocations",
        update: "/update_allocations",
        updateSingleRow: "/update_single_row",
        find_years: "/find_years",
        add_years: "/add_years",
        lock_years: "/lock_years",

      },
      marketing_spend_management: {
        base: "api/marketing_spend_management",
        findOneViaLeadGroup: "/find_one_via_leadgroup",
        findLeadGroups: "/find_leadgroups",
        findLeadGroupsAndOverrides: "/find_leadgroups_and_overrides",
        update: "/update",
        updateOverride: "/update_override",
        updateSingleRow: "/update_single_row",
        refreshCache: "/refresh_cache",
        refreshCacheInfo: "/refresh_cache_info",

        find_years: "/find_years",
        add_years: "/add_years",
        lock_years: "/lock_years",

      },
      rolesandpermissions: {
        base: "api/rolesandpermissions",
        allRolesAndPermissions: "/all_roles_and_permissions",

      },
      benchmark_lead_group_management: {
        base: "api/benchmark_lead_group_management",
        find: "/find",
        add: "/add",
        update: "/update",
        update_lead_group: "/update_lead_group",
        delete: "/delete",
        recreatePreviousToTarget: "/recreate_previous_to_target",

        find_years: "/find_years",
        add_years: "/add_years",
        clone_years: "/clone_years",
        lock_years: "/lock_years",

      },

      benchmark_lead_group: {
        base: "api/benchmark_lead_group",
        find: "/find",
      },
      user_presets_tables: {
        base: "api/user_presets_tables",
        fetch: "/fetch",
        add: "/add",
        update: "/update",
        update_global: "/update_global",
        delete: "/delete",
      },

      user_presets_report_filters: {
        base: "api/user_presets_report_filters",    
        add: "/add",
        update: "/update",
        update_global: "/update_global",
        delete: "/delete",
    
        add_group: "/add_group",
        update_group: "/update_group",
        update_global_group: "/update_global_group",
        delete_group: "/delete_group",
      },
    }
  },

  actions: {
    getURL(key1, key2) {
      let target = this[key1]

      return target.base + target[key2]
    },

  }


});



