<template>
  <canvas ref="gaugeCanvas" :width="config.canvasSize" :height="getCanvasHeight"></canvas>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'

class Gauge {
  constructor(canvas, maxValue) {
    this.canvas = canvas
    this.context = canvas.getContext('2d')
    this.maxValue = maxValue
    this.segments = []
    this.needleValue = 0
    this.needleLengthRatio = 0.75 // Default needle length ratio
    this.textPadding = 20 // Default padding
    this.textSize = 36 // Default text size
    this.textColor = 'black' // Default text color

    this.textPaddingLabel = 10 // Default padding
    this.textSizeLabel = 10 // Default text size
    this.textColorLabel = 'black' // Default text color
    this.useLables = true
    this.useWedgeStrokes = true
    this.wedgeStrokeWidth = 10
    this.textFontFamily = 'Arial'
  }

  addSegment(value, color, rerender = true) {
    this.segments.push({ value, color })
    if (rerender) this.draw()
  }

  clearSegments(rerender = true) {
    this.segments = []
    if (rerender) this.draw()
  }

  setNeedleValue(value, rerender = true) {
    this.needleValue = value
    if (rerender) this.draw()
  }

  setNeedleLengthRatio(ratio, rerender = true) {
    this.needleLengthRatio = ratio
    if (rerender) this.draw()
  }

  setTextPadding(padding, rerender = true) {
    this.textPadding = padding
    if (rerender) this.draw()
  }

  setTextPaddingLabel(padding, rerender = true) {
    this.textPaddingLabel = padding
    if (rerender) this.draw()
  }

  setTextSize(size, rerender = true) {
    this.textSize = size
    if (rerender) this.draw()
  }

  setTextSizeLabel(size, rerender = true) {
    this.textSizeLabel = size
    if (rerender) this.draw()
  }

  setTextColor(color, rerender = true) {
    this.textColor = color
    if (rerender) this.draw()
  }

  setTextColorLabel(color, rerender = true) {
    this.textColorLabel = color
    if (rerender) this.draw()
  }

  setTextFontFamily(fontFamily, rerender = true) {
    this.textFontFamily = fontFamily
    if (rerender) this.draw()
  }

  setUseLabels(use, rerender = true) {
    this.useLables = use
    if (rerender) this.draw()
  }

  setUseWedgeStrokes(use, rerender = true) {
    this.useWedgeStrokes = use
    if (rerender) this.draw()
  }

  setWedgeStrokeWidth(size, rerender = true) {
    this.wedgeStrokeWidth = size
    if (rerender) this.draw()
  }

  setMaxValue(maxValue, rerender = true) {
    this.maxValue = maxValue
    if (rerender) this.draw()
  }

  

  draw() {
    const ctx = this.context
    const width = this.canvas.width
    const height = this.canvas.width / 2
    var outerRadius = this.canvas.width / 2
    if (this.useWedgeStrokes) {
      outerRadius -= this.wedgeStrokeWidth
    }
    const innerRadius = outerRadius * 0.5
    const needleLength = innerRadius * this.needleLengthRatio
    const cx = width / 2
    const cy = height
    const startAngle = Math.PI
    const endAngle = 2 * Math.PI
    let currentAngle = startAngle

    // Clear the canvas
    ctx.clearRect(0, 0, width, height)

    // Draw the segments
    for (let segment of this.segments) {
      const segmentAngle = (segment.value / this.maxValue) * (endAngle - startAngle)
      ctx.beginPath()
      ctx.arc(cx, cy, outerRadius, currentAngle, currentAngle + segmentAngle)
      ctx.arc(cx, cy, innerRadius, currentAngle + segmentAngle, currentAngle, true)
      ctx.closePath()
      ctx.fillStyle = segment.color
      if (this.useWedgeStrokes) {
        ctx.strokeStyle = 'white'
        ctx.lineWidth = this.wedgeStrokeWidth
        ctx.stroke()
      }
      ctx.fill()

      currentAngle += segmentAngle
    }

    if (this.needleValue) {
      // Draw the needle
      const needleAngle = startAngle + (Math.min(this.needleValue,this.maxValue) / this.maxValue) * (endAngle - startAngle)
      const needleX = cx + needleLength * Math.cos(needleAngle)
      const needleY = cy + needleLength * Math.sin(needleAngle)

      // Needle line
      ctx.beginPath()
      ctx.moveTo(cx, cy)
      ctx.lineTo(needleX, needleY)
      ctx.strokeStyle = 'black'
      ctx.lineWidth = 2
      ctx.stroke()

      // Needle base circle
      ctx.beginPath()
      ctx.arc(cx, cy, innerRadius * 0.1, 0, 2 * Math.PI)
      ctx.fillStyle = 'black'
      ctx.fill()

      // Needle arrowhead
      ctx.beginPath()
      ctx.moveTo(needleX, needleY)
      ctx.lineTo(
        needleX - 5 * Math.cos(needleAngle - Math.PI / 6),
        needleY - 5 * Math.sin(needleAngle - Math.PI / 6)
      )
      ctx.lineTo(
        needleX - 5 * Math.cos(needleAngle + Math.PI / 6),
        needleY - 5 * Math.sin(needleAngle + Math.PI / 6)
      )
      ctx.closePath()
      ctx.fillStyle = 'black'
      ctx.fill()

      // Draw the text below the half circle
      const textValue = this.needleValue //this.segments.reduce((acc, seg) => acc + seg.value, 0).toFixed(0);
      ctx.fillStyle = this.textColor
      ctx.font = `bold ${this.textSize}px Arial`
      ctx.textAlign = 'center'
      ctx.textBaseline = 'top'
      ctx.fillText(textValue, cx, cy + this.textPadding)
    }

    if (this.useLables) {
      // Draw the start text label
      const textValueStart = 0
      ctx.fillStyle = this.textColorLabel
      ctx.font = `bold ${this.textSizeLabel}px ${this.textFontFamily}`
      ctx.textAlign = 'center'
      ctx.textBaseline = 'top'
      ctx.fillText(
        textValueStart,
        cx - outerRadius + (outerRadius - innerRadius) / 2,
        cy + this.textPaddingLabel
      )

      // Draw the end text label
      const textValueEnd = this.maxValue
      ctx.fillStyle = this.textColorLabel
      ctx.font = `bold ${this.textSizeLabel}px Arial`
      ctx.textAlign = 'center'
      ctx.textBaseline = 'top'
      ctx.fillText(
        textValueEnd,
        cx + outerRadius - (outerRadius - innerRadius) / 2,
        cy + this.textPaddingLabel
      )
    }
  }
}

const props = defineProps({
  config: {
    type: Object,
    required: true
  }
})

const getCanvasHeight = computed(() => {
  let val = props.config.canvasSize / 2 + props.config.textPadding + props.config.textSize
 
  return val
})

const gaugeCanvas = ref(null)

onMounted(() => {
 
  const gauge = new Gauge(gaugeCanvas.value, props.config.maxValue)

  props.config.segments.forEach((segment) => {
    gauge.addSegment(segment.value, segment.color, false)
  })

  gauge.setNeedleValue(props.config.needleValue, false)

  if (props.config.textPadding) {
    gauge.setTextPadding(props.config.textPadding, false)
  }

  if (props.config.textPaddingLabel) {
    gauge.setTextPaddingLabel(props.config.textPaddingLabel, false)
  }

  if (props.config.textSize) {
    gauge.setTextSize(props.config.textSize, false)
  }
  if (props.config.textColor) {
    gauge.setTextColor(props.config.textColor, false)
  }

  if (props.config.textSizeLabel) {
    gauge.setTextSizeLabel(props.config.textSizeLabel, false)
  }
  if (props.config.textColorLabel) {
    gauge.setTextColorLabel(props.config.textColorLabel, false)
  }

  if (props.config.wedgeStrokeWidth) {
    gauge.setWedgeStrokeWidth(props.config.wedgeStrokeWidth, false)
  }

  if (props.config.textFontFamily) {
    gauge.setTextFontFamily(props.config.textFontFamily, false)
  }

  gauge.setMaxValue(props.config.maxValue, false)

  gauge.draw()
})
</script>
