import { useReportCore } from '@/stores/ReportCore.js'
export function useBuildMetricDataTimeline2(metrics, metricsSource, title, format, metricKey) {
  const reportCore = useReportCore()

  let metric = metrics[title]
  if (!metric) {
    metrics[title] = []
    metrics[title][0] = []
    metrics[title][1] = []
  }
//console.log(metricKey)
 // console.log(metricsSource)
  //since i know its a 12 month timeline the arrays have this length
  for (let i = 0; i < 12; i++) {
    let valueCurrent1 = null
    let valueCurrent2 = null
    if (Array.isArray(metricKey)) {

     

      let metricTarget1 = null
      let metricTarget2 = null

      let metricTarget1b = null
      let metricTarget2b = null

      if (Array.isArray(metricKey[0])) {   
       
        let s1 =  metricsSource.timelineYearCurrent[i][metricKey[0][0]]   
        let s2 =  metricsSource.timelineYearCurrent[i][metricKey[0][1]]   

        let s3 =  metricsSource.timelineYearPrevious[i][metricKey[0][0]]   
        let s4 =  metricsSource.timelineYearPrevious[i][metricKey[0][1]]   
        if(s1 && s2){
          metricTarget1 = (s1 / s2 )  *100  
          metricTarget1b = (s3 / s4  )*100
        }   
      } else {
        let s1 =  metricsSource.timelineYearCurrent[i][metricKey[0]]  
        let s2 =  metricsSource.timelineYearPrevious[i][metricKey[0]]  
        if(s1){ 
        metricTarget1  = s1   
        metricTarget1b  = s2  
        } 
      }
      if (Array.isArray(metricKey[1])) {     
        let s1 =  metricsSource.timelineYearCurrent[i][metricKey[1][0]]   
        let s2 =  metricsSource.timelineYearCurrent[i][metricKey[1][1]]   

        let s3 =  metricsSource.timelineYearPrevious[i][metricKey[1][0]]   
        let s4 =  metricsSource.timelineYearPrevious[i][metricKey[1][1]]   
        if(s1 && s2){
          metricTarget2 = (s1 / s2 )*100    
          metricTarget2b = (s3 / s4)  *100
        }   
      } else {
        let s1 =  metricsSource.timelineYearCurrent[i][metricKey[1]]  
        let s2 =  metricsSource.timelineYearPrevious[i][metricKey[1]]  
        if(s1){ 
        metricTarget2  = s1   
        metricTarget2b  = s2  
        } 
      }

     
      //if (metricTarget1 && metricTarget2) {
        valueCurrent1 = metricTarget1 / metricTarget2

        valueCurrent2 = metricTarget1b / metricTarget2b
     // }

      if (isNaN(valueCurrent1) || !valueCurrent1 || !isFinite(valueCurrent1)) {
        valueCurrent1 = 0
      }
      if (isNaN(valueCurrent2) || !valueCurrent2 || !isFinite(valueCurrent2)) {
        valueCurrent2 = 0
      }

      let complexValue1 = {}
      let complexValue2 = {}
      complexValue1.value = valueCurrent1
      complexValue2.value = valueCurrent2

      if (format === 'USD') {
        valueCurrent1 = reportCore.formatCurrency(valueCurrent1)
        valueCurrent2 = reportCore.formatCurrency(valueCurrent2)
      }
      if (format === '%') {
        valueCurrent1 = reportCore.formatPercent(valueCurrent1)
        valueCurrent2 = reportCore.formatPercent(valueCurrent2)
      }
      if (format === '%ROI') {
        valueCurrent1 = reportCore.formatPercentROI(valueCurrent1)
        valueCurrent2 = reportCore.formatPercentROI(valueCurrent2)
      }

      if (format === 'INT') {
        valueCurrent1 = Math.round(valueCurrent1)
        valueCurrent2 = Math.round(valueCurrent2)
      }

      if (format === 'FLOAT') {
        valueCurrent1 = reportCore.formatFloat(valueCurrent1)
        valueCurrent2 = reportCore.formatFloat(valueCurrent2)
      }

      complexValue1.formattedValue = valueCurrent1
      complexValue2.formattedValue = valueCurrent2

      metrics[title][0][i] = complexValue1
      metrics[title][1][i] = complexValue2
    } else {
      
         
        valueCurrent1 = metricsSource.timelineYearCurrent[i][metricKey]  
        valueCurrent2 = metricsSource.timelineYearPrevious[i][metricKey]  
     

      if (isNaN(valueCurrent1) || !valueCurrent1 || !isFinite(valueCurrent1)) {
        valueCurrent1 = 0
      }
      if (isNaN(valueCurrent2) || !valueCurrent2 || !isFinite(valueCurrent2)) {
        valueCurrent2 = 0
      }

      let complexValue1 = {}
      let complexValue2 = {}
      complexValue1.value = valueCurrent1
      complexValue2.value = valueCurrent2

      if (format === 'USD') {
        valueCurrent1 = reportCore.formatCurrency(valueCurrent1)
        valueCurrent2 = reportCore.formatCurrency(valueCurrent2)
      }
      if (format === '%') {
        valueCurrent1 = reportCore.formatPercent(valueCurrent1)
        valueCurrent2 = reportCore.formatPercent(valueCurrent2)
      }
      if (format === '%ROI') {
        valueCurrent1 = reportCore.formatPercentROI(valueCurrent1)
        valueCurrent2 = reportCore.formatPercentROI(valueCurrent2)
      }

      if (format === 'INT') {
        valueCurrent1 = Math.round(valueCurrent1)
        valueCurrent2 = Math.round(valueCurrent2)
      }

      if (format === 'FLOAT') {
        valueCurrent1 = reportCore.formatFloat(valueCurrent1)
        valueCurrent2 = reportCore.formatFloat(valueCurrent2)
      }

      complexValue1.formattedValue = valueCurrent1
      complexValue2.formattedValue = valueCurrent2

      metrics[title][0][i] = complexValue1
      metrics[title][1][i] = complexValue2
    }
  }
}
