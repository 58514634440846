import { defineStore } from "pinia";
import { ref } from "vue";
export const useToasts = defineStore("Toasts", {
  state() {
    return {

      message: "test error message",
      type: "success",
      display: false,
      messageMap: new Map(),
        currentIndex: 0,
        intervalId : null,    
     

    }
  },

  actions: {
   

    setErrorMessage(payload) {
      let messageOb = {}
      messageOb.type = "error";
      messageOb.message = payload;
      this.load(messageOb)



    },
    setSuccessMessage(payload) {
      let messageOb = {}
      messageOb.type = "success";
      messageOb.message = payload;
      this.load(messageOb)


    },
    setWarningMessage(payload) {
      let messageOb = {}
      messageOb.type = "warning";
      messageOb.message = payload;
      this.load(messageOb)


    },

    load(messageOb) {
      // Add the new message to the map
      this.messageMap.set(this.messageMap.size, messageOb);

    

      // Start the interval for subsequent messages if it's not already running
      if (!this.intervalId) {
        this.processNextMessage();
          this.startLoop();
      }
  },

  startLoop() {
      this.intervalId = setInterval(() => {
          if (this.currentIndex < this.messageMap.size) {
              this.processNextMessage();
          } else {
              this.clearLoop();
          }
      }, 1500);
  },

  processNextMessage() {
    this.display = true;
      // Get the next message in the map
      const message = this.messageMap.get(this.currentIndex);

      if (message) {
          // Process the message
          this.type = message.type;
          this.message = message.message;

          // Optionally, handle the display of the message
          console.log(`Displaying ${this.type}: ${this.message}`);

          // Move to the next message
          this.currentIndex++;
      }
  },

  clearLoop() {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.currentIndex = 0;
      this.messageMap.clear();
      this.display = false;
  },

  dismiss() {
      // Stop the interval and clear the map
      this.clearLoop();
  }

  },
});
