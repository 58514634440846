<template>
  <div class="layout-group py-4">
    <div class="text-color-2 text-center h3 display-5 fw-normal m-0">
      Please verify your password reset
    </div>
    <div class="text-color-2 text-center fs-6 fw-normal m-0">
      An email with an OTP has been sent to you , enter this OTP below and enter your new password
    </div>
  </div>
  <div class="layout-group">
    <b-form-group
      label="OTP:"
      label-for="nested-otp"
      :invalid-feedback="formfield1Invalid"
      :state="formfield1State"
      class="fs-9 text-align-left text-color-1"
    >
      <b-form-input
        :disabled="requests.loading"
        id="nested-otp"
        v-model="formfield1"
        :state="formfield1State"
        trim
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Password:"
      label-for="nested-password"
      :invalid-feedback="formfield3Invalid"
      :state="formfield3State"
      class="fs-9 text-align-left text-color-1"
    >
      <b-input-group>
        <template #append>
          <b-button
            class="btn-no-focus-shadow button-minimal border-0 border-radius-0"
            variant="light"
            @click="showpassword = !showpassword"
          >
            <span
              class="mdi icon icon-color-2"
              :class="{
                'mdi-eye': !showpassword,
                'mdi-eye-off': showpassword
              }"
            ></span>
          </b-button>
        </template>
        <b-form-input
          :disabled="requests.loading"
          :type="showpassword ? 'text' : 'password'"
          id="nested-password"
          v-model="formfield3"
          :state="formfield3State"
          trim
        ></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group
      label="Confirm Password:"
      label-for="nested-password-confirm"
      :invalid-feedback="formfield4Invalid"
      :state="formfield4State"
      class="fs-9 text-align-left text-color-1"
    >
      <b-input-group>
        <template #append>
          <b-button
            class="btn-no-focus-shadow button-minimal border-0 border-radius-0"
            variant="light"
            @click="showpassword = !showpassword"
          >
            <span
              class="mdi icon icon-color-2"
              :class="{
                'mdi-eye': !showpassword,
                'mdi-eye-off': showpassword
              }"
            ></span>
          </b-button>
        </template>
        <b-form-input
          :disabled="requests.loading"
          :type="showpassword ? 'text' : 'password'"
          id="nested-password-confirm"
          v-model="formfield4"
          :state="formfield4State"
          trim
        ></b-form-input>
      </b-input-group>
    </b-form-group>
  </div>
  <div class="layout-group mt-5">
    <b-button
      class="p-1 fs-4 fw-bolder w-100"
      variant="primary"
      @click="verifyResetPassword"
      :disabled="requests.loading"
      >verify</b-button
    >
  </div>
 
  <div class="layout-group mt-6">
    <div class="text-color-2 text-center fs-6 fw-normal m-0 mb-2">
      Click below if you would like to have a new OTP sent to you
    </div>
    <b-button
      class="p-1 fs-4 fw-bolder w-100"
      variant="primary"
      @click="resend"
      :disabled="requests.loading"
      >send</b-button
    >
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useURLS } from '@/stores/URLS.js'
import { useAuth } from '@/stores/Auth.js'
import { useRequests } from '@/stores/Requests.js'
import { useRouter } from 'vue-router'
import DividerComponent from '@/components/navigation/DividerComponent.vue'
import { useToasts } from '@/stores/Toasts.js'

const urls = useURLS()
const auth = useAuth()
const requests = useRequests()
const router = useRouter()
const formfield1 = ref('')

const formfield3 = ref('')
const formfield4 = ref('')
const showpassword = ref(false)
const toasts = useToasts()

var formValidate = ref(false)
var formfield1InvalidMessage = ref('')

var formfield3InvalidMessage = ref('')
var formfield4InvalidMessage = ref('')

function setformfield1InvalidMessage(value) {
  formfield1InvalidMessage.value = value
}

function setformfield3InvalidMessage(value) {
  formfield3InvalidMessage.value = value
}
function setformfield4InvalidMessage(value) {
  formfield4InvalidMessage.value = value
}

//------------------------------------------------------------------

const formfield1State = computed(() => {
  if (!formValidate.value) return undefined
  if (!formfield1.value) {
    setformfield1InvalidMessage('Name is required')

    return false
  }

  setformfield1InvalidMessage('')
  return true
})

const formfield1Invalid = computed(() => {
  if (!formValidate.value) return undefined
  return formfield1InvalidMessage.value
})

//------------------------------------------------------------------

const formfield3State = computed(() => {
  if (!formValidate.value) return undefined
  if (!formfield3.value) {
    setformfield3InvalidMessage('Password is required')

    return false
  }
  //DISABLED DURING DEV FOR EASY TO USE PASSWORDS , UNCOMMENT FOR PRODUCTION SO WE DONT SUPPORT WEAK PASSWORDS

  if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(formfield3.value)) {
    setformfield3InvalidMessage(
      "Password must contain at least lowercase letter, one number, a special character and one uppercase letter."
    );
    return false;
  }
  setformfield3InvalidMessage('')
  return true
})

const formfield3Invalid = computed(() => {
  if (!formValidate.value) return undefined
  return formfield3InvalidMessage.value
})

//------------------------------------------------------------------

const formfield4State = computed(() => {
  if (!formValidate.value) return undefined
  if (!formfield4.value) {
    setformfield4InvalidMessage('Confirm Password is required')

    return false
  }

  if (formfield3.value !== formfield4.value) {
    setformfield4InvalidMessage('Confirm Password must match password')

    return false
  }

  setformfield4InvalidMessage('')
  return true
})

const formfield4Invalid = computed(() => {
  if (!formValidate.value) return undefined
  return formfield4InvalidMessage.value
})

//------------------------------------------------------------------

function verifyResetPassword() {
  formValidate.value = false
  formValidate.value = true
  if (!formfield1State.value || !formfield3State.value || !formfield4State.value) {
    return
  }
  resetPassword()
}

function resetPassword() {
  var formData = {}
  formData.otp = formfield1.value
  formData.password = formfield3.value
  formData.password_confirmation = formfield4.value
  formData.email = auth.user.email

  requests
    .post({
      url: urls.getURL('auth','verifyPasswordOTP'),
      data: formData
    })
    .then((response) => {
      toasts.setSuccessMessage('password reset successful')    
      router.push('login')
    })
}
function resend() {
  let formData = {}
  formData.email = auth.user.email

  requests
    .post({
      url: urls.getURL('auth','resendVerifyPasswordOTP'),
      data: formData
    })
    .then((response) => {
      toasts.setSuccessMessage('An email with a new OTP to reset your password was sent')
    })
}
</script>

<style scoped>
.theme-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 0;
}
.form-label {
  margin-bottom: 0 !important;
}
</style>
