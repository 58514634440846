export function useComponentData() {
  const data = {
    key: null,
    skipSerialization:false,
    sharedKey:null,
    styling: null,
    interaction: null,
    display: null,
    content: null,
    vifValue: true,
    disabled: false,
    enabled: false,
    
    
   
    
  };

  return data;
}
