import { defineStore } from 'pinia'
import { useURLS } from '@/stores/URLS.js'
import { useReportCore } from '@/stores/ReportCore.js'
import { useDataKPI1Store } from '@/stores/DataKPI1Store.js'

import { useBuildMetricDataTables2 } from '@/scripts/utils/BuildMetricDataTables2.js'
import { useConvertObjectToSortedArray } from '@/scripts/utils/ConvertObjectToSortedArray.js'
import { useBuildMetricDataTimeline2 } from '@/scripts/utils/BuildMetricDataTimeline2.js'
import { shallowRef, markRaw, toRaw } from 'vue'
import { useErrorHandler } from '@/stores/ErrorHandler.js'
import { useAuth } from '@/stores/Auth.js'
import { useBuildMetricDataScoreCards } from '@/scripts/utils/BuildMetricDataScoreCards.js'
import { useDataPresetsReportFiltersStore } from '@/stores/DataPresetsReportFiltersStore.js'
import { useToasts } from '@/stores/Toasts.js'
import { useRequests } from '@/stores/Requests'
import SimpleDate from '../scripts/utils/SimpleDate'
export const useReportMarketing = defineStore('ReportMarketing', {
  state: () => {
    return {
      dataRawBudgetYears: markRaw([]),
      yearSelectionCurrent: null,
      dataRawSpendWriteDebugInfo: markRaw([]),
      dataRawBudgetSpends: markRaw([]),
     
      dataBudgetYears: null,
      dataBudgetAllocations: null,
      dataBudgetSpends: null,
      queryCacheDateBudgetSpend: null,

      reportCore: markRaw(useReportCore()),
      dataKPI1Store: markRaw(useDataKPI1Store()),
      urls: markRaw(useURLS()),
      requests: markRaw(useRequests()),
      toasts: markRaw(useToasts()),
      dataPresetsReportFiltersStore:markRaw(useDataPresetsReportFiltersStore()),

      section1Expanded: false,

      dataBudgetAllocationsOptimized: markRaw({}),
      dataBudgetSpendsOptimized: markRaw({}),

      reportElementProps: markRaw([
        'APPOINTMENTS_COUNT',
        'ISSUED_APPOINTMENTS_COUNT',
        'QUOTES_COUNT',
        'SALES_COUNT',
        'LEADS_COUNT',
        'SALES_SUM'
      ]),

      metrics: markRaw({}),

      editSpendExpanded: false,

      leadGroupSpendData:{},

      dataTableOwners: {},
      dataTableGroups: {},
      dataTableLocations: {},

      tableDataOwnersObj: null,
      tableDataLocationsObj: null,
      tableDataLeadGroupsObj: null,

      ownersExcludedByStartDate: null,
      ownersExcludedByStartDateButValid: null,

      ownersExcludedByStartDateArray: null,
      ownersExcludedByStartDateButValidArray: null,

      allOwnersFromKPIData: null,

      showDebugData: false,

      dataTableDebugOwnersMonthCurrent: {},
      dataTableDebugOwnersMonthYOY: {},

      ownerFilter: null,
      locationFilter: null,
      leadGroupFilter: null,

      scorecardLabels: null,

     /* propsMap: markRaw({
        OWNER: 'Owner',
        LOCATION: 'Location',
        LEAD_GROUP: 'Lead Group',
        SALES_SUM: 'Amount',
        SALES_COUNT: 'Sales',
        LEADS_COUNT: 'Leads',
        APPOINTMENTS_COUNT: 'Appointments',
        ISSUED_APPOINTMENTS_COUNT: 'Appointments Issued',
        QUOTES_COUNT: 'Quotes'
      }),*/

      ownerSelectiveFilters: markRaw({}),
      hasOwnerSelectiveFilters: false,

      locationSelectiveFilters: markRaw({}),
      hasLocationSelectiveFilters: false,

      leadgroupSelectiveFilters: markRaw({}),
      hasLeadGroupSelectiveFilters: false,

      scoreCards: shallowRef({}),


      timelineMetrics: markRaw({}),
      tableColumnsMetrics: markRaw([
        'Spend Percentage',
        'Spend Amount',
        'Sales Amount',
        'Cost per Lead',
        'Cost per Appointment',
        'Cost per Issued Appointment',
        'Cost per Quote',
        'Cost per Sale',
        'ROI',
        'NSL',
        'NSA',
        'NSLI',
        'Issued Appointment by Leads',
        'Sales by Leads'
      ]),

      currentLineChartMetric: 'Spend Amount',

      timelineData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            label: new Date().getFullYear(),
            fill: false,
            lengendBlockStyle: 'width:20px;height:20px;background-color:#ad84aa;',
            borderColor: '#ad84aa',
            yAxisID: 'y',
            tension: 0,
            pointRadius: 6,
            data: []
          },
          {
            label: new Date().getFullYear() - 1,
            fill: false,
            lengendBlockStyle: 'width:20px;height:20px;background-color:#6b7887;',
            borderColor: '#6b7887',
            yAxisID: 'y',
            tension: 0,
            pointRadius: 6,
            data: []
          }
        ]
      }
    }
  },


  getters: {

    validateEditSpendOption(state) {
      if (!state.ownerFilter) {
        return false
      }
      if (state.reportCore.dateRangeStart.month != state.reportCore.dateRangeEnd.month) {
        return false
      }
      return true
    },

  },

  actions: {
   
    //-------------------------------------------------------------------------------------------------

    changeTimelineMetric(metric) {
      this.currentLineChartMetric = metric
      this.timelineSingleMetricSet1 = this.timelineMetrics[metric][0]
      this.timelineSingleMetricSet2 = this.timelineMetrics[metric][1]
      this.timelineData.datasets[0].data = this.timelineMetrics[metric][0].map(
        (dataPoint) => dataPoint.value
      )
      this.timelineData.datasets[1].data = this.timelineMetrics[metric][1].map(
        (dataPoint) => dataPoint.value
      )
    },

    //-------------------------------------------------------------------------------------------------

    async refreshQueryCacheKPI($event, key) {
      let asyncProcessSuccessful = false
      asyncProcessSuccessful = await this.dataKPI1Store.refreshQueryCache($event, key)
      if (!asyncProcessSuccessful) return
      asyncProcessSuccessful = await this.loadMarketingSpendYears()
      if (!asyncProcessSuccessful) return
      asyncProcessSuccessful = await this.loadMarketingSpendDebug()
      if (!asyncProcessSuccessful) return
      asyncProcessSuccessful = await this.loadDataBudgetSpend()
      if (!asyncProcessSuccessful) return

      this.processLoadedData()

      this.buildReport()
    },

    //-------------------------------------------------------------------------------------------------

    async loadAllData() {
      let key = null

     

      //gets all kpi data
      let asyncProcessSuccessful = false
      asyncProcessSuccessful = await this.dataKPI1Store.loadDataAll()
      if (!asyncProcessSuccessful) return
      asyncProcessSuccessful = await this.loadMarketingSpendYears()
      if (!asyncProcessSuccessful) return
      asyncProcessSuccessful = await this.loadMarketingSpendDebug()
      if (!asyncProcessSuccessful) return
      asyncProcessSuccessful = await this.loadDataBudgetSpend()
      if (!asyncProcessSuccessful) return

      this.processLoadedData()

      this.buildReport()
    },

    //-------------------------------------------------------------------------------------------------

    processLoadedData() {
     
      this.buildOptimizedData()
    },

    //-------------------------------------------------------------------------------------------------

    buildOptimizedData() {
      try {


        let i = this.dataRawSpendWriteDebugInfo.length

        this.dataSpendWriteDebugInfo = {}
        this.dataSpendWriteDebugInfo.owner_unmapped = []
        this.dataSpendWriteDebugInfo.leadgroup_unmapped = []

        this.dataSpendWriteDebugInfo.owner_mapped = []
        this.dataSpendWriteDebugInfo.leadgroup_mapped = []

        // this.dataSpendWriteDebugInfo.owner_existing = []
        // this.dataSpendWriteDebugInfo.leadgroup_existing = []

        while (i--) {
          let element = this.dataRawSpendWriteDebugInfo[i]
          if (element.owner_unmapped) {
            this.dataSpendWriteDebugInfo.owner_unmapped.push(element.owner_unmapped)
          }
          if (element.leadgroup_unmapped) {
            this.dataSpendWriteDebugInfo.leadgroup_unmapped.push(element.leadgroup_unmapped)
          }

          if (element.owner_mapped) {
            this.dataSpendWriteDebugInfo.owner_mapped.push(
              `${element.owner_mapped} to ${element.owner_existing}`
            )
          }
          if (element.leadgroup_mapped) {
            this.dataSpendWriteDebugInfo.leadgroup_mapped.push(
              `${element.leadgroup_mapped} to ${element.leadgroup_existing}`
            )
          }

          this.dataSpendWriteDebugInfo.owner_unmapped.sort()
          this.dataSpendWriteDebugInfo.leadgroup_unmapped.sort()
          this.dataSpendWriteDebugInfo.owner_mapped.sort()
          this.dataSpendWriteDebugInfo.leadgroup_mapped.sort()


        }

        let dataRef = this.dataBudgetSpends = markRaw({})

        i = this.dataRawBudgetSpends.length

        while (i--) {
          let element = this.dataRawBudgetSpends[i]
          // console.log(element)
          let yearOb = dataRef[element.year]
          if (!yearOb) {
            yearOb = dataRef[element.year] = {}
          }
          let yearMonthOb = yearOb[element.month]
          if (!yearMonthOb) {
            yearMonthOb = yearOb[element.month] = {}
          }

          let userInternal = this.reportCore.dataOwners[element.owner_id]
          let yearMonthObOwner = yearMonthOb[userInternal.name]
          if (!yearMonthObOwner) {
            yearMonthObOwner = yearMonthOb[userInternal.name] = {}
          }


          let leadGroupInternal = this.reportCore.dataLeadGroups[element.group_id]

          yearMonthObOwner[leadGroupInternal.name] = element.value
        }


      } catch (error) {
        useErrorHandler().processScriptError(error)
      }
    },

    //-------------------------------------------------------------------------------------------------

    async loadMarketingSpendYears() {
      //get budget data
      const urls = useURLS()

      this.reportCore.setLoadHeavy(true, 'Marketing Years Data')

      var data = {}
      var urlToUse = urls.getURL('marketing_spend_report', 'marketingSpendYearsFind')
      try {
        let result = await this.reportCore.loadServerData(urlToUse, data)
        if (result) {
           this.reportCore.setLoadHeavy(false)
          this.dataRawBudgetYears = markRaw(result)
          if (this.yearSelectionCurrent) {
            this.yearSelectionCurrent = this.dataRawBudgetYears.find(element => element.year === this.yearSelectionCurrent.year)
          } else {
            this.yearSelectionCurrent = this.dataRawBudgetYears[0]
          }
          return true
        }
      } catch (error) {
        this.reportCore.setLoadHeavy(false)
        console.log(error)
        return false
      }
    },

    //-------------------------------------------------------------------------------------------------

    async loadMarketingSpendDebug() {
      //get budget data

      this.reportCore.setLoadHeavy(true, 'Marketing Debug Data')

      const urls = useURLS()
      var data = {}
      var urlToUse = urls.getURL('marketing_spend_report', 'getDebugInfo')
      try {
        let result = await this.reportCore.loadServerData(urlToUse, data)
        if (result) {
          this.reportCore.setLoadHeavy(false)
          this.dataRawSpendWriteDebugInfo = markRaw(result)
          return true
        }
      } catch (error) {
        this.reportCore.setLoadHeavy(false)
        console.log(error)
        return false
      }
    },

    //-------------------------------------------------------------------------------------------------

    async loadDataBudgetSpend() {
      //get budget data

      this.reportCore.setLoadHeavy(true, 'Marketing Data')
      const urls = useURLS()
      var data = {}
      var urlToUse = urls.getURL('marketing_spend_report', 'marketingSpendFind')
      try {
        let result = await this.reportCore.loadServerData(urlToUse, data)
        if (result) {
          this.reportCore.setLoadHeavy(false)
          this.dataRawBudgetSpends = markRaw(result)
          return true
        }
      } catch (error) {
        this.reportCore.setLoadHeavy(false)
        console.log(error)
        return false
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcessOwnersExcludedByStartDate(ownerObj) {
      let ownerName = ownerObj.name
      if (!ownerName || ownerName === '' || ownerName === 'null') {
        ownerName = 'empty'
      }

      let debugOwner = (this.ownersExcludedByStartDate[ownerName] = {})
      debugOwner.name = ownerName
      debugOwner.props = {}

      debugOwner.props.dateValue = {
        label: 'started at',
        value: this.reportCore.dataOwners[ownerName].started_at.toString()
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcessOwnersExcludedByStartDateButValid(ownerObj) {
      let ownerName = ownerObj.name
      if (!ownerName || ownerName === '' || ownerName === 'null') {
        ownerName = 'empty'
      }

      let debugOwner = (this.ownersExcludedByStartDateButValid[ownerName] = {})
      debugOwner.name = ownerName
      debugOwner.props = {}

      debugOwner.props.dateValue = {
        label: 'started at',
        value: this.reportCore.dataOwners[ownerName].started_at.toString()
      }

      debugOwner.props.salesSumValue = {
        label: 'sales sum',
        value: ownerObj.data['SALES_SUM'] || 0
      }
      debugOwner.props.spendValue = {
        label: 'spend',
        value: ownerObj.data['SPEND'] || 0
      }
    },



    //-------------------------------------------------------------------------------------------------

    buildReportProcess7() {
      let tableDataOwners = []
      //let tableDataLocations = []
      let tableDataLeadGroups = []


      for (let ownerName in this.tableDataOwnersObj) {
        let ownerItem = this.tableDataOwnersObj[ownerName]
        if (!ownerItem.isMapped) {
          let unmappedItem = this.dataKPI1Store.unmappedKPIOwnersCORE[ownerName]
          let unmappedItem2 = this.dataKPI1Store.unmappedKPIOwnersSM[ownerName]
          if (unmappedItem) {
            unmappedItem.props.sales.value = this.reportCore.formatCurrency(
              ownerItem.data.SALES_SUM
            )
          }
          if (unmappedItem2) {
            unmappedItem2.props.sales.value = this.reportCore.formatCurrency(
              ownerItem.data.SALES_SUM
            )
          }
        } else {
          let systemOwner = this.reportCore.dataOwners[ownerName]
          let ignore = false
          if (systemOwner.started_at > this.reportCore.dateRangeEnd) {
            ignore = true
          }
          if (ignore) {
            if (ownerItem.data.SALES_SUM > 0 || ownerItem.data.SPEND > 0) {
              ignore = false
              this.buildReportProcessOwnersExcludedByStartDateButValid(ownerItem)
            }
          }
          if (!ignore) {
            ownerItem.data.OWNER = ownerName
            this.buildMetricsTables(ownerItem.data, tableDataOwners)
          } else {
            this.buildReportProcessOwnersExcludedByStartDate(ownerItem)
          }
        }
      }

      /*for (let locationName in this.tableDataLocationsObj) {
        let locationItem = this.tableDataLocationsObj[locationName]
        locationItem.data.LOCATION = locationName
        this.buildMetricsTables(locationItem.data, tableDataLocations)
      }*/

      for (let leadGroupName in this.tableDataLeadGroupsObj) {
        let leadGroupItem = this.tableDataLeadGroupsObj[leadGroupName]

        if (!leadGroupItem.isMapped) {
          let unmappedItem = this.dataKPI1Store.unmappedKPILeadGroupsCORE[leadGroupName]
          let unmappedItem2 = this.dataKPI1Store.unmappedKPILeadGroupsSM[leadGroupName]
          if (unmappedItem) {
            unmappedItem.props.sales.value = this.reportCore.formatCurrency(
              leadGroupItem.data.SALES_SUM
            )
          }
          if (unmappedItem2) {
            unmappedItem2.props.sales.value = this.reportCore.formatCurrency(
              leadGroupItem.data.SALES_SUM
            )
          }
        } else {
          let systemOwner = this.reportCore.dataOwners[leadGroupItem.ownerName]
          let ignore = false
          if (systemOwner.started_at > this.reportCore.dateRangeEnd) {
            ignore = true
          }
          if (ignore) {
            if (leadGroupItem.data.SALES_SUM > 0 || leadGroupItem.data.SPEND > 0) {
              ignore = false
            }
          }
          if (!ignore) {
            leadGroupItem.data.LEAD_GROUP = leadGroupName
            this.buildMetricsTables(leadGroupItem.data, tableDataLeadGroups)
          }
        }
      }

      this.dataTableOwners.tableData = tableDataOwners
      // this.dataTableLocations.tableData = tableDataLocations
      this.dataTableGroups.tableData = tableDataLeadGroups

      this.buildMetricsTimeline(this.timeLineData)
      this.changeTimelineMetric(this.currentLineChartMetric)
      this.buildScoreCards(this.metrics)

      //convert some objects to sorted arrays for better ordered display

      this.ownersExcludedByStartDateArray = useConvertObjectToSortedArray(
        this.ownersExcludedByStartDate
      )
      this.ownersExcludedByStartDateButValidArray = useConvertObjectToSortedArray(
        this.ownersExcludedByStartDateButValid
      )

      this.dataKPI1Store.buildDebugInfo()
    },

    //-------------------------------------------------------------------------------------------------

    getLeadGroupStorage(leadGroupName, ownerName) {
      let leadGroupStorage = this.tableDataLeadGroupsObj[leadGroupName]

      if (!leadGroupStorage) {
        leadGroupStorage = this.tableDataLeadGroupsObj[leadGroupName] = {}
        let systemLeadGroup = this.reportCore.dataLeadGroups[leadGroupName]
        let isMapped = true
        if (!systemLeadGroup) {
          isMapped = false
        }
        let ownerStorage = this.getOwnerStorage(ownerName)
        leadGroupStorage.isMapped = isMapped
        leadGroupStorage.name = leadGroupName
        leadGroupStorage.ownerName = ownerName
        leadGroupStorage.ownerType = ownerStorage.ownerType
        leadGroupStorage.data = {}
      }

      return leadGroupStorage
    },

    getOwnerStorage(ownerName) {
      let ownerStorage = this.tableDataOwnersObj[ownerName]
      if (!ownerStorage) {
        ownerStorage = this.tableDataOwnersObj[ownerName] = {}
        let systemOwner = this.reportCore.dataOwners[ownerName]

        let isMapped = true
        let ownerType = null
        if (!systemOwner) {
          isMapped = false
        } else {
          if (systemOwner.started_at.year !== this.reportCore.currentDate.year) {
            ownerType = 'legacy'
          }

          if (systemOwner.started_at.year === this.reportCore.currentDate.year) {
            ownerType = 'new'
          }
        }

        ownerStorage.isMapped = isMapped
        ownerStorage.ownerType = ownerType
        ownerStorage.name = ownerName
        ownerStorage.data = {}
      }

      return ownerStorage
    },

    validateDimensionFiltering(ownerName, locationName, leadGroupName) {
      let valid = true
      if (this.dataPresetsReportFiltersStore.useDimensionFilters) {
        if (this.dataPresetsReportFiltersStore.getPresetData.useDimensionFilterOwner) {
          let systemOwner = this.dataPresetsReportFiltersStore.getPresetData.dataOwnersNoAliases[ownerName]
          if (systemOwner) {
            let selected = systemOwner.selected
            if (this.dataPresetsReportFiltersStore.getPresetData.isInclusive) {
              if (!selected) {
                return
              }
            }
            if (this.dataPresetsReportFiltersStore.getPresetData.isExclusive) {
              if (selected) {
                return
              }
            }
          } else {
            //if useDimensionFilters is true and no system owner , i have to ignore the owner as filtering will possibly fail
            return
          }
        }

        

        //-------------------------------------

        if (this.dataPresetsReportFiltersStore.getPresetData.useDimensionFilterLeadGroup) {
          let systemLeadGroup = this.dataPresetsReportFiltersStore.getPresetData.dataLeadGroupsNoAliases[leadGroupName]
          if (systemLeadGroup) {
           
            let selected = systemLeadGroup.selected
            if (this.dataPresetsReportFiltersStore.getPresetData.isInclusive) {
              if (!selected) {
                return
              }
            }
            if (this.dataPresetsReportFiltersStore.getPresetData.isExclusive) {
              if (selected) {
                return
              }
            }
          } else {
          
            //if useDimensionFilters is true and no system leadGroup , i have to ignore the leadGroup as filtering will possibly fail
            return
          }
        }
      }

      return valid
    },

    buildSpendProcess(ownerName, dateBeingProcessed, dateRangesValidities) {
      const spendDataYear = this.dataBudgetSpends[dateBeingProcessed.year]
      const spendDataMonth = spendDataYear?.[dateBeingProcessed.month]
      const spendDataOwner = spendDataMonth?.[ownerName]
      if (!spendDataOwner) {
        return
      }

      //  console.log(ownerName)
      // console.log(spendDataOwner)

      for (let leadGroup in spendDataOwner) {
        if (this.leadGroupFilter) {
          if (this.leadGroupFilter !== leadGroup) {
            continue
          }
        }

        let spendDataLeadGroup = spendDataOwner[leadGroup]
        if (!spendDataLeadGroup) {
          continue
        }

        if (!this.validateDimensionFiltering(ownerName, null, leadGroup)) {
          continue
        }

        let ownerStorage = this.getOwnerStorage(ownerName)
        let leadGroupStorage = this.getLeadGroupStorage(leadGroup, ownerName)

        if (dateRangesValidities.isDateRangeValidCurrent) {
          ownerStorage.data.SPEND = ownerStorage.data.SPEND || 0
          ownerStorage.data.SPEND += spendDataLeadGroup

          leadGroupStorage.data.SPEND = leadGroupStorage.data.SPEND || 0
          leadGroupStorage.data.SPEND += spendDataLeadGroup

          this.metrics.rangeCurrent.SPEND = this.metrics.rangeCurrent.SPEND || 0
          this.metrics.rangeCurrent.SPEND += spendDataLeadGroup
        }

        if (dateRangesValidities.isDateRangeValidPOP) {
          this.metrics.rangeSub2.SPEND = this.metrics.rangeSub2.SPEND || 0
          this.metrics.rangeSub2.SPEND += spendDataLeadGroup
        }

        if (dateRangesValidities.isDateRangeValidYOY) {
          this.metrics.rangeSub1.SPEND = this.metrics.rangeSub1.SPEND || 0
          this.metrics.rangeSub1.SPEND += spendDataLeadGroup
        }

        if (dateRangesValidities.isDateRangeValidTimelineCurrent) {
          this.timeLineData.timelineYearCurrent[dateBeingProcessed.month].SPEND =
            this.timeLineData.timelineYearCurrent[dateBeingProcessed.month].SPEND || 0
          this.timeLineData.timelineYearCurrent[dateBeingProcessed.month].SPEND +=
            spendDataLeadGroup
        }

        if (dateRangesValidities.isDateRangeValidTimelinePrevious) {
          this.timeLineData.timelineYearPrevious[dateBeingProcessed.month].SPEND =
            this.timeLineData.timelineYearPrevious[dateBeingProcessed.month].SPEND || 0
          this.timeLineData.timelineYearPrevious[dateBeingProcessed.month].SPEND +=
            spendDataLeadGroup
        }
      }
    },

    buildReportProcess6(
      ownerKPIObj,
      locationName,
      leadGroupName,
      dateBeingProcessed,
      dateRangesValidities
    ) {
      if (!this.validateDimensionFiltering(ownerKPIObj.name, locationName, leadGroupName)) {
        return
      }
      let locationKPIObj =
        ownerKPIObj.years[dateBeingProcessed.year].months[dateBeingProcessed.month].days[
          dateBeingProcessed.day
        ].locations[locationName]

      let leadGroupKPIObj = locationKPIObj.leadGroups[leadGroupName]
      if (!leadGroupKPIObj) {
        return
      }
      let ownerStorage = null
      let locationStorage = null
      let leadGroupStorage = null

      if (dateRangesValidities.isDateRangeValidCurrent) {
        ownerStorage = this.getOwnerStorage(ownerKPIObj.name)

        locationStorage = this.tableDataLocationsObj[locationName]

        if (!locationStorage) {
          locationStorage = this.tableDataLocationsObj[locationName] = {}
          locationStorage.data = {}
        }

        leadGroupStorage = this.getLeadGroupStorage(leadGroupName, ownerKPIObj.name)
      }

      for (let i = 0; i < this.reportElementProps.length; i++) {
        let prop = this.reportElementProps[i]
        if (dateRangesValidities.isDateRangeValidCurrent) {
          ownerStorage.data[prop] = ownerStorage.data[prop] || 0
          ownerStorage.data[prop] += leadGroupKPIObj.kpiData[prop]

          locationStorage.data[prop] = locationStorage.data[prop] || 0
          locationStorage.data[prop] += leadGroupKPIObj.kpiData[prop]

          leadGroupStorage.data[prop] = leadGroupStorage.data[prop] || 0
          leadGroupStorage.data[prop] += leadGroupKPIObj.kpiData[prop]

          this.metrics.rangeCurrent[prop] = this.metrics.rangeCurrent[prop] || 0
          this.metrics.rangeCurrent[prop] += leadGroupKPIObj.kpiData[prop] || 0
        }

        if (dateRangesValidities.isDateRangeValidPOP) {
          this.metrics.rangeSub2[prop] = this.metrics.rangeSub2[prop] || 0
          this.metrics.rangeSub2[prop] += leadGroupKPIObj.kpiData[prop] || 0
        }

        if (dateRangesValidities.isDateRangeValidYOY) {
          this.metrics.rangeSub1[prop] = this.metrics.rangeSub1[prop] || 0
          this.metrics.rangeSub1[prop] += leadGroupKPIObj.kpiData[prop] || 0
        }

        if (dateRangesValidities.isDateRangeValidTimelineCurrent) {
          this.timeLineData.timelineYearCurrent[dateBeingProcessed.month][prop] =
            this.timeLineData.timelineYearCurrent[dateBeingProcessed.month][prop] || 0
          this.timeLineData.timelineYearCurrent[dateBeingProcessed.month][prop] +=
            leadGroupKPIObj.kpiData[prop] || 0
        }

        if (dateRangesValidities.isDateRangeValidTimelinePrevious) {
          this.timeLineData.timelineYearPrevious[dateBeingProcessed.month][prop] =
            this.timeLineData.timelineYearPrevious[dateBeingProcessed.month][prop] || 0
          this.timeLineData.timelineYearPrevious[dateBeingProcessed.month][prop] +=
            leadGroupKPIObj.kpiData[prop] || 0
        }
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess5(ownerKPIObj, locationName, dateBeingProcessed, dateRangesValidities) {
      let locationKPIObj = ownerKPIObj.years[dateBeingProcessed.year]
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.months[dateBeingProcessed.month]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.days[dateBeingProcessed.day]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.locations[locationName]
      } else {
        return
      }

      if (!locationKPIObj) {
        return
      }

      if (this.leadGroupFilter) {
        //console.log(this.leadGroupFilter)
        this.buildReportProcess6(
          ownerKPIObj,
          locationName,
          this.leadGroupFilter,
          dateBeingProcessed,
          dateRangesValidities
        )
      } else {
        for (let leadGroupName in locationKPIObj.leadGroups) {
          this.buildReportProcess6(
            ownerKPIObj,
            locationName,
            leadGroupName,
            dateBeingProcessed,
            dateRangesValidities
          )
        }
      }
    },

    buildReportProcess4(ownerName, dataSource, dateBeingProcessed, dateRangesValidities) {
      let ownerKPIObj = dataSource[ownerName]
      //console.log(`buildReportProcess4 ownerName ${ownerName}`)
      if (this.locationFilter) {
        // console.log(this.locationFilter)
        if (ownerKPIObj) {
          this.buildReportProcess5(
            ownerKPIObj,
            this.locationFilter,
            dateBeingProcessed,
            dateRangesValidities
          )
        }
      } else {
        if (ownerKPIObj) {
          let locationRef = ownerKPIObj.years[dateBeingProcessed.year]
          if (locationRef) {
            locationRef = locationRef.months[dateBeingProcessed.month]
          } else {
            return
          }
          if (locationRef) {
            locationRef = locationRef.days[dateBeingProcessed.day]
          } else {
            return
          }
          if (locationRef) {
            locationRef = locationRef.locations
          } else {
            return
          }

          for (let locationName in locationRef) {
            this.buildReportProcess5(
              ownerKPIObj,
              locationName,
              dateBeingProcessed,
              dateRangesValidities
            )
          }
        }
      }
    },

    //-------------------------------------------------------------------------------------------------


    buildReportProcess3(dateBeingProcessed) {
      let dateRangesValidities = {}
      dateRangesValidities.isDateRangeValidCurrent = true
      dateRangesValidities.isDateRangeValidPOP = true
      dateRangesValidities.isDateRangeValidYOY = true
      dateRangesValidities.isDateRangeValidTimelineCurrent = true
      dateRangesValidities.isDateRangeValidTimelinePrevious = true

      if (dateBeingProcessed > this.reportCore.dateRangeEnd) {
        dateRangesValidities.isDateRangeValidCurrent = false
      }

      if (dateBeingProcessed < this.reportCore.dateRangeStart) {
        dateRangesValidities.isDateRangeValidCurrent = false
      }

      if (dateBeingProcessed > this.reportCore.periodBeforeEnd) {
        dateRangesValidities.isDateRangeValidPOP = false
      }

      if (dateBeingProcessed < this.reportCore.periodBeforeStart) {
        dateRangesValidities.isDateRangeValidPOP = false
      }

      if (dateBeingProcessed > this.reportCore.oneYearBeforeEnd) {
        dateRangesValidities.isDateRangeValidYOY = false
      }

      if (dateBeingProcessed < this.reportCore.oneYearBeforeStart) {
        dateRangesValidities.isDateRangeValidYOY = false
      }
      if (dateBeingProcessed.year === this.reportCore.currentDate.year) {
        dateRangesValidities.isDateRangeValidTimelinePrevious = false
      }
      if (dateBeingProcessed.year === this.reportCore.currentDate.year - 1) {
        dateRangesValidities.isDateRangeValidTimelineCurrent = false
      }

      //------------------------------------------------------------------

      let dataSource = null

      let blendDate = null
      let dataSourceKey = null

      if (this.ownerFilter) {
        if (this.reportCore.useDataSourceOverride) {
          blendDate = this.reportCore.dataBlendDate
          dataSourceKey = this.reportCore.dataSourceCurrent
        } else {
          //get user , get preferred source info , if it exists , otherwise default SM
          let systemOwner = this.reportCore.dataOwners[this.ownerFilter]
          if (systemOwner) {
            blendDate = systemOwner.UserDefaultDataSource.blend_date_sd
            dataSourceKey =
              systemOwner.UserDefaultDataSource.value || this.reportCore.DATA_SOURCE_BLEND
          } else {
            //unmapped owners get system defaults , which are same as overrides
            blendDate = this.reportCore.dataBlendDate
            dataSourceKey = this.reportCore.dataSourceCurrent
          }
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
          dataSource = this.dataKPI1Store.processedDataCORE
        }
        if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
          dataSource = this.dataKPI1Store.processedDataSM
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
          dataSource = this.dataKPI1Store.processedDataCORE

          if (dateBeingProcessed >= blendDate) {
            dataSource = this.dataKPI1Store.processedDataSM
          }
        }

        this.buildReportProcess4(
          this.ownerFilter,
          dataSource,
          dateBeingProcessed,
          dateRangesValidities
        )
        if (dateBeingProcessed.day === 1) {
          this.buildSpendProcess(this.ownerFilter, dateBeingProcessed, dateRangesValidities)
        }
      } else {
        for (let ownerName in this.reportCore.dataOwnersNoAliases) {
          let systemOwner = this.reportCore.dataOwnersNoAliases[ownerName]
          if (this.reportCore.useDataSourceOverride) {
            blendDate = this.reportCore.dataBlendDate
            dataSourceKey = this.reportCore.dataSourceCurrent
          } else {
            blendDate = systemOwner.UserDefaultDataSource.blend_date_sd
            dataSourceKey =
              systemOwner.UserDefaultDataSource.value || this.reportCore.DATA_SOURCE_BLEND
          }
          if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
            dataSource = this.dataKPI1Store.processedDataCORE
          }
          if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
            dataSource = this.dataKPI1Store.processedDataSM
          }

          if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
            dataSource = this.dataKPI1Store.processedDataCORE

            if (dateBeingProcessed >= blendDate) {
              dataSource = this.dataKPI1Store.processedDataSM
            }
          }

          if (dataSource[ownerName]) {
            this.buildReportProcess4(
              ownerName,
              dataSource,
              dateBeingProcessed,
              dateRangesValidities
            )

            if (dateBeingProcessed.day === 1) {
              this.buildSpendProcess(ownerName, dateBeingProcessed, dateRangesValidities)
            }

          } else {
            // console.log(`ownerName ${ownerName} not found in kpi data source ${dataSourceKey}`)
          }
        }

        //once system owners are looped , need to loop kpi sets and process unmapped owners
        //as the above loop will miss them


        if (this.reportCore.useDataSourceOverride) {
          blendDate = this.reportCore.dataBlendDate
          dataSourceKey = this.reportCore.dataSourceCurrent
        } else {
          blendDate = this.reportCore.dataBlendDateUnmapped
          dataSourceKey = this.reportCore.dataSourceUnmapped
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
          dataSource = this.dataKPI1Store.processedDataCORE
        }
        if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
          dataSource = this.dataKPI1Store.processedDataSM
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
          dataSource = this.dataKPI1Store.processedDataCORE

          if (dateBeingProcessed >= blendDate) {
            dataSource = this.dataKPI1Store.processedDataSM
          }
        }
        for (let ownerName in dataSource) {
          let kpiOwner = dataSource[ownerName]
          if (!kpiOwner.isMapped) {
            this.buildReportProcess4(
              ownerName,
              dataSource,
              dateBeingProcessed,
              dateRangesValidities
            )
          }
          //unmapped owners wont have spend data ..   
          /*
          if (dateBeingProcessed.day === 1) {
            this.buildSpendProcess(ownerName, dateBeingProcessed, dateRangesValidities)
          }*/

        }
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess2() {
      //year/month values to loop
      //you always need all months from the curent year and previous year because of the timeline on the report
      //all POP and YOY ranges will fall within these ranges

      let yearMonthDayValues = this.reportCore.getYearMonthDayValuesForKPIRange()

      for (let i = 0; i < yearMonthDayValues.length; i++) {
        this.buildReportProcess3(yearMonthDayValues[i])
      }
    },

    buildReportProcess1() {
      const auth = useAuth()
      if (!auth.hasPermission('read_marketing_spend_report_all_owners')) {
        this.ownerFilter = auth.user.name
        if (auth.hasRole('staff')) {
        //  console.log(this.reportCore.dataStaffs)
          this.ownerFilter = this.reportCore.dataStaffs[auth.user.name]?.parentName
        }
      }
     

      this.reportCore.calculateGrowthDateRanges()

      this.unmappedKPIOwners = markRaw({})
      this.unmappedKPILeadGroups = markRaw({})

      this.ownersExcludedByStartDate = markRaw({})
      this.ownersExcludedByStartDateButValid = markRaw({})

      this.tableDataOwnersObj = markRaw({})
      this.tableDataLocationsObj = markRaw({})
      this.tableDataLeadGroupsObj = markRaw({})

      this.timeLineData = markRaw({})
      this.timeLineData.timelineYearCurrent = {}
      this.timeLineData.timelineYearPrevious = {}
      for (let i = 0; i < 12; i++) {
        this.timeLineData.timelineYearCurrent[i] = {}
        this.timeLineData.timelineYearPrevious[i] = {}
      }

      //-------------------------------------------------------------------

      this.metrics = markRaw({})
      this.metrics.rangeCurrent = {}
      this.metrics.rangeSub1 = {}
      this.metrics.rangeSub2 = {}

      this.dataKPI1Store.clearDebugInfo()

      this.buildReportProcess2() // buildReportProcess 3,4,5 ,6called from here

      this.buildReportProcess7()

      this.reportCore.setLoadHeavy(false)
    },

    //-------------------------------------------------------------------------------------------------

    buildScoreCards(metrics) {
      this.scorecardLabels = {
        subValue1: 'yoy',
        subValue2: this.reportCore.momRangeValid ? 'mom' : 'pop',
        subMetric1: 'YOY',
        subMetric2: this.reportCore.momRangeValid ? 'MOM' : 'POP'
      }

      this.scoreCards.spend_percentage = useBuildMetricDataScoreCards(
        'Spend Percentage',
        '%',
        metrics,
        ['SPEND', 'SALES_SUM']
      )
      this.scoreCards.spend_amount = useBuildMetricDataScoreCards(
        'Spend Amount',
        'USD',
        metrics,
        'SPEND'
      )
      this.scoreCards.sales_amount = useBuildMetricDataScoreCards(
        'Sales Amount',
        'USD',
        metrics,
        'SALES_SUM'
      )
      this.scoreCards.cost_per_lead = useBuildMetricDataScoreCards(
        'Cost per Lead',
        'USD',
        metrics,
        ['SPEND', 'LEADS_COUNT']
      )
      this.scoreCards.cost_per_appointment = useBuildMetricDataScoreCards(
        'Cost per Appointment',
        'USD',
        metrics,
        ['SPEND', 'APPOINTMENTS_COUNT']
      )
      this.scoreCards.cost_per_issued_appointment = useBuildMetricDataScoreCards(
        'Cost per Issued Appointment',
        'USD',
        metrics,
        ['SPEND', 'ISSUED_APPOINTMENTS_COUNT']
      )
      this.scoreCards.cost_per_quote = useBuildMetricDataScoreCards(
        'Cost per Quote',
        'USD',
        metrics,
        ['SPEND', 'QUOTES_COUNT']
      )
      this.scoreCards.cost_per_sale = useBuildMetricDataScoreCards(
        'Cost per Sale',
        'USD',
        metrics,
        ['SPEND', 'SALES_COUNT']
      )
      this.scoreCards.ROI = useBuildMetricDataScoreCards('ROI', '%ROI', metrics, [
        ['SALES_SUM', 'LEADS_COUNT'],
        ['SPEND', 'LEADS_COUNT']
      ])

      this.scoreCards.nsl = useBuildMetricDataScoreCards('NSL', 'USD', metrics, [
        'SALES_SUM',
        'LEADS_COUNT'
      ])
      this.scoreCards.nsa = useBuildMetricDataScoreCards('NSA', 'USD', metrics, [
        'SALES_SUM',
        'APPOINTMENTS_COUNT'
      ])
      this.scoreCards.nsia = useBuildMetricDataScoreCards('NSLI', 'USD', metrics, [
        'SALES_SUM',
        'ISSUED_APPOINTMENTS_COUNT'
      ])
      this.scoreCards.issued_appointment_by_leads = useBuildMetricDataScoreCards(
        'Issued Appointment by Leads',
        '%',
        metrics,
        ['ISSUED_APPOINTMENTS_COUNT', 'LEADS_COUNT']
      )
      this.scoreCards.sales_by_leads = useBuildMetricDataScoreCards(
        'Sales by Leads',
        'USD',
        metrics,
        ['SALES_SUM', 'LEADS_COUNT']
      )
    },

    //-------------------------------------------------------------------------------------------------

    buildMetricsTimeline(timelineMetricsTemp) {
      //only did calculated fields here , no string fields or uncalculated values ( although they might need to be processed for null values  from DB )

      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Spend Percentage',
        '%',
        ['SPEND', 'SALES_SUM']
      )
      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Spend Amount',
        'USD',
        'SPEND'
      )
      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Sales Amount',
        'USD',
        'SALES_SUM'
      )

      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Cost per Lead',
        'USD',
        ['SPEND', 'LEADS_COUNT']
      )

      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Cost per Appointment',
        'USD',
        ['SPEND', 'APPOINTMENTS_COUNT']
      )

      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Cost per Issued Appointment',
        'USD',
        ['SPEND', 'ISSUED_APPOINTMENTS_COUNT']
      )
      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Cost per Quote',
        'USD',
        ['SPEND', 'QUOTES_COUNT']
      )

      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Cost per Sale',
        'USD',

        ['SPEND', 'SALES_COUNT']
      )

      useBuildMetricDataTimeline2(this.timelineMetrics, timelineMetricsTemp, 'ROI', '%ROI', [
        ['SALES_SUM', 'LEADS_COUNT'],
        ['SPEND', 'LEADS_COUNT']
      ])

      useBuildMetricDataTimeline2(this.timelineMetrics, timelineMetricsTemp, 'NSL', 'USD', [
        'SALES_SUM',
        'LEADS_COUNT'
      ])
      useBuildMetricDataTimeline2(this.timelineMetrics, timelineMetricsTemp, 'NSA', 'USD', [
        'SALES_SUM',
        'APPOINTMENTS_COUNT'
      ])
      useBuildMetricDataTimeline2(this.timelineMetrics, timelineMetricsTemp, 'NSLI', 'USD', [
        'SALES_SUM',
        'ISSUED_APPOINTMENTS_COUNT'
      ])
      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Issued Appointment by Leads',
        '%',
        ['ISSUED_APPOINTMENTS_COUNT', 'LEADS_COUNT']
      )

      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Sales by Leads',
        'USD',
        ['SALES_SUM', 'LEADS_COUNT']
      )
    },

    //-------------------------------------------------------------------------------------------------

    buildMetricsTables(metrics, targetData) {
      /*
      'Spend Percentage',
      'Spend Amount',
      'Cost per Lead',
      'Cost per Appointment',
      'Cost per Issued Appointment',
      'Cost per Quote',
      'Cost per Sale',
      'ROI',
      'NSL',
      'NSA',
      'NSLI'*/

      let ob = {}

      useBuildMetricDataTables2(ob, 'Owner', 'STRING', metrics, 'OWNER')
      useBuildMetricDataTables2(ob, 'Lead Group', 'STRING', metrics, 'LEAD_GROUP')

      useBuildMetricDataTables2(ob, 'Spend Percentage', '%', metrics, ['SPEND', 'SALES_SUM'])
      useBuildMetricDataTables2(ob, 'Spend Amount', 'USD', metrics, 'SPEND')
      useBuildMetricDataTables2(ob, 'Sales Amount', 'USD', metrics, 'SALES_SUM')
      useBuildMetricDataTables2(ob, 'Cost per Lead', 'USD', metrics, ['SPEND', 'LEADS_COUNT'])
      useBuildMetricDataTables2(ob, 'Cost per Appointment', 'USD', metrics, [
        'SPEND',
        'APPOINTMENTS_COUNT'
      ])
      useBuildMetricDataTables2(ob, 'Cost per Issued Appointment', 'USD', metrics, [
        'SPEND',
        'ISSUED_APPOINTMENTS_COUNT'
      ])
      useBuildMetricDataTables2(ob, 'Cost per Quote', 'USD', metrics, ['SPEND', 'QUOTES_COUNT'])
      useBuildMetricDataTables2(ob, 'Cost per Sale', 'USD', metrics, ['SPEND', 'SALES_COUNT'])
      useBuildMetricDataTables2(ob, 'ROI', '%ROI', metrics, [
        ['SALES_SUM', 'LEADS_COUNT'],
        ['SPEND', 'LEADS_COUNT']
      ])

      useBuildMetricDataTables2(ob, 'NSL', 'USD', metrics, ['SALES_SUM', 'LEADS_COUNT'])
      useBuildMetricDataTables2(ob, 'NSA', 'USD', metrics, ['SALES_SUM', 'APPOINTMENTS_COUNT'])
      useBuildMetricDataTables2(ob, 'NSLI', 'USD', metrics, [
        'SALES_SUM',
        'ISSUED_APPOINTMENTS_COUNT'
      ])

      useBuildMetricDataTables2(ob, 'Issued Appointment by Leads', '%', metrics, [
        'ISSUED_APPOINTMENTS_COUNT',
        'LEADS_COUNT'
      ])

      useBuildMetricDataTables2(ob, 'Sales by Leads', 'USD', metrics, ['SALES_SUM', 'LEADS_COUNT'])

      targetData.push(ob)
    },

    //-------------------------------------------------------------------------------------------------

    doubleRaf(callback, ...args) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          try {
            callback(...args)
          } catch (error) {
            useErrorHandler().processScriptError(error)
          }
        })
      })
    },

    buildReport() {
      this.reportCore.setLoadHeavy(true, 'Marketing Report',"process")

      this.doubleRaf(() => this.buildReportProcess1())
    },

    //-------------------------------------------------------------------------------------------------




    async loadSpendCacheInfo() {
      this.reportCore.setLoadHeavy(true,"Cache Info")
      var formData = { year: this.reportCore.currentDate.year }
      let result
      try {
        result = await this.requests.post({
          url: this.urls.getURL('marketing_spend_report', 'refreshCacheInfo'),
          data: formData
        })
      } catch (error) {
        this.reportCore.setLoadHeavy(false)
        console.log(error)
        return
      }
      if (result) {
        this.queryCacheDateBudgetSpend = SimpleDate.getDateTimeStringFromJSDateObject(
          new Date(result.data.queryCacheDate)
        )
        this.reportCore.setLoadHeavy(false)
      }
    },

    //----------------------------------------------------------------------

    async refreshQueryCacheBudgetSpend() {
      this.reportCore.setLoadHeavy(true, 'Marketing data', 'building cache')
      var formData = {}

      let data = {}
      data.years = []
      let years = {}


      let keys = Object.keys(this.dataKPI1Store.processedDataCORELoaded);
      keys.forEach(element => years[element] = true)

      keys = Object.keys(this.dataKPI1Store.processedDataSMLoaded);
      keys.forEach(element => years[element] = true)

      keys = Object.keys(years);
      keys.forEach(element => data.years.push(parseInt(element)))


      let result
      try {
        result = await this.requests.post({
          url: this.urls.getURL('marketing_spend_report', 'refreshCache'),
          data: data
        })
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return
      }
      if (result) {
        this.reportCore.setLoadHeavy(false)
        await this.loadEditSpendData()
        await this.loadSpendCacheInfo()
        this.loadAllData()


        this.toasts.setSuccessMessage(result.data.message)
      }
    },

    //-----------------------------------------------------------------------

    async loadEditSpendData() {
      //only if the edit spend display is valid , load values
      if (!this.validateEditSpendOption || !this.editSpendExpanded) {
        return
      }

      this.reportCore.setLoadHeavy(true, 'Marketing Data')

      let url = this.urls.getURL('marketing_spend_report', 'spendDataForMonthForOwner')
      let data = { year: this.reportCore.currentDate.year, month: this.reportCore.dateRangeStart.month, owner: this.ownerFilter }

      let result
      try {
        result = await this.requests.post({
          url: url,
          data
        })
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return
      }
      if (result) {
        this.reportCore.setLoadHeavy(false)
        this.processEditSpendData(result.data)

      }

    },

    //--------------------------------------------------------------------

    processEditSpendData(result) {


      let resultArr = []
      for (let i = 0; i < result.length; i++) {
        let spendItem = {}
        const element = result[i]
    
        spendItem.name = element.LeadGroup.name
        spendItem.id = element.id
        spendItem.value = element.value;
        spendItem.api_controlled = element.api_controlled
        spendItem.monthName =this.reportCore.getMonthNameFromIndex(element.month)
        spendItem.override = element.override
        resultArr.push(spendItem)
    
      }
    
      this.leadGroupSpendData = resultArr
    
    },

    //--------------------------------------------------------------------------

    cacheInputValueSpendEdit(spendItem) {

      spendItem.cachedValue = spendItem.value
    
    },
    
    validateInputValueSpendEdit(spendItem) {
    
      if (spendItem.cachedValue != spendItem.value) {
        //update cache value as user may have pressed enter key and i dont want to update again on blur after that
        spendItem.cachedValue = spendItem.value
        this.updateSingleValue(spendItem)
      }
    },

    //----------------------------------------------------------------------------

    async updateSingleValue(spendItem) {
      //console.log('updateSingleValue')
      // console.log(month)
    
    
      let url =  this.urls.getURL('marketing_spend_report', 'updateSingleRow')
      let data = {};
      data.rowData = {};
      data.rowData.value = spendItem.value
    
      if (
          isNaN(data.rowData.value) ||
          !data.rowData.value ||
          !isFinite(data.rowData.value)
        ) {
          spendItem.value = spendItem.cachedValue =  data.rowData.value = 0;
        }
    
    
      spendItem.api_controlled = spendItem.api_controlled && !spendItem.override
      data.rowData.api_controlled = spendItem.api_controlled
      data.rowData.override = spendItem.override
    
      data.rowID = spendItem.id
    
      var formData = data || {}
      let result
      try {
        result = await  this.requests.post({
          url: url,
          data: formData
        })
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return
      }
      if (result) {
    
    
        this.toasts.setSuccessMessage(result.data.message, 1000)
      }
    },

    //---------------------------------------------------------------------------------


    async onLeadGroupOverrideChanged(spendItem) {
      spendItem.override = !spendItem.override
      let message = "";
      if (spendItem.override) {
        message = 'are you sure you want to override any api driven data for this lead group month spend?'
      } else {
        message = 'are you sure you want to remove your override of api driven data for this lead group month spend?'
      }
      let result = await this.reportCore.modalConfirmCancelStart(message)
      if (result) {
        this.updateSingleValue(spendItem)
    
      } else {
        spendItem.override = !spendItem.override
      }
    
    },

    //-----------------------------------------------------------------------

    editSpendExpandHandler() {
      this.editSpendExpanded = !this.editSpendExpanded;
      this.loadEditSpendData()
    
    }
    
  }
})
