import { useUIComponentMap } from '@/scripts/ui/UIComponentMap'
import { useApplicationStore } from '@/stores/ApplicationStore'
export function useUIBuilder() {
  const applicationStore = useApplicationStore()
  const UIComponentMap = useUIComponentMap()

  
  const data = {}
  data.radioGroups = {}
  data.uiSideNav = []
  data.componentData = {}

  function build(uiData, targetArray) {
    for (let i = 0; i < uiData.length; i++) {
      let item = uiData[i]

      let uiItem = UIComponentMap[item.component_type]()

      uiItem.attributes.componentData.key = item.key
      if (item.radio_group) {
        let radioGroup = data.radioGroups[item.radio_group]
        if (!radioGroup) {
          radioGroup = data.radioGroups[item.radio_group] = UIComponentMap.ButtonRadioGroup()
         
        }
        if(item.enabled){
          radioGroup.currentSelection = uiItem.attributes.componentData;
        }
        uiItem.attributes.componentData.interaction.radioGroup = radioGroup
      }

      if (item.display) {
        uiItem.attributes.componentData.display = {
          ...uiItem.attributes.componentData.display,
          ...item.display
        }
      }
      if (item.interaction) {
        uiItem.attributes.componentData.interaction = {
          ...uiItem.attributes.componentData.interaction,
          ...item.interaction
        }
      }
    
      //if (item.enabled !== null && item.enabled !== undefined) {
        uiItem.attributes.componentData.enabled = item.enabled
       
     // }

      targetArray.push(uiItem)
      data.componentData[item.key] = uiItem.attributes.componentData
      if (item.children) {
        if (Array.isArray(item.children)) {
          build(item.children, uiItem.attributes.componentData.children)
        } else {
          //try get array from loaded shared data , item.children would be the key
          let injectChildren = applicationStore.dataObjects.ui.shared[item.children]
          if (injectChildren) {
            build(injectChildren, uiItem.attributes.componentData.children)
          }
        }
      }
    }
  }

  build(applicationStore.dataObjects.ui, data.uiSideNav)
  applicationStore.uiObjects = data
 
 
  applicationStore.updateUIStates()
}
