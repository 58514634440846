import { useComponentData } from "@/scripts/ui/ComponentData";

export function useFlexContainer1() {
  let data = {};
  data.component = "FlexContainerComponent";
  let attr = (data.attributes = {});
  attr.componentData = useComponentData();
   //set this to true because these are not interactive but are serialized
   attr.componentData.enabled = true;
  attr.componentData.styling = { classStringContainerDefault: "mb-4" };
  attr.componentData.children = [];

  return data;
}
