
export function useComponentStylingType2() {
   
    const data = {
        classStringContainerDefault:"container-type-1 py-2 my-1",
        classStringContainerSelected:"container-type-2 py-2 my-1",
        classStringTextDefault:"text-type-2 text-color-6",
        classStringTextSelected:"text-type-2 text-color-5",
        classStringIconDefault:"mdi icon icon-color-6 mdi-checkbox-blank-outline",
        classStringIconSelected:"mdi icon text-color-7 mdi-checkbox-marked-outline",
     
          };

 
  return data;
}