<template>
  <div ref="domElement" class="w-100 mx-auto" :class="applicationStore.getPadding">
    <div class="content-bg-11" :class="{
    'pa-4': !applicationStore.grid.tabletAndUnder,
    'elevation-2': !applicationStore.grid.tabletAndUnder,
    'rounded-2': !applicationStore.grid.tabletAndUnder
  }">
      <div class="pa-4 pt-1 fs-3 text-color-6 text-align-left fw-bold">Budget Sales</div>
      <ContentExpansionHeader v-if="auth.hasPermission('edit_budget_sale_management_data')"
        @click="expandSectionCreate = !expandSectionCreate" label="Create Budget Year"
        :expanded="expandSectionCreate" />

      <div class="pa-4 mb-8 content-bg-12"
        v-if="expandSectionCreate && auth.hasPermission('edit_budget_sale_management_data')">
        <div class="elevation-4 pa-4 content-bg-11 rounded-2 w-100">
          <DataEditGenericComponent @submitemit="onSubmitCreate" :useSubmit="true"
            :payload="createBudgetYearFieldSet" />
        </div>
      </div>

      <ContentExpansionHeader @click="expandSectionListing = !expandSectionListing" label="Budget Edit"
        :expanded="expandSectionListing" />
      <div class="budgets pa-4 mb-8 content-bg-12" v-if="expandSectionListing">
        <div class="d-flex justify-content-left mb-3">
          <SelectButton @update:modelValue="onYearChange" v-model="yearSelectionCurrent"
            :options="optionsBudgetYearsComputed" optionLabel="year" :allowEmpty="false" />
        </div>

        <TabView>
          <TabPanel header="Yearly Sales">
            <div>
              <div class="pa-4 content-bg-5-inverted" style="position: sticky; top: 0; z-index: 20">
                <div class="d-flex flex-wrap align-items-center justify-content-end gap-2">
                  <div v-if="manage_budget_lock" class="flex-ui-1" style="align-items: center">
                    <Checkbox @change="updateYearLock" v-model="yearSelectionCurrent.locked" inputId="locked"
                      :binary="true" />
                    <label for="locked" class="fs-8 text-color-2-inverted text-type-10 ml-2">
                      lock year
                    </label>
                  </div>

                  <div class="flex-ui-1" style="align-items: center">
                    <Checkbox @update:modelValue="toggleShowMonthAllAllocations" v-model="showMonthAllAllocations"
                      inputId="showallmonths" :binary="true" />
                    <label for="showallmonths" class="fs-8 text-color-2-inverted text-type-10 ml-2">
                      show all months
                    </label>
                  </div>

                </div>
              </div>

              <div class="text-align-left py-2 content-border-b-1 fs-6 mb-2 fw-bold text-color-1 px-2" :class="{
    'ui-bg-4': !record.toggleEdit,
    'content-bg-12': record.toggleEdit,
    'mb-1': !record.toggleEdit,
    'my-4': record.toggleEdit
  }" v-for="record in budgetAllocationData.value" :key="'budgetallocation_' + record.owner_id">
                <div class="d-flex flex-wrap align-items-center gap-3">
                  <div class="text-color-2 me-auto" style="min-width: 200px">{{ getNameAndDate(record)}}</div>

                  <b-form-input style="max-width: 150px" :id="'budgetallocation_' + record.owner_id"
                    v-model="record.value" @focus="cacheInputValue(record)" @blur="validateInputValue(record)" @keydown.enter="validateInputValue(record)" trim
                    :disabled="!manage_budget_info || !record.fixed_months"></b-form-input>

                  <div class="flex-ui-1" style="align-items: center">
                    <Checkbox v-model="record.fixed_months" @change="updateSingleValue(record)"
                      inputId="budgetfixedmonth" :binary="true" :disabled="!manage_budget_info" />
                    <label for="budgetfixedmonth" class="fs-8 text-color-1 text-type-10 ml-2">
                      fixed months
                    </label>
                  </div>

                  <button class="button-minimal pa-0" :disabled="requests.loading"
                    @click="record.toggleEdit = !record.toggleEdit" @keypress.enter="navigate">
                    <div class="fs-10 fw-bolder nav-color-1">show months</div>
                  </button>
                </div>

                <div v-if="record.toggleEdit" class="mt-2 mb-4 w-100 d-grid"
                  style="grid-template-columns: repeat(auto-fit, minmax(90px, 1fr)); gap: 0.2rem">
                  <b-form-group v-for="month in record.months" :key="'budgetallocation_' + month.name"
                    :label="month.name" :label-for="'budgetallocation_' + record.owner_id + month.name"
                    style="margin-bottom: 0 !important" class="mb-0 fs-9 text-align-left text-color-1">
                    <b-form-input :id="'budgetallocation_' + record.owner_id + month.name" v-model="month.value"
                      @focus="cacheInputValue(month)" @blur="validateInputValue(month, record)" @keydown.enter="validateInputValue(month, record)" trim
                      :disabled="record.fixed_months || yearSelectionCurrent.locked"></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
          </TabPanel>

        </TabView>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, nextTick, toRaw, computed, watch, reactive } from 'vue'
import DataEditGenericComponent from '@/components/ui/DataEditGenericComponent.vue'
import { useRequests } from '@/stores/Requests.js'
import { useURLS } from '@/stores/URLS.js'
import { useApplicationStore } from '@/stores/ApplicationStore.js'

import { useRoute } from 'vue-router'
import { useToasts } from '@/stores/Toasts.js'
import ContentExpansionHeader from '@/components/ui/ContentExpansionHeader.vue'
import { useReportCore } from '@/stores/ReportCore.js'
import { useAuth } from '@/stores/Auth.js'
import SimpleDate from '../scripts/utils/SimpleDate'
const applicationStore = useApplicationStore()
const urls = useURLS()
const route = useRoute()
const auth = useAuth()
const results = ref()
const toasts = useToasts()
const reportCore = useReportCore()
const expandSectionCreate = ref(false)
const expandSectionListing = ref(true)
const requests = useRequests()
const yearSelectionCurrent = ref({ locked: false })
const budgetAllocationData = reactive({ value: [] })
const leadGroupData = reactive({ value: [] })
const leadGroupSpendData = reactive({ value: [] })

const showMonthAllSpends = ref(false)
const showMonthAllAllocations = ref(false)
const getMonthSpendModel = computed((element) => {
  return element[monthSelectOptionIndex.value].value
})

function getNameAndDate(record){
  return `${record.name} ${record.started_at.toString()}`
}

const monthSelectOption = ref('jan')
const monthSelectOptionIndex = ref(0)
var monthSelectOptions = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
function onUpdateMonthSelection(selection) {
  //console.log(selection)
}

const toggleBudgetSpendQueryCacheExtendedDisplay = ref(false)
const queryCacheDateBudgetSpend = ref('')
var currentSelected = null
var yearCurrent = null
var leadGroupCurrent = null
const optionsBudgetYears = ref()
var pendingNewBudgetYear = null

//---------------------------------------------------------------------------------------
const optionsBudgetYearsComputed = computed(() => {
  return optionsBudgetYears.value
})

//---------------------------------------------------------------------------------------



//---------------------------------------------------------------------------------------

const manage_budget_info = computed(() => {
  return auth.hasPermission('edit_budget_sale_management_data') && !yearSelectionCurrent.value?.locked
})

//---------------------------------------------------------------------------------------

const manage_budget_lock = computed(() => {
  return auth.hasPermission('edit_budget_sale_management_lock')
})


//---------------------------------------------------------------------------------------

function toggleShowMonthAllAllocations(toggleValue) {
  budgetAllocationData.value.forEach((element) => (element.toggleEdit = toggleValue))
}

//---------------------------------------------------------------------------------------

function cacheInputValue(target) {

  target.cachedValue = target.value
  // console.log(target)
}

function validateInputValue(target, parent) {

  if (target.cachedValue != target.value) {
    //update cache value as user may have pressed enter key and i dont want to update again on blur after thatr
    target.cachedValue = target.value
    //if a month value is changed , the parent is the record..
    // if the record itself is changed , the target is record
    if (parent) {
      updateSingleValue(parent)
    } else {
      updateSingleValue(target)
    }

  }
}

async function updateSingleValue(record) {

  //since i dont reload the front end based on these updates , i need to manually ust make some changes to the local record if needed,
  //based upon the value of fixed_months
  buildMonthValues(record)

  //update the record value based on the sum of month values if fixed_month is false
  if (!record.fixed_months) {
    let total = 0;
    for (let j = 0; j < 12; j++) {
      total += parseInt(record.months[j].value)
    }
    record.value = total
  }


  let url = urls.getURL('budget_sale_management', 'updateSingleRow')
  let result
  try {
    result = await requests.post({
      url: url,
      data: record
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {



    toasts.setSuccessMessage(result.data.message, 1000)
  }




}


//---------------------------------------------------------------------------------------

async function updateYearLock() {
  reportCore.setLoadHeavy(true,"Budget Year Lock","update")
  let url = urls.getURL('budget_sale_management', 'lock_years')

  let data = yearSelectionCurrent.value

  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    toasts.setSuccessMessage(result.data.message)
  }
}

//---------------------------------------------------------------------------------------



//---------------------------------------------------------------------------------------

function onYearChange(event) {
  yearCurrent = event.year
  loadBudgetAllocation(urls.getURL('budget_sale_management', 'find'), { year: event.year })

  //the year changed and spend data is on demand , so have to clear current spend data ,
  //and close/unexpand all leadgroups , so user need to expand again to access new data
  leadGroupSpendData.value = []
  leadGroupData.value.forEach((element) => (element.expanded = false))
}

//---------------------------------------------------------------------------------------



//---------------------------------------------------------------------------------------

async function loadBudgetAllocation(url, data) {
  reportCore.setLoadHeavy(true,"Budget Allocations")
  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    processBudgetAllocation(result.data)
    reportCore.setLoadHeavy(false)
  }
}

//---------------------------------------------------------------------------------------

function processBudgetAllocation(data) {
  let result = {}
  let resultArr = []
  let totalNew = 0;
  let totalLegacy = 0;
  for (let i = 0; i < data.length; i++) {
    const element = data[i]
    let ownerData = result[element.Owner.User.name]
    if (!ownerData) {
      ownerData = result[element.Owner.User.name] = {}
      ownerData.id = element.id
      ownerData.months = []
      ownerData.monthsOriginal = []
      ownerData.year = element.year
      ownerData.name = element.Owner.User.name
      ownerData.value = element.value
      ownerData.toggleEdit = showMonthAllAllocations.value     
      ownerData.started_at = new SimpleDate()
      ownerData.started_at.getValuesFromDateString( element.Owner.started_at)
     
      ownerData.fixed_months = element.fixed_months
      resultArr.push(ownerData)
    }


    for (let j = 0; j < 12; j++) {
      let val = element[getMonthName(j).toLowerCase()]
      ownerData.monthsOriginal[j] = { name: getMonthName(j), value: val }
    }
    buildMonthValues(ownerData)
    if (ownerData.year === reportCore.currentDate.year) {
     
      
      if (ownerData.started_at.year === reportCore.currentDate.year) {
        totalNew += ownerData.value
      } else {
        totalLegacy += ownerData.value
      }

    }

   

  }



resultArr.sort((a, b) => a.started_at.valueOf() - b.started_at.valueOf());
  budgetAllocationData.value = resultArr
}

//---------------------------------------------------------------------------------------

function buildMonthValues(record) {

 
  let year = record.started_at.year
  let month = record.started_at.month
  let valueCalculated = 0;

  for (let j = 0; j < 12; j++) {
    let val = null;



    if (record.fixed_months) {
      val = getYearMonthPercent(j, record.value)
      if (year === record.year) {
        if (j < month) {
          val = 0
        }
      }
    } else {

      if (record.months[j] !== null && record.months[j] !== undefined) {
        val = record.months[j].value
        record.monthsOriginal[j].value = val;
      } else {
        val = record.monthsOriginal[j].value
      }
      valueCalculated += val


    }
    record.months[j] = { name: getMonthName(j), value: val }
  }
  if (!record.fixed_months) {
    record.value = valueCalculated
  }




}

//---------------------------------------------------------------------------------------

function getYearMonthPercent(monthIndex, value) {
  let month = getMonthName(monthIndex).toLowerCase()
  let percent = yearSelectionCurrent.value[month] / 100
  return (value * percent) << 0
}

//---------------------------------------------------------------------------------------

function getMonthName(monthIndex) {
  if (monthIndex === 0) return 'Jan'
  if (monthIndex === 1) return 'Feb'
  if (monthIndex === 2) return 'Mar'
  if (monthIndex === 3) return 'Apr'
  if (monthIndex === 4) return 'May'
  if (monthIndex === 5) return 'Jun'
  if (monthIndex === 6) return 'Jul'
  if (monthIndex === 7) return 'Aug'
  if (monthIndex === 8) return 'Sep'
  if (monthIndex === 9) return 'Oct'
  if (monthIndex === 10) return 'Nov'
  if (monthIndex === 11) return 'Dec'
}







//---------------------------------------------------------------------------------------

function createFieldSetYears(fieldSet, obj, index) {
  fieldSet.fields = []

  let field = {}
  field.vif = true
  field.attributes = {}
  field.label = 'year'
  field.modelKey = 'year'
  field.modelTable = 'Budget'
  field.modelValue = null
  field.modelID = null
  field.component = 'b-form-input'
  field.index = `budget_${index}_${fieldSet.fields.length}`
  fieldSet.fields.push(field)
}

//---------------------------------------------------------------------------------------

function onSubmitCreate(payload) {
  let fieldsWrite = [...payload.fields]
  pendingNewBudgetYear = parseInt(fieldsWrite[0].modelValue)
  addBudgetYears(urls.getURL('budget_sale_management', 'add_years'), { fields: fieldsWrite })
}

//---------------------------------------------------------------------------------------


async function addBudgetYears(url, data) {
  reportCore.setLoadHeavy(true ,"Budget Year","create")
  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    if (loadDataValidate()) {
      await loadBudgetYears(urls.getURL('budget_sale_management', 'find_years'))
      await tryLoadDefaultYear()
    }

    toasts.setSuccessMessage(result.data.message)
  }
}

//---------------------------------------------------------------------------------------

const createBudgetYearFieldSet = ref()

function setupCreateBudgetYears() {
  let fieldSet = {}
  fieldSet.key = 'budgetyears'
  createFieldSetYears(fieldSet, null, 'create', null)
  createBudgetYearFieldSet.value = fieldSet
}

//---------------------------------------------------------------------------------------

async function loadBudgetYears(url, data) {
  reportCore.setLoadHeavy(true,"Budget Years")
  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    // console.log(result)
    processBudgetYears(result.data)
    reportCore.setLoadHeavy(false)
  }
}

//---------------------------------------------------------------------------------------

function processBudgetYears(data) {
  optionsBudgetYears.value = data
}

//---------------------------------------------------------------------------------------

async function tryLoadDefaultYear() {
  // console.log('tryLoadDefaultYear')
  if (optionsBudgetYears.value) {
    let yeartouse = pendingNewBudgetYear || optionsBudgetYears.value[0]?.year
    pendingNewBudgetYear = null
    let yearObject = optionsBudgetYears.value.find((element) => element.year === yeartouse)
    if (yearObject) {
      onYearChange({ year: yearObject.year })
      yearSelectionCurrent.value = yearObject
      // console.log(yearSelectionCurrent.value)
    }
  }
}

//---------------------------------------------------------------------------------------





onMounted(() => {
  init()
})

//---------------------------------------------------------------------------------------

onUnmounted(() => {
  pendingNewBudgetYear = null
})

//---------------------------------------------------------------------------------------

async function init() {
  
  setupCreateBudgetYears()
  if (loadDataValidate()) {
    await loadBudgetYears(urls.getURL('budget_sale_management', 'find_years'))
  }
  await tryLoadDefaultYear()
 
}

//---------------------------------------------------------------------------------------

function loadDataValidate() {
  if (route.path !== '/application/budget_sale_management') {
    return false
  }

  return true
}
</script>

<style>
.budgets .form-label {
  margin-bottom: 0.1rem !important;
  font-size: 0.75rem !important;
}

.budgets .form-control {
  border: 0;
  border-radius: 0 !important;
  font-weight: 600;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: var(--content-bg-11);
  border-color: var(--icon-color-7);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: var(--content-bg-11);
}

.p-tabview .p-tabview-nav {
  background: transparent !important;
}

.p-tabview {
  background: transparent !important;
}

.p-tabview .p-tabview-panels {
  background: var(--content-bg-11);
  padding: 0.4rem !important;
}

.p-tabview-title {
  color: var(--text-color-6) !important;
  font-weight: 700 !important;
  font-size: 0.96rem;
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}
</style>
