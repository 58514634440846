import { defineStore } from 'pinia'
import { useURLS } from '@/stores/URLS.js'
import { useRequests } from '@/stores/Requests.js'
import { useReportCore } from '@/stores/ReportCore.js'
import { useAuth } from '@/stores/Auth.js'
import { useToasts } from '@/stores/Toasts.js'
import { markRaw ,reactive} from 'vue'
export const useDataPresetsReportFiltersStore = defineStore('DataPresetsReportFiltersStore', {
    state: () => {
        return {
            requests: markRaw(useRequests()),
            urls: markRaw(useURLS()),
            reportCore: markRaw(useReportCore()),
            auth: markRaw(useAuth()),
            toasts: markRaw(useToasts()),
            newPresetGroupName: null,
            newPresetName: null,
            presetData: {},
            selectedPreset: null,
            presets: [],
            presetGroups: [],
            useDimensionFilters: false,
            renderKey:0,

        }
    },

    getters: {
        getPresetsWithoutGroup: (state) => {
            return state.presets.filter(element => element.filter_group_id === null)
        },
        getPresetsWithGroup: (state) => {
            return (groupID) => {
                return state.presets.filter(element => element.filter_group_id === groupID)
            }
        },

        getPresets: (state) => {
            return state.presets
        },
        getPresetData: (state) => {
            return state.presetData
        },

        getDataOwnersLocal: (state) => {
            return state.presetData.dataOwnersLocal
        },
        getDataLeadGroupsLocal: (state) => {
            return state.presetData.dataLeadGroupsLocal
        },

        getPresetsGroups: (state) => {
            return state.presetGroups
        }
    },

    actions: {

        resetPresetToDefaultEmpty() {

            this.presetData = {}
            this.presetData.dataOwnersLocal = []
            this.presetData.dataLeadGroupsLocal = []
            this.presetData.dataOwnersNoAliases = {}
            this.presetData.dataLeadGroupsNoAliases = {}
            this.presetData.useDimensionFilterOwner = true
            this.presetData.useDimensionFilterLeadGroup = false
            this.presetData.isInclusive = true
            this.presetData.isExclusive = false


            this.selectedPreset = null;
            this.useDimensionFilters = false;


            for (let key in this.reportCore.dataOwnersNoAliases) {
                let ob = this.reportCore.dataOwnersNoAliases[key]
                let item = this.presetData.dataOwnersNoAliases[key] = {};
                item.name = ob.name;
                item.selected = false;

            }

            for (let key in this.reportCore.dataLeadGroupsNoAliases) {
                let ob = this.reportCore.dataLeadGroupsNoAliases[key]
                let item = this.presetData.dataLeadGroupsNoAliases[key] = {};
                item.name = ob.name;
                item.selected = false;

            }



            this.generateAlphabetListingsData(
                Object.values(this.presetData.dataOwnersNoAliases),
                'dataOwnersLocal'
            )
            this.generateAlphabetListingsData(
                Object.values(this.presetData.dataLeadGroupsNoAliases),
                'dataLeadGroupsLocal'
            )

            this.renderKey++;



        },

        generateAlphabetListingsData(sourceData, targetData) {
            let alphaSortedObjects = []
            let alphabetArray = [
                'a',
                'b',
                'c',
                'd',
                'e',
                'f',
                'g',
                'h',
                'i',
                'j',
                'k',
                'l',
                'm',
                'n',
                'o',
                'p',
                'q',
                'r',
                's',
                't',
                'u',
                'v',
                'w',
                'x',
                'y',
                'z'
            ]
            alphabetArray.forEach((letter) => {
                let filteredObjects = Object.values(sourceData).filter((obj) =>
                    obj.name.toLowerCase().startsWith(letter)
                )

                if (filteredObjects.length) {
                    let ob = reactive({ name: letter.toUpperCase(), data: filteredObjects })
                    alphaSortedObjects.push(ob)
                }
            })

            this.presetData[targetData] = alphaSortedObjects
        },

        setPreset(preset) {
           

            Object.values(this.presetData.dataOwnersNoAliases).forEach(element => {
                element.selected = false;
            });

            Object.values(this.presetData.dataLeadGroupsNoAliases).forEach(element => {
                element.selected = false;
            });

            let selections = preset.owner_names.split(",")
            if (selections.length) {
                selections.forEach(element => {
                    if (element !== '') {
                        this.presetData.dataOwnersNoAliases[element].selected = true
                    }
                })
            }

            selections = preset.leadgroup_names.split(",")
            if (selections.length) {
                selections.forEach(element => {
                    if (element !== '') {
                        this.presetData.dataLeadGroupsNoAliases[element].selected = true
                    }
                })
            }

            this.presetData.isInclusive = preset.is_inclusive
            this.presetData.isExclusive = preset.is_exclusive
            this.presetData.useDimensionFilterOwner = preset.use_dimension_filter_owner
            this.presetData.useDimensionFilterLeadGroup = preset.use_dimension_filter_leadgroup

          

        },


        async addGroup() {
          

            if (this.newPresetGroupName === null || this.newPresetGroupName === undefined || this.newPresetGroupName === "") {
                this.toasts.setWarningMessage("Name must be suppied")
                return
            }

            this.reportCore.setLoadHeavy(true, 'Add Preset Data')
            try {
                let newPresetGroup = {};
                newPresetGroup.name = this.newPresetGroupName
                newPresetGroup.is_global = true;
                newPresetGroup.user_id = this.auth.user.user_id;

                let result = await this.requests.post({
                    url: this.urls.getURL('user_presets_report_filters', 'add_group'),
                    data: newPresetGroup
                })

                if (result) {
                    this.reportCore.setLoadHeavy(false)
                    if (result.data.wasCreated) {
                        newPresetGroup.id = result.data.id
                        this.presetGroups.push(newPresetGroup)

                    }
                    this.toasts.setSuccessMessage(result.data.message)
                }
            } catch (error) {
                console.log(error)
                this.reportCore.setLoadHeavy(false)
            }

        },

        //--------------------------------------------------------------------
        process(result) {
          

            this.presets = result.filters
            this.presetGroups = result.filterGroups;

            this.presets.forEach(element => {
                element.selectedGroup = null;
                if (element.filter_group_id) {
                    element.selectedGroup = this.presetGroups.find(group => group.id === element.filter_group_id)

                }
            })


        },

        updatePresetFiltersGroup(event, preset) {
           

            if (event) {

                preset.filter_group_id = event.id
            } else {
                preset.filter_group_id = null;
            }
            this.update(preset);

        },

        //--------------------------------------------------------------------

        async addPreset() {
          


            if (this.newPresetName === null || this.newPresetName === undefined || this.newPresetName === "") {
                this.toasts.setWarningMessage("Name must be suppied")
                return
            }
           
            let newPreset = {}
            newPreset.name = this.newPresetName
            newPreset.owner_names = Object.values(this.presetData.dataOwnersNoAliases).filter(element => element.selected === true).map(item => item.name).toString()
            newPreset.leadgroup_names = Object.values(this.presetData.dataLeadGroupsNoAliases).filter(element => element.selected === true).map(item => item.name).toString()
            newPreset.is_inclusive = this.presetData.isInclusive
            newPreset.is_exclusive = this.presetData.isExclusive
            newPreset.use_dimension_filter_owner = this.presetData.useDimensionFilterOwner
            newPreset.use_dimension_filter_leadgroup = this.presetData.useDimensionFilterLeadGroup
            //newPreset.report_key = null; currently all report filter presets are for all reports
            newPreset.filter_group_id = null; //group is assigned after preset is created     
            newPreset.user_id = this.auth.user.user_id
            newPreset.is_global = true;//currently report filter presets are all global
            newPreset.selectedGroup = null;
            this.presets.push(newPreset)

           


            this.reportCore.setLoadHeavy(true, 'Add Preset Data')
            try {
                let result = await this.requests.post({
                    url: this.urls.getURL('user_presets_report_filters', 'add'),
                    data: newPreset
                })

                if (result) {
                    this.reportCore.setLoadHeavy(false)
                    newPreset.id = result.data.id
                    this.toasts.setSuccessMessage(result.data.message)
                }
            } catch (error) {
                console.log(error)
                this.reportCore.setLoadHeavy(false)
            }
        },
        //--------------------------------------------------------------------

        async updatePresetGroup(presetGroup) {

            this.reportCore.setLoadHeavy(true, 'Update Preset Data')
            try {
                let result = await this.requests.post({
                    url: this.urls.getURL('user_presets_report_filters', 'update_group'),
                    data: presetGroup
                })

                if (result) {


                    this.reportCore.setLoadHeavy(false)
                    this.toasts.setSuccessMessage(result.data.message)
                }
            } catch (error) {
                console.log(error)
                this.reportCore.setLoadHeavy(false)
            }


        },

        cacheInputGroupNameValue(presetGroup) {

            presetGroup.cachedValue = presetGroup.name

        },

        validateInputGroupNameValue(presetGroup) {

            if (presetGroup.cachedValue !== presetGroup.name) {
                //update cache value as user may have pressed enter key and i dont want to update again on blur after thatr
                presetGroup.cachedValue = presetGroup.name

                this.updatePresetGroup(presetGroup)


            }
        },

        cacheInputPresetNameValue(preset) {

            preset.cachedValue = preset.name

        },

        validateInputPresetNameValue(preset) {

            if (preset.cachedValue !== preset.name) {
                //update cache value as user may have pressed enter key and i dont want to update again on blur after thatr
                preset.cachedValue = preset.name

                this.update(preset)


            }
        },



        async update(preset) {


            this.reportCore.setLoadHeavy(true, 'Update Preset Data')
            try {
                let result = await this.requests.post({
                    url: this.urls.getURL('user_presets_report_filters', 'update'),
                    data: preset
                })

                if (result) {


                    this.reportCore.setLoadHeavy(false)
                    this.toasts.setSuccessMessage(result.data.message)
                }
            } catch (error) {
                console.log(error)
                this.reportCore.setLoadHeavy(false)
            }
        },



        //--------------------------------------------------------------------

        async delete(preset) {

            this.presets = this.presets.filter((element) => {
                return element.id !== preset.id
            })

            this.reportCore.setLoadHeavy(true, 'Delete Preset Data')
            try {
                let result = await this.requests.post({
                    url: this.urls.getURL('user_presets_report_filters', 'delete'),
                    data: preset
                })

                if (result) {
                    this.reportCore.setLoadHeavy(false)
                    this.toasts.setSuccessMessage(result.data.message)
                }
            } catch (error) {
                console.log(error)
                this.reportCore.setLoadHeavy(false)
            }
        },

        async deletePreset(preset) {


          

            this.presets = this.presets.filter((element) => {
                return element.id !== preset.id
            })

            this.reportCore.setLoadHeavy(true, 'Delete Preset Data')
            try {
                let result = await this.requests.post({
                    url: this.urls.getURL('user_presets_report_filters', 'delete'),
                    data: preset
                })

                if (result) {
                    this.reportCore.setLoadHeavy(false)
                    this.toasts.setSuccessMessage(result.data.message)
                }
            } catch (error) {
                console.log(error)
                this.reportCore.setLoadHeavy(false)
            }


        },

        async deletePresetGroup(presetGroup) {


            this.presets.forEach((element) => {
                if (element.filter_group_id === presetGroup.id) {
                    element.filter_group_id = null
                    element.selectedGroup = null
                }

            })

            this.presetGroups = this.presetGroups.filter((element) => {
                return element.id !== presetGroup.id
            })

            this.reportCore.setLoadHeavy(true, 'Delete Preset Data')
            try {
                let result = await this.requests.post({
                    url: this.urls.getURL('user_presets_report_filters', 'delete_group'),
                    data: presetGroup
                })

                if (result) {
                    this.reportCore.setLoadHeavy(false)
                    this.toasts.setSuccessMessage(result.data.message)
                }
            } catch (error) {
                console.log(error)
                this.reportCore.setLoadHeavy(false)
            }


        },



        setSelectionToPreset(preset) {
            //
        }
    }
})
