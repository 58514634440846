<template>
    <div @click="handleInteraction"
    :class="{
                'content-bg-14': componentDataLocal.value.selected || componentDataLocal.value.selectedSuper
                
              }"
    class="cursor-pointer d-flex text-align-left align-items-baseline justify-content-start">
        <span class="mdi icon icon-color-7"
        :class="{
                'mdi-checkbox-marked-outline': componentDataLocal.value.selected || componentDataLocal.value.selectedSuper,
                'mdi-checkbox-blank-outline': !componentDataLocal.value.selected && !componentDataLocal.value.selectedSuper
              }"
        ></span>
        <div class="ms-1 fs-7 text-color-3">{{ componentDataLocal.value.name }}</div>
    </div>
</template>

<script setup>
import {watch ,reactive} from 'vue'
const emit = defineEmits(["select"]);
const props = defineProps({
  componentData: {
    type: Object,
    default: () => {
      return null;
    },
  }
  
});

const componentDataLocal = reactive({ value: props.componentData });
watch(
  () => props.componentData,
  () => {
    
    componentDataLocal.value = { ...props.componentData };
  }
);

function handleInteraction($event) {
  componentDataLocal.value.selected = !componentDataLocal.value.selected

  emit("select", componentDataLocal.value);
}

</script>

<style scoped>
.grid-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
