<template>
    <div class="confirm-cancel">
      <div
        class="shadow rounded content-bg-5 toast-content"
       
      >
        <div class="p-3 d-flex align-items-center">
          <div >  


            <span style="font-size:60px" class="mdi icon  mdi-alert icon-color-11"
           
            ></span>
          </div>
          <div class="mx-8" style="width:1px;height:40px;background-color:var(--text-color-1)"></div>
          <div class=" text-color-1">
            <div class="fw-bolder fs-4" style="line-height: normal">
              Confirm
            </div>
            <div class="fs-9">
              {{ modalMessage }}
            </div>
           
            
          </div>
        </div>
        <div class="d-flex gap-1 align-items-center justify-content-center">

            <b-button
              variant="outline-light"
              style=" border-radius: 0 !important;width: 100% !important;background-color:var(--content-bg-5-inverted) !important"
              class="dismiss button-minimal p-2 px-4 text-uppercase fs-6 fw-bolder text-color-button"
              @click="reportCore.modalConfirmCancelEnd(false)"
              >cancel</b-button>

              <b-button
              variant="outline-light"
              style=" border-radius: 0 !important;width: 100% !important;background-color:var(--content-bg-5-inverted) !important"
              class="dismiss button-minimal p-2 px-4 text-uppercase fs-6 fw-bolder text-color-button"
              @click="reportCore.modalConfirmCancelEnd(true)"
              >continue</b-button>


        </div>
       
      </div>
    </div>
  </template>
  
  <script setup>
  import { computed, watch, onMounted } from 'vue'
  import { useReportCore } from '@/stores/ReportCore.js'
  import { gsap } from 'gsap'
  const reportCore = useReportCore()
  
  const modalMessage = computed(() => {
    return reportCore.modalConfirmCancelMessage
  })
  
 
  
  const display = computed(() => {
    return reportCore.modalConfirmCancelPending
  })
  
  onMounted(() => {
    gsap.set('.confirm-cancel', { autoAlpha: 0 })
  })
  
  watch(display, (newDisplay, prevDisplay) => {
    if (newDisplay) {
      gsap.to('.confirm-cancel', { autoAlpha: 1 })
    } else {
      gsap.to('.confirm-cancel', { autoAlpha: 0 })
    }
  })
  </script>
  
  <style scoped>
  .dismiss:hover {
    background-color: #ffffff2d;
    color: white;
  }
  .toast-content {
    width: fit-content;
    max-width: 600px;
    margin: 0 auto;
    
    border: 4px solid #ffffff;
    
  }
  .confirm-cancel {
    
    width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10002;
  background-color: #00000073;
    
  }
  
  
  .text-color-button{
    color:var(--content-bg-5) !important
  }
  
  .shadow {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 40%) !important;
  }
  .btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa38 !important;
  }
  </style>
  