<template>
  <ItemContainerLeftRightSlotsComponent>
    <template #left>
      <button class="button-minimal" @click="handleInteraction" style="display: block; width: 100%">
        <div :class="getClassText">{{ componentData.display.label }}</div>
      </button>
    </template>
  </ItemContainerLeftRightSlotsComponent>
</template>

<script setup>
import ItemContainerLeftRightSlotsComponent from "@/components/layout/ItemContainerLeftRightSlotsComponent.vue";

import { ref, reactive, watch, computed } from "vue";
const emit = defineEmits(["select"]);
const props = defineProps({
  componentData: {
    type: Object,
    default: () => {
      return null;
    },
  },
  styling: {
    type: Object,
    default: () => {
      return null;
    },
  },
});
const componentDataLocal = reactive({ value: props.componentData });
watch(
  () => props.componentData,
  () => {
    componentDataLocal.value = { ...props.componentData };
  }
);

let getClassText = computed(() => {
  return props.componentData.enabled
    ? props.styling?.classStringTextSelected ?? props.componentData.styling.classStringTextSelected
    : props.styling?.classStringTextDefault ?? props.componentData.styling.classStringTextDefault;
});

let getClassIcon = computed(() => {
  return props.componentData.enabled
    ? props.styling?.classStringIconSelected ?? props.componentData.styling.classStringIconSelected
    : props.styling?.classStringIconDefault ?? props.componentData.styling.classStringIconDefault;
});

let getHasIcon = computed(() => {
  return props.styling?.classStringIconDefault ?? props.componentData.styling.classStringIconDefault;
});

function handleInteraction($event) {
  emit("select", componentDataLocal.value, $event);
}
</script>
