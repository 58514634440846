<template>
  <div ref="domElement" class="w-100 mx-auto" :class="applicationStore.getPadding">
    <div class="content-bg-11" :class="{
    'pa-4': !applicationStore.grid.tabletAndUnder,
    'elevation-2': !applicationStore.grid.tabletAndUnder,
    'rounded-2': !applicationStore.grid.tabletAndUnder
  }">
      <div class="pa-4 pt-1 fs-3 text-color-6 text-align-left fw-bold">Locations</div>
      <ContentExpansionHeader @click="expandSectionCreate = !expandSectionCreate" label="Create"
        :expanded="expandSectionCreate" />

      <div class="pa-4 mb-8 content-bg-12" v-if="expandSectionCreate">
        <div class="content-bg-11 w-100" :class="{
    'pa-4': !applicationStore.grid.tabletAndUnder,
    'elevation-2': !applicationStore.grid.tabletAndUnder,
    'rounded-2': !applicationStore.grid.tabletAndUnder
  }">
          <DataEditGenericComponent @submitemit="onSubmitCreate" :useSubmit="true" :payload="createModelFieldSet" />
        </div>
      </div>

      <ContentExpansionHeader @click="expandSectionListing = !expandSectionListing" label="Listing"
        :expanded="expandSectionListing" />
      <div v-if="expandSectionListing">
        <div style="position: relative" class="text-align-left pa-2 content-border-b-1" :class="{
    'ui-bg-4': !record.toggleEdit,
    'content-bg-12': record.toggleEdit,
    'mb-1': !record.toggleEdit,
    'my-4': record.toggleEdit
  }" v-for="record in records.value" :key="'gbc_' + record.id + ' ' + recordsKeyCurrent">
          <div :data-id="record.id" style="cursor: pointer" @click="onHandleExpand(record, $event)"
            class="d-flex align-items-center h6 text-align-left fw-bold m-0 text-color-1">
            <div class="m-0" :class="{
    'text-color-2': record.toggleEdit,
    h4: record.toggleEdit,
    h6: !record.toggleEdit,
    'mb-2': record.toggleEdit
  }">
              {{ record.name }}
            </div>
            <span v-if="record.alert" class="h6 m-0 ms-2 mdi icon icon-color-11 mdi-alert"></span>
          </div>
          <div v-if="record.toggleEdit">
            <TabView v-model:activeIndex="tabIndexActive">
              <TabPanel header="Profile">
                <div class="w-100" :class="{
    'pa-4': !applicationStore.grid.tabletAndUnder,
    'elevation-2': !applicationStore.grid.tabletAndUnder,
    'rounded-2': !applicationStore.grid.tabletAndUnder
  }" v-for="(fieldsetitem, index) in record.fieldSets" :key="'fsi_' + record.id + '_' + index">
                  <DataEditGenericComponent @submitemit="onSubmitExisting" @deleteemit="onDeleteExisting"
                    :useSubmit="true" :useDelete="true" :payload="fieldsetitem" />
                </div>
              </TabPanel>




              <TabPanel v-if="auth.hasPermission('manage_aliases')" header="Aliases">
                <div class="w-100" :class="{
    'pa-4': !applicationStore.grid.tabletAndUnder,
    'elevation-2': !applicationStore.grid.tabletAndUnder,
    'rounded-2': !applicationStore.grid.tabletAndUnder
  }">
                  <div>
                    <div class="mb-4 fs-8 text-align-left text-color-1">
                      Add any aliases to this dimension. Theses aliases will be used by the backend and frontend for
                      name mapping data points from various data sources.

                    </div>
                    <div>

                      <div class="pa-2 content-border-b-1 content-bg-4 mb-1 d-flex flex-wrap align-items-center gap-3"
                        v-for="(alias, index) in record.aliases" :key="'alias_' + record.id + '_' + index">

                        <div class="me-auto text-color-2 fw-bold" style="min-width: 200px">
                          {{ alias.name }}
                        </div>








                        <button class="button-minimal" @click="deleteAlias($event, record, alias.name)">
                          <span class="mdi icon nav-color-1 mdi-delete"></span>
                        </button>
                      </div>



                    </div>

                    <b-form-input class="mt-4 text-color-1" style="max-width: 280px" :id="'new_alias_input'"
                      v-model="record.new_alias" trim></b-form-input>

                    <b-button style="width:100%;max-width: 280px" class="mt-2 p-1 fs-6 fw-bolder px-3" variant="primary"
                      @click="addAlias($event, record)">Add Alias</b-button>
                  </div>
                </div>
              </TabPanel>


            </TabView>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>
<script setup>
import { onMounted, onUnmounted, ref, nextTick, toRaw, computed, watch, reactive, onUpdated } from 'vue'
import GenericBrowseComponent from '@/components/ui/GenericBrowseComponent.vue'
import { useRequests } from '@/stores/Requests.js'
import { useRouter, useRoute } from 'vue-router'
import { useURLS } from '@/stores/URLS.js'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import { useScrollToElement } from '@/scripts/utils/ScrollToElement.js'
import { useToasts } from '@/stores/Toasts.js'
import DataEditGenericComponent from '@/components/ui/DataEditGenericComponent.vue'
import ContentExpansionHeader from '@/components/ui/ContentExpansionHeader.vue'
import { useReportCore } from '@/stores/ReportCore.js'
import { useAuth } from '@/stores/Auth.js'
const applicationStore = useApplicationStore()
const urls = useURLS()
const auth = useAuth()
const route = useRoute()
const records = reactive({ value: {} })
const results = ref()
const router = useRouter()
const toasts = useToasts()
var onUpdatedConsumed = false
var resultID = null
var currentSelected = null
var ownerOptions = [];
const domElement = ref()
const expandSectionCreate = ref(false)
const expandSectionListing = ref(true)
const reportCore = useReportCore()
const createModelFieldSet = ref()
const tabIndexActive = ref(0);
const recordsKeyCurrent = ref(0)
onUnmounted(() => {
  records.value = {}
  results.value = null
  currentSelected = null
  resultID = null
  onUpdatedConsumed = false
})




async function addAlias($event, record) {

  reportCore.setLoadHeavy(true,"Alias Location","create")
  const requests = useRequests()
  var formData = { id: record.id, location_id: record.location_id, alias: record.new_alias }
  let result
  try {
    result = await requests.post({
      url: urls.getURL('location_management', 'addAlias'),
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    onUpdatedConsumed = false
    loadDataValidate()
    resultID = result.data.id
    toasts.setSuccessMessage(result.data.message)
  }


}

async function deleteAlias($event, record, alias) {

  reportCore.setLoadHeavy(true,"Alias Location","delete")
  const requests = useRequests()
  var formData = { id: record.id, location_id: record.location_id, alias }
  let result
  try {
    result = await requests.post({
      url: urls.getURL('location_management', 'deleteAlias'),
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    onUpdatedConsumed = false
    loadDataValidate()
    resultID = result.data.id
    toasts.setSuccessMessage(result.data.message)
  }

}

function onSubmitCreate(payload) {
  console.log(payload)
  let fieldsWrite = [...payload.fields]

  updateData(urls.getURL('location_management', 'add'), { fields: fieldsWrite })
}

function onSubmitExisting(payload) {

  let fieldsWrite = [...payload.fields]
  resultID = results.value.locations[payload.resultItemIndex].id
  updateData(urls.getURL('location_management', 'update'), { location: { modelID: resultID }, fields: fieldsWrite })
}
function onDeleteExisting(payload) {
  let fieldsWrite = [...payload.fields]
  resultID = results.value.locations[payload.resultItemIndex].id
  updateData(urls.getURL('location_management', 'delete'), { location: { modelID: resultID }, fields: fieldsWrite })
}

async function updateData(url, data) {
  reportCore.setLoadHeavy(true,"Location Item","update");
  const requests = useRequests()
  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false);
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false);
    onUpdatedConsumed = false
    loadDataValidate()
    resultID = result.data.id
    toasts.setSuccessMessage(result.data.message)
  }
}

async function loadData(url, data) {
  reportCore.setLoadHeavy(true,"Location Data");
  const requests = useRequests()
  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false);
    return
  }
  if (result) {
    results.value = result.data
    console.log(result.data)
    processData(result.data)
    reportCore.setLoadHeavy(false);
    reportCore.loadSystemDimensions();
    reportCore.refreshKPIAgain = true;
  }
}
function onHandleExpand(record, event) {
  if (currentSelected) {
    currentSelected.toggleEdit = false
  }
  currentSelected = record
  currentSelected.toggleEdit = true

  useScrollToElement(event.currentTarget.offsetParent, toRaw(applicationStore.contentDomElement))
}

onUpdated(() => {
  onUpdatedAction()
})

async function onUpdatedAction() {
  if (onUpdatedConsumed) return
  if (resultID) {
    onUpdatedConsumed = true
    const domElement = await findElementByDataId(resultID)
    if (domElement) {
      let record = records.value.find((element) => element.id === resultID)
      onHandleExpand(record, { currentTarget: domElement })
    } else {
      resultID = null
    }
  }
}
const findElementByDataId = async (value) => {
  await nextTick() // Wait for the next DOM update

  return domElement.value.querySelector(`[data-id="${value}"]`)
}

onMounted(() => {
  loadDataValidate()
})

function createFieldset1(fieldSet, obj, index, resultItem) {
  fieldSet.fields = []

  let field = {}
  field.vif = true;
  field.attributes = {}
  field.label = 'name'
  field.modelKey = 'name'
  field.modelTable = 'Location'
  field.modelValue = obj?.name
  field.modelID = obj?.id
  field.component = 'b-form-input'
  field.index = `owner_${index}_${fieldSet.fields.length}`
  fieldSet.fields.push(field)




  field = {}
  field.vif = true;
  field.attributes = {}
  field.label = 'owner'
  field.modelKey = 'owner_id'
  field.modelTable = 'Location'
  field.modelValue = obj?.owner_id;
  field.modelID = obj?.id
  field.attributes.options = ownerOptions
  field.attributes['value-field'] = "owner_id"
  field.component = 'b-form-select'
  field.index = `owner_${index}_${fieldSet.fields.length}`
  fieldSet.fields.push(field)
  if (!obj?.owner_id) {
    if (resultItem) {
      resultItem.alert = true
    }
  }

}


function processData(result) {
  console.log(result)
  for (const [index, obj] of result.owners.entries()) {
    ownerOptions.push({ text: obj.User.name, owner_id: obj.id })
  }

  let fieldSet = {}
  fieldSet.key = 'Location'
  createFieldset1(fieldSet, null, 'create', null)
  createModelFieldSet.value = fieldSet

  let resultsParsed = []
  for (const [index, obj] of result.locations.entries()) {
    //result item
    let resultItem = {}
    resultItem.id = obj.id
    resultItem.location_id = obj.id
    resultItem.name = obj.name
    resultItem.toggleEdit = false
    resultItem.aliases = obj.DimensionAliasLocations

    if (resultID) {
      if (resultItem.id === resultID) {
        resultItem.toggleEdit = true
      }
    }
    resultItem.fieldSets = []
    let fieldSet = {}
    fieldSet.key = 'Location'
    fieldSet.resultItemIndex = index


    //handle all fields
    createFieldset1(fieldSet, obj, index, resultItem)







    resultItem.fieldSets.push(fieldSet)

    //finally push resultItem
    resultsParsed.push(resultItem)
  }
  recordsKeyCurrent.value++;
  records.value = resultsParsed
}



function loadDataValidate() {
  if (route.path !== '/application/location_management') {
    reportCore.setLoadHeavy(false);
    return
  }
  
  loadData(urls.getURL('location_management', 'find'))
}


</script>
