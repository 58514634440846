<template>
  <div class="data-edit-generic-component" :style="getStyle">
    <div v-for="comp in payload.fields" :key="'g_' + comp.index">
      <b-form-group
        v-if="comp.vif"
        :label="comp.label"
        :label-for="comp.modelKey"
        :invalid-feedback="comp.invalid"
        :state="comp.state"
        class="fs-9 text-align-left text-color-1"
      >
        <component
          :is="comp.component"
          v-model="comp.modelValue"
          v-bind="comp.attributes"
          :id="comp.modelKey"
          :state="comp.state"
        >
        </component>
      </b-form-group>
    </div>
  </div>
  <div class="d-flex flex-wrap flex-row-reverse justify-content-start gap-2">
    <b-button
      v-if="props.useSubmit"
      class="p-1 px-2 fs-6 fw-bolder"
      variant="primary"
      @click="submitemit"
      :disabled="requests.loading"
      >submit</b-button
    >
    <b-button
      v-if="props.useDelete"
      class="p-1 px-2 fs-6 fw-bolder"
      variant="danger"
      @click="deleteemit"
      :disabled="requests.loading"
      >delete</b-button
    >
  </div>
</template>
<script setup>
import { useRequests } from '@/stores/Requests.js'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import { computed } from 'vue'
const applicationStore = useApplicationStore()
const requests = useRequests()

const emit = defineEmits(['submitemit', 'deleteemit'])

function submitemit() {
  emit('submitemit', props.payload)
}

function deleteemit() {
  emit('deleteemit', props.payload)
}

const props = defineProps({
  payload: {
    type: Object,
    default: () => {
      return {}
    }
  },
  useSubmit: {
    type: Boolean,
    default: true
  },
  useDelete: {
    type: Boolean,
    default: false
  }
})

const getStyle = computed(() => {
  let columnVal = applicationStore.grid.smaller
    ? '1fr'
    : ' repeat(auto-fit, minmax(calc(50% - 0.5rem), 1fr))'
  let gapVal = applicationStore.grid.smaller ? '0.5rem' : '0.5rem 0.5rem'

  return `display: grid; grid-template-columns:${columnVal}; grid-gap:${gapVal}`
})
</script>
