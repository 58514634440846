<template class="w-100 h-100">
  <div class="h-100 d-flex flex-column">
    <b-img
      class="mb-4 d-block mx-auto"
      width="200px"
      :src="(theme.themeKey === 'light')?   ('/images/menu_logo_1_light.png'): ('/images/menu_logo_1_dark.png')"
    ></b-img>
    <div
      ref="snc"
      style="overflow-y: auto;padding-right: 10px;position: relative;"
    >
      <component
        v-for="(item, index) in uiBuildData"
        :key="'sidenavitem_' + index"
        :is="item.component"
        v-bind="item.attributes"
        :keyref="'sidenavitem_' + index"
        @select="onSelect"
      >
      </component>
    </div>
  </div>
</template>

<script setup>

import { useTheme } from "@/stores/Theme.js";
import { useApplicationStore } from "@/stores/ApplicationStore.js";
import {useScrollToElement} from "@/scripts/utils/ScrollToElement.js"
import { ref, nextTick, computed } from "vue";
const snc = ref(null);
const theme = useTheme();
const applicationStore = useApplicationStore()

const uiBuildData = computed(() => {
  return applicationStore.uiObjects.uiSideNav;
});



function onSelect(payload, event) {
  applicationStore.processConfigurationUpdate(payload)
  useScrollToElement(event.currentTarget,snc.value);
  
}


</script>

<style></style>
