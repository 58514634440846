import { defineStore } from 'pinia'
import { useRequests } from '@/stores/Requests'
import { useLoadingOverlayStore } from '@/stores/LoadingOverlayStore'
import { useURLS } from '@/stores/URLS.js'
import { shallowRef, markRaw } from 'vue'
import SimpleDate from '@/scripts/utils/SimpleDate.js'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
export const useReportCore = defineStore('ReportCore', {
  state: () => {
    return {
      applicationStore: markRaw(useApplicationStore()),
      loadingOverlayStore: markRaw(useLoadingOverlayStore()),
      dataOwners: null,
      dataSalesPersons: null,
      dataStaffs: null,

      dataOwnersAliases: null,
      dataOwnersNoAliases: null,

      dataSalesPersonsAliases: null,
      dataSalesPersonsNoAliases: null,

      dataStaffsAliases: null,
      dataStaffsNoAliases: null,

      dataLocations: null,
      dataLocationsAliases: null,
      dataLocationsNoAliases: null,
      dataLeadGroups: null,
      dataLeadGroupsAliases: null,
      dataLeadGroupsNoAliases: null,
      DATA_SOURCE_CORE: 'DATA_SOURCE_CORE',
      DATA_SOURCE_SM: 'DATA_SOURCE_SM',
      DATA_SOURCE_BLEND: 'DATA_SOURCE_BLEND',
      dataSourceCurrent: 'DATA_SOURCE_SM',
      useDataSourceOverride: false,
      yearMonthDayValuesFor2YearsCached: markRaw({}),

      refreshDataBenchmarkLeadGroup: false,

      isInclusive: true,
      isExclusive: false,

      refreshKPIAgain: false,
      refreshKPI2Again: false,

      useDimensionFilters: false,
      useDimensionFilterOwner: false,
      userDimensionFilterLocation: false,
      useDimensionFilterLeadGroup: false,

      currentDate: new SimpleDate(),

      dateToday:  new SimpleDate(),
      yearInvalidated: true,
      modalConfirmCancelResolve: null,
      modalConfirmCancelPending: false,
      modalConfirmCancelMessage: null,
      dataBlendDate: new SimpleDate(2024, 0, 1),

      dataBlendDateUnmapped: new SimpleDate(2024, 0, 1),
      dateSelectionBlend:new Date(
        2024,
        0,
        1
      ),
      dataSourceUnmapped: 'DATA_SOURCE_BLEND',

      dateRangeStart: new SimpleDate(),
      dateRangeEnd: new SimpleDate(),
      periodBeforeStart: new SimpleDate(),
      periodBeforeEnd: new SimpleDate(),
      rolling22Start: new SimpleDate(),
      rolling22End: new SimpleDate(),
      rolling30Start: new SimpleDate(),
      rolling30End: new SimpleDate(),
      rolling60Start: new SimpleDate(),
      rolling60End: new SimpleDate(),
      popRangeValid: null,
      momRangeValid:null,
      oneYearBeforeStart: new SimpleDate(),
      oneYearBeforeEnd: new SimpleDate(),
      expandScorecards: false,
      showDataAsDailyAvg: false,
      applyLocationMatchingForGrowthRates: false,
      rangeLength: 0,
      rangeLengthExSundays: 0,
     
      valueFormatsMap: markRaw({
        Owner: 'STRING',
        Location: 'STRING',
        'Lead Group': 'STRING',
        Amount: 'USD',
        Sales: 'INT',
        'Appt Closing Rate': '%',
        'Sales Avg': 'USD',
        Leads: 'INT',
        Appointments: 'INT',
        Quotes: 'INT',
        'Appt Set Rate': '%',
        'Issued Rate': '%',
        'Closing Rate': '%',
        'Issued Closing Rate': '%',
        'Demo Rate': '%',

        'Sales This Year': 'USD',
        'Sales Last Year': 'USD',
        'Sales Budget': 'USD',
        'Budget % Delta': '%',
        YOY: '%',
        'Spend Percentage': '%',
        'Spend Amount': 'USD',
        'Sales Amount': 'USD',
        'Cost per Lead': 'USD',
        'Cost per Appointment': 'USD',
        'Cost per Issued Appointment': 'USD',
        'Issued Appointment by Leads': '%',
        'Sales by Leads': 'USD',

        'Cost per Quote': 'USD',
        'Cost per Sale': 'USD',
        ROI: '%ROI',
        NSL: 'USD',
        NSA: 'USD',
        NSLI: 'USD',
        'Lead Efficiency': '%',
        'Predictive Index 30': 'USD',
        'Predictive Index 60': 'USD',
        Benchmark: '%',
        'Appointments Expected': 'FLOAT',
        'Appointments Issued Expected': 'FLOAT',       
        'Quotes Expected': 'FLOAT',
        'Sales Expected': 'FLOAT',
        Grade: 'GRADE',
        'Score': 'INTX100',
        'Score Weighted': 'INT',       
        'Appt Set Rate Score': 'INTX100',
        'Issued Rate Score': 'INTX100',
        'Demo Rate Score': 'INTX100',
        'Closing Rate Score': 'INTX100',
      }),

      loadingHeavy: false,
      loadingHeavyTitle: null,
      loadingHeavyDescription: null,
      loadingHeavyTitleDefault: 'LOADING',
      loadingHeavyDescriptionDefault: 'please wait..',
      rolesAndPermissions: null,
      isInitialized: false,

      textColor: getComputedStyle(document.documentElement).getPropertyValue('--text-color'),
      textColorSecondary: getComputedStyle(document.documentElement).getPropertyValue('--text-color-secondary'),
      surfaceBorder:getComputedStyle(document.documentElement).getPropertyValue('--surface-600'),

      tableScrollHeightDefault:400,
      tableScrollHeight:400,
      tableScrollHeightActive:true,


    
    }
  },

  getters:{
    getTableScollHeight(state){
      if(!state.tableScrollHeight){
        return "";
      }
      return state.tableScrollHeight+"px";
    }

  },

  actions: {

    toggleTableScrollHeight(){
      this.tableScrollHeightActive = !this.tableScrollHeightActive;
      if(this.tableScrollHeightActive){
        this.tableScrollHeight = this.tableScrollHeightDefault
      }else{
        this.tableScrollHeight = null
      }

    },
   
    reset() {
      this.dateToday = new SimpleDate()
      this.currentDate = new SimpleDate()
      this.dataBlendDate = new SimpleDate(2024, 0, 1)

      this.dataBlendDateUnmapped = new SimpleDate(2024, 0, 1)
      this.dataSourceUnmapped = this.DATA_SOURCE_BLEND

      this.dateSelectionBlend = new Date(
        this.dataBlendDate.year,
        this.dataBlendDate.month,
        this.dataBlendDate.day
      )
      this.useDataSourceOverride = false

      this.dateRangeStart = new SimpleDate()

      this.rolling22Start = new SimpleDate()
      this.rolling22End = new SimpleDate()
      this.rolling30Start = new SimpleDate()
      this.rolling30End = new SimpleDate()
      this.rolling60Start = new SimpleDate()
      this.rolling60End = new SimpleDate()

      this.dateRangeEnd = new SimpleDate()
      this.periodBeforeStart = new SimpleDate()
      this.periodBeforeEnd = new SimpleDate()
      this.popRangeValid = null
      this.momRangeValid = null
      this.oneYearBeforeStart = new SimpleDate()
      this.oneYearBeforeEnd = new SimpleDate()

      this.isInclusive = true
      this.isExclusive = false

      this.useDimensionFilters = false
      this.useDimensionFilterOwner = false
      this.userDimensionFilterLocation = false
      this.useDimensionFilterLeadGroup = false

      //just check its not null for first time reset is called , after log in these values are always there
      if (this.dataOwners) {
        for (let prop in this.dataOwners) {
          this.dataOwners[prop].selected = false
        }

        for (let prop in this.dataLocations) {
          this.dataLocations[prop].selected = false
        }

        for (let prop in this.dataLeadGroups) {
          this.dataLeadGroups[prop].selected = false
        }
      }
    },
   

    setLoadHeavy(value, title, description) {
      this.loadingOverlayStore.setLoadHeavy(value, title, description)
    },

    //when making changes to Owners , locations / lead groups , always call this to invalidate the stored owners , locations , leadgroups used by reports

    async loadSystemDimensions() {
      const requests = useRequests()
      const urls = useURLS()
      try {
        let result = await requests.post({
          url: urls.getURL('application', 'systemDimensions'),
          data: null
        })

        if (result) {
          this.processSystemDimensions(result.data)
        }
      } catch (error) {
        console.log(error)
      }
    },

    // owners , locations , leadgroups are stored using their system name as well as
    //using their id and any alias

    processSystemDimensions(systemDimensions) {
      // console.log('processSystemDimensions')
      //  console.log(systemDimensions)

      this.dataOwners = {}
      this.dataOwnersAliases = {}
      this.dataOwnersNoAliases = {}

      this.dataSalesPersons = {}
      this.dataSalesPersonsAliases = {}
      this.dataSalesPersonsNoAliases = {}

      this.dataStaffs = {}
      this.dataStaffsAliases = {}
      this.dataStaffsNoAliases = {}

      systemDimensions.users.forEach((element) => {
        let user = {}
        user.name = element.name.trim()
        user.id = element.id
        let roleModelKey = null
        let storageCurrent = null
        let storageAlias = null
        let storageNoAlias = null
        let dimensionAliasKey = null
        if (element.Owner) {
          roleModelKey = 'Owner'
          storageCurrent = this.dataOwners
          storageAlias = this.dataOwnersAliases
          storageNoAlias = this.dataOwnersNoAliases
          dimensionAliasKey = 'DimensionAliasOwners'
          user.locations = [] //not storing locations any more , locations dont seem to matter much to dean
        }
        if (element.SalesPerson) {
          roleModelKey = 'SalesPerson'
          storageCurrent = this.dataSalesPersons
          storageAlias = this.dataSalesPersonsAliases
          storageNoAlias = this.dataSalesPersonsNoAliases
          dimensionAliasKey = 'DimensionAliasSalesPersons'

          user.parentOwnerID = element.SalesPerson.owner_id
          user.parentName = element.SalesPerson.Owner.User?.name
        }

        if (element.Staff) {
          roleModelKey = 'Staff'
          storageCurrent = this.dataStaffs
          storageAlias = this.dataStaffsAliases
          storageNoAlias = this.dataStaffsNoAliases
          dimensionAliasKey = 'DimensionAliasStaffs'

          user.parentOwnerID = element.Staff.owner_id
          user.parentName = element.Staff.Owner?.User?.name
        }

        if (roleModelKey) {
          storageCurrent[user.name] = user
          user.exclude_from_reports = element[roleModelKey].exclude_from_reports

          let sd = new SimpleDate()
          sd.getValuesFromDateString(element[roleModelKey].started_at)
          user.started_at = sd

          storageNoAlias[user.name] = user
          storageCurrent[element[roleModelKey].id] = user

          let aliasTemp = []
          aliasTemp.push(user.name)
          storageAlias[user.name] = aliasTemp
          if (element[roleModelKey][dimensionAliasKey]) {
            element[roleModelKey][dimensionAliasKey].forEach((alias) => {
              storageCurrent[alias.name] = user
              aliasTemp.push(alias.name)
              storageAlias[alias.name] = aliasTemp
            })
          }
        }

       

        user.UserDefaultDataSource = element.UserDefaultDataSource

        if (!user.UserDefaultDataSource) {
          user.UserDefaultDataSource = {}
          user.UserDefaultDataSource.blend_date_sd = this.dataBlendDateUnmapped
          user.UserDefaultDataSource.blend_date_model = new Date(
            this.dataBlendDateUnmapped.year,
            this.dataBlendDateUnmapped.month,
            this.dataBlendDateUnmapped.day
          )
        } else {
          if (user.UserDefaultDataSource?.blend_date) {
            let sd = (user.UserDefaultDataSource.blend_date_sd = new SimpleDate())
            sd.getValuesFromDateString(user.UserDefaultDataSource.blend_date)
            user.UserDefaultDataSource.blend_date_model = new Date(sd.year, sd.month, sd.day)
          } else {
            user.UserDefaultDataSource.blend_date_sd = this.dataBlendDateUnmapped
            user.UserDefaultDataSource.blend_date_model = new Date(
              this.dataBlendDateUnmapped.year,
              this.dataBlendDateUnmapped.month,
              this.dataBlendDateUnmapped.day
            )
          }
        }

        //set blend as default if not setting is found
        // owner.UserDefaultDataSource.value = owner.UserDefaultDataSource.value||this.DATA_SOURCE_BLEND
      })

      //-------------------------------------------------------

      this.dataLocations = {}
      this.dataLocationsAliases = {}
      this.dataLocationsNoAliases = {}
      systemDimensions.locations.forEach((element) => {
        let location = {}
        location.name = element.name.trim()
        location.id = element.id
        this.dataLocations[location.name] = location
        this.dataLocationsNoAliases[location.name] = location
        let locationAliases = []
        locationAliases.push(location.name)
        this.dataLocations[location.id] = location
        element.DimensionAliasLocations.forEach((alias) => {
          this.dataLocations[alias.name] = location
          locationAliases.push(alias.name)
          this.dataLocationsAliases[alias.name] = locationAliases
        })

        let owner = this.dataOwners[element.Owner.User.name.trim()]
        if (owner) {
          location.owner = owner.name
          owner.locations.push(location)
        }
      })

      //-------------------------------------------------------

      this.dataLeadGroups = {}
      this.dataLeadGroupsAliases = {}
      this.dataLeadGroupsNoAliases = {}
      systemDimensions.leadgroups.forEach((element) => {
        let leadgroup = {}
        leadgroup.name = element.name.trim()

        leadgroup.id = element.id
        this.dataLeadGroups[leadgroup.name] = leadgroup
        this.dataLeadGroupsNoAliases[leadgroup.name] = leadgroup
        let leadGroupAliases = []
        leadGroupAliases.push(leadgroup.name)
        this.dataLeadGroups[leadgroup.id] = leadgroup
        element.DimensionAliasLeadGroups.forEach((alias) => {
          this.dataLeadGroups[alias.name] = leadgroup
          leadGroupAliases.push(alias.name)
          this.dataLeadGroupsAliases[alias.name] = leadGroupAliases
        })
      })

      //NO Leadgroup
      //for every owner add a no lead group because some records come though in the kpi without a lead group and this is how i map them
      for (let ownerName in this.dataOwnersNoAliases) {
        let leadgroup = {}
        leadgroup.name = `${ownerName} NO Leadgroup`
        this.dataLeadGroups[leadgroup.name] = leadgroup
      }
    },

    //---------------------------------------------------------------------------------------------

    setRolesAndPermissions(systemRolesAndPermissions) {
      this.rolesAndPermissions = markRaw(systemRolesAndPermissions)
    },

    //---------------------------------------------------------------------------------------------

    getYearRangeMin() {
      return new Date(2021, 0, 1)
    },
    getYearRangeMax() {
      return new Date()
    },

    getYearRange() {
      return `2021:${new SimpleDate().year}`
    },

    dateForThisYear() {
      this.dateRangeStart.getValuesFromYearMonthDay(this.currentDate.year, 0, 1)
      this.dateRangeEnd.getValuesFromCurrentDate()
    },

    dateForFullYear(year) {
      if (this.currentDate.year !== year) {
        this.yearInvalidated = true
      }
      this.currentDate.year = year

      this.dateRangeStart.getValuesFromYearMonthDay(year, 0, 1)

      if (year === new SimpleDate().year) {
        this.dateRangeEnd.getValuesFromCurrentDate()
      } else {
        this.dateRangeEnd.getValuesFromYearMonthDay(year, 11, 31)
      }
    },

    //---------------------------------------------------------------------------------------------

    dateForMonth(date) {
      let year = date.getFullYear()
      if (this.currentDate.year !== year) {
        this.yearInvalidated = true
      }

      this.currentDate.year = year
      this.currentDate.month = date.getMonth()

      let datenow = new SimpleDate()

      this.dateRangeStart.getValuesFromYearMonthDay(
        this.currentDate.year,
        this.currentDate.month,
        1
      )
      this.dateRangeEnd.getValuesFromYearMonthDay(
        this.currentDate.year,
        this.currentDate.month + 1,
        0
      )

      if (this.currentDate.year === datenow.year && this.currentDate.month === datenow.month) {
        // Adjust the end date to the current date and time
        this.dateRangeEnd.day = datenow.day
        this.dateRangeEnd.invalidate()
      }
    },

    dateYTD() {
      this.dateRangeStart.getValuesFromYearMonthDay(this.currentDate.year, 0, 1)
      this.dateRangeEnd = this.dateToday.clone()
    },

    dateYTLM() {
      this.dateRangeStart.getValuesFromYearMonthDay(this.currentDate.year, 0, 1)
      let lastMonthDate = this.dateToday.clone()
      lastMonthDate.setDate(0)
      this.dateRangeEnd = lastMonthDate.clone()
    },

    //---------------------------------------------------------------------------------------------

    dateQ1() {
      this.dateRangeStart.getValuesFromYearMonthDay(this.currentDate.year, 0, 1)
      this.dateRangeEnd.getValuesFromYearMonthDay(this.currentDate.year, 2, 31)
    },

    //---------------------------------------------------------------------------------------------

    dateQ2() {
      this.dateRangeStart.getValuesFromYearMonthDay(this.currentDate.year, 3, 1)
      this.dateRangeEnd.getValuesFromYearMonthDay(this.currentDate.year, 5, 30)
    },

    //---------------------------------------------------------------------------------------------

    dateQ3() {
      this.dateRangeStart.getValuesFromYearMonthDay(this.currentDate.year, 6, 1)
      this.dateRangeEnd.getValuesFromYearMonthDay(this.currentDate.year, 8, 30)
    },

    //---------------------------------------------------------------------------------------------

    dateQ4() {
      this.dateRangeStart.getValuesFromYearMonthDay(this.currentDate.year, 9, 1)
      this.dateRangeEnd.getValuesFromYearMonthDay(this.currentDate.year, 11, 31)
    },

    countDaysExcludingSundays(startDate, endDate) {
      let count = 0
      let currentDate = startDate.clone()

      while (currentDate <= endDate) {
        // Check if the current day is not a Sunday (day index 0)
        if (currentDate.getDayOfWeek() !== 0) {
          count++
        }

        // Move to the next day
        currentDate.incrementOneDay()
      }

      return count
    },

    //---------------------------------------------------------------------------------------------

    formatPercent(value) {
      if (value === null) {
        return ''
      }
      return (value * 100).toFixed(2) + '%'
    },

    formatIntX100(value) {
      if (value === null) {
        return ''
      }
      return Math.round(value * 100);
    },

    formatPercentWithoutSymbol (value){
      if (value === null) {
        return ''
      }
      return (value * 100).toFixed(2)
    },

    formatFloat(value) {
      if (value === null) {
        return ''
      }
      return (value).toFixed(2) + ''
    },

    formatPercentROI(value) {
      if (value === null) {
        return ''
      }
      return value.toFixed(2) + 'X'
    },

    formatGrade(value) {
      if (value === null || isNaN(value)) {
        return ''
      }
      let grade = 'F'

      if (value >= 60) {
        grade = 'D-'
      }
      if (value >= 63) {
        grade = 'D'
      }
      if (value >= 67) {
        grade = 'D+'
      }
      if (value >= 70) {
        grade = 'C-'
      }
      if (value >= 73) {
        grade = 'C'
      }
      if (value >= 77) {
        grade = 'C+'
      }
      if (value >= 80) {
        grade = 'B-'
      }
      if (value >= 83) {
        grade = 'B'
      }
      if (value >= 87) {
        grade = 'B+'
      }
      if (value >= 90) {
        grade = 'A-'
      }
      if (value >= 93) {
        grade = 'A'
      }
      if (value >= 97) {
        grade = 'A+'
      }

      return grade
    },

    //---------------------------------------------------------------------------------------------

    formatCurrency(value) {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    },

    //---------------------------------------------------------------------------------------------

    calculateGrowthDateRanges() {
      //check to see if the year is invalidated via  this.dateRangeStarts year
      if (this.currentDate.year !== this.dateRangeStart.year) {
        this.currentDate.year = this.dateRangeStart.year
        this.currentDate.month = this.dateRangeStart.month
        this.currentDate.invalidate()
        this.yearInvalidated = true
      }

      //POP requires start on the first and end on last day of month

      //initalize as true , then check if need to be false
      this.popRangeValid = true

      let POPStartValid = false
      let POPEndValid = false
      if (this.dateRangeStart.day === 1) {
        POPStartValid = true
      }
      let POPEndDate = this.dateRangeEnd.clone()
      let POPEndDateCompare = this.dateRangeEnd.clone()
      POPEndDateCompare.getValuesFromYearMonthDay(
        POPEndDateCompare.year,
        POPEndDateCompare.month + 1,
        0
      )

      if (POPEndDate.day === POPEndDateCompare.day) {
        POPEndValid = true
      }
      if (!POPStartValid || !POPEndValid) {
        this.popRangeValid = false
      }

      this.momRangeValid = true
      if (this.dateRangeStart.month !== this.dateRangeEnd.month) {
        this.momRangeValid = false
      }

      this.rangeLengthExSundays = this.countDaysExcludingSundays(
        this.dateRangeStart,
        this.dateRangeEnd
      )

      var rangeLength =
        (this.dateRangeEnd.valueOf() - this.dateRangeStart.valueOf()) / (1000 * 60 * 60 * 24) + 1

      if (this.momRangeValid) {
        rangeLength -= 1
        this.periodBeforeStart = this.dateRangeStart.clone()

        this.periodBeforeStart.getValuesFromYearMonthDay(
          this.periodBeforeStart.year,
          this.periodBeforeStart.month - 1,
          this.periodBeforeStart.day
        )

        //first get periodBeforeEnd based on periodBeforeStart and range
        this.periodBeforeEnd = this.periodBeforeStart.clone()
        this.periodBeforeEnd.setDate(this.periodBeforeEnd.day + rangeLength)

        // except if dateRangeEnd is the last day of a month , then
        // then adjust periodBeforeEnd to round up or down as needed to provide the full month range
        this.testEndOfMonth = this.dateRangeEnd.clone()

        this.testEndOfMonth.setDate(this.testEndOfMonth.day + 1)

        if (
          this.testEndOfMonth.month !== this.dateRangeEnd.month &&
          this.dateRangeStart.day === 1
        ) {
          //if this condition is met,  then dateRangeEnd date is the end of the month and dateRangeStart is at the beginning adjustments are needed for periodBeforeEnd
          this.periodBeforeEnd = this.dateRangeStart.clone()
          this.periodBeforeEnd.adjustDateToPreviousMonthEnd()
        }
      } else {
        // Generate the first set of date ranges (period before)

        this.periodBeforeStart = this.dateRangeStart.clone()
        this.periodBeforeStart.setDate(this.periodBeforeStart.day - rangeLength)
        this.periodBeforeStart.adjustDateToEndOrStartViaThreshold()
        this.periodBeforeEnd = this.dateRangeStart.clone()
        this.periodBeforeEnd.adjustDateToPreviousMonthEnd()
      }

      // Generate the second set of date ranges (minus one year)
      this.oneYearBeforeStart = this.dateRangeStart.clone()
      this.oneYearBeforeStart.year -= 1
      this.oneYearBeforeStart.invalidate()

      this.oneYearBeforeEnd = this.dateRangeEnd.clone()
      //ok found an issue with leap years here
      if (this.oneYearBeforeEnd.month === 1 && this.oneYearBeforeEnd.day === 29) {
        this.oneYearBeforeEnd.day = 28
      }
      this.oneYearBeforeEnd.year -= 1
      this.oneYearBeforeEnd.invalidate()

      //called for current monselected month range
      this.getPredictiveIndexRollingStartEnds(this, this.dateRangeEnd, false)
    },

    //called for current month selection and for all dates in a month for timeline data
    getPredictiveIndexRollingStartEnds(target, dateFrom, allowFullMonth) {
      //calculate period 22 and period 60 , days before current date , or last day of month is month is not current
      let rangeLength22 = 22
      let rangeLength30 = 33
      let rangeLength60 = 63
      target.rolling22End = dateFrom.clone()
      //let isDayAtMonthEnd = this.dateToday.isLastDayOfMonth();

      if (!allowFullMonth) {
        if (this.dateToday.day < target.rolling22End.day) {
          target.rolling22End.setDate(this.dateToday.day)
        }

        target.rolling22End.setDate(target.rolling22End.day - 1)
      }

      rangeLength22 -= 1 //why
      rangeLength30 -= 1 //why
      rangeLength60 -= 1 //why

      target.rolling22Start = target.rolling22End.clone()

      target.rolling30End = target.rolling22End.clone()
      target.rolling30Start = target.rolling22End.clone()

      target.rolling60End = target.rolling22End.clone()
      target.rolling60Start = target.rolling22End.clone()

      target.rolling22Start.setDate(target.rolling22Start.day - rangeLength22)
      target.rolling30Start.setDate(target.rolling60Start.day - rangeLength30)
      target.rolling60Start.setDate(target.rolling60Start.day - rangeLength60)
    },

    //---------------------------------------------------------------------------------------------

    getGrowth(prevValue, newValue) {
      return (newValue - prevValue) / prevValue
    },

    getYearMonthDayValuesForKPIRange() {      

   

      const startDate = new Date(this.oneYearBeforeStart.year, 0, 1) // January 1st of oneYearBeforeStart's year
      const endDate = new Date(this.dateRangeEnd.year, 11, 31) // December 31st of dateRangeEnd's year

      const dayValues = []

      for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
        const year = date.getFullYear()
        const month = date.getMonth()
        const day = date.getDate() // Day of the month

        dayValues.push(new SimpleDate(year, month, day))
      }
      this.yearMonthDayValuesFor2YearsCached[this.currentDate.year] = dayValues
      return dayValues
    },

    getMonthIndexsForTimeline() {
      let indexStart = 0
      let yearStart = this.currentDate.year - 1
      let indexEnd = 11
      let yearEnd = this.currentDate.year
      let a = []
      let valid = true
      while (valid) {
        a.push({ month: indexStart, year: yearStart })
        indexStart++
        if (indexStart > 11) {
          yearStart++
          indexStart = 0
        }
        if (yearStart > yearEnd) {
          valid = false
        }
        if (indexStart > indexEnd && yearStart === yearEnd) {
          valid = false
        }
      }

      return a
    },

    getMonthIndexsInRange() {
      let indexStart = this.dateRangeStart.month
      let yearStart = this.dateRangeStart.year
      let indexEnd = this.dateRangeEnd.month
      let yearEnd = this.dateRangeEnd.year
      let a = []
      let valid = true
      while (valid) {
        a.push({ month: indexStart, year: yearStart })
        indexStart++
        if (indexStart > 11) {
          yearStart++
          indexStart = 0
        }
        if (yearStart > yearEnd) {
          valid = false
        }
        if (indexStart > indexEnd && yearStart === yearEnd) {
          valid = false
        }
      }

      return a
    },

    getMonthIndexsInRangePOP() {
      let indexStart = this.periodBeforeStart.month
      let yearStart = this.periodBeforeStart.year
      let indexEnd = this.periodBeforeEnd.month
      let yearEnd = this.periodBeforeEnd.year
      let a = []
      let valid = true
      while (valid) {
        a.push({ month: indexStart, year: yearStart })
        indexStart++
        if (indexStart > 11) {
          yearStart++
          indexStart = 0
        }
        if (yearStart > yearEnd) {
          valid = false
        }
        if (indexStart > indexEnd && yearStart === yearEnd) {
          valid = false
        }
      }

      return a
    },
    getMonthIndexsInRangeYOY() {
      let indexStart = this.oneYearBeforeStart.month
      let yearStart = this.oneYearBeforeStart.year
      let indexEnd = this.oneYearBeforeEnd.month
      let yearEnd = this.oneYearBeforeEnd.year
      let a = []
      let valid = true
      while (valid) {
        a.push({ month: indexStart, year: yearStart })
        indexStart++
        if (indexStart > 11) {
          yearStart++
          indexStart = 0
        }
        if (yearStart > yearEnd) {
          valid = false
        }
        if (indexStart > indexEnd && yearStart === yearEnd) {
          valid = false
        }
      }

      return a
    },

    fixDates(data, requestModelNameToIndex) {
      data.forEach((element) => {
        let index = requestModelNameToIndex['DATE']
        let elementDate = element[index]
        let sd = new SimpleDate()
        sd.getValuesFromDateString(elementDate)

        element[index] = sd
      })
    },
    //---------------------------------------------------------------------------------------------

    async loadServerData(url, data) {
      const requests = useRequests()
      var formData = data || {}
      let result
      try {
        result = await requests.post({
          url: url,
          data: formData
        })
      } catch (error) {
        console.log(error)
        throw error
      }
      if (result) {
        return result.data
      }
    },

    //-----------------------------------------------------------------------------

    getMonthNameFromIndex(monthIndex) {
      if (monthIndex === 0) return 'Jan'
      if (monthIndex === 1) return 'Feb'
      if (monthIndex === 2) return 'Mar'
      if (monthIndex === 3) return 'Apr'
      if (monthIndex === 4) return 'May'
      if (monthIndex === 5) return 'Jun'
      if (monthIndex === 6) return 'Jul'
      if (monthIndex === 7) return 'Aug'
      if (monthIndex === 8) return 'Sep'
      if (monthIndex === 9) return 'Oct'
      if (monthIndex === 10) return 'Nov'
      if (monthIndex === 11) return 'Dec'
    },

    //---------------------------------------------------------------------------------------------
    //confirm cancel modals , are well , modal , but i cant stop a user from changing the url , which will trigger
    //some other page load .. but then possibly leave a promise hanging
    // so the code that listens for route changes and updates the ui will also call this function
    //to clean up any pending modal actions ( resolves them as false )
    clearPendingActions() {
      if (this.modalConfirmCancelResolve) {
        this.modalConfirmCancelPending = false
        this.modalConfirmCancelResolve(false)
        this.modalConfirmCancelResolve = null
        this.applicationStore.blurContent(false)
      }
    },

    //---------------------------------------------------------------------------------------------

    modalConfirmCancelStart(message) {
      this.modalConfirmCancelPending = true
      this.modalConfirmCancelMessage = message || ''
      this.applicationStore.blurContent(true)
      return new Promise((resolve, reject) => {
        this.modalConfirmCancelResolve = resolve
      })
    },

    //---------------------------------------------------------------------------------------------

    modalConfirmCancelEnd(value) {
      this.modalConfirmCancelPending = false
      if (value) {
        this.modalConfirmCancelResolve(true)
      } else {
        this.modalConfirmCancelResolve(false)
      }
      this.modalConfirmCancelResolve = null
      this.applicationStore.blurContent(false)
    }

    //---------------------------------------------------------------------------------------------
  }
})
