<template>
  <div ref="domElement" class="w-100 mx-auto" :class="applicationStore.getPadding">
    <div class="content-bg-11" :class="{
    'pa-4': !applicationStore.grid.tabletAndUnder,
    'elevation-2': !applicationStore.grid.tabletAndUnder,
    'rounded-2': !applicationStore.grid.tabletAndUnder
  }">
      <div class="pa-4 pt-1 fs-3 text-color-6 text-align-left fw-bold">Marketing Spend</div>
      <ContentExpansionHeader v-if="auth.hasPermission('create_marketing_spend_management_year')"
        @click="expandSectionCreate = !expandSectionCreate" label="Create Marketing Year"
        :expanded="expandSectionCreate" />

      <div class="pa-4 mb-8 content-bg-12" v-if="expandSectionCreate && auth.hasPermission('create_marketing_spend_management_year')
    ">
        <div class="elevation-4 pa-4 content-bg-11 rounded-2 w-100">
          <DataEditGenericComponent @submitemit="onSubmitCreate" :useSubmit="true"
            :payload="createBudgetYearFieldSet" />
        </div>
      </div>

      <ContentExpansionHeader @click="expandSectionListing = !expandSectionListing" label="Marketing Edit"
        :expanded="expandSectionListing" />
      <div class="budgets pa-4 mb-8 content-bg-12" v-if="expandSectionListing">
        <div class="d-flex justify-content-left mb-3">
          <SelectButton @update:modelValue="onYearChange" v-model="yearSelectionCurrent"
            :options="optionsBudgetYearsComputed" optionLabel="year" :allowEmpty="false" />
        </div>

        <TabView>
          <TabPanel header="Yearly Spends">
            <div class="">
              <div class="pa-4" style="background-color: var(--content-bg-13)">
                <div v-if="manage_marketing_lock" class="flex-ui-1" style="align-items: center">
                  <Checkbox @change="updateYearLock" v-model="yearSelectionCurrent.locked" inputId="locked"
                    :binary="true" />
                  <label for="locked" class="fs-8 icon-color-1 text-type-10 ml-2">
                    lock year
                  </label>
                </div>
                <div class="flex-ui-1 align-items-center">
                  <div class="text-type-11 icon-color-1 p-0 mr-2">query cached:</div>
                  <div class="text-type-11 p-0" style="color: #ffca00">
                    {{ queryCacheDateBudgetSpend ?? '' }}
                  </div>
                  <button v-if="auth.hasPermission('invalidate_marketing_spend_cache')" class="button-minimal"
                    @click="
    toggleBudgetSpendQueryCacheExtendedDisplay =
    !toggleBudgetSpendQueryCacheExtendedDisplay
    ">
                    <span class="mdi icon icon-color-1 mdi-help-circle"></span>
                  </button>
                </div>
              </div>

              <div v-if="toggleBudgetSpendQueryCacheExtendedDisplay" class="mt-min pa-4"
                style="background-color: var(--content-bg-13)">
                <div class="text-type-11 icon-color-1 p-0">
                  Queries are cached once a day at midnight
                </div>
                <div class="mt-1 d-flex flex-wrap align-items-center gap-2">
                  <b-button class="p-1 fs-6 fw-bolder px-3" variant="primary"
                    @click="refreshQueryCacheBudgetSpend()">refresh</b-button>
                </div>
              </div>
              <div class="mt-2">
                <div v-for="leadgroup in leadGroupData.value" style="position: relative"
                  :key="'lead_group' + leadgroup.group_id" class="text-align-left content-border-b-1" :class="{
    'ui-bg-4': !leadgroup.expanded,

    'mb-1': !leadgroup.expanded,
    'my-4': leadgroup.expanded
  }">
                  <div class="pa-2" :class="{ 'content-bg-5-inverted': leadgroup.expanded }"
                    style="position: sticky; top: 0">
                    <div class="d-flex align-items-center fs-6 text-align-left fw-bold m-0 text-color-1"
                      @click="handleExpand(leadgroup, $event)" style="cursor: pointer">
                      <div class="m-0 fw-bold" :class="{
    'fs-4': leadgroup.expanded,
    'fs-6': !leadgroup.expanded,
    'text-color-2': !leadgroup.expanded,
    'text-color-2-inverted': leadgroup.expanded
  }">
                        {{ leadgroup.name }}
                      </div>
                      <span class="ms-auto mdi icon text-color-1" :class="{
    ' mdi-plus-circle': !leadgroup.expanded,
    ' mdi-minus-circle': leadgroup.expanded,
    'text-color-2-inverted': leadgroup.expanded
  }">
                      </span>
                    </div>

                    
                  </div>
                  <div v-if="leadgroup.expanded" class="">
                    <div v-for="record in leadGroupSpendData.value" :key="'budgetallocation_' + record.owner_id"
                      class="py-2 text-align-left content-border-b-1 fs-6 fw-bold text-color-1 px-2 ui-bg-4">
                      <div class="w-100 d-flex flex-wrap align-items-center gap-3">
                        <div class="icon-color-7" style="min-width: 200px">
                          {{ record.name }}
                        </div>

                        <div class="mt-2 mb-4 w-100 d-grid" style="
                            grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
                            gap: 0.2rem;
                          ">
                          <div v-for="month in record.months" :key="'budgetallocation_' + month.name"                            
                            style="margin-bottom: 0 !important" class="mb-0 fs-9 text-align-left text-color-1">

<div class="d-flex align-items-center">
<label :for="'budgetallocation_' + record.owner_id + month.name">{{ month.name }}</label>
 
                            <button class="button-minimal" @click="onLeadGroupOverrideChanged(month)">
                              <span class="mdi icon nav-color-1" :class="{
    'mdi-cloud-lock-open': !month.override,
    'mdi-cloud-lock': month.override,
  }"></span>
                            </button>


</div>
<b-form-input :id="'budgetallocation_' + record.owner_id + month.name" v-model="month.value"
                              @focus="cacheInputValue(month)" @blur="validateInputValue(month)" @keydown.enter="validateInputValue(month)" trim :class="{
    'input-bg-color': (month.api_controlled && !month.override),
    'input-bg-color-2': (month.override),
    'fw-regular': month.api_controlled,
    'fw-bold': month.api_controlled
  }"
                              :disabled="!manage_marketing_data || (month.api_controlled && !month.override)"></b-form-input>

                            

</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, nextTick, toRaw, computed, watch, reactive } from 'vue'
import DataEditGenericComponent from '@/components/ui/DataEditGenericComponent.vue'
import { useRequests } from '@/stores/Requests.js'
import { useRouter, useRoute } from 'vue-router'
import { useURLS } from '@/stores/URLS.js'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import { useScrollToElement } from '@/scripts/utils/ScrollToElement.js'
import { useToasts } from '@/stores/Toasts.js'
import ContentExpansionHeader from '@/components/ui/ContentExpansionHeader.vue'
import { useReportCore } from '@/stores/ReportCore.js'
import { useAuth } from '@/stores/Auth.js'
import SimpleDate from '../scripts/utils/SimpleDate'
const applicationStore = useApplicationStore()
const urls = useURLS()
const route = useRoute()
const auth = useAuth()
const results = ref()
const toasts = useToasts()
const reportCore = useReportCore()
const expandSectionCreate = ref(false)
const expandSectionListing = ref(true)
const requests = useRequests()
const yearSelectionCurrent = ref({ locked: false })
const budgetAllocationData = reactive({ value: [] })
const leadGroupData = reactive({ value: [] })
//const leadGroupOverrideData = reactive({ value: [] })
const leadGroupSpendData = reactive({ value: [] })

const showMonthAllSpends = ref(false)

async function onLeadGroupOverrideChanged(month) {
  month.override = !month.override
  let message = "";
  if (month.override) {
    message = 'are you sure you want to override any api driven data for this lead group month spend?'
  } else {
    message = 'are you sure you want to remove your override of api driven data for this lead group month spend?'
  }
  let result = await reportCore.modalConfirmCancelStart(message)
  if (result) {

    updateSingleValue(month)

  } else {
    month.override = !month.override
  }

}






function cacheInputValue(month) {
  // console.log('cacheInputValue')
  month.cachedValue = month.value
  // console.log(month)
}

function validateInputValue(month) {
  // console.log('validateInputValue')
  if (month.cachedValue != month.value) {
//update cache value as user may have pressed enter key and i dont want to update again on blur after that
month.cachedValue = month.value

    updateSingleValue(month)
  }
}

async function updateSingleValue(month) {
  //console.log('updateSingleValue')
  // console.log(month)


  let url = urls.getURL('marketing_spend_management', 'updateSingleRow')
  let data = {};
  data.rowData = {};
  data.rowData.value = month.value

  if (
      isNaN(data.rowData.value) ||
      !data.rowData.value ||
      !isFinite(data.rowData.value)
    ) {
      month.value = month.cachedValue =  data.rowData.value = 0;
    }

  month.api_controlled = month.api_controlled && !month.override
  data.rowData.api_controlled = month.api_controlled
  data.rowData.override =month.override

  data.rowID = month.id

  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    //should not need to reload the budget data again .. pointless .. updated values exist in objects and were saved

    /* loadBudgetSpendForLeadGroup(urls.getURL('marketing_spend_management', 'findOneViaLeadGroup'), {
       year: yearCurrent,
       group_id: leadGroupCurrent
     })*/


    toasts.setSuccessMessage(result.data.message, 1000)
  }




}

const toggleBudgetSpendQueryCacheExtendedDisplay = ref(false)
const queryCacheDateBudgetSpend = ref('')
var currentSelected = null
var yearCurrent = null
var leadGroupCurrent = null
const optionsBudgetYears = ref()
var pendingNewBudgetYear = null

//---------------------------------------------------------------------------------------
const optionsBudgetYearsComputed = computed(() => {
  return optionsBudgetYears.value
})

//---------------------------------------------------------------------------------------



//---------------------------------------------------------------------------------------

const manage_marketing_data = computed(() => {
  return (
    auth.hasPermission('edit_marketing_spend_management_data') &&
    !yearSelectionCurrent.value?.locked
  )
})

//---------------------------------------------------------------------------------------

const manage_marketing_lock = computed(() => {
  return auth.hasPermission('edit_marketing_spend_management_lock')
})

//---------------------------------------------------------------------------------------

async function updateYearLock() {
  reportCore.setLoadHeavy(true,"Marketing Year Lock","update")
  let url = urls.getURL('marketing_spend_management', 'lock_years')

  let data = yearSelectionCurrent.value

  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    toasts.setSuccessMessage(result.data.message)
  }
}

//---------------------------------------------------------------------------------------

function handleExpand(record, event) {
  //if expanded , then this is a contraction so expand false and exit function
  if (record.expanded) {
    record.expanded = false
    return
  }
  if (currentSelected) {
    currentSelected.expanded = false
  }
  currentSelected = record
  currentSelected.expanded = true

  useScrollToElement(
    event.currentTarget.offsetParent.offsetParent,
    toRaw(applicationStore.contentDomElement)
  )
  leadGroupCurrent = record.group_id
  loadBudgetSpendForLeadGroup(urls.getURL('marketing_spend_management', 'findOneViaLeadGroup'), {
    year: yearCurrent,
    group_id: record.group_id
  })
}

//---------------------------------------------------------------------------------------

function onYearChange(event) {
  yearCurrent = event.year

  //the year changed and spend data is on demand , so have to clear current spend data ,
  //and close/unexpand all leadgroups , so user need to expand again to access new data
  leadGroupSpendData.value = []
  leadGroupData.value.forEach((element) => (element.expanded = false))
 // invalidateOverrides()
}

//---------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------

function getMonthName(monthIndex) {
  if (monthIndex === 0) return 'Jan'
  if (monthIndex === 1) return 'Feb'
  if (monthIndex === 2) return 'Mar'
  if (monthIndex === 3) return 'Apr'
  if (monthIndex === 4) return 'May'
  if (monthIndex === 5) return 'Jun'
  if (monthIndex === 6) return 'Jul'
  if (monthIndex === 7) return 'Aug'
  if (monthIndex === 8) return 'Sep'
  if (monthIndex === 9) return 'Oct'
  if (monthIndex === 10) return 'Nov'
  if (monthIndex === 11) return 'Dec'
}

//---------------------------------------------------------------------------------------

/*async function submitEditSpend() {
  reportCore.setLoadHeavy(true)
  let url = urls.getURL('marketing_spend_management', 'update')
  let data = leadGroupSpendData.value

  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    loadBudgetSpendForLeadGroup(urls.getURL('marketing_spend_management', 'findOneViaLeadGroup'), {
      year: yearCurrent,
      group_id: leadGroupCurrent
    })

    toasts.setSuccessMessage(result.data.message)
  }
}*/

//---------------------------------------------------------------------------------------

async function loadBudgetSpendForLeadGroup(url, data) {
  reportCore.setLoadHeavy(true,"Marketing Data")
  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    processBudgetSpendForLeadGroup(result.data)
    reportCore.setLoadHeavy(false)
  }
}

//---------------------------------------------------------------------------------------

function processBudgetSpendForLeadGroup(data) {
  //console.log('processBudgetSpendForLeadGroup')
  // console.log(data)
  let result = {}
  let resultArr = []
  for (let i = 0; i < data.length; i++) {
    const element = data[i]
    let ownerData = result[element.owner_id]
    if (!ownerData) {
      ownerData = result[element.owner_id] = {}
      ownerData.months = []
      ownerData.monthsObject = {}
      ownerData.year = element.year
      ownerData.owner_id = element.owner_id
      ownerData.group_id = element.group_id
      ownerData.name = element.Owner.User.name
      ownerData.toggleEdit = showMonthAllSpends.value
      resultArr.push(ownerData)
    }

    ownerData.months[element.month] = {
      id: element.id,
      name: getMonthName(element.month),
      value: element.value,
      override:element.override,
      api_controlled: element.api_controlled
    }
  }

  leadGroupSpendData.value = resultArr
}

//---------------------------------------------------------------------------------------

function createFieldSetYears(fieldSet, obj, index) {
  fieldSet.fields = []

  let field = {}
  field.vif = true
  field.attributes = {}
  field.label = 'year'
  field.modelKey = 'year'
  field.modelTable = 'Budget'
  field.modelValue = null
  field.modelID = null
  field.component = 'b-form-input'
  field.index = `budget_${index}_${fieldSet.fields.length}`
  fieldSet.fields.push(field)
}

//---------------------------------------------------------------------------------------

function onSubmitCreate(payload) {
  let fieldsWrite = [...payload.fields]
  pendingNewBudgetYear = parseInt(fieldsWrite[0].modelValue)
  addBudgetYears(urls.getURL('marketing_spend_management', 'add_years'), { fields: fieldsWrite })
}

//---------------------------------------------------------------------------------------

async function refreshQueryCacheBudgetSpend() {
  reportCore.setLoadHeavy(true,"Marketing Data","build cache")
  var data = {}
  data.years = []
  data.years.push(parseInt( yearSelectionCurrent.value.year))
  
  let result
  try {
    result = await requests.post({
      url: urls.getURL('marketing_spend_management', 'refreshCache'),
      data
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    if (loadDataValidate()) {
      //the data was updated on the backend and spend data is on demand , so have to clear current spend data ,
      //and close/unexpand all leadgroups , so user need to expand again to access new data
      leadGroupSpendData.value = []
      leadGroupData.value.forEach((element) => (element.expanded = false))
      reportCore.setLoadHeavy(false)
      await loadSpendCacheInfo()
    }

    toasts.setSuccessMessage(result.data.message)
  }
}

async function addBudgetYears(url, data) {
  reportCore.setLoadHeavy(true,"Marketing Year","create")
  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    if (loadDataValidate()) {
      await loadBudgetYears(urls.getURL('marketing_spend_management', 'find_years'))
      await tryLoadDefaultYear()
    }

    toasts.setSuccessMessage(result.data.message)
  }
}

//---------------------------------------------------------------------------------------

const createBudgetYearFieldSet = ref()

function setupCreateBudgetYears() {
  let fieldSet = {}
  fieldSet.key = 'budgetyears'
  createFieldSetYears(fieldSet, null, 'create', null)
  createBudgetYearFieldSet.value = fieldSet
}

//---------------------------------------------------------------------------------------

async function loadBudgetYears(url, data) {
  reportCore.setLoadHeavy(true,"Marketing Years")
  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    processBudgetYears(result.data)
    reportCore.setLoadHeavy(false)
  }
}

//---------------------------------------------------------------------------------------

function processBudgetYears(data) {
  optionsBudgetYears.value = data
}

//---------------------------------------------------------------------------------------

async function tryLoadDefaultYear() {
  // console.log('tryLoadDefaultYear')
  if (optionsBudgetYears.value) {
    let yeartouse = pendingNewBudgetYear || optionsBudgetYears.value[0]?.year
    pendingNewBudgetYear = null
    let yearObject = optionsBudgetYears.value.find((element) => element.year === yeartouse)
    if (yearObject) {
      onYearChange({ year: yearObject.year })
      yearSelectionCurrent.value = yearObject
      // console.log(yearSelectionCurrent.value)
    }
  }
}

//---------------------------------------------------------------------------------------

async function loadLeadGroups(url, data) {
  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    return
  }
  if (result) {
    // console.log(result)
    processLeadGroups(result.data)
  }
}

//---------------------------------------------------------------------------------------

function processLeadGroups(data) {
  //leadGroupData
  let a = []
  for (let i = 0; i < data.length; i++) {
    const element = data[i]
    var ob = {}
    ob.group_id = element.id
    ob.name = element.name
    ob.expanded = false
    a.push(ob)
  }
  leadGroupData.value = a
  //leadGroupOverrideData.value = data.overrides
  //invalidateOverrides();
}

/*function invalidateOverrides() {
  leadGroupData.value.forEach((element) => (element.override = leadGroupOverrideData.value.find(overrideValue => {

    return overrideValue.year === yearCurrent && overrideValue.group_id === element.group_id
  })))
}*/

//---------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------

async function loadSpendCacheInfo() {
  reportCore.setLoadHeavy(true,"Cache Info")
  var formData = {year:reportCore.currentDate.year}
  let result
  try {
    result = await requests.post({
      url: urls.getURL('marketing_spend_management', 'refreshCacheInfo'),
      data: formData
    })
  } catch (error) {
    reportCore.setLoadHeavy(false)
    console.log(error)
    return
  }
  if (result) {
    queryCacheDateBudgetSpend.value = SimpleDate.getDateTimeStringFromJSDateObject(
      new Date(result.data.queryCacheDate)
    )
    reportCore.setLoadHeavy(false)
  }
}

onMounted(() => {
  init()
})

//---------------------------------------------------------------------------------------

onUnmounted(() => {
  pendingNewBudgetYear = null
})

//---------------------------------------------------------------------------------------

async function init() {
  
  setupCreateBudgetYears()
  if (loadDataValidate()) {
    await loadBudgetYears(urls.getURL('marketing_spend_management', 'find_years'))
    await loadLeadGroups(urls.getURL('marketing_spend_management', 'findLeadGroups'))
    await loadSpendCacheInfo()
  }
  await tryLoadDefaultYear()
 
}

//---------------------------------------------------------------------------------------

function loadDataValidate() {
  if (route.path !== '/application/marketing_spend_management') {
    return false
  }

  return true
}
</script>

<style>
.budgets .form-label {
  margin-bottom: 0.1rem !important;
  font-size: 0.75rem !important;
}

.budgets .form-control {
  border: 0;
  border-radius: 0 !important;
  font-weight: 600;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: var(--content-bg-11);
  border-color: var(--icon-color-7);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: var(--content-bg-11);
}

.p-tabview .p-tabview-nav {
  background: transparent !important;
}

.p-tabview {
  background: transparent !important;
}

.p-tabview .p-tabview-panels {
  background: var(--content-bg-11);
  padding: 0.4rem !important;
}

.p-tabview-title {
  color: var(--text-color-6) !important;
  font-weight: 700 !important;
  font-size: 0.96rem;
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}
</style>
