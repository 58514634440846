import { useReportCore } from '@/stores/ReportCore.js'

export function useScoreCardBudgetPredictiveIndexSummed(title, reportBudget,rollingKey) {
    const reportCore = useReportCore()

    let data = {}
    data.title = title 


    data.value = reportBudget[`predictiveIndexSummed${rollingKey}`] 

    if (isNaN(data.value) || !data.value || !isFinite(data.value)) {
        data.value = 0
    }
    if (isNaN(data.valueSub1) || !data.valueSub1 || !isFinite(data.valueSub1)) {
        data.valueSub1 = 0
    }
    if (isNaN(data.valueSub2) || !data.valueSub2 || !isFinite(data.valueSub2)) {
        data.valueSub2 = 0
    }

    data.valueFormat = 'USD'
    data.valueSub1Format = 'INT'
    data.valueSub2Format = 'INT'
    data.valueSub1Valid = false
    data.valueSub2Valid = false
    data.metricSub1Valid = false
    data.metricSub2Valid = false

   
    

    data.metricSub1Threshold = 0
    data.metricSub2Threshold = 0

   

    return data
}
