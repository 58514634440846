<template>
  <div class="mx-2">
    <ItemContainerLeftRightSlotsComponent>
      <template #left>
        <div :class="getClassText">{{ componentData.display.label }}</div>
      </template>
      <template #right>
        <span
          v-if="getHasIcon"
          :class="getClassIcon"
        ></span>
         <div
          v-if="getHasThumb"
          :style="getThumbStyle"
          :class="getClassThumb"
        ></div>
      </template>
    </ItemContainerLeftRightSlotsComponent>
  </div>
</template>

<script setup>
import ItemContainerLeftRightSlotsComponent from "@/components/layout/ItemContainerLeftRightSlotsComponent.vue";
import { computed } from "vue";

const props = defineProps({
  componentData: {
    type: Object,
    default: () => {
      return null;
    },
  },
  styling: {
    type: Object,
    default: () => {
      return null;
    },
  },
  
});
let getThumbStyle = computed(() => { 
  return "background-image:url('"+window.location_url+props.componentData.display.thumb +"')";
});
let getClassText = computed(() => {
  return props.componentData.enabled
    ? props.styling?.classStringTextSelected ?? props.componentData.styling.classStringTextSelected
    : props.styling?.classStringTextDefault ?? props.componentData.styling.classStringTextDefault;
});

    let getClassIcon = computed(() => {
  return props.componentData.enabled
    ? props.styling?.classStringIconSelected ?? props.componentData.styling.classStringIconSelected
    : props.styling?.classStringIconDefault ?? props.componentData.styling.classStringIconDefault;
});

 let getClassThumb = computed(() => {
  return props.componentData.enabled
    ? props.styling?.classStringThumbSelected ?? props.componentData.styling.classStringThumbSelected
    : props.styling?.classStringThumbDefault ?? props.componentData.styling.classStringThumbDefault;
});
let getHasThumb = computed(() => {
  return props.styling?.classStringThumbDefault ??  props.componentData.styling.classStringThumbDefault;
});
let getHasIcon = computed(() => {
  return props.styling?.classStringIconDefault ??  props.componentData.styling.classStringIconDefault
});
</script>

