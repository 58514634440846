//this class serves the purpose of dealing with timezones better
//1. you do want timezone representation in the front end
//2. you dont want time zone calculations when testing dates from front end to backend 
//3 dates stored in the backend are strings and the timezone info can be thrown away
// avoid creating actual dates with backend data , and store year , month , day numeric values
// 

class SimpleDate{

    //constructor creates a date object and gets year month and day as separate values
    // these functions will be timezone specific and that is how it should be to represent the date
    //properly in a users timezone
    constructor(year,month,day){

      
        let date = null;
        if(!isNaN(year) && !isNaN(month) && !isNaN(day)){
            date =new Date(year,month,day);
        }else{
            date = new Date();
        }
        
       
        this.year = date.getFullYear();
        this.month = date.getMonth();//month is zero based
        this.day = date.getDate();
        this.dateAsTimeNumber = date.getTime();

    }

    //this function will be used to compare instances of this class with comparison operators
    //since both dates from the front end and backend use this class ,
    //and year month and day values are avoiding timezones, both will get compared here in UTC timestamps

    valueOf() {
        return this.dateAsTimeNumber;
    }

    toString() {
        return this.getDateString();
    }

    getDateString(){

       //month and day need zero padding , month need to be incremented as date strings and not zero based
        const month = String(this.month + 1).padStart(2, '0')
        const day = String(this.day).padStart(2, '0')
        return `${this.year}-${month}-${day}`
    }

    getDateTimeString(){
        let ds = this.getDateString();
        return `${ds} 00:00:00`
    }

    static getDateTimeStringFromJSDateObject(date) {
        // Helper function to format numbers with leading zero
        const padWithZero = (number) => String(number).padStart(2, '0')
  
        // Extract date components
        const year = date.getUTCFullYear()
        const month = padWithZero(date.getMonth() + 1)
        const day = padWithZero(date.getDate())
  
        // Extract time components
        const hours = padWithZero(date.getHours())
        const minutes = padWithZero(date.getMinutes())
        const seconds = padWithZero(date.getSeconds())
  
        // Format date and time
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      }

    getDateTimeUTCString(){
        let ds = this.getDateString();
        return `${ds}T00:00:00.000Z`
    }

    getValuesFromDateString(dateString){

        dateString = dateString.slice(0, 10)
        let ds = dateString.split('-')
        this.year = parseInt(ds[0]);
        this.month = parseInt(ds[1])-1; // dateStrings are not zero based , so need to be adjusted
        this.day = parseInt(ds[2]);
        let  date =new Date(this.year,this.month,this.day);
        this.dateAsTimeNumber = date.getTime();

    }

    // getting year month day from an actual js date , again separate , year, month and day and ignore timezone
    getValuesFromDate(date){
        date = new Date(date)
        this.year = date.getFullYear();
        this.month = date.getMonth();
        this.day = date.getDate();
        this.dateAsTimeNumber = date.getTime();
       

    }
    getValuesFromYearMonthDay(year,month,day){
        //createing a date object and getting values from it because it handles overflows/underflows in the month and day values
        let date = new Date(year,month,day);
        this.year = date.getFullYear();
        this.month = date.getMonth();
        this.day = date.getDate();
        this.dateAsTimeNumber = date.getTime();
    }
    getValuesFromCurrentDate(){
        let date = new Date();
        this.year = date.getFullYear();
        this.month = date.getMonth();
        this.day = date.getDate();
        this.dateAsTimeNumber = date.getTime();
    }

    setDate(dateDay){
        let date = new Date(this.year, this.month,this.day )
        date.setDate(dateDay)
        this.year = date.getFullYear();
        this.month = date.getMonth();
        this.day = date.getDate();
        this.dateAsTimeNumber = date.getTime();
    }

    clone(){
       
        return new SimpleDate(this.year, this.month,this.day );
    }

    getNativeDate(){
        return new Date(this.year,this.month,this.day);
    }

    getDayOfWeek(){
        return new Date(this.year, this.month,this.day ).getDay()
    }

    incrementOneDay(){
        let date = new Date(this.year, this.month,this.day+1 )
        this.year = date.getFullYear();
        this.month = date.getMonth();
        this.day = date.getDate();
        this.dateAsTimeNumber = date.getTime();
    }

    decrementOneDay(){
        let date = new Date(this.year, this.month,this.day-1 )
        this.year = date.getFullYear();
        this.month = date.getMonth();
        this.day = date.getDate();
        this.dateAsTimeNumber = date.getTime();
    }

    adjustDateToPreviousMonthEnd() {
        this.getValuesFromDate(new Date(this.year, this.month,0 ))
       
      }

      isLastDayOfMonth(){
        let monthEnd = this.clone()
        monthEnd.getValuesFromYearMonthDay(monthEnd.year,monthEnd.month+1,0)
        if(this.day === monthEnd.day){
            return true
        }else{
            return false
        }

      }

      invalidate(){
        let date = new Date(this.year, this.month,this.day )
        this.year = date.getFullYear();
        this.month = date.getMonth();
        this.day = date.getDate();
        this.dateAsTimeNumber = date.getTime();
      }

      adjustDateToEndOrStartViaThreshold(date) {
        const threshold = 4 // Number of days threshold
  
        let monthEnd = this.clone()
        monthEnd.getValuesFromYearMonthDay(monthEnd.year,monthEnd.month+1,0)
  
        // Check if the date is within 3 days of the end of the month , if so move to the first of next month
        if (this.day > monthEnd - threshold) {
            this.setDate(1)
            this.getValuesFromYearMonthDay(this.year,this.month+ 1,1)
        }
        // Check if the date is within 3 days from the start of the month, if so make it the first
        else if (this.day <= threshold) {
            this.setDate(1)
        }
  
       
      }


}

// Overriding comparison operators for SimpleDate instances
SimpleDate.prototype[Symbol.toPrimitive] = function(hint) {
    if (hint === 'number') {
        return this.valueOf();
    }
    if (hint === 'string') {
        return this.toString();
    }
}

export default SimpleDate;