<template>
  <div ref="domElement" class="w-100 mx-auto" :class="applicationStore.getPadding">
    <div class="content-bg-11" :class="{
    'pa-4': !applicationStore.grid.tabletAndUnder,
    'elevation-2': !applicationStore.grid.tabletAndUnder,
    'rounded-2': !applicationStore.grid.tabletAndUnder
  }">

      <div class="pa-4 pt-1 fs-3 text-color-6 text-align-left fw-bold">Benchmarks Lead Groups</div>
      <ContentExpansionHeader
        v-if="kpiBenchmarkLeadGroupManagement.auth.hasPermission('create_benchmark_lead_group_management_year')"
        @click="expandSectionCreate = !expandSectionCreate" label="Create Benchmark Year"
        :expanded="expandSectionCreate" />



        <TabView  v-if="expandSectionCreate && kpiBenchmarkLeadGroupManagement.auth.hasPermission('create_benchmark_lead_group_management_year')" v-model:activeIndex="tabIndexCreateActive">
          <TabPanel header="new">

            <div class="pa-4 mb-8 content-bg-12"
       >
        <div class="elevation-4 pa-4 content-bg-11 rounded-2 w-100">
          <DataEditGenericComponent @submitemit="kpiBenchmarkLeadGroupManagement.createBenchmarkYear" :useSubmit="true"
            :payload="kpiBenchmarkLeadGroupManagement.createBenchmarkYearFieldSet" />
        </div>
      </div>

            </TabPanel>

            <TabPanel header="clone">


              <div class="pa-4 mb-8 content-bg-12"
        >
        <div class="elevation-4 pa-4 content-bg-11 rounded-2 w-100">
          <DataEditGenericComponent @submitemit="kpiBenchmarkLeadGroupManagement.createCloneBenchmarkYear" :useSubmit="true"
            :payload="kpiBenchmarkLeadGroupManagement.createCloneBenchmarkYearFieldSet" />
        </div>
      </div>

</TabPanel>
          </TabView>    


     

      <ContentExpansionHeader @click="expandSectionListing = !expandSectionListing" label="Benchmark Edit"
        :expanded="expandSectionListing" />

      <div class="budgets pa-4 mb-8 content-bg-12" v-if="expandSectionListing">
        <div class="d-flex align-items-center gap-3 flex-wrap justify-content-left mb-3">
          <SelectButton @update:modelValue="kpiBenchmarkLeadGroupManagement.onYearChange"
            v-model="kpiBenchmarkLeadGroupManagement.yearSelectionCurrent"
            :options="kpiBenchmarkLeadGroupManagement.optionsBenchmarkYearsComputed" optionLabel="year"
            :allowEmpty="false" />

         

        </div>


        <div v-if="kpiBenchmarkLeadGroupManagement.auth.hasPermission('edit_benchmark_lead_group_management_lock') && kpiBenchmarkLeadGroupManagement.yearCurrent" class="pa-4" style="background-color: var(--content-bg-13)">
          <div
           
            class="flex-ui-1" style="align-items: center">
            <Checkbox @change="kpiBenchmarkLeadGroupManagement.updateYearLock"
              v-model="kpiBenchmarkLeadGroupManagement.yearSelectionCurrent.locked" inputId="locked" :binary="true" />
            <label for="locked" class="fs-8 icon-color-1 text-type-10 ml-2">
              lock year
            </label>
          </div>

        </div>








        <div v-for="benchmark in kpiBenchmarkLeadGroupManagement.benchmarkData" style="position: relative"
          :key="'bench_mark' + benchmark.name" class="text-align-left content-border-b-1" :class="{
    'ui-bg-4': !benchmark.expanded,
    'mb-1': !benchmark.expanded,
    'my-4': benchmark.expanded
  }">
          <div class="pa-2" :class="{ 'content-bg-5-inverted': benchmark.expanded }" style="position: sticky; top: 0;z-index: 50;">
            <div class="d-flex align-items-center fs-6 text-align-left fw-bold m-0 text-color-1"
              @click="kpiBenchmarkLeadGroupManagement.handleExpand(benchmark, $event)" style="cursor: pointer">
              <div class="m-0 fw-bold" :class="{
    'fs-4': benchmark.expanded,
    'fs-6': !benchmark.expanded,
    'text-color-2': !benchmark.expanded,
    'text-color-2-inverted': benchmark.expanded
  }">
                {{ benchmark.name }}
              </div>
              <span class="ms-auto mdi icon text-color-1" :class="{
    ' mdi-plus-circle': !benchmark.expanded,
    ' mdi-minus-circle': benchmark.expanded,
    'text-color-2-inverted': benchmark.expanded
  }">
              </span>
            </div>

            <div class="d-flex align-items-center gap-2 mt-2 p-1" v-if="benchmark.expanded">

              


              <div class="ms-auto flex-ui-1" style="align-items: center">
                    
                    <label :for="'benchmarkweight' + benchmark.name"  class="fs-8 text-color-2-inverted mr-2 text-type-10">
                      weight
                    </label>

                    <b-form-input style="width:100px;color:#fff !important" :id="'benchmarkweight' + benchmark.name" v-model="benchmark.weight"
  class="fw-regular text-color-2-inverted" @focus="kpiBenchmarkLeadGroupManagement.cacheInputBenchmarkWeightValue(benchmark)"
  @blur="kpiBenchmarkLeadGroupManagement.validateInputBenchmarkWeightValue(benchmark)" @keydown.enter="kpiBenchmarkLeadGroupManagement.validateInputBenchmarkWeightValue(benchmark)"
  trim :disabled="!kpiBenchmarkLeadGroupManagement.manageBenchmarkData"></b-form-input>
                  </div>           

            </div>


          </div>
          <div v-if="benchmark.expanded" class="">
            <div v-for="record in benchmark.leadGroups" :key="'budgetallocation_' + record.group_id"
              class="py-2 text-align-left content-border-b-1 fs-6 fw-bold text-color-1 px-2 ui-bg-4">
              <div class="w-100 d-flex flex-wrap align-items-center gap-3">
                <div class="icon-color-7" style="min-width: 200px">
                  {{ record.LeadGroup.name }}
                </div>
                <div style="width:100px;">

                  <b-form-input :id="'benchmark' + record.group_id" v-model="record.value"
                    class="fw-regular" @focus="kpiBenchmarkLeadGroupManagement.cacheInputBenchmarkValue(record)"
                    @blur="kpiBenchmarkLeadGroupManagement.validateInputBenchmarkValue(record)" @keydown.enter="kpiBenchmarkLeadGroupManagement.validateInputBenchmarkValue(record)"
                    trim :disabled="!kpiBenchmarkLeadGroupManagement.manageBenchmarkData"></b-form-input>
                </div>

                <div class="flex-ui-1" style="align-items: center">
                    <Checkbox v-model="record.active" @change="kpiBenchmarkLeadGroupManagement.updateBenchmarkItem(record)"
                      inputId="benchmarkactive" :binary="true" :disabled="!kpiBenchmarkLeadGroupManagement.manageBenchmarkData" />
                    <label for="benchmarkactive" class="fs-8 text-color-1 text-type-10 ml-2">
                      active
                    </label>
                  </div>


              </div>
            </div>
          </div>
        </div>






      </div>

    </div>
  </div>

</template>
<script setup>
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import { useKPIBenchmarkLeadGroupManagement } from '@/stores/KPIBenchmarkLeadGroupManagement.js'
import { onMounted, onUnmounted, ref, computed } from 'vue'
import ContentExpansionHeader from '@/components/ui/ContentExpansionHeader.vue'
import DataEditGenericComponent from '@/components/ui/DataEditGenericComponent.vue'
const applicationStore = useApplicationStore()
const kpiBenchmarkLeadGroupManagement = useKPIBenchmarkLeadGroupManagement()
const expandSectionCreate = ref(false)
const expandSectionListing = ref(true)
const tabIndexCreateActive = ref(0)

onMounted(() => {
  kpiBenchmarkLeadGroupManagement.pageIsActive = true;
  kpiBenchmarkLeadGroupManagement.init()
})

onUnmounted(() => {
  kpiBenchmarkLeadGroupManagement.pageIsActive = false;
})

//---------------------------------------------------------------------------------------






</script>
