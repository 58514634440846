import { defineStore } from 'pinia'
import { useUIBuilder } from '@/scripts/ui/UIBuilder'
import { useGlobalInjects } from '@/stores/GlobalInjects.js'
import { useSideNavUI } from '@/scripts/ui/builds/SideNavUI'
import { useUIRadioGroup } from '@/scripts/ui/UIRadioGroup'
import { useGrid } from 'vue-screen'
import { markRaw } from 'vue';
export const useApplicationStore = defineStore('ApplicationStore', {
  state: () => {
    return {
      dataObjects: {},
      uiObjects: {},
      isNavDisplaying: true,
      route:null,
      router:null,     
      
      grid: {},
      domElements:markRaw({}),     
      contentDomElement: null,
      displayScollToTopButton: false,
      currentRouteUIPayload: null
    }
  },

  getters: {

    getPadding(state) {
      return {
        'px-6': !state.grid.tabletAndUnder,
        'px-4': state.grid.tabletAndUnder,
        'py-4': !state.grid.tabletAndUnder,
        'py-2': state.grid.tabletAndUnder
      }
    },

  },

  actions: {
  
    blurContent(state){
      if(!this.domElements.content)return
      if(state){
        this.domElements.content.style.filter = 'blur(4px)';
      }else{
        this.domElements.content.style.filter = 'none';
      }
    },
    scollToTop() {
      if (!this.contentDomElement) return
      this.contentDomElement.scrollTo({
        top: 0
      })
    },
    invalidateGrid() {
      let newGrid = useGrid('bootstrap')
      newGrid.tabletAndUnder =
        newGrid.breakpoint === 'xs' || newGrid.breakpoint === 'sm' || newGrid.breakpoint === 'md'
      newGrid.smaller = newGrid.breakpoint === 'xs' || newGrid.breakpoint === 'sm'
      this.grid = newGrid
    },
   

    //---------------------------------------------------------------------------------------------

    getUIData(key) {
      let item = this.uiObjects.componentData[key]
      if (!item) {
        console.log('getUIData failed for key: ', key)
      }
      return item
    },

    //---------------------------------------------------------------------------------------------
    //ok pretty nasty to have to do this but since it is possible for a user to manually type in a url , in a valid logged in state
    // the app will reach the route but then the UI would not be in sync , so need to find the relevant ui item matching the path and make it active
    // or deactivate it if no match etc..
    updateUIStates() {
      

      
      let path = useGlobalInjects().route.path
     
      let UIItemProcessed = false
      for (let UIItemKey in this.uiObjects.componentData) {
        let uiItem = this.uiObjects.componentData[UIItemKey]
        if (uiItem.interaction?.action?.path === path) {
          this.currentRouteUIPayload = uiItem
          uiItem.enabled = true
          useUIRadioGroup(uiItem)
          UIItemProcessed = true
          break
        }
      }
      if (!UIItemProcessed) {
        if (this.currentRouteUIPayload) {
          this.currentRouteUIPayload.enabled = false
          let group = this.currentRouteUIPayload?.interaction?.radioGroup
          if (group) {
            group.currentSelection = null
          }
          this.currentRouteUIPayload = null
        }
      }
    },

    //---------------------------------------------------------------------------------------------

    processConfigurationUpdate(payload) {
      //console.log(payload)
      useUIRadioGroup(payload)
      if (payload.interaction.action) {
        this.currentRouteUIPayload = payload
        useGlobalInjects().router.push(payload.interaction.action)
       // if (this.grid.tabletAndUnder) {
          this.isNavDisplaying = false
       // }
      }
    },

    //---------------------------------------------------------------------------------------------

    processNewUI() {
      this.dataObjects = {};
      this.uiObjects = {}
      this.dataObjects.ui = useSideNavUI()
      useUIBuilder()
    }
  }
})
