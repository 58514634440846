
export function useComponentStylingAccordion1() {
   
    const data = {
     
        classStringContainerDefault:"button-type-accordion-1 py-2",
        classStringContainerSelected:"button-type-accordion-1 py-2",
        classStringTextDefault:"text-type-1 text-color-5",
        classStringTextSelected:"text-type-1 text-color-5",
        classStringIconDefault:"mdi icon icon-color-1 mdi-magnify-plus-outline",
        classStringIconSelected:"mdi icon icon-color-1 mdi-magnify-minus-outline",
       
          };

 
  return data;
}