<template>
  <div class="layout-group py-4">
    <div class="text-color-2 text-center h3 display-5 fw-normal m-0">
      Welcome
    </div>
    <div class="text-color-2 text-center h4 display-6 fw-normal m-0">
      register to continue
    </div>
  </div>
  <div class="layout-group">

<b-form-group
      label="Name:"
      label-for="nested-name"
      :invalid-feedback="formfield1Invalid"
      :state="formfield1State"
      class="fs-9 text-align-left text-color-1"
    >
      <b-form-input
      :disabled="requests.loading"
        id="nested-name"
        v-model="formfield1"
        :state="formfield1State"
        trim
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Email:"
      label-for="nested-email"
      :invalid-feedback="formfield2Invalid"
      :state="formfield2State"
      class="fs-9 text-align-left text-color-1"
    >
      <b-form-input
      :disabled="requests.loading"
        id="nested-email"
        v-model="formfield2"
        :state="formfield2State"
        trim
      ></b-form-input>
    </b-form-group>
    
    <b-form-group
      label="Password:"
      label-for="nested-password"
      :invalid-feedback="formfield3Invalid"
      :state="formfield3State"
      class="fs-9 text-align-left text-color-1"
    >
    <b-input-group>
      <template #append>
       <b-button class="btn-no-focus-shadow button-minimal border-0 border-radius-0" variant="light" @click="showpassword = !showpassword">
<span
          class="mdi icon icon-color-2"
          :class="{
            'mdi-eye': !showpassword,
            'mdi-eye-off': showpassword,
          }"
        ></span>

       </b-button>
    </template>
      <b-form-input
      :disabled="requests.loading"
      :type="showpassword?'text':'password'"
        id="nested-password"
        v-model="formfield3"
        :state="formfield3State"
        trim
      ></b-form-input>
       </b-input-group>
    </b-form-group>



     <b-form-group
      label="Confirm Password:"
      label-for="nested-password-confirm"
      :invalid-feedback="formfield4Invalid"
      :state="formfield4State"
      class="fs-9 text-align-left text-color-1"
    >
    <b-input-group>
      <template #append>
       <b-button class="btn-no-focus-shadow button-minimal border-0 border-radius-0" variant="light" @click="showpassword = !showpassword">
<span
          class="mdi icon icon-color-2"
          :class="{
            'mdi-eye': !showpassword,
            'mdi-eye-off': showpassword,
          }"
        ></span>

       </b-button>
    </template>
      <b-form-input
      :disabled="requests.loading"
      :type="showpassword?'text':'password'"
        id="nested-password-confirm"
        v-model="formfield4"
        :state="formfield4State"
        trim
      ></b-form-input>
       </b-input-group>
    </b-form-group>
   
  </div>
  <div class="layout-group mt-5">
    <b-button class="p-1 fs-4 fw-bolder w-100" variant="primary" @click="submit" :disabled="requests.loading"
      >submit</b-button
    >
    
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useURLS } from "@/stores/URLS.js";
import { useAuth } from "@/stores/Auth.js";
import { useRequests } from "@/stores/Requests.js";
import { useRouter} from "vue-router";


const urls = useURLS();
const auth = useAuth();
const requests = useRequests();
const router = useRouter();
const formfield1 = ref("");
const formfield2 = ref("");
const formfield3 = ref("");
const formfield4 = ref("");
const showpassword = ref(false);

var formValidate = ref(false);
var formfield1InvalidMessage = ref("");
var formfield2InvalidMessage = ref("");
var formfield3InvalidMessage = ref("");
var formfield4InvalidMessage = ref("");

function setformfield1InvalidMessage(value) {
  formfield1InvalidMessage.value = value;
}
function setformfield2InvalidMessage(value) {
  formfield2InvalidMessage.value = value;
}
function setformfield3InvalidMessage(value) {
  formfield3InvalidMessage.value = value;
}
function setformfield4InvalidMessage(value) {
  formfield4InvalidMessage.value = value;
}


//------------------------------------------------------------------


const formfield1State = computed(() => {
  if (!formValidate.value) return undefined;
  if (!formfield1.value) {
    setformfield1InvalidMessage("Name is required");

    return false;
  }
 
  setformfield1InvalidMessage("");
  return true;
});

const formfield1Invalid = computed(() => {
  if (!formValidate.value) return undefined;
  return formfield1InvalidMessage.value;
});

//------------------------------------------------------------------

const formfield2State = computed(() => {
  if (!formValidate.value) return undefined;
  if (!formfield2.value) {
    setformfield2InvalidMessage("E-mail is required");

    return false;
  }
  if (!/.+@.+\..+/.test(formfield2.value)) {
    setformfield2InvalidMessage("E-mail must be valid");

    return false;
  }
  setformfield2InvalidMessage("");
  return true;
});

const formfield2Invalid = computed(() => {
  if (!formValidate.value) return undefined;
  return formfield2InvalidMessage.value;
});

//------------------------------------------------------------------

const formfield3State = computed(() => {
  if (!formValidate.value) return undefined;
  if (!formfield3.value) {
    setformfield3InvalidMessage("Password is required");

    return false;
  }
  //DISABLED DURING DEV FOR EASY TO USE PASSWORDS , UNCOMMENT FOR PRODUCTION SO WE DONT SUPPORT WEAK PASSWORDS

  /*if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(formfield3.value)) {
    setformfield3InvalidMessage(
      "Password must contain at least lowercase letter, one number, a special character and one uppercase letter."
    );
    return false;
  }*/
  setformfield3InvalidMessage("");
  return true;
});

const formfield3Invalid = computed(() => {
  if (!formValidate.value) return undefined;
  return formfield3InvalidMessage.value;
});

//------------------------------------------------------------------

const formfield4State = computed(() => {
  if (!formValidate.value) return undefined;
  if (!formfield4.value) {
    setformfield4InvalidMessage("Confirm Password is required");

    return false;
  }

  if (formfield3.value !== formfield4.value) {
    setformfield4InvalidMessage("Confirm Password must match password");

    return false;
  }
  
  setformfield4InvalidMessage("");
  return true;
});

const formfield4Invalid = computed(() => {
  if (!formValidate.value) return undefined;
  return formfield4InvalidMessage.value;
});

//------------------------------------------------------------------

function submit() {
  formValidate.value = false;
  formValidate.value = true;
  if (!formfield1State.value || !formfield2State.value || !formfield3State.value|| !formfield4State.value) {
    return;
  }
  register();
}

function register(email, password) {

     var formData = {};
      formData.name = formfield1.value;
      formData.email = formfield2.value;
      formData.password = formfield3.value;
      formData.password_confirmation = formfield4.value;
  requests
    .post({
      url: urls.auth.register,
      data: formData,
    })
    .then((response) => {
      console.log(response)
      console.log(response.data)
      //call store mutations
      auth.setVerification(response.data)
      auth.setToken(response.data)     
      router.push("verifyEmail");
    

      
    });
}
</script>

<style scoped>
.theme-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 0;
}
.form-label {
  margin-bottom: 0 !important;
}

</style>
