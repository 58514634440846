import { defineStore } from "pinia";


import { useAuth } from "@/stores/Auth.js";
import { useApplicationStore } from "@/stores/ApplicationStore.js";
import { useDataBenchmarkLeadGroupStore } from "@/stores/DataBenchmarkLeadGroupStore.js";
import { useDataKPI1Store } from "@/stores/DataKPI1Store.js";
import { useDataKPI2Store } from "@/stores/DataKPI2Store.js";
import { useDataPresetsTablesStore } from "@/stores/DataPresetsTablesStore.js";
import { useDynamicConfigStore } from "@/stores/DynamicConfigStore.js";
import { useKPIBenchmarkLeadGroupManagement } from "@/stores/KPIBenchmarkLeadGroupManagement.js";
import { useReportBudget } from "@/stores/ReportBudget.js";
import { useReportCore } from "@/stores/ReportCore.js";
import { useReportKPI1 } from "@/stores/ReportKPI1.js";
import { useReportKPI2 } from "@/stores/ReportKPI2.js";
import { useReportKPIBenchmarkLeadGroup } from "@/stores/ReportKPIBenchmarkLeadGroup.js";
import { useReportMarketing } from "@/stores/ReportMarketing.js";



import { markRaw } from "vue";

export const useStoreManagement = defineStore("StoreManagement", {
    state() {
        return {
            stores: markRaw([
                { key: "useAuth", store: markRaw(useAuth()) },
                //{ key: "useApplicationStore", store: markRaw(useApplicationStore()) },
                { key: "useDataBenchmarkLeadGroupStore", store: markRaw(useDataBenchmarkLeadGroupStore()) },
                { key: "useDataKPI1Store", store: markRaw(useDataKPI1Store()) },
                { key: "useDataKPI2Store", store: markRaw(useDataKPI2Store()) },
                
                { key: "useKPIBenchmarkLeadGroupManagement", store: markRaw(useKPIBenchmarkLeadGroupManagement()) },
                { key: "useReportBudget", store: markRaw(useReportBudget()) },
                { key: "useReportCore", store: markRaw(useReportCore()) },
                { key: "useReportKPI1", store: markRaw(useReportKPI1()) },
                { key: "useReportKPI2", store: markRaw(useReportKPI2()) },
                { key: "useReportKPIBenchmarkLeadGroup", store: markRaw(useReportKPIBenchmarkLeadGroup()) },
                { key: "useReportMarketing", store: markRaw(useReportMarketing()) },
                { key: "useDataPresetsTablesStore", store: markRaw(useDataPresetsTablesStore()) },
                { key: "useDynamicConfigStore", store: markRaw(useDynamicConfigStore()) } // this has to go last, or at least after the stores it references and uses
            ])

        };
    },

    actions: {
        //--------------------------------------------
        resetStore(storeKey) {
            let storeItem = this.stores.find(element =>{
                element.key = storeKey
            })
            if (storeItem) {
                storeItem.store.$reset()
            }
        },
        //--------------------------------------------
        resetStoresAll() {
            this.stores.forEach(element =>{
                element.store.$reset()
            })
           
        },
       


    },
});
