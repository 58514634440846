<template>
  <div class="w-100 mx-auto printable" style="max-width: 99%" :class="applicationStore.getPadding">
    <div class="content-bg-11" :class="{
    'pa-4': !applicationStore.grid.tabletAndUnder,
    'elevation-2': !applicationStore.grid.tabletAndUnder,
    'rounded-2': !applicationStore.grid.tabletAndUnder
  }">
      <div class="pa-4 d-flex flex-column" style="background-color: var(--content-bg-13)">
        <div class="d-flex align-items-center" style="cursor: pointer"
          @click="reportBudget.section1Expanded = !reportBudget.section1Expanded">
          <div class="flex-ui-1 text-type-10">
            <div class="fs-7 mr-2 text-type-10 icon-color-1">report:</div>
            <div class="icon-color-1" style="font-weight: 700; font-size: 20px">Budget Report</div>
          </div>

          <span class="ms-auto mdi icon icon-color-1" :class="{
    ' mdi-plus-circle': !reportBudget.section1Expanded,
    ' mdi-minus-circle': reportBudget.section1Expanded
  }">
          </span>
        </div>

        <div v-if="reportBudget.section1Expanded">
          <div class="d-flex align-items-center flex-wrap text-type-10 p-0">
            <div class="icon-color-1">Current Filters Applied:</div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">owner:</div>
              <div style="color: #ffca00">{{ reportBudget.ownerFilter || 'All Owners' }} ,</div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">location:</div>
              <div style="color: #ffca00">
                {{ reportBudget.locationFilter || 'All Locations' }} ,
              </div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">group:</div>
              <div style="color: #ffca00">{{ reportBudget.leadGroupFilter || 'All Groups' }} ,</div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">date:</div>
              <div style="color: #ffca00">
                {{ reportCore.dateRangeStart.getDateString() }}
              </div>
            </div>
            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mx-2 text-type-10 icon-color-1">to</div>
              <div style="color: #ffca00">
                {{ reportCore.dateRangeEnd.getDateString() }}
              </div>
            </div>
          </div>

          <div class="mt-2 flex-ui-1" style="align-items: center">
            <Checkbox @change="onUseDataSourceOverrideChanged" v-model="reportCore.useDataSourceOverride"
              inputId="expandScorecards" :binary="true" />
            <label for="expandScorecards" class="fs-8 text-color-5 text-type-10 ml-2">
              override per user data sources
            </label>
          </div>

          <div v-if="reportCore.useDataSourceOverride">
            <div class="mt-3 text-type-11 icon-color-1 p-0">select data source:</div>
            <div class="mt-1 mb-2 flex-ui-1 gap-3" style="align-items: center">
              <div class="d-flex align-items-center">
                <RadioButton @update:modelValue="onKPIRouteKeyChange" v-model="reportCore.dataSourceCurrent"
                  inputId="datasource1" name="kpi_route_key" :value="reportCore.DATA_SOURCE_CORE" />
                <label for="datasource1" class="fs-8 text-color-5 text-type-10 ml-2">CORE</label>
              </div>
              <div class="d-flex align-items-center">
                <RadioButton @update:modelValue="onKPIRouteKeyChange" v-model="reportCore.dataSourceCurrent"
                  inputId="datasource2" name="kpi_route_key" :value="reportCore.DATA_SOURCE_SM" />
                <label for="datasource2" class="fs-8 text-color-5 text-type-10 ml-2">SM</label>
              </div>
              <div class="d-flex align-items-center">
                <RadioButton @update:modelValue="onKPIRouteKeyChange" v-model="reportCore.dataSourceCurrent"
                  inputId="datasource3" name="kpi_route_key" :value="reportCore.DATA_SOURCE_BLEND" />
                <label for="datasource3" class="fs-8 text-color-5 text-type-10 ml-2">BLEND</label>
              </div>
            </div>

            <div v-if="reportCore.dataSourceCurrent === reportCore.DATA_SOURCE_BLEND"
              class="mt-3 mb-3 d-flex justify-content-start" :class="{
    'flex-column': applicationStore.grid.tabletAndUnder,
    'align-items-start': applicationStore.grid.tabletAndUnder,
    'align-items-center': !applicationStore.grid.tabletAndUnder
  }">
              <p class="text-type-9 text-color-5 p-0 mb-0 mr-2 fs-8">Blend Date</p>
              <Calendar panelClass="min-w-min" v-model="reportCore.dateSelectionBlend"
                @date-select="onDateSelectionBlend" selectionMode="single" :manualInput="false" inputId="range" />
            </div>
          </div>

          <div class="mt-3 flex-ui-1" style="align-items: center">
            <div class="text-type-11 icon-color-1 p-0 mr-2">KPI CORE cached:</div>
            <div class="text-type-11 p-0" style="color: #ffca00">
              {{ dataKPI1Store.queryCacheDateCORE ?? '' }}
            </div>
            <button v-if="auth.hasPermission('invalidate_kpi_1_report_cache')" class="button-minimal"
              @click="toggleQueryCacheExtendedDisplay = !toggleQueryCacheExtendedDisplay">
              <span class="mdi icon icon-color-1 mdi-help-circle"></span>
            </button>
          </div>
          <div class="flex-ui-1" style="align-items: center">
            <div class="text-type-11 icon-color-1 p-0 mr-2">KPI SM cached:</div>
            <div class="text-type-11 p-0" style="color: #ffca00">
              {{ dataKPI1Store.queryCacheDateSM ?? '' }}
            </div>
            <button v-if="auth.hasPermission('invalidate_kpi_1_report_cache')" class="button-minimal"
              @click="toggleQueryCacheExtendedDisplay = !toggleQueryCacheExtendedDisplay">
              <span class="mdi icon icon-color-1 mdi-help-circle"></span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="toggleQueryCacheExtendedDisplay && reportBudget.section1Expanded" class="mt-min pa-4"
        style="background-color: var(--content-bg-13)">
        <div class="text-type-11 icon-color-1 p-0">Queries are cached once a day at midnight</div>
        <div class="mt-1 d-flex flex-wrap align-items-center gap-2">
          <b-button class="p-1 fs-6 fw-bolder px-3" variant="primary"
            @click="reportBudget.refreshQueryCacheKPI($event, 'kpi1')">refresh KPI CORE</b-button>

          <b-button class="p-1 fs-6 fw-bolder px-3" variant="primary"
            @click="reportBudget.refreshQueryCacheKPI($event, 'kpi1SM')">refresh KPI SM</b-button>
        </div>
      </div>

      <div class="mt-min pa-1 py-3 px-3" style="background-color: var(--content-bg-13)">
        <div class="d-flex justify-content-left mb-4">
          <SelectButton @update:modelValue="onYearChange" v-model="reportBudget.yearSelectionCurrent"
            :options="optionsBudgetYearsComputed" optionLabel="year" :allowEmpty="false" />
        </div>

        <div class="mb-4" v-if="auth.hasPermission('read_budget_sale_report_all_owners')">
          <div class="mt-2 text-type-11 icon-color-1 p-0">Owner table filter:</div>
          <div class="mt-1 d-flex gap-3 align-items-center">
            <div class="d-flex align-items-center">
              <RadioButton @update:modelValue="onOwnerTableFilterChange" v-model="reportBudget.ownerTableFilterCurrent"
                inputId="ownerFilterNew" name="owner_table_filter" value="new" />
              <label for="ownerFilterNew" class="fs-8 text-color-5 text-type-10 ml-2">NEW</label>
            </div>
            <div class="d-flex align-items-center">
              <RadioButton @update:modelValue="onOwnerTableFilterChange" v-model="reportBudget.ownerTableFilterCurrent"
                inputId="ownerFilterLegacy" name="owner_table_filter" value="legacy" />
              <label for="ownerFilterLegacy" class="fs-8 text-color-5 text-type-10 ml-2">LEGACY</label>
            </div>
            <div class="d-flex align-items-center">
              <RadioButton @update:modelValue="onOwnerTableFilterChange" v-model="reportBudget.ownerTableFilterCurrent"
                inputId="ownerFilterAll" name="owner_table_filter" value="all" />
              <label for="ownerFilterAll" class="fs-8 text-color-5 text-type-10 ml-2">ALL</label>
            </div>
          </div>
        </div>

        <div>
          <div style="cursor: pointer" @click="() => {
      showMonthSelectSingle = !showMonthSelectSingle
      showMonthSelectMulti = false
    }
    ">
            <div class="mt-2 d-flex align-items-center">
              <span class="me-2 mdi icon icon-color-1" :class="{
    ' mdi-plus-circle': !showMonthSelectSingle,
    ' mdi-minus-circle': showMonthSelectSingle
  }">
              </span>
              <div class="text-type-11 icon-color-1 p-0">Select Month</div>
            </div>
          </div>
          <div v-if="showMonthSelectSingle">
            <div class="d-grid" :style="getGridStyle">
              <div v-for="(month, index) in monthSelectSingleOptions" :key="'monthsingle_' + month"
                class="text-type-12 icon-color-1 p-2 text-center" :class="{
    'nav-item-1-bg': !month.selected,
    'nav-item-1-selected-bg': month.selected
  }" style="cursor: pointer" @click="selectMonthSingle(month, index)">
                {{ month.name }}
              </div>
            </div>
          </div>

          <div style="cursor: pointer" @click="() => {
      showMonthSelectMulti = !showMonthSelectMulti
      showMonthSelectSingle = false
    }
    ">
            <div class="mt-2 d-flex align-items-center">
              <span class="me-2 mdi icon icon-color-1" :class="{
    ' mdi-plus-circle': !showMonthSelectMulti,
    ' mdi-minus-circle': showMonthSelectMulti
  }">
              </span>
              <div class="text-type-11 icon-color-1 p-0" style="min-width: 150px">
                Select Month Range
              </div>
            </div>
          </div>
          <div v-if="showMonthSelectMulti">
            <div class="d-grid" :style="getGridStyle">
              <div v-for="(month, index) in monthSelectMultiOptions" :key="'monthsingle_' + month"
                class="text-type-12 icon-color-1 p-2 text-center" :class="{
    'nav-item-1-bg': !month.selected,
    'nav-item-1-selected-bg': month.selected
  }" style="cursor: pointer" @click="selectMonthMulti(month, index)">
                {{ month.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

<!-- <ReportFiltersComponent @update="reportBudget.buildReport()"/>-->

      <div class="d-flex mt-4 flex-wrap justify-content-around gap-2">
        <div class="d-flex flex-column justify-content-left" :style="getTableContainerStyle">
          <div class="d-flex justify-content-left mb-2">
            <SelectButton v-model="currentTableSelection" :options="options" optionLabel="name" :allowEmpty="false" />
          </div>

          <div class="mb-4 d-flex-inline">
            <ReportTableComponent configKey="reportBudgetTableOwner" :vShowKey="currentTableSelection?.name"/>
            <ReportTableComponent configKey="reportBudgetTableLeadGroup" :vShowKey="currentTableSelection?.name"/>

          </div>
        </div>
        <div v-if="!applicationStore.grid.smaller" class="d-flex flex-column justify-content-left"
          :style="getTableContainerStyle">
          <div class="d-flex justify-content-left mb-2">
            <SelectButton v-model="currentTableSelection2" :options="options" optionLabel="name" :allowEmpty="false" />
          </div>

          <div class="mb-4 d-flex-inline">
            <ReportTableComponent configKey="reportBudgetTableOwner" :vShowKey="currentTableSelection2?.name"/>
            <ReportTableComponent configKey="reportBudgetTableLeadGroup" :vShowKey="currentTableSelection2?.name"/>

          </div>
        </div>
      </div>

      <div class="pa-4 mb-2 d-flex flex-column content-bg-10">
        <div class="d-flex flex-wrap fs-8 fw-bold p-0"
         :class="{ 'align-items-center': !applicationStore.grid.tabletAndUnder, 'align-items-start': applicationStore.grid.tabletAndUnder, 'flex-column': applicationStore.grid.tabletAndUnder }"
       
          v-if="auth.hasPermission('view_budget_report_predictive_index') && isSingleMonth">
          <div class="text-color-1 fs-6">Predictive Index Ranges:</div>

          <div class="flex-ui-1 ml-2">
            <div class="fs-7 mr-2 text-type-10 text-color-1">Rolling 22 days:</div>
            <div style="color: #ff6600">
              {{ reportCore.rolling22Start.getDateString() }}
            </div>
            <div class="fs-7 mx-2 text-type-10 text-color-1">to</div>
            <div style="color: #ff6600">
              {{ reportCore.rolling22End.getDateString() }}
            </div>
          </div>

          <div class="flex-ui-1 ml-2">
            <div class="fs-7 mr-2 text-type-10 text-color-1">Rolling 33 days:</div>
            <div style="color: #ff6600">
              {{ reportCore.rolling30Start.getDateString() }}
            </div>
            <div class="fs-7 mx-2 text-type-10 text-color-1">to</div>
            <div style="color: #ff6600">
              {{ reportCore.rolling30End.getDateString() }}
            </div>
          </div>

          <div class="flex-ui-1 ml-2">
            <div class="fs-7 mr-2 text-type-10 text-color-1">Rolling 63 days:</div>
            <div style="color: #ff6600">
              {{ reportCore.rolling60Start.getDateString() }}
            </div>
            <div class="fs-7 mx-2 text-type-10 text-color-1">to</div>
            <div style="color: #ff6600">
              {{ reportCore.rolling60End.getDateString() }}
            </div>
          </div>
        </div>

        <div class="mt-2 flex-ui-1" style="align-items: center">
          <Checkbox v-model="reportCore.expandScorecards" inputId="expandScorecards" :binary="true" />
          <label for="expandScorecards" class="fs-8 text-color-1 text-type-10 ml-2">
            Expand Scorecards
          </label>
        </div>
      </div>

      <div class="w-100" style="
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          grid-gap: 0.5rem 0.5rem;
        ">
        <ScoreCardComponent v-if="noTableFiltering" style="flex: 0 0 0"
          :componentData="reportBudget?.scoreCards.sales_legacy" :labels="reportBudget.scorecardLabelsSales">
        </ScoreCardComponent>
        <ScoreCardComponent v-if="noTableFiltering" style="flex: 0 0 0"
          :componentData="reportBudget?.scoreCards.sales_new" :labels="reportBudget.scorecardLabelsSales">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportBudget?.scoreCards.sales_all"
          :labels="reportBudget.scorecardLabelsSales">
        </ScoreCardComponent>
        <ScoreCardComponent v-if="noTableFiltering" style="flex: 0 0 0"
          :componentData="reportBudget?.scoreCards.variance_legacy" :labels="reportBudget.scorecardLabelsVariance">
        </ScoreCardComponent>
        <ScoreCardComponent v-if="noTableFiltering" style="flex: 0 0 0"
          :componentData="reportBudget?.scoreCards.variance_new" :labels="reportBudget.scorecardLabelsVariance">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportBudget?.scoreCards.variance_all"
          :labels="reportBudget.scorecardLabelsVariance">
        </ScoreCardComponent>
        <ScoreCardComponent v-if="noTableFiltering" style="flex: 0 0 0"
          :componentData="reportBudget?.scoreCards.owners_over" :labels="reportBudget.scorecardLabelsOwners">
        </ScoreCardComponent>
        <ScoreCardComponent v-if="noTableFiltering" style="flex: 0 0 0"
          :componentData="reportBudget?.scoreCards.owners_under" :labels="reportBudget.scorecardLabelsOwners">
        </ScoreCardComponent>
        <ScoreCardComponent v-if="auth.hasPermission('view_budget_report_predictive_index') && isSingleMonth"
          style="flex: 0 0 0" :componentData="reportBudget?.scoreCards.predictive_index_30"
          :labels="reportBudget.scorecardLabelsPredictiveIndex">
        </ScoreCardComponent>

        
        <ScoreCardComponent v-if="auth.hasPermission('view_budget_report_predictive_index') && isSingleMonth"
          style="flex: 0 0 0" :componentData="reportBudget?.scoreCards.predictive_index_60"
          :labels="reportBudget.scorecardLabelsPredictiveIndex">
        </ScoreCardComponent>

        
      </div>





      <div  class="elevation-4 pa-4 content-bg-4 rounded-2 w-100 mt-4">
        <div class="mb-2">
          <ItemContainerLeftRightSlotsComponent>
            <template #left>
              <p class="text-type-10 nav-color-1 p-0 m-0 fs-6">
                {{ reportBudget.currentLineChartMetric }}
              </p>
              <div class="ml-2 d-flex align-content-center">
                <div class="d-inline-block" :style="reportBudget.timelineData.datasets[0].lengendBlockStyle"></div>
                <p class="d-inline-block text-type-10 nav-color-1 p-0 m-0 fs-8 ms-1">
                  Sales Amount
                </p>
              </div>

              <div class="ml-2 d-flex align-content-center">
                <div class="d-inline-block" :style="reportBudget.timelineData.datasets[1].lengendBlockStyle"></div>
                <p class="d-inline-block text-type-10 nav-color-1 p-0 m-0 fs-8 ms-1">
                  Predictive Index 30
                </p>
              </div>
              <div class="ml-2 d-flex align-content-center">
                <div class="d-inline-block" :style="reportBudget.timelineData.datasets[2].lengendBlockStyle"></div>
                <p class="d-inline-block text-type-10 nav-color-1 p-0 m-0 fs-8 ms-1">
                  Predictive Index 60
                </p>
              </div>
            </template>
            <template #right>
             
            </template>
          </ItemContainerLeftRightSlotsComponent>
          <div class="mt-2 ml-2 flex-ui-1" style="align-items: center">
          <Checkbox v-model="reportBudget.showFocusedChartData" @update:modelValue="reportBudget.updateChartFocusMode"  inputId="showfocuseddata" :binary="true" />
          <label for="showdebugdata" class="fs-8 nav-color-1 text-type-10 ml-2">
            Show Focused Data
          </label>
        </div>
        </div>

        <Chart :key="reportBudget.chartKey" type="line" :data="reportBudget.timelineData" :options="reportBudget.chartOptions" :height="260"  />
      </div>



      <div v-if="auth.hasPermission('view_budget_sale_report_debug')" class="pa-4 mt-2"
        style="background-color: var(--content-bg-13)">
        <div class="flex-ui-1" style="align-items: center">
          <Checkbox v-model="reportBudget.showDebugData" inputId="showdebugdata" :binary="true" />
          <label for="showdebugdata" class="fs-8 icon-color-1 text-type-10 ml-2">
            Show Debug Data
          </label>
        </div>
      </div>

      <div v-if="reportBudget.showDebugData && auth.hasPermission('view_budget_sale_report_debug')
    " class="mt-4">
        <div v-if="dataKPI1Store.unmappedKPIOwnersArrayCORE?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped owners CORE</div>
          <ListOfGrid :listData="dataKPI1Store.unmappedKPIOwnersArrayCORE" classDynamic="data-table-4"></ListOfGrid>
        </div>

        <div v-if="dataKPI1Store.unmappedKPIOwnersArraySM?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped owners SM</div>
          <ListOfGrid :listData="dataKPI1Store.unmappedKPIOwnersArraySM" classDynamic="data-table-4"></ListOfGrid>
        </div>

        <div v-if="dataKPI1Store.unmappedKPILeadGroupsArrayCORE?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped lead groups CORE</div>
          <ListOfGrid :listData="dataKPI1Store.unmappedKPILeadGroupsArrayCORE" classDynamic="data-table-4"></ListOfGrid>
        </div>

        <div v-if="dataKPI1Store.unmappedKPILeadGroupsArraySM?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped lead groups SM</div>
          <ListOfGrid :listData="dataKPI1Store.unmappedKPILeadGroupsArraySM" classDynamic="data-table-4"></ListOfGrid>
        </div>

        <div v-if="reportBudget.ownersExcludedByStartDateArray?.length">
          <div class="mt-2 fs-6 text-color-2 fw-bold">owners excluded by start bate</div>
          <ListOfGrid :listData="reportBudget.ownersExcludedByStartDateArray" classDynamic="data-table-4"></ListOfGrid>
        </div>

        <div v-if="reportBudget.ownersExcludedByStartDateButValidArray?.length">
          <div class="mt-2 fs-6 text-color-2 fw-bold">owners excluded by start bate but valid</div>
          <ListOfGrid :listData="reportBudget.ownersExcludedByStartDateButValidArray" classDynamic="data-table-4">
          </ListOfGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useReportCore } from '@/stores/ReportCore.js'
import { useDataKPI1Store } from '@/stores/DataKPI1Store.js'
import { useReportBudget } from '@/stores/ReportBudget.js'
import { onMounted, ref, computed, reactive } from 'vue'
import { useAuth } from '@/stores/Auth.js'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import ItemContainerLeftRightSlotsComponent from '@/components/layout/ItemContainerLeftRightSlotsComponent.vue'
import ListOfGrid from '@/components/ui/ListOfGrid.vue'
import ScoreCardComponent from '@/components/charts/ScoreCardComponent.vue'
import ReportTableComponent from '@/components/ui/ReportTableComponent.vue'
import ReportFiltersComponent from '@/components/ui/ReportFiltersComponent.vue'
const reportBudget = useReportBudget()
const reportCore = useReportCore()
const dataKPI1Store = useDataKPI1Store()
const auth = useAuth()
const routeKeyCurrent = ref('kpi1')
const dateSelectionMonth = ref()
const invalidateRangeCalenderKey = ref(0)
const toggleQueryCacheExtendedDisplay = ref(false)

const showMonthSelectSingle = ref(true)
const showMonthSelectMulti = ref(false)
//const chartOptions = reactive({value:null})
const monthSelectedSingleItem = ref(null)
var monthSelectedMultiStartItem = null
var monthSelectedMultiEndItem = null

var selectMonthSinglePending = false
var selectMonthSingleFirstIndex = null

//----------------------------------------------------------------------

function onUpdateTimelineMetric(event) {
  reportBudget.changeTimelineMetric(event)
}


function getFilteredTableColumnsMetrics() {
  if (!auth.hasPermission('view_budget_report_predictive_index')) {
    return reportBudget.tableColumnsMetrics.filter((element) => {
      return element !== 'Predictive Index 30' && element !== 'Predictive Index 60'
    })
  }
  return reportBudget.tableColumnsMetrics
}

const getGridStyle = computed(() => {
  let val = 'grid-template-columns: repeat(auto-fit, minmax(60px, 1fr)); gap: 0.2rem'
  if (applicationStore.grid.tabletAndUnder) {
    val = 'grid-template-columns: repeat(6, minmax(60px, 1fr)); gap: 0.2rem'
  }

  if (applicationStore.grid.smaller) {
    val = 'grid-template-columns: repeat(4, minmax(60px, 1fr)); gap: 0.2rem'
  }

  return val
})

const optionsBudgetYearsComputed = computed(() => {
  return reportBudget.dataRawBudgetYears
})

function onYearChange(event) {
  reportCore.currentDate.year = event.year
  reportCore.currentDate.invalidate()
  //check if currently a range is in use
  if (!monthSelectedMultiStartItem && monthSelectedMultiEndItem) {
    reportCore.dateRangeStart.getValuesFromDate(
      new Date(
        reportCore.currentDate.year,
        reportCore.dateRangeStart.month,
        reportCore.dateRangeStart.day
      )
    )
    reportCore.dateRangeEnd.getValuesFromDate(
      new Date(reportCore.currentDate.year, reportCore.dateRangeEnd.month + 1, 0)
    )
  } else {
    reportCore.dateForMonth(
      new Date(reportCore.currentDate.year, reportCore.dateRangeStart.month, 1)
    )
  }

  reportBudget.buildReport()
  
}

function selectMonthSingle(month, index) {
  //reset multi selections
  if (monthSelectedMultiStartItem) {
    monthSelectedMultiStartItem.selected = false
    monthSelectedMultiStartItem = null
  }
  if (monthSelectedMultiEndItem) {
    monthSelectedMultiEndItem.selected = false
    monthSelectedMultiEndItem = null
  }

  //handle single selects
  month.selected = !month.selected
  if (monthSelectedSingleItem.value) {
    monthSelectedSingleItem.value.selected = false
  }
  monthSelectedSingleItem.value = month

  //load data
  reportCore.dateForMonth(new Date(reportCore.currentDate.year, index, 1))
  reportBudget.buildReport()
  
}

const isSingleMonth = computed(() => {
  return monthSelectedSingleItem.value !== null && monthSelectedSingleItem.value !== undefined
})

function selectMonthMulti(month, index) {
  if (!selectMonthSinglePending) {
    if (monthSelectedSingleItem.value) {
      monthSelectedSingleItem.value.selected = false
      monthSelectedSingleItem.value = null
    }

    //deselect all ui for months in range
    for (let i = 0; i < monthSelectMultiOptions.length; i++) {
      monthSelectMultiOptions[i].selected = false
    }

    monthSelectedMultiStartItem = null
    monthSelectedMultiEndItem = null

    month.selected = !month.selected

    monthSelectedMultiStartItem = month
    selectMonthSinglePending = true
    selectMonthSingleFirstIndex = index

    return
  }

  //check which index is higher as months can be selected in any order
  let startIndex = selectMonthSingleFirstIndex
  let endIndex = index
  if (endIndex < startIndex) {
    startIndex = index
    endIndex = selectMonthSingleFirstIndex
  }

  month.selected = !month.selected
  //in case someone selects the same month for start and end
  if (endIndex === startIndex) {
    month.selected = true
  }

  //select all ui for months in range
  for (let i = startIndex; i < endIndex + 1; i++) {
    monthSelectMultiOptions[i].selected = true
  }

  monthSelectedMultiEndItem = month
  selectMonthSinglePending = false

  reportCore.dateRangeStart.getValuesFromDate(new Date(reportCore.currentDate.year, startIndex, 1))

  reportCore.dateRangeEnd.getValuesFromDate(new Date(reportCore.currentDate.year, endIndex + 1, 0))

  //load data

  reportBudget.buildReport()
  
}

const monthSelectSingleOptions = reactive([
  { name: 'jan', selected: false },
  { name: 'feb', selected: false },
  { name: 'mar', selected: false },
  { name: 'apr', selected: false },
  { name: 'may', selected: false },
  { name: 'jun', selected: false },
  { name: 'jul', selected: false },
  { name: 'aug', selected: false },
  { name: 'sep', selected: false },
  { name: 'oct', selected: false },
  { name: 'nov', selected: false },
  { name: 'dec', selected: false }
])

const monthSelectMultiOptions = reactive([
  { name: 'jan', selected: false },
  { name: 'feb', selected: false },
  { name: 'mar', selected: false },
  { name: 'apr', selected: false },
  { name: 'may', selected: false },
  { name: 'jun', selected: false },
  { name: 'jul', selected: false },
  { name: 'aug', selected: false },
  { name: 'sep', selected: false },
  { name: 'oct', selected: false },
  { name: 'nov', selected: false },
  { name: 'dec', selected: false }
])



const optionsAllOwners = ref([{ name: 'Owners' }, { name: 'Lead Groups' }])
const optionsOwner = ref([{ name: 'Owners' }, { name: 'Lead Groups' }])
const currentTableSelection = ref(null)
const currentTableSelection2 = ref(null)

const applicationStore = useApplicationStore()

//const ownerTableFilterCurrent = ref('legacy')

async function onOwnerTableFilterChange(key) {
  reportBudget.ownerFilter = null

  reportBudget.leadGroupFilter = null
  reportBudget.locationFilter = null

  reportBudget.buildReport()
  
}

//-------------------------------------------------------------------

function onUseDataSourceOverrideChanged() {
  reportBudget.loadAllData()
}

function onDateSelectionBlend() {
  reportCore.dataBlendDate.getValuesFromDate(reportCore.dateSelectionBlend)
  if (reportCore.useDataSourceOverride) {
    reportBudget.loadAllData()
  }
}

function onKPIRouteKeyChange(val) {
  reportCore.dataSourceCurrent = val
  if (reportCore.useDataSourceOverride) {
    reportBudget.loadAllData()
  }
}

onMounted(() => {
  console.log(`on mounted in ReportBudgetView`)
  reportCore.reset()
  reportBudget.setChartOptions()
  //chartOptions.value = setChartOptions()
  

  reportBudget.ownerTableFilterCurrent = reportBudget.ownerTableFilterValueALL
  monthSelectedSingleItem.value = monthSelectSingleOptions[reportCore.currentDate.month]
  monthSelectedSingleItem.value.selected = true
  dateSelectionMonth.value = new Date(reportCore.currentDate.year, reportCore.currentDate.month, 1)
  reportCore.dateForMonth(dateSelectionMonth.value)

 

  if (auth.hasPermission('read_budget_sale_report_all_owners')) {
    currentTableSelection.value = { name: 'Owners' }
    currentTableSelection2.value = { name: 'Lead Groups' }
  } else {
    currentTableSelection.value = { name: 'Owners' }
    currentTableSelection2.value = { name: 'Lead Groups' }
  }

  invalidateRangeCalenderKey.value++
  applicationStore.scollToTop()
  reportBudget.loadAllData(routeKeyCurrent.value)
})



//----------------------------------------------------------------------

const noTableFiltering = computed(() => {
  return !reportBudget.ownerFilter && !reportBudget.locationFilter && !reportBudget.leadGroupFilter
})

//----------------------------------------------------------------------

const options = computed(() => {
  let optionsToUse = optionsOwner.value
  if (auth.hasPermission('read_budget_sale_report_all_owners')) {
    optionsToUse = optionsAllOwners.value
  }
  return optionsToUse
})

//----------------------------------------------------------------------

const getTableContainerStyle = computed(() => {
  return applicationStore.grid.smaller ? 'flex:1 1 0;width:100%' : 'flex:1 1 0;max-width:49%'
})

//----------------------------------------------------------------------

const getDateFiltersClass = computed(() => {
  return {
    'justify-content-left': !applicationStore.grid.tabletAndUnder,
    'justify-content-center': applicationStore.grid.tabletAndUnder,
    'flex-column': applicationStore.grid.smaller
  }
})

//----------------------------------------------------------------------

const getDebugTableStyle = computed(() => {
  return applicationStore.grid.tabletAndUnder
    ? ' flex:1 1 0;width:100%'
    : 'flex:1 1 0;max-width: 49%;'
})




//const setChartOptions = 

</script>
