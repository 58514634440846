<template>
<div class="width-100 height-100">
<div :class="getClassText">{{ componentData.display.label }}</div>
     <div
          v-if="getHasThumb"
          :style="getThumbStyle"
          :class="getClassThumb"
        ></div>

</div>
   
</template>

<script setup>

import { computed } from "vue";
import { useRequests } from "@/stores/Requests.js";
const requests = useRequests();
const props = defineProps({
  componentData: {
    type: Object,
    default: () => {
      return null;
    },
  },
  styling: {
    type: Object,
    default: () => {
      return null;
    },
  },
  
});
let getThumbStyle = computed(() => { 
  if(props.componentData.display.thumb){
  return "background-image:url('"+window.location_url+props.componentData.display.thumb +"')";
  }
  if(props.componentData.display.color){
  return "background-color:"+props.componentData.display.color;
  }
  return null;
 
});
let getClassText = computed(() => {
  return props.componentData.enabled
    ? props.styling?.classStringTextSelected ?? props.componentData.styling.classStringTextSelected
    : props.styling?.classStringTextDefault ?? props.componentData.styling.classStringTextDefault;
});

    let getClassIcon = computed(() => {
  return props.componentData.enabled
    ? props.styling?.classStringIconSelected ?? props.componentData.styling.classStringIconSelected
    : props.styling?.classStringIconDefault ?? props.componentData.styling.classStringIconDefault;
});

 let getClassThumb = computed(() => {
  return props.componentData.enabled
    ? props.styling?.classStringThumbSelected ?? props.componentData.styling.classStringThumbSelected
    : props.styling?.classStringThumbDefault ?? props.componentData.styling.classStringThumbDefault;
});
let getHasThumb = computed(() => {
  return props.styling?.classStringThumbDefault ??  props.componentData.styling.classStringThumbDefault;
});
</script>

