
export function useButtonInteraction() {
   
  const data = {
   
      toggle:true,          
      radioGroup:null,     
      action:null
    
     
        };


return data;
}