import { useComponentData } from "@/scripts/ui/ComponentData";

export function useContainer() {
  let data = {};
  data.component = "ContainerComponent";
  let attr = (data.attributes = {});
  attr.componentData = useComponentData();
  
   attr.componentData.enabled = true; 
  attr.componentData.children = [];

  return data;
}
