import { useReportCore } from '@/stores/ReportCore.js'

export function useScoreCardBudgetPredictiveIndex(title, reportBudget,rollingKey) {
    const reportCore = useReportCore()

    let data = {}
    data.title = title

    let predictiveIndex = 0
    let estimatedAppointments = 0
    let estimatedSoldJobs = 0

    let appointmentCount22 = 0
    let appointmentCountDynamicRolling = 0
    let salesSumDynamicRolling = 0
    let salesCountDynamicRolling = 0
    let budget = 0

    let dynamicRollingKey = `dataRolling${rollingKey}`


    for (let ownerName in reportBudget.tableDataOwnersObj) {
        let ownerObj = reportBudget.tableDataOwnersObj[ownerName]

        let appointmentCount22Temp = ownerObj.dataRolling22.APPOINTMENTS_COUNT || 0
        let appointmentCountDynamicRollingTemp = ownerObj[dynamicRollingKey].APPOINTMENTS_COUNT || 0
        let salesSumDynamicRollingTemp = ownerObj[dynamicRollingKey].SALES_SUM || 0
        let salesCountDynamicRollingTemp = ownerObj[dynamicRollingKey].SALES_COUNT || 0
        let budgetTemp = ownerObj.data.BUDGET || 0


        if (ownerObj.ignore || !ownerObj.isMapped) {
            continue
        }

        if (ownerObj.ownerType === reportBudget.ownerTableFilterCurrent || reportBudget.ownerTableFilterCurrent === reportBudget.ownerTableFilterValueALL) {

            appointmentCount22 += appointmentCount22Temp || 0
            appointmentCountDynamicRolling += appointmentCountDynamicRollingTemp || 0
            salesSumDynamicRolling += salesSumDynamicRollingTemp || 0
            salesCountDynamicRolling += salesCountDynamicRollingTemp || 0
            budget += budgetTemp|0


        }

    }

   /* console.log(`appointmentCount22 ${appointmentCount22}`)
    console.log(`appointmentCountDynamicRolling ${appointmentCountDynamicRolling}`)
    console.log(`salesCountDynamicRolling ${salesCountDynamicRolling}`)
    console.log(`salesSumDynamicRolling ${salesSumDynamicRolling}`)
    console.log(`appointment close rate ${salesCountDynamicRolling/appointmentCountDynamicRolling}`)
    console.log(`avg job ${salesSumDynamicRolling/salesCountDynamicRolling}`)
    console.log(`budget ${budget}`)*/
    estimatedAppointments = (appointmentCount22/22*30)
    //console.log(`estimatedAppointments ${estimatedAppointments}`)

    estimatedSoldJobs = (estimatedAppointments * (salesCountDynamicRolling/appointmentCountDynamicRolling))
   // console.log(`estimatedSoldJobs ${estimatedSoldJobs}`)

    predictiveIndex = (estimatedSoldJobs * (salesSumDynamicRolling/salesCountDynamicRolling))


    data.value = predictiveIndex ?? 0
    data.valueSub1 = estimatedAppointments|0 ?? 0
    data.valueSub2 = estimatedSoldJobs|0 ?? 0

    if (isNaN(data.value) || !data.value || !isFinite(data.value)) {
        data.value = 0
    }
    if (isNaN(data.valueSub1) || !data.valueSub1 || !isFinite(data.valueSub1)) {
        data.valueSub1 = 0
    }
    if (isNaN(data.valueSub2) || !data.valueSub2 || !isFinite(data.valueSub2)) {
        data.valueSub2 = 0
    }

    data.valueFormat = 'USD'
    data.valueSub1Format = 'INT'
    data.valueSub2Format = 'INT'
    data.valueSub1Valid = true
    data.valueSub2Valid = true
    data.metricSub1Valid = false
    data.metricSub2Valid = true

    data.metricSub2 = (data.value / budget)-1
    

    data.metricSub1Threshold = 0
    data.metricSub2Threshold = 0

    if (isNaN(data.metricSub2) || !data.metricSub2 || !isFinite(data.metricSub2)) {
        data.metricSub2Valid = false
    }

  /*  if (isNaN(data.metricSub1) || !data.metricSub1 || !isFinite(data.metricSub1)) {
        data.valueSub1Valid = false
    }

    if (isNaN(data.metricSub2) || !data.metricSub2 || !isFinite(data.metricSub2)) {
        data.valueSub2Valid = false
    }*/

    return data
}
