<template>
  <div :style="getStyle" :class="getClassButton">
    <component
      @select="onChildSelect"
      :is="componentDataLocal.value.content"
      v-bind="{ componentData: componentDataLocal.value,styling:styling }"
    >
    </component>
  </div>
</template>
<script setup>
import { reactive, watch, computed } from "vue";


const emit = defineEmits(["select"]);
const props = defineProps({
  componentData: {
    type: Object,
    default: () => {
      return null;
    },
  },
  styling: {
    type: Object,
    default: () => {
      return null;
    },
  },
  keyref: {
    type: String,
    default: "",
  },
});
const componentDataLocal = reactive({ value: props.componentData });
watch(
  () => props.componentData,
  () => {
    componentDataLocal.value = { ...props.componentData };
  }
);

let getClassButton = computed(() => {
  return componentDataLocal.value.enabled
    ? props.styling?.classStringContainerSelected ?? componentDataLocal.value.styling.classStringContainerSelected
    : props.styling?.classStringContainerDefault ?? componentDataLocal.value.styling.classStringContainerDefault;
});

let getStyle = computed(() => {
  return "cursor:default !important";
});

function onChildSelect(payload, event) {
  emit("select", payload, event);
}
</script>
