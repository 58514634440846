import pinia from "@/stores/index.js";
import { useAuth } from '@/stores/Auth.js';
export default function auth( to, from ) { 
  const auth = useAuth()
//console.log(user)
//console.log(to.meta.permission_page_view)
 if(!auth.hasPermission(to.meta.permission_page_view)){
    return {
        path: "/application/access-denied",
      };
 }
  

  return true;
}
