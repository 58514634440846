<template>
  <div :class="getClassDynamic" style="width: 100%; ">

    <DataTable :metaKeySelection="false" selectionMode="single" @rowSelect="onSelect" @rowUnselect="onUnselect"
      :sortField="sortFieldDefault" :sortOrder="sortOrderDefault" class="p-datatable-sm" scrollable :scrollHeight="reportCore.getTableScollHeight"
      paginator :rows="200" paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink"
      currentPageReportTemplate="{first} to {last} of {totalRecords}" :value="tableData" :tableStyle="getTableStyle">
      <template #paginatorstart>
        <button class="button-minimal" @click="refresh">
          <span style="font-size: 30px;color:#fff" class="mdi icon nav-color-1 mdi-refresh"></span>
        </button>
      </template>
      <Column v-for="col of tableHeaders" style="min-width: 100px" sortable :key="col.field" :field="col.field"
        :header="col.header.toLowerCase()">

        <template #body="{ data, field }">
          <div :style="getColumnFieldColor(data, field)">
            <div v-if="field === 'Owner'" class="d-flex align-items-center gap-2">

              <div class="circle" :style="getIconNameFieldColor(data, field)">
                {{ formatIconNameField(data, field) }}
              </div>

              <span>{{ formatField(data, field) }}</span>
            </div>
            <div v-else>
              {{ formatField(data, field) }}
            </div>

          </div>


        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, reactive, watch } from 'vue'
import { useReportCore } from '@/stores/ReportCore.js'
import { useApplicationStore } from "@/stores/ApplicationStore.js";
const applicationStore = useApplicationStore()
const emit = defineEmits(['select', 'unselect', 'refresh']);
const props = defineProps({

  componentData: {
    type: Object,
    default: null,
  },
  classDynamic: {
    type: String,
    default: ""
  },
  sortField: {
    type: String,
    default: null
  },
  sortOrder: {
    type: Number,
    default: -1
  }


});








const tableStyle = ref("")

const getTableStyle = computed(() => {

  return applicationStore.grid.tabletAndUnder ? "font-size:13px" : "font-size:14px";
})


const getClassDynamic = computed(() => {

  let ob = {
    'pa-0': applicationStore.grid.breakpoint === 'xs',
    'pa-4': applicationStore.grid.breakpoint !== 'xs',
    'rounded-8': !applicationStore.grid.tabletAndUnder,
    'elevation-6': !applicationStore.grid.tabletAndUnder

  }
  ob[props.classDynamic] = true
  return ob
})




const reportCore = useReportCore()

const tableData = computed(() => {

  return props.componentData?.tableData ?? []
})
const tableHeaders = computed(() => {
  return props.componentData?.tableHeaders ?? []
})

const sortFieldDefault = computed(() => {
  return props.sortField
})

const sortOrderDefault = computed(() => {
  return props.sortOrder
})






const onSelect = (event) => {
  emit("select", event);
}
const onUnselect = (event) => {
  emit("unselect", event);

}

function refresh() {

  emit("refresh");
}

function formatHead(slotProps) {
  console.log(slotProps)
  console.log(slotProps.column.props.header)
  slotProps.column.props.header = slotProps.column.props.header.toLowerCase()




}

function getColumnFieldColor(data, field) {
  if (field !== "Owner") {
    return 'background:transparent !important'
  } else {
    let systemUser = reportCore.dataOwners[data[field]]
    if (systemUser) {
      return 'background:transparent !important'
    } else {
      return 'background:#496689 !important'
    }
  }

}

function getIconNameFieldColor(data, field) {
  let systemUser = reportCore.dataOwners[data[field]]
  let style = null;

  if (reportCore.useDataSourceOverride) {
    style = 'background-color:#222222;'
  }else{

    let dataSourceToUse = systemUser?.UserDefaultDataSource?.value || reportCore.DATA_SOURCE_BLEND

if (dataSourceToUse === reportCore.DATA_SOURCE_CORE) {
  style = 'background-color:#db3838;'
}
if (dataSourceToUse === reportCore.DATA_SOURCE_SM) {
  style = 'background-color:#ff8f37;'
}

if (dataSourceToUse === reportCore.DATA_SOURCE_BLEND) {
  style = 'background-color:#2986c4;'


}


  }

 





  return style
}

function formatIconNameField(data, field) {

  let systemUser = reportCore.dataOwners[data[field]]
  let val = null;
  let dataSourceToUse = null
  if (reportCore.useDataSourceOverride) {
    dataSourceToUse = reportCore.dataSourceCurrent
  } else {
    dataSourceToUse = systemUser?.UserDefaultDataSource?.value || reportCore.DATA_SOURCE_BLEND
  }

  if (dataSourceToUse === reportCore.DATA_SOURCE_CORE) {
    val = 'C'
  }
  if (dataSourceToUse === reportCore.DATA_SOURCE_SM) {
    val = 'S'
  }

  if (dataSourceToUse === reportCore.DATA_SOURCE_BLEND) {
    val = 'B'


  }

  return val
}

function formatField(data, field) {


  let val = data[field] ?? '';

  if (val != '' && reportCore.valueFormatsMap[field] === "USD") {
    val = reportCore.formatCurrency(val)
  }
  if (val != '' && reportCore.valueFormatsMap[field] === "%") {
    val = reportCore.formatPercent(val)
  }
  if (val != '' && reportCore.valueFormatsMap[field] === "%ROI") {
    val = reportCore.formatPercentROI(val)
  }
  if (val != '' && reportCore.valueFormatsMap[field] === "INT") {
    val = Math.round(val)
  }
  if (val != '' && reportCore.valueFormatsMap[field] === "FLOAT") {
    val = reportCore.formatFloat(val)
  }
  if (val != '' && reportCore.valueFormatsMap[field] === "FLOATX100") {
    val = reportCore.formatPercentWithoutSymbol(val)
  }
  if (val != '' && reportCore.valueFormatsMap[field] === "INTX100") {
    val = reportCore.formatIntX100(val)
  }

  
  if (val != '' && reportCore.valueFormatsMap[field] === "GRADE") {
   
    val = reportCore.formatGrade(val)
   
  }

  return val


}


</script>

<style>
.p-datatable-wrapper {
  min-height: 400px;

}

.p-datepicker table {
  font-size: 0.8rem;

}

.p-datepicker table td {
  padding: 0.3rem;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  color: white;
  font-size: 10px;
  font-weight: 600;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
}
</style>