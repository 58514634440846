import { useReportCore } from '@/stores/ReportCore.js'

export function useScoreCardBudgetVariance(title, reportBudget, key) {
  const reportCore = useReportCore()

  let data = {}
  data.title = title

  let valueCurrent = null
  let valueCurrentYOY = null
  let valueSub1 = null
  let valueSub2 = null
 

  valueSub1 = 0
  valueSub2 = 0
  valueCurrentYOY = 0

  valueCurrent = 0

  for (let ownerName in reportBudget.tableDataOwnersObj) {
    let ownerObj = reportBudget.tableDataOwnersObj[ownerName]
  // console.log(ownerObj)
    if (ownerObj.ignore) {
      continue
    }
    let salesSum = ownerObj.data.SALES_SUM || 0
    

    if (key === reportBudget.ownerTableFilterValueLEGACY) {
      if (ownerObj.ownerType === reportBudget.ownerTableFilterValueLEGACY) {
      
        valueCurrent += salesSum || 0       
       
      }
    }
    if (key === reportBudget.ownerTableFilterValueNEW) {
      if (ownerObj.ownerType === reportBudget.ownerTableFilterValueNEW) {
      
        valueCurrent += salesSum || 0       
       
      }
    }
    if (key === reportBudget.ownerTableFilterValueALL) {
           
          valueCurrent += salesSum || 0    
      
    }
   

  } 

  for (let ownerName in reportBudget.tableDataOwnersBudgetsObj) {
    let ownerBudgetItem = reportBudget.tableDataOwnersBudgetsObj[ownerName]

    let mustProcess = false;
    if (!reportBudget.ownerFilter && !reportBudget.leadGroupFilter) {
      if (ownerBudgetItem.ownerType === key || key === reportBudget.ownerTableFilterValueALL) {
        mustProcess = true;
      }

    }

    if (reportBudget.ownerFilter) {
      mustProcess = true;
    }

    if (!reportBudget.ownerFilter && reportBudget.leadGroupFilter) {
      if (ownerBudgetItem.ownerType === reportBudget.ownerTableFilterCurrent || reportBudget.ownerTableFilterCurrent === reportBudget.ownerTableFilterValueALL) {
        mustProcess = true;
      }

    }
    if (mustProcess) {
      for (let budgetYearKey in ownerBudgetItem.budgetStore) {
        let budgetYear = ownerBudgetItem.budgetStore[budgetYearKey]
        for (let budgetMonthKey in budgetYear) {
          let budgetMonth = budgetYear[budgetMonthKey]
          valueSub2 += budgetMonth.value
        }
      }
    }
  }

  data.value = (valueCurrent-valueSub2) ?? 0
  data.valueSub2 = valueSub2

  if (isNaN(data.value) || !data.value || !isFinite(data.value)) {
    data.value = 0
  }
  if (isNaN(data.valueSub1) || !data.valueSub1 || !isFinite(data.valueSub1)) {
    data.valueSub1 = 0
  }
  if (isNaN(data.valueSub2) || !data.valueSub2 || !isFinite(data.valueSub2)) {
    data.valueSub2 = 0
  }

  data.valueFormat = 'USD'
  data.valueSub1Valid = true
  data.valueSub2Valid = true

  

  data.metricSub2 =  (data.value / valueSub2)

  data.metricSub1Threshold = 0
  data.metricSub2Threshold = 0

  if (isNaN(data.metricSub1) || !data.metricSub1 || !isFinite(data.metricSub1)) {
    data.valueSub1Valid = false
  }

  if (isNaN(data.metricSub2) || !data.metricSub2 || !isFinite(data.metricSub2)) {
    data.valueSub2Valid = false
  }

  return data
}
