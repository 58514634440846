import { defineStore } from 'pinia'
import {  markRaw } from 'vue'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
export const useLoadingOverlayStore = defineStore('LoadingOverlayStore', {
  state: () => {
    return {
        applicationStore: markRaw(useApplicationStore()),

      loadingHeavy: false,
      loadingHeavyInfo: null,
      loadingHeavyTitle: null,
      loadingHeavyTitleDefault: 'loading',
      loadingHeavyInfoDefault: '...',
      
      loadingCounter:0
     
    }
  },

  actions: {
   

    setLoadHeavy(value, info,title) {
      if (value) {
        this.loadingCounter++;
        this.loadingHeavyTitle = title || this.loadingHeavyTitleDefault
        this.loadingHeavyInfo = info || this.loadingHeavyInfoDefault
        this.applicationStore.blurContent(true)
      } else {
        this.loadingCounter--;
        this.applicationStore.blurContent(false)
      }
     // console.log(` this.loadingCounter ${ this.loadingCounter}`)

      if(this.loadingCounter <= 0){
        this.loadingCounter = 0;// should not have to do this , only gets out of sync if remove load is called more than once for a related load
      this.loadingHeavy = false
      }else{
        this.loadingHeavy = true
      }
    },

   
  }
})
