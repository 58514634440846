<template>
  <div v-if="auth.showComponent" id="global-cover">
    <div
      class="w-100 d-flex pa-4 flex-column justify-content-center"
      style="
        background-color: #fff;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
      "
    >
      <div class="text-center fs-4 text-type-10 p-0">Dormant Session</div>
      <div class="text-center text-type-11 p-0 mb-2">
        Your session is close to expiring , log out or refresh
      </div>
      <div class="d-flex justify-content-center gap-3 mt-2">
        <button class="button-minimal" @click="auth.logout">
          <div class="fs-10 fw-bolder nav-color-1">logout</div>
        </button>
        <button class="button-minimal" @click="auth.refreshToken">
          <div class="fs-10 fw-bolder nav-color-1">refresh</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuth } from '@/stores/Auth.js'
import { onUnmounted } from 'vue'
const auth = useAuth()


</script>
