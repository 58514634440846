import { nextTick } from 'vue'
export async function useScrollToElement(elementItem, elementScroll) {
  // since setting dom element scroll to smooth , math of positions broke due to interpolation over time
  //using next tick seems to be enough for it to get the correct values
  //keep an eye on it , if it fails again .. then scroll-behavior: smooth; needs to be removed.
  if (!elementItem) return
  await nextTick()
  let scrollDistance = 0

  while (elementItem) {
    scrollDistance += elementItem.offsetTop
    elementItem = elementItem.offsetParent
    if (elementItem === elementScroll) {
      break
    }
  }

  elementScroll.scrollTo(0, scrollDistance)
}
