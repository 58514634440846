<template>
<div :class="getClassText">{{ componentData.display.label }}</div>   
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  componentData: {
    type: Object,
    default: () => {
      return null;
    },
  },
  styling: {
    type: Object,
    default: () => {
      return null;
    },
  },
  
});

let getClassText = computed(() => {
  return  props.styling?.classStringTextDefault ?? props.componentData.styling.classStringTextDefault;
});


</script>

: